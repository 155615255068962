import * as React from "react";
import { Amount, Unit } from "uom";
import { useDebounce } from "./hooks/use-debounce";
import { PropertySelectorInputContainer } from "./styled-textfield";

export interface AmountInputBoxProps {
  readonly key?: string;
  readonly value: Amount.Amount<unknown> | undefined;
  readonly unit: Unit.Unit<unknown>;
  readonly decimalCount: number;
  readonly notNumericMessage: string;
  readonly isRequiredMessage: string;
  readonly errorMessage: string;
  readonly isValid?: boolean;
  readonly readOnly: boolean;
  readonly onValueChange: (newAmount: Amount.Amount<unknown> | undefined) => void;
  readonly debounceTime: number;
  readonly index?: number;
}

export interface State {
  readonly textValue: string;
  readonly hasFocus: boolean;
}

// eslint-disable-next-line functional/no-class
export function AmountInputBox(props: AmountInputBoxProps): JSX.Element {
  const [hasFocus, setHasFocus] = React.useState(false);
  const [textValue, setTextValue] = React.useState(getString(props.value, props.decimalCount, props.unit));

  React.useEffect(() => {
    if (!hasFocus) {
      setTextValue(getString(props.value, props.decimalCount, props.unit));
    }
  }, [hasFocus, props.value, props.decimalCount, props.unit]);

  const getInputErrorMessage = (): string | undefined => {
    const { isRequiredMessage, unit, notNumericMessage } = props;
    const amount = getAmount(textValue, unit, props.decimalCount);
    if (textValue.trim() === "" && isRequiredMessage) {
      return isRequiredMessage;
    } else if (textValue.trim() !== "" && !amount && isRequiredMessage) {
      return notNumericMessage;
    }
    return undefined;
  };

  const onValueChange = React.useCallback(
    (onChangeValue?: string): void => {
      const newValue = onChangeValue ?? textValue;
      setTextValue(newValue);
      const errorMessage = getInputErrorMessage();
      const valueChanged = getString(props.value, props.decimalCount, props.unit) !== newValue;
      if (!errorMessage && valueChanged) {
        const amount = getAmount(newValue, props.unit, props.decimalCount);
        props.onValueChange(amount);
      }
    },
    [textValue, props.value, props.unit.name, props.decimalCount]
  );
  const debouncedOnValueChange = useDebounce(() => onValueChange(), props.debounceTime);

  const errorMessage = getInputErrorMessage() || props.errorMessage;

  return (
    <PropertySelectorInputContainer
      value={textValue}
      onChange={(e) => {
        setTextValue(e.currentTarget.value);
        debouncedOnValueChange.debouncedFunc();
      }}
      valid={!errorMessage}
      disabled={props.readOnly}
      onFocus={() => setHasFocus(true)}
      onBlur={() => {
        debouncedOnValueChange.cancel();
        onValueChange(textValue);
        setHasFocus(false);
      }}
    />
  );
}

function getString(amount: Amount.Amount<unknown> | undefined, decimalCount: number, unit: Unit.Unit<unknown>): string {
  if (!amount) {
    return "";
  }
  const valueToUse = Amount.valueAs(unit, amount);
  return valueToUse.toFixed(decimalCount);
}

function getAmount(text: string, unit: Unit.Unit<unknown>, decimalCount: number): Amount.Amount<unknown> | undefined {
  if (!text || text.length === 0) {
    return undefined;
  }
  const parsedFloatValue = parseFloat(text);
  if (!Number.isFinite(parsedFloatValue)) {
    return undefined;
  }
  const decimals = getDecimalCountFromString(text);
  return Amount.create(parsedFloatValue, unit, decimalCount ?? decimals);
}

function getDecimalCountFromString(stringValue: string): number {
  const decimalIndex = stringValue.replace(",", ".").indexOf(".");
  if (decimalIndex >= 0) {
    return stringValue.length - decimalIndex - 1;
  }
  return 0;
}
