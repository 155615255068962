// Getting a user from the OIDC token is managed in this file
// We need to handle creating a User from the token in the Client and Server
// The token looks a little different but the information in it is the same
import { PropertyValue, PropertyValueSet, PropertyValueSet as PVS } from "@promaster-sdk/property";
import { Amount } from "uom";
import { EhbUnits } from "../units";

export type ActiveUser = {
  readonly externalId: string;
  readonly userName: string;
  readonly email: string;
  readonly accessToken: string;
  readonly customerNumber?: string;
  readonly companyName?: string;
  readonly customerNumberApproved?: boolean;
  readonly claims: UserClaims;
};
export type UserClaims = { readonly [key: string]: string | boolean | number } & {
  readonly user_type: string;
  readonly customer_number: string;
  readonly company_name: string;
  readonly activated_date: string;
  readonly default_discount: string;
  readonly is_admin: boolean;
  readonly responsible_email: string;
  readonly currency: string;
};
export interface UntypedToken {
  readonly [key: string]: string;
}
export type DecodedToken = UntypedToken;

export type UserError = ActiveUserError | UserNeedApprovalError | UserExpiredError;
export type ActiveUserError = {
  readonly type: "Error";
  readonly reason: string;
};
export type UserNeedApprovalError = {
  readonly type: "UserNeedApproval";
  readonly reason: string;
};

export type UserExpiredError = {
  readonly type: "UserExpired";
  readonly reason: string;
};
export type ActiveUserReason = "Error" | "UserNeedApproval" | "UserExpired";

interface ClientToken {
  readonly profile: unknown;
}
type ServerToken = UserClaims & { readonly [key: string]: string };

export function buildActiveUser(token: ClientToken | ServerToken, accessToken: string): ActiveUser | UserError {
  if (isClientToken(token)) {
    const claims = token.profile as UntypedToken & UserClaims;
    const claimesWithUser = { ...claims };
    const user: ActiveUser = {
      externalId: claims.sub,
      userName: claims["name"],
      email: claims["email"],
      companyName: claims["company_name"],
      accessToken: accessToken,
      claims: claimesWithUser,
    };
    return validateUser(user);
  } else {
    const user = {
      externalId: token["sub"],
      userName: token["name"],
      email: token["email"],
      accessToken: accessToken,
      customerNumber: token["ehb_customer_number"],
      companyName: token["company_name"],
      claims: token,
    };
    return validateUser(user);
  }
}

function validateUser(user: ActiveUser): ActiveUser | UserError {
  const todayTimeStamp = new Date().getTime() / 1000;
  const activatedDateTimeStamp = new Date(user.claims.activated_date).getTime() / 1000; // 1000 ;
  const secondsInYear = 31556926;
  const diffInSeconds = todayTimeStamp - activatedDateTimeStamp;
  if (user.claims.activated_date === "" || user.claims.activated_date === undefined) {
    return { type: "UserNeedApproval", reason: "Your account is waiting for approval" };
  } else if (diffInSeconds > secondsInYear) {
    return { type: "UserExpired", reason: "Your account has been deactivated." };
  } else {
    return user;
  }
}

export function isValidUser(activeUser: ActiveUser | UserError): activeUser is ActiveUser {
  if ((activeUser as UserError).type !== undefined) {
    return false;
  }

  return true;
}

export function getApprovedCustomerNumber(activeUser: ActiveUser): string | undefined {
  if (activeUser.customerNumber && activeUser.customerNumberApproved) {
    return activeUser.customerNumber;
  } else {
    return undefined;
  }
}

function isClientToken(token: ClientToken | ServerToken): token is ClientToken {
  if ((token as ClientToken).profile !== undefined) {
    return true;
  }

  return false;
}

export function isInternalUser(userClaims: UserClaims): boolean {
  return userClaims.user_type === "internal" ? true : false;
}

export function isExternalUser(userClaims: UserClaims): boolean {
  return userClaims.user_type === "external" ? true : false;
}

export function isEndCustomer(userClaims: UserClaims): boolean {
  return userClaims.user_type === "endcustomer" ? true : false;
}

export function canChangeCurrencyOrDiscount(userClaims: UserClaims): boolean {
  return isInternalUser(userClaims);
}

const userClaimProperties = {
  defaultDiscount: "discount",
  currency: "currency",
};

export function updateUserClaimProperties(
  userClaims: UserClaims,
  selectedProperties: PVS.PropertyValueSet
): PVS.PropertyValueSet {
  let pvs = selectedProperties;

  if (isInternalUser(userClaims)) {
    pvs = PVS.setInteger("user_type", 1, pvs);
  } else if (isExternalUser(userClaims)) {
    pvs = PVS.setInteger("user_type", 2, pvs);
  } else {
    pvs = PVS.setInteger("user_type", 3, pvs);
  }

  if (
    !canChangeCurrencyOrDiscount(userClaims) ||
    !PropertyValueSet.hasProperty(userClaimProperties.defaultDiscount, pvs)
  ) {
    pvs = PVS.set(
      userClaimProperties.defaultDiscount,
      PropertyValue.create("amount", Amount.create(+userClaims.default_discount, EhbUnits.Percent)),
      pvs
    );
  }

  if (!canChangeCurrencyOrDiscount(userClaims) || !PropertyValueSet.hasProperty(userClaimProperties.currency, pvs)) {
    pvs = PVS.setInteger(userClaimProperties.currency, userClaims.currency === "SEK" ? 1 : 2, pvs);
  }

  return pvs;
}

export function getUserClaimPropertyNames(): ReadonlyArray<string> {
  return Object.values(userClaimProperties);
}
