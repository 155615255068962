import { Accessories } from "..";
import * as Types from "./types";
import * as Search from "../search";

export function calculatePrice(
  selectedProduct: Search.ProductVariantsRow,
  accessories: ReadonlyArray<Accessories.Accessory>,
  query: Search.Query,
  discount: number
): Types.Price {
  const isEUR = query.userCurrency.toUpperCase() === "EUR";
  const price = (isEUR ? selectedProduct.price_eur : selectedProduct.price_sek) || undefined;

  if (!price) {
    throw new Error(`Could not find price for selected product ${selectedProduct.model}`);
  }

  const accessoryPrice = accessories.reduce((s, c) => s + Number(isEUR ? c.price_eur : c.price_sek), 0);
  return { grossPrice: (price + accessoryPrice) * (1 - discount), priceType: isEUR ? "price_eur" : "price_sek" };
}
