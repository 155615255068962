import * as React from "react";
import * as THREE from "three";
import { A3D, MagicloudApi, Texts } from "@ehb/shared";
import { useCameraControl } from "./use-camera-control";

const standardLights = [
  A3D.lightCreate(A3D.vec3Create(-20, 20, 0), A3D.vec3Zero, A3D.white, 0.4),
  A3D.lightCreate(A3D.vec3Create(0, -20, 20), A3D.vec3Zero, A3D.white, 0.3),
  A3D.lightCreate(A3D.vec3Create(40, 10, 10), A3D.vec3Zero, A3D.white, 0.3),
  A3D.lightCreate(A3D.vec3Create(-40, -10, 10), A3D.vec3Zero, A3D.white, 0.3),
  A3D.lightCreate(A3D.vec3Create(20, 20, 40), A3D.vec3Zero, A3D.white, 0.4),
  A3D.lightCreate(A3D.vec3Create(20, -20, -40), A3D.vec3Zero, A3D.white, 0.4),
  A3D.lightCreate(A3D.vec3Create(10, 40, -40), A3D.vec3Zero, A3D.white, 0.4),
];

export type OnCameraUpdateFn = (camera: A3D.OrbitCamera) => void;
export interface RenderState {
  readonly width: number;
  readonly height: number;
  readonly element: Element | undefined;
  readonly sizeElement: React.MutableRefObject<HTMLDivElement | null>;
  readonly scene: THREE.Scene | undefined;
  readonly camera: THREE.PerspectiveCamera | undefined;
  readonly renderer: THREE.WebGLRenderer | undefined;
  readonly orbitCamera: A3D.OrbitCamera;
  readonly onCameraUpdate: OnCameraUpdateFn | undefined;
  readonly shouldRerender: boolean;
}

const defaultCamera = A3D.orbitCameraDefault(500);

export function Viewer3d(props: {
  readonly x3dModel: MagicloudApi.X3DModel;
  readonly orbitCamera?: A3D.OrbitCamera;
  readonly onCameraUpdate?: OnCameraUpdateFn;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  const [glNotSupported, setGlNotSupported] = React.useState(false);
  const [element, setElement] = React.useState<Element | null>(null);
  const sizeElement = React.useRef<HTMLDivElement | null>(null);

  const stateRef = React.useRef<RenderState>({
    width: 0,
    height: 0,
    element: undefined,
    sizeElement: sizeElement,
    scene: undefined,
    camera: undefined,
    renderer: undefined,
    orbitCamera: props.orbitCamera || defaultCamera,
    onCameraUpdate: props.onCameraUpdate,
    shouldRerender: false,
  });

  React.useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();
    stateRef.current = {
      ...stateRef.current,
      scene,
      camera,
      shouldRerender: true,
    };
  }, []);

  React.useEffect(() => {
    if (!props.orbitCamera) {
      return;
    }
    stateRef.current = {
      ...stateRef.current,
      orbitCamera: props.orbitCamera,
    };
  }, [props.orbitCamera]);

  React.useEffect(() => {
    stateRef.current = {
      ...stateRef.current,
      onCameraUpdate: props.onCameraUpdate,
    };
  }, [props.onCameraUpdate]);

  React.useEffect(() => {
    const { scene, orbitCamera } = createThreeScene(props.x3dModel);
    stateRef.current = {
      ...stateRef.current,
      scene,
      orbitCamera: props.orbitCamera || orbitCamera,
      shouldRerender: true,
    };
  }, [props.x3dModel]);

  const { onMouseMove, onMouseDown, onContextMenu, onMouseUp } = useCameraControl(stateRef);

  React.useEffect(() => {
    if (!element) {
      return () => undefined;
    }
    let renderer: THREE.WebGLRenderer | undefined = undefined;
    try {
      renderer = new THREE.WebGLRenderer({ antialias: true });
    } catch (e) {
      setGlNotSupported(true);
      return () => undefined;
    }
    renderer.setClearColor("#ffffff");
    element.appendChild(renderer.domElement);
    stateRef.current = {
      ...stateRef.current,
      renderer,
      element,
      shouldRerender: true,
    };
    startAnimation(stateRef);
    return () => {
      renderer?.dispose();
      stateRef.current = {
        ...stateRef.current,
        renderer: undefined,
      };
    };
  }, [element]);

  return glNotSupported ? (
    <div className="m-4 text-center">{props.translate(Texts.texts.webgl_not_available_message)}</div>
  ) : (
    <div className="relative w-full aspect-square cursor-grab" ref={sizeElement}>
      <div
        className="absolute"
        ref={setElement}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onContextMenu={onContextMenu}
      ></div>
    </div>
  );
}

function startAnimation(stateRef: React.MutableRefObject<RenderState>): void {
  function animate(): void {
    const { element, renderer, scene, camera, orbitCamera, sizeElement, shouldRerender, width, height } =
      stateRef.current;

    if (!element || !renderer) {
      return;
    }

    requestAnimationFrame(animate);

    const rect = sizeElement.current?.getBoundingClientRect();
    const newWidth = rect?.width || 0;
    const newHeight = rect?.height || 0;
    const sizeChanged = width !== newHeight || height !== newHeight;
    if (sizeChanged) {
      renderer.setSize(newWidth, newHeight);
      if (camera) {
        updateThreeCamera(camera, orbitCamera, newWidth, newHeight);
      }
      stateRef.current = {
        ...stateRef.current,
        width: newWidth,
        height: newHeight,
      };
    }

    if (!scene || !camera || (!shouldRerender && !sizeChanged)) {
      return;
    }

    if (camera) {
      updateThreeCamera(camera, orbitCamera, newWidth, newHeight);
    }

    renderer.render(scene, camera);

    stateRef.current = {
      ...stateRef.current,
      shouldRerender: false,
    };
  }

  requestAnimationFrame(animate);
}

function updateThreeCamera(
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
  a3dOrbitCamera: A3D.OrbitCamera,
  width: number,
  height: number
): void {
  const orbitCamera = A3D.orbitCameraToCamera(a3dOrbitCamera);
  camera.position.set(orbitCamera.position.x, orbitCamera.position.y, orbitCamera.position.z);
  camera.up.set(orbitCamera.up.x, orbitCamera.up.y, orbitCamera.up.z);
  camera.near = orbitCamera.near;
  camera.far = orbitCamera.far;
  if (
    (orbitCamera.lookAt.x !== 0 && !orbitCamera.lookAt.x) ||
    (orbitCamera.lookAt.y !== 0 && !orbitCamera.lookAt.y) ||
    (orbitCamera.lookAt.z !== 0 && !orbitCamera.lookAt.z)
  ) {
    camera.lookAt(0, 0, 0); // Workaround for a rare issue of lookAt.x being undefined
  } else {
    camera.lookAt(orbitCamera.lookAt.x, orbitCamera.lookAt.y, orbitCamera.lookAt.z);
  }
  if (camera.type === "OrthographicCamera" && orbitCamera.type === "OrthoCamera") {
    camera.left = orbitCamera.left;
    camera.right = orbitCamera.right;
    camera.top = orbitCamera.top;
    camera.bottom = orbitCamera.bottom;
  }
  if (camera.type === "PerspectiveCamera") {
    camera.aspect = height === 0 ? 1 : width / height;
  }
  camera.updateProjectionMatrix();
}

function createThreeScene(x3dModel: MagicloudApi.X3DModel): {
  readonly scene: THREE.Scene;
  readonly orbitCamera: A3D.OrbitCamera;
} {
  const scene = new THREE.Scene();

  if (!x3dModel.a3dMesh) {
    return { scene, orbitCamera: defaultCamera };
  }

  const mesh = A3D.x3dMeshCreate("Center", x3dModel.a3dMesh);
  const threeMesh = A3D.x3dMeshThree(mesh);
  scene.add(threeMesh);

  const ambientLight = new THREE.AmbientLight(new THREE.Color(0.1, 0.1, 0.1));
  scene.add(ambientLight);

  for (const l of standardLights) {
    scene.add(A3D.lightThree(l));
  }

  const orbitCamera = A3D.getCoveringCameraForX3dMesh(mesh);

  return { scene, orbitCamera };
}
