import { TextTranslateFn, translateTextKey } from "./translation";
import { TextTypes } from "./texts";
import * as GQLOps from "../generated/generated-operations";
import { Texts } from "..";

export type TranslateFn = TextTranslateFn<TextTypes>;

export function createTranslateFn(translationQuery: GQLOps.TranslationsQuery): Texts.TranslateFn {
  const translations = Texts.translationsFromQuery(translationQuery);
  return (text, defaultText) => translateTextKey(translations, text, defaultText);
}
