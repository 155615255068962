import * as R from "ramda";
import React from "react";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { ImageTableRows } from "@ehb/shared/src/calculator";
import { OnPropertiesChanged } from "@promaster-sdk/react-property-selectors";
import { ActiveUser } from "@ehb/shared/src/user";
import { Unit } from "uom";
import { FP, GetFieldFormatFn, GetFieldFormatsFn, SelectableFormat } from "@ehb/shared";
import { TranslateFn, key, texts } from "@ehb/shared/src/lang-texts";
import { createBlobUrlCreator, CreateBlobUrl } from "@ehb/client-infra";
import { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import * as PropertyDef from "./property-selector-def";
import { createFilterPrettyPrint } from "./filter-pretty-print";
import { Group } from "../group";
import { useWindowScroll } from "../hooks/use-window-onscroll";
import { withTw } from "../with-tw";
import { ImageCombobox } from "../popup-description";
import { AmountSelector } from "./amount-selector";
import { DiscreteSelector } from "./discrete-selector";
import { TextSelector } from "./text-selector";

const defaultGroup = "main";

export interface PropertiesSelectorProps {
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly properties: ReadonlyArray<PropertyDef.PropertySelectorDef>;
  readonly onChange: OnPropertiesChanged;
  readonly onPropertyFormatChanged: (fieldName: string, unit: Unit.Unit<unknown>, decimalCount: number) => void;
  readonly onPropertyFormatCleared: (fieldName: string) => void;
  readonly translate: TranslateFn;
  readonly activeUser: ActiveUser;
  readonly productImages: FP.Result<string, ReadonlyArray<ImageTableRows>>;
  readonly productKey: string;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly disableSingleItemDropdowns?: boolean;
}

export function PropertiesSelector({
  selectedProperties,
  properties,
  onChange,
  onPropertyFormatChanged,
  onPropertyFormatCleared,
  translate,
  activeUser,
  productImages,
  productKey,
  getFieldFormats,
  getFieldFormat,
  disableSingleItemDropdowns,
}: PropertiesSelectorProps): React.ReactElement<PropertiesSelectorProps> {
  const scrollToggle = useWindowScroll();
  const groups = R.groupBy((p) => p.group.split(",")[0] || defaultGroup, properties);
  const createBlobUrl = createBlobUrlCreator(activeUser.accessToken);
  return (
    <div className="flex flex-col space-y-4">
      {R.keys(groups).map((group) => (
        <PropertyGroup
          key={group}
          group={group}
          propertyValueSet={selectedProperties}
          properties={groups[group]}
          onChange={onChange}
          onPropertyFormatChanged={onPropertyFormatChanged}
          onPropertyFormatCleared={onPropertyFormatCleared}
          translate={translate}
          createBlobUrl={createBlobUrl}
          productImages={productImages}
          productKey={productKey}
          getFieldFormats={getFieldFormats}
          getFieldFormat={getFieldFormat}
          disableSingleItemDropdowns={disableSingleItemDropdowns}
          parentScrollToggle={scrollToggle}
          activeUser={activeUser}
        />
      ))}
    </div>
  );
}

interface PropertyGroupProps {
  readonly group: string;
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly properties: ReadonlyArray<PropertyDef.PropertySelectorDef>;
  readonly onChange: OnPropertiesChanged;
  readonly onPropertyFormatChanged: (fieldName: string, unit: Unit.Unit<unknown>, decimalCount: number) => void;
  readonly onPropertyFormatCleared: (fieldName: string) => void;
  readonly translate: TranslateFn;
  readonly createBlobUrl: CreateBlobUrl;
  readonly productImages: FP.Result<string, ReadonlyArray<ImageTableRows>>;
  readonly productKey: string;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly disableSingleItemDropdowns?: boolean;
  readonly parentScrollToggle: boolean | undefined;
  readonly activeUser: ActiveUser;
}

function PropertyGroup(props: PropertyGroupProps): JSX.Element | null {
  const {
    group,
    propertyValueSet,
    properties,
    onChange,
    onPropertyFormatChanged,
    translate,
    createBlobUrl,
    productImages,
    productKey,
    getFieldFormats,
    getFieldFormat,
    disableSingleItemDropdowns,
    parentScrollToggle,
    activeUser,
  } = props;
  const filterPrettyPrint = React.useMemo(
    () => createFilterPrettyPrint(translate, getFieldFormat, properties, propertyValueSet),
    [properties, translate, getFieldFormat, PropertyValueSet.toString(propertyValueSet)]
  );
  const [closedGroups, setClosedGroups] = React.useState<ReadonlyArray<string>>([]);
  const visibleProperties = properties.filter((p) => PropertyFilter.isValid(propertyValueSet, p.visibilityFilter));
  if (visibleProperties.length === 0) {
    return null;
  }

  const subGroups = R.groupBy((p) => p.group.split(",")[1] || "", visibleProperties);

  return (
    <Group
      header={translate(texts.property_group(group, productKey), "")}
      closed={closedGroups.includes(group)}
      onToggleClosed={() =>
        closedGroups.includes(group)
          ? setClosedGroups(closedGroups.filter((g) => g !== group))
          : setClosedGroups([...closedGroups, group])
      }
    >
      <div className="flex flex-col space-y-2">
        {R.keys(subGroups).map((subGroup) => (
          <React.Fragment key={subGroup}>
            {subGroup && (
              <div className="text-sm text-red-700 font-bold pt-2">
                {translate(texts.property_group(subGroup, productKey), "")}
              </div>
            )}
            {subGroups[subGroup].map((p) => (
              <PropertySelector
                key={p.name}
                property={p}
                onChange={onChange}
                propertyValueSet={propertyValueSet}
                translate={translate}
                filterPrettyPrint={filterPrettyPrint}
                createBlobUrl={createBlobUrl}
                productImages={productImages}
                productKey={productKey}
                getFieldFormats={getFieldFormats}
                getFieldFormat={getFieldFormat}
                setFieldFormat={(fieldName, format) =>
                  onPropertyFormatChanged(fieldName, format.unit, format.decimalCount)
                }
                disableSingleItemDropdowns={disableSingleItemDropdowns}
                parentScrollToggle={parentScrollToggle}
                activeUser={activeUser}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </Group>
  );
}

const PropertyValue = withTw("div", (p: { readonly fullWidth?: boolean }) => (p.fullWidth ? "" : "w-1/2"));
const Property = withTw(
  "div",
  "min-h-8 property-selector-property first:mt-[5px] flex",
  (p: { readonly fullWidth?: boolean }) => {
    if (p.fullWidth) {
      return "flex-col justify-start items-stretch space-y-1";
    } else {
      return "flex-row justify-between items-center";
    }
  }
);

interface PropertySelectorProps {
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly property: PropertyDef.PropertySelectorDef;
  readonly onChange: OnPropertiesChanged;
  readonly setFieldFormat: (propertyName: string, format: SelectableFormat) => void;
  readonly translate: TranslateFn;
  readonly filterPrettyPrint: FilterPrettyPrint;
  readonly productImages: FP.Result<string, ReadonlyArray<ImageTableRows>>;
  readonly productKey: string;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly disableSingleItemDropdowns?: boolean;
  readonly createBlobUrl: CreateBlobUrl;
  readonly parentScrollToggle: boolean | undefined;
  readonly activeUser: ActiveUser;
}

function PropertySelector(props: PropertySelectorProps): JSX.Element {
  const {
    propertyValueSet,
    property,
    onChange,
    setFieldFormat,
    translate,
    filterPrettyPrint,
    productImages,
    productKey,
    getFieldFormats,
    getFieldFormat,
    createBlobUrl,
    parentScrollToggle,
    activeUser,
  } = props;
  const propertyImage = property.propertyImage ? createBlobUrl(property.propertyImage.image!) : undefined;
  const errorMessage = filterPrettyPrint(property.validationFilter);
  const fullWidth = property.type === "Text" && property.selectorType === "multiline";
  return (
    <div>
      {propertyImage && (
        <div className="flex flex-row justify-center w-full mt-2 mb-2">
          <img src={propertyImage} className="w-64 h-64" />
        </div>
      )}
      <Property fullWidth={fullWidth}>
        <PropertyLabel
          productKey={productKey}
          property={property}
          productImages={productImages}
          createBlobUrl={createBlobUrl}
          translate={translate}
          fullWidth={fullWidth}
        />
        <PropertyValue fullWidth={fullWidth}>
          {property.type === "Amount" && (
            <AmountSelector
              property={property}
              pvs={propertyValueSet}
              disabled={property.readOnly}
              onChange={(newValue) =>
                newValue &&
                onChange(PropertyValueSet.setAmount(property.name, newValue, propertyValueSet), [property.name])
              }
              getFieldFormat={getFieldFormat}
              getFieldFormats={getFieldFormats}
              setFieldFormat={(format) => setFieldFormat(property.fieldName, format)}
              errorMessage={errorMessage}
              activeUser={activeUser}
            />
          )}
          {property.type === "Discrete" && (
            <DiscreteSelector
              property={property}
              pvs={propertyValueSet}
              disabled={property.readOnly}
              onChange={(newValue) =>
                onChange(PropertyValueSet.set(property.name, newValue, propertyValueSet), [property.name])
              }
              errorMessage={errorMessage}
              createBlobUrl={createBlobUrl}
              parentScrollToggle={parentScrollToggle}
              filterPrettyPrint={filterPrettyPrint}
            />
          )}
          {property.type === "Text" && (
            <TextSelector
              property={property}
              pvs={propertyValueSet}
              errorMessage={errorMessage}
              disabled={property.readOnly}
              onChange={(newValue) =>
                onChange(PropertyValueSet.setText(property.name, newValue, propertyValueSet), [property.name])
              }
            />
          )}
        </PropertyValue>
      </Property>
    </div>
  );
}

interface PropertyLabelProps {
  readonly productKey: string;
  readonly property: PropertyDef.PropertyDefBase;
  readonly productImages: FP.Result<string, ReadonlyArray<ImageTableRows>>;
  readonly translate: TranslateFn;
  readonly createBlobUrl: CreateBlobUrl;
  readonly fullWidth?: boolean;
}

function PropertyLabel({
  productKey,
  property,
  productImages,
  createBlobUrl,
  translate,
  fullWidth,
}: PropertyLabelProps): JSX.Element {
  const images = productImages.type === "Ok" ? productImages.value : [];
  return (
    <div className={`flex flex-row ${fullWidth ? "" : "w-1/2"}`}>
      <span className="cursor-default">{property.translation}</span>
      {property.propInfo && property.propInfo.exists && (
        <ImageCombobox
          images={images}
          createBlobUrl={createBlobUrl}
          description={translate(key(property.propInfo.textKey, {}, productKey))}
        />
      )}
    </div>
  );
}
