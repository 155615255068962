export interface Face3 {
  readonly v0: number;
  readonly v1: number;
  readonly v2: number;
}

export function face3Create(v0: number, v1: number, v2: number): Face3 {
  return {
    v0: v0,
    v1: v1,
    v2: v2
  };
}

export function face3Equals(a: Face3, b: Face3): boolean {
  return a === b || (a.v0 === b.v0 && a.v1 === b.v1 && a.v2 === b.v2);
}
