export type NonNull<T> = {
  readonly [P in keyof T]: Exclude<T[P], null>;
};

export function isNonNull<T>(row: NonNull<T> | T): row is NonNull<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(row as any).every((key) => (row as any)[key] !== null);
}

export function nullToEmpty<T>(row: NonNull<T> | T): NonNull<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(row as any).reduce<any>((sofar, key) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sofar[key] = (row as any)[key] ?? "";
    return sofar;
  }, {});
}
