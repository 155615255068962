import * as React from "react";
import { Icon } from "./icon";
import { withTw } from "./with-tw";

const Toggle = withTw("div", "text-lg select-none");

interface Props {
  readonly header?: string;
  readonly children?: React.ReactNode;
  readonly closed?: boolean;
  readonly onToggleClosed?: () => void;
}

export function Group(props: Props): React.ReactElement<Props> {
  const { header, children, closed, onToggleClosed } = props;
  const closable = closed !== undefined && !!header;
  return (
    <div className="rounded border-slate-100 border p-4 w-full">
      {closable ? (
        <div onClick={() => onToggleClosed && onToggleClosed()} className="flex items-center space-x-2 mb-4">
          <a>
            <Toggle className="cursor-pointer">{header}</Toggle>
          </a>
          <Icon className="text-lg text-gray-400 ml-2" icon={closed ? "angle-down" : "angle-up"} />
        </div>
      ) : (
        header && <Toggle className="mb-4">{header}</Toggle>
      )}
      {!closed && <div>{children}</div>}
    </div>
  );
}
