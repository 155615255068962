// import { PropertyFilter as PF, PropertyValue as PV, PropertyValueSet as PVS } from "@promaster-sdk/property";
import { PropertyFilter as PF } from "@promaster-sdk/property";
import { ElementTableRows, WeightTableRow } from "./get-tables";

export interface ElectricHeaterSupplier {
  readonly getDimensions: (input: GetDimensionsInput) => GetDimensionsOutput;
  readonly getWeight: (input: GetWeightInput) => GetWeightOutput;
}

export type PropertyFilterTable = ReadonlyArray<PropertyFilterTableRow>;

export interface PropertyFilterTableRow {
  readonly propertyFilter: PF.PropertyFilter;
  readonly inputs: Record<string, string | number>;
}

export interface GetDimensionsInput {
  readonly widthMm: number;
  readonly heightMm: number;
  readonly powerKw: number;
  readonly voltageV: number;
  readonly phases: number;
  readonly powerStepsKw: ReadonlyArray<number>;
  readonly type: Type;
  readonly model: Model;
  readonly elementTable: ReadonlyArray<ElementTableRows>;
  readonly connection: Connection;
  readonly current: number;
}

export type GetDimensionsOutput = Dimensions;

interface Dimensions {
  readonly widthMm: number;
  readonly heightMm: number;
  readonly depthMm: number;
  readonly faceWidthMm: number;
  readonly faceHeightMm: number;
}

export type AirMode = "UnitAirTempOut" | "CustomAirTempOut" | "Capacity";

export interface ParamsAw {
  readonly airMode: AirMode;
  readonly airTempOutC: number;
  readonly capacityKw: number;
}

export enum Insulation {
  NI = 1,
  RI = 2,
}
export enum PowerStepDistribution {
  even = 1,
  delayedGeometric = 2,
  geometric = 3,
}
export enum Model {
  VFL = 1,
  VFLPG = 2,
  "VRA-FU" = 3,
  VTL = 4,
}

export enum Type {
  MTXL = 1,
  MTEML = 2,
  MTUL = 3,
  MQXL = 4,
  MQEML = 5,
  MQUL = 6,
  M = 7,
  ML = 8,
}
export enum AirDirection {
  R = 1,
  L = 2,
}

export enum Connection {
  Rectangular = 1,
  Circular = 2,
}
export enum IP_Class {
  IP43 = 43,
  IP55 = 55,
  IP65 = 65,
}

export enum Method {
  Power = 1,
  DeltaT = 2,
  Temperature = 3,
}

export enum CableInlet {
  U1 = 1,
  D1 = 2,
  M1 = 3,
  U2 = 4,
  D2 = 5,
  M2 = 6,
  RM = 7,
  LM = 8,
}

export enum Material {
  A = 1,
  S = 2,
  SA = 3,
}

export enum Currency {
  SEK = 1,
  EUR = 2,
}
// even = [1, 1, 1, 1, 1]
// delayedGeometric = [1, 1, 2, 4, 8]
// geometric = [1, 2, 4, 8, 16]

export enum DeliveryTerms {
  EXWStandardExcl = 1,
  EXWStandardIncl = 2,
  DAP = 3,
  DAT = 4,
  DDP = 5,
  FCA = 6,
}

export enum PaymentTerms {
  AdvBeforeProduction = 1,
  FreeDelvMont20daysNet = 2,
  ThirtydaysNetFromInvoice = 3,
  FortyFiveDaysNetFromInvoice = 4,
  SixtyDaysNetFromInvoice = 5,
}

export enum Voltage {
  Phase1Volt230 = 1230,
  Phase3Volt230 = 3230,
  Phase2Volt400 = 2400,
  Phase3Volt400 = 3400,
}

export enum MaxOutTemp {
  _50C = 50,
  _120C = 120,
}
export interface GetWeightInput {
  readonly widthMm: number;
  readonly heightMm: number;
  readonly depthMm: number;
  readonly power: number;
  readonly amperage: number;
  readonly model: Model;
  readonly weightTable: ReadonlyArray<WeightTableRow>;
}

export interface GetWeightOutput {
  readonly weightKg: number;
}

export type CalculateRequest = {
  readonly model: Model;
  readonly type: Type;
  readonly method: Method;
  readonly powerKw: number;
  readonly deltaTempC: number;
  readonly voltage: number;
  readonly airVolumeM3h: number;
  readonly widthMm: number;
  readonly heightMm: number;
  readonly ipClass: IP_Class;
  readonly material: Material;
  readonly powerStepDistribution: PowerStepDistribution;
  readonly cableInlet: CableInlet;
  readonly powerSteps: number;
  readonly currency: Currency;
  readonly airDirection: AirDirection;
  readonly connection: Connection;
  readonly diameter: number;
  readonly diameterMm: number;
  readonly inletTempC: number;
  readonly outletTempC: number;
  readonly airHumidityPct: number;
  readonly insulation: Insulation;
  readonly maxOutputTempC: MaxOutTemp;
  readonly ductsensorTGK330: number;
  readonly ductsensorTGK360: number;
  readonly pressureswitchDTV300: number;
  readonly roomsensorTGR430: number;
  readonly deliveryTerms: DeliveryTerms;
  readonly productionTime: number;
  readonly paymentTerms: PaymentTerms;
  readonly quotationNumber: string;
  readonly discount: number;
  readonly notes: string;
  readonly notesDrawing: string;
  readonly manualDepth: number;
  readonly includePrice: number;
  readonly includeDiscount: number;
  readonly meshInlet: number;
  readonly meshOutlet: number;
  readonly project: string;
  readonly reference: string;
};

export type CalculateResult = {
  readonly model: Model;
  readonly type: Type;
  readonly material: Material;
  readonly ipClass: IP_Class;
  readonly insulation: Insulation;
  readonly cableInlet: CableInlet;
  readonly airDirection: AirDirection;
  readonly connection: Connection;
  readonly supplierCode: string;
  readonly widthMm: number;
  readonly heightMm: number;
  readonly airHeightMm: number;
  readonly airWidthMm: number;
  readonly depthMm: number;
  readonly powerKw: number;
  readonly weightKg: number;
  readonly voltage: number;
  readonly areaM2: number;
  readonly pressureDropPa: number;
  readonly rows: number;
  readonly airVolumeM3h: number;
  readonly velocityMs: number;
  readonly current: number;
  readonly powerStepsKw: ReadonlyArray<number>;
  readonly powerStepsKwText: ReadonlyArray<string>;
  readonly outletTempC: number;
  readonly temperatureIncreaseC: number;
  readonly heaterPrice: number;
  readonly totalPrice: number;
  readonly currency: Currency;
  readonly maxOutputTempC: number;
  readonly numberElectricalElements: number;
  readonly articleNumberUsed: string;
  readonly resultStatus: "OK" | "CONTACT-VEAB";
  readonly fullGlandEntryString: string;
  readonly heatingRodSurfaceTemperatureC: number;
  readonly project: string;
  readonly reference: string;
};

export type CustomItem = {
  readonly id: string;
  readonly sortNo: number;
  readonly name: string;
  readonly quantity: number;
  readonly singlePrice: number;
  readonly totalPrice: number;
  readonly currency: string;
};

export type CustomItems = ReadonlyArray<CustomItem>;

export type ErrorStatus = "ERROR" | "CONTACT-VEAB";
export type ErrorResult = {
  readonly errorCode: CalculatorErrorCode;
  readonly errorMessage: string;
  readonly errorStatus: ErrorStatus;
};

export type CalculatorErrorCode = {
  readonly errorCode: CALCULATORERRORCODES;
  readonly errorCodeDescription: keyof typeof CALCULATORERRORCODES;
};

export enum CALCULATORERRORCODES {
  ERROR_IN_CALCULATION = 1,
  ERROR_METHOD_NOT_POSSIBLE = 2,
  ERROR_SELECTION_NOT_POSSIBLE = 3,
  ERROR_CABLE_INLET = 4,
  ERROR_MIN_WIDTH_DUCT = 5,
  ERROR_MIN_HEIGHT_DUCT = 6,
  ERROR_MAX_HEIGHT = 7,
  ERROR_MIN_HEIGHT = 8,
  ERROR_MAX_WIDTH = 9,
  ERROR_MIN_WIDTH = 10,
  ERROR_MAX_TEMP = 11,
  ERROR_CIRCULAR_NOT_POSSIBLE = 12,
  ERROR_MAX_POWER_FOR_1X230V = 13,
  ERROR_MAX_POWER_FOR_MQ = 14,
  ERROR_MAX_POWER_FOR_MQ_3X230V = 15,
  ERROR_MAX_POWER_FOR_MTUL = 16,
  ERROR_MAX_POWER_MTUL_3X230V = 17,
  ERROR_FRAME_TO_BIG = 18,
  ERROR_MAX_DEPTH = 19,
  ERROR_AIRSPEED_TO_HIGH = 20,
  ERROR_AIRSPEED_TO_LOW = 21,
  ERROR_AIRSPEED_TO_LOW_FOR_120C = 22,
  ERROR_MAX_STEPSIZE_FOR_1X230V = 23,
  ERROR_MAX_STEPSIZE_FOR_2X400V = 24,
  ERROR_MAX_POWER_3X230V = 25,
  ERROR_MAX_POWER_3X400V = 26,
  ERROR_MAX_POWER = 27,
  ERROR_DEPTH_NOT_POSSIBLE = 28,
  ERROR_STEP1_TO_HIGH = 29,
  ERROR_IPCLASS_NOT_POSSIBLE = 30,
}
type CalculatorErrorCodesRecord = Record<keyof typeof CALCULATORERRORCODES, CalculatorErrorCode> & {
  readonly [key: string]: CalculatorErrorCode | undefined;
};

export const CalculatorErrorCodes: CalculatorErrorCodesRecord = {
  ERROR_IN_CALCULATION: {
    errorCode: CALCULATORERRORCODES.ERROR_IN_CALCULATION,
    errorCodeDescription: "ERROR_IN_CALCULATION",
  },
  ERROR_METHOD_NOT_POSSIBLE: {
    errorCode: CALCULATORERRORCODES.ERROR_METHOD_NOT_POSSIBLE,
    errorCodeDescription: "ERROR_METHOD_NOT_POSSIBLE",
  },
  ERROR_SELECTION_NOT_POSSIBLE: {
    errorCode: CALCULATORERRORCODES.ERROR_SELECTION_NOT_POSSIBLE,
    errorCodeDescription: "ERROR_SELECTION_NOT_POSSIBLE",
  },
  ERROR_CABLE_INLET: {
    errorCode: CALCULATORERRORCODES.ERROR_CABLE_INLET,
    errorCodeDescription: "ERROR_CABLE_INLET",
  },
  ERROR_MIN_WIDTH_DUCT: {
    errorCode: CALCULATORERRORCODES.ERROR_MIN_WIDTH_DUCT,
    errorCodeDescription: "ERROR_MIN_WIDTH_DUCT",
  },
  ERROR_MIN_HEIGHT_DUCT: {
    errorCode: CALCULATORERRORCODES.ERROR_MIN_HEIGHT_DUCT,
    errorCodeDescription: "ERROR_MIN_HEIGHT_DUCT",
  },
  ERROR_MAX_HEIGHT: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_HEIGHT,
    errorCodeDescription: "ERROR_MAX_HEIGHT",
  },
  ERROR_MIN_HEIGHT: {
    errorCode: CALCULATORERRORCODES.ERROR_MIN_HEIGHT,
    errorCodeDescription: "ERROR_MIN_HEIGHT",
  },
  ERROR_MAX_WIDTH: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_WIDTH,
    errorCodeDescription: "ERROR_MAX_WIDTH",
  },
  ERROR_MIN_WIDTH: {
    errorCode: CALCULATORERRORCODES.ERROR_MIN_WIDTH,
    errorCodeDescription: "ERROR_MIN_WIDTH",
  },
  ERROR_MAX_TEMP: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_TEMP,
    errorCodeDescription: "ERROR_MAX_TEMP",
  },
  ERROR_CIRCULAR_NOT_POSSIBLE: {
    errorCode: CALCULATORERRORCODES.ERROR_CIRCULAR_NOT_POSSIBLE,
    errorCodeDescription: "ERROR_CIRCULAR_NOT_POSSIBLE",
  },
  ERROR_MAX_POWER_FOR_1X230V: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_POWER_FOR_1X230V,
    errorCodeDescription: "ERROR_MAX_POWER_FOR_1X230V",
  },
  ERROR_MAX_POWER_FOR_MQ: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_POWER_FOR_MQ,
    errorCodeDescription: "ERROR_MAX_POWER_FOR_MQ",
  },
  ERROR_MAX_POWER_FOR_MQ_3X230V: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_POWER_FOR_MQ_3X230V,
    errorCodeDescription: "ERROR_MAX_POWER_FOR_MQ_3X230V",
  },
  ERROR_MAX_POWER_FOR_MTUL: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_POWER_FOR_MTUL,
    errorCodeDescription: "ERROR_MAX_POWER_FOR_MTUL",
  },
  ERROR_MAX_POWER_MTUL_3X230V: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_POWER_MTUL_3X230V,
    errorCodeDescription: "ERROR_MAX_POWER_MTUL_3X230V",
  },
  ERROR_FRAME_TO_BIG: {
    errorCode: CALCULATORERRORCODES.ERROR_FRAME_TO_BIG,
    errorCodeDescription: "ERROR_FRAME_TO_BIG",
  },
  ERROR_MAX_DEPTH: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_DEPTH,
    errorCodeDescription: "ERROR_MAX_DEPTH",
  },
  ERROR_AIRSPEED_TO_HIGH: {
    errorCode: CALCULATORERRORCODES.ERROR_AIRSPEED_TO_HIGH,
    errorCodeDescription: "ERROR_AIRSPEED_TO_HIGH",
  },
  ERROR_AIRSPEED_TO_LOW_FOR_120C: {
    errorCode: CALCULATORERRORCODES.ERROR_AIRSPEED_TO_LOW_FOR_120C,
    errorCodeDescription: "ERROR_AIRSPEED_TO_LOW_FOR_120C",
  },
  ERROR_MAX_STEPSIZE_FOR_1X230V: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_STEPSIZE_FOR_1X230V,
    errorCodeDescription: "ERROR_MAX_STEPSIZE_FOR_1X230V",
  },
  ERROR_MAX_STEPSIZE_FOR_2X400V: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_STEPSIZE_FOR_2X400V,
    errorCodeDescription: "ERROR_MAX_STEPSIZE_FOR_2X400V",
  },
  ERROR_MAX_POWER_3X230V: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_POWER_3X230V,
    errorCodeDescription: "ERROR_MAX_POWER_3X230V",
  },
  ERROR_MAX_POWER_3X400V: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_POWER_3X400V,
    errorCodeDescription: "ERROR_MAX_POWER_3X400V",
  },
  ERROR_MAX_POWER: {
    errorCode: CALCULATORERRORCODES.ERROR_MAX_POWER,
    errorCodeDescription: "ERROR_MAX_POWER",
  },
  ERROR_DEPTH_NOT_POSSIBLE: {
    errorCode: CALCULATORERRORCODES.ERROR_DEPTH_NOT_POSSIBLE,
    errorCodeDescription: "ERROR_DEPTH_NOT_POSSIBLE",
  },
  ERROR_AIRSPEED_TO_LOW: {
    errorCode: CALCULATORERRORCODES.ERROR_AIRSPEED_TO_LOW,
    errorCodeDescription: "ERROR_AIRSPEED_TO_LOW",
  },
  ERROR_STEP1_TO_HIGH: {
    errorCode: CALCULATORERRORCODES.ERROR_STEP1_TO_HIGH,
    errorCodeDescription: "ERROR_STEP1_TO_HIGH",
  },
  ERROR_IPCLASS_NOT_POSSIBLE: {
    errorCode: CALCULATORERRORCODES.ERROR_IPCLASS_NOT_POSSIBLE,
    errorCodeDescription: "ERROR_IPCLASS_NOT_POSSIBLE",
  },
};
