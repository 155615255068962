import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import * as FalIcons from "./fal-icons";
import * as FasIcons from "./fas-icons";

export function registerFontAwesomeIcons(): void {
  const falIcons = Object.values(FalIcons) as ReadonlyArray<IconDefinition>;
  const fasIcons = Object.values(FasIcons) as ReadonlyArray<IconDefinition>;
  library.add(...falIcons);
  library.add(...fasIcons);
}
