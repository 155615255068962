import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { SharedState } from "@ehb/client-infra";
import { FP } from "@ehb/shared";
import { isInternalUser } from "@ehb/shared/src/user";
import React, { Dispatch } from "react";
import { texts } from "@ehb/shared/src/lang-texts";
import { PropertiesSelector, Spinner, withTw } from "../../elements";
import { State, Action } from "./state";
import { mapPropertiesToSelector } from "../../elements/properties-selector/property-selector-def";
import { Button } from "../../elements/button";

const Container = withTw(
  "div",
  "flex relative gap-4 block gap-10 text-xs flex-col lg:flex-row max-w-[1200px] ml-auto mr-auto w-full pt-4 pb-4"
);
const DataContainer = withTw("div", "flex flex-col space-y-4");

export function View({
  state,
  sharedState,
  dispatch,
}: {
  readonly state: State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const { activeUser, translate, selectedLanguage, getFieldFormat, getFieldFormats } = sharedState;
  const { variant, productQuery } = state;

  const propertySelectorDefs = React.useMemo(() => {
    return mapPropertiesToSelector(activeUser, productQuery, variant, translate);
  }, [productQuery, selectedLanguage, PropertyValueSet.toString(variant)]);

  const variantOk = React.useMemo(() => {
    return propertySelectorDefs.every((p) => {
      if (!PropertyFilter.isValid(variant, p.visibilityFilter)) {
        return true;
      }

      if (p.type === "Discrete" && p.items.length > 0) {
        const propertyValue = PropertyValueSet.getInteger(p.name, variant);
        const selectedItem = p.items.find((i) => i.value?.value === propertyValue);
        if (selectedItem && !PropertyFilter.isValid(variant, selectedItem.validationFilter)) {
          return false;
        }
      }

      return PropertyFilter.isValid(variant, p.validationFilter);
    });
  }, [propertySelectorDefs, PropertyValueSet.toString(variant)]);

  if (!isInternalUser(activeUser.claims)) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center flex-col gap-12">
        <div className="font-bold text-lg">{translate(texts.not_allowed)}</div>
        <div className="">
          <a href={"/"}>
            <Button label={translate(texts.go_back)} iconLeft={"arrow-left"} type={"primary"} />
          </a>
        </div>
      </div>
    );
  }

  if (!productQuery?.product) {
    return <Spinner />;
  }

  return (
    <Container>
      <DataContainer className="min-w-sm">
        <h1 className="text-2xl text-center">{translate(texts.options)}</h1>
        <div className="min-w-sm w-full">
          <div className="flex flex-col space-y-4">
            <PropertiesSelector
              selectedProperties={variant}
              properties={propertySelectorDefs}
              onChange={(
                newSelectedProperties: PropertyValueSet.PropertyValueSet,
                _propertyNames: ReadonlyArray<string>
              ) => {
                dispatch(Action.SetVariant(newSelectedProperties));
              }}
              onPropertyFormatChanged={(fieldName, unit, decimalCount) => {
                dispatch(Action.SetSelectedFormat(fieldName, { unit, decimalCount }));
              }}
              onPropertyFormatCleared={(fieldName) => {
                dispatch(Action.ClearFieldUnit(fieldName));
              }}
              activeUser={activeUser}
              productImages={FP.Ok([])}
              translate={translate}
              productKey={productQuery.product.key}
              getFieldFormat={getFieldFormat}
              getFieldFormats={getFieldFormats}
              disableSingleItemDropdowns={true}
            />
          </div>
        </div>
      </DataContainer>
      <DataContainer className="min-w-sm">
        <Button
          disabled={state.calculating || !variantOk}
          title={
            state.calculating
              ? "Calculation in progress"
              : !variantOk
              ? "Missing inputs. Fill out all inputs before calculating"
              : ""
          }
          label={translate(texts.calculate)}
          onClick={() => dispatch(Action.StartCalculate())}
        />

        {state.calculationResults && (
          <>
            <h1 className="text-2xl text-center">{translate(texts.results)}</h1>
            <div>
              {state.calculationResults?.type === "Err" &&
                state.calculationResults.error.messages.map((m) => <div key={m.text.key}>{translate(m.text)}</div>)}

              {state.calculationResults?.type === "Ok" && (
                <table className="w-full">
                  <tbody>
                    {Object.entries(state.calculationResults.value)
                      .filter(([k]) => k !== "coilInput")
                      .map(([k, v]) => (
                        <tr key={k}>
                          <td>{k}:</td> <td>{JSON.stringify(v)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
      </DataContainer>
    </Container>
  );
}
