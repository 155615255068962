import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { gql } from "graphql-tag";
import { SharedState, NavigationEffectManager as Navigation, Routes } from "@ehb/client-infra";
import { projectIdQueryParam } from "@ehb/shared/src/project";
import * as GQLOps from "../../generated/generated-operations";
import * as ProjectState from "../../project-state";

const projectListQuery = gql`
  query projectList($pageCursor: String) {
    projectList(pageCursor: $pageCursor) {
      projects {
        project {
          id
          name
          owner
          createdDate
          modifiedDate
        }
      }
      projectCount
      nextPageCursor
    }
  }
`;

export type ListEntry = GQLOps.ProjectListQuery["projectList"]["projects"][0];

export type ListState =
  | {
      readonly type: "loading";
    }
  | { readonly type: "loaded"; readonly nextPageCursor: string | undefined };

export type State = {
  readonly projectList: ReadonlyArray<ListEntry> | undefined;
  readonly projectState: ProjectState.State;
  readonly projectCount: number | undefined;
  readonly listState: ListState;
  readonly sortSetting: SortSetting | undefined;
};

export type Order = "asc" | "desc";
export type Column = Exclude<keyof GQLOps.Project, "__typename">;
export type SortSetting = {
  readonly order: Order;
  readonly column: Column;
};

export const initialSortOrders: { readonly [T in Column]: Order } = {
  id: "asc",
  name: "asc",
  owner: "asc",
  createdDate: "desc",
  modifiedDate: "desc",
};

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState,
  queryParams: Record<string, string>
): readonly [State, Cmd<Action>?] {
  let state: State = {
    projectList: undefined,
    projectCount: undefined,
    listState: { type: "loading" },
    sortSetting: prevState?.sortSetting,
    projectState: {
      type: "closed",
    },
    ...(prevState || {}),
  };

  const cmds = [];

  const [projectState, projectCmd] = ProjectState.init(state?.projectState, sharedState, queryParams);
  state = {
    ...state,
    projectState: projectState,
  };
  cmds.push(Cmd.map(Action.DispatchProject, projectCmd));

  const currentProjectId = ProjectState.getCurrentProjectId(state.projectState);
  if (state.projectList === undefined || !currentProjectId) {
    const [loadState, loadCmd] = loadNextPage(state, sharedState, undefined);
    state = loadState;
    cmds.push(loadCmd);
  } else if (currentProjectId && !state.projectList.some((p) => p.project.id === currentProjectId)) {
    const [loadState, loadCmd] = loadNextPage(state, sharedState, undefined);
    state = loadState;
    cmds.push(loadCmd);
  }

  return [state, Cmd.batch(cmds)];
}

export const Action = ctorsUnion({
  DispatchProject: (action: ProjectState.Action) => ({ action }),
  SetSortOrder: (column: Column, order: Order) => ({ column, order }),
  SetOpenProject: (projectId: string | undefined) => ({ projectId }),
  QueryResponse: (query: GQLOps.ProjectListQuery) => ({ query }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "DispatchProject": {
      const projectAction = action.action;
      let newState = state;
      const [newProjectState, cmd, sharedStateAction] = ProjectState.update(
        projectAction,
        state.projectState,
        sharedState
      );
      newState = {
        ...newState,
        projectState: newProjectState,
      };
      switch (projectAction.type) {
        case "UpdateProjectResponse": {
          const updatedProject =
            newState.projectState.type === "open" ? newState.projectState.project.project : undefined;
          if (updatedProject) {
            newState = {
              ...newState,
              projectList: newState.projectList?.map((p) =>
                p.project.id === updatedProject.id ? { ...p, project: updatedProject } : p
              ),
            };
          }
          break;
        }
        default:
          break;
      }
      return [newState, Cmd.map(Action.DispatchProject, cmd), sharedStateAction];
    }
    case "SetSortOrder": {
      return [{ ...state, sortSetting: action }];
    }
    case "SetOpenProject": {
      return [
        state,
        Navigation.replaceUrl<Action>(
          Routes.buildUrl(
            Routes.RootLocation.MainLocation(
              Routes.MainLocation.Projects(action.projectId ? { [projectIdQueryParam]: action.projectId } : {})
            )
          ),
          undefined,
          false
        ),
      ];
    }
    case "QueryResponse": {
      if (state.listState.type !== "loading") {
        return [state];
      }
      const projectList = action.query.projectList;
      return [
        {
          ...state,
          listState: { type: "loaded", nextPageCursor: projectList.nextPageCursor || undefined },
          projectList: projectList.projects,
          projectCount: projectList.projectCount,
        },
      ];
    }
    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function loadNextPage(
  state: State,
  sharedState: SharedState.SharedState,
  nextPageCursor: string | undefined
): readonly [State, Cmd<Action>] {
  const gqlCmd = sharedState.graphQLQuery<GQLOps.ProjectListQuery, GQLOps.ProjectListQueryVariables, Action>(
    projectListQuery,
    { pageCursor: nextPageCursor || null },
    (data) => {
      return Action.QueryResponse(data);
    }
  );
  return [
    {
      ...state,
      listState: { type: "loading" },
    },
    gqlCmd,
  ];
}
