import { Amount, Unit } from "uom";
import { UnitsFormat } from "uom-units";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as Ast from "@promaster-sdk/property/lib/property-filter-ast";
import { texts, TranslateFn } from "@ehb/shared/src/lang-texts";
import { filterPrettyPrintIndented, FilterPrettyPrintMessages } from "@promaster-sdk/property-filter-pretty";
import { PropertyValueSet, PropertyFilter, PropertyValue } from "@promaster-sdk/property";
import { EhbUnitsLookup, GetFieldFormatFn } from "@ehb/shared";
import { getUnitLabel } from "@ehb/shared/src/units/unit-labels";
import { PropertySelectorDef } from "./property-selector-def";

export function createFilterPrettyPrint(
  translate: TranslateFn,
  getFieldFormat: GetFieldFormatFn,
  properties: ReadonlyArray<PropertySelectorDef>,
  pvs: PropertyValueSet.PropertyValueSet
): (pf: PropertyFilter.PropertyFilter) => string {
  const availableValues = new Map(
    properties.map((p) => [
      p.name,
      p.type === "Discrete" ? p.items.map((v) => (v.value?.type === "integer" ? v.value.value : -999)) : [],
    ])
  );
  return (pf: PropertyFilter.PropertyFilter) => {
    const simplifiedAst = removeMatchingConstraints(pf.ast, pvs, false, availableValues);
    if (simplifiedAst === "matching") {
      // The filter is matching so nothing to show
      return "";
    }
    if (simplifiedAst === "impossible") {
      return translate(texts.unselectable_option);
    }
    const propertyNameTranslations: { [propertyName: string]: string } = {};
    const propertyValueTranslations: { [propertyNameValue: string]: string } = {};
    properties.forEach((p) => {
      propertyNameTranslations[p.name] = p.translation;
      if (p.type === "Discrete") {
        p.items.forEach(({ value, text }) => {
          if (value?.type === "integer") {
            propertyValueTranslations[`${p.name}_${value.value}`] = text;
          }
        });
      }
    });
    const simplifiedFilter: PropertyFilter.PropertyFilter = {
      ...pf,
      ast: simplifiedAst,
    };
    return filterPrettyPrintIndented(
      createFilterPrettyPrintMessages(
        translate,
        getFieldFormat,
        propertyNameTranslations,
        propertyValueTranslations,
        properties
      ),
      5,
      " ",
      simplifiedFilter,
      UnitsFormat,
      EhbUnitsLookup
    );
  };
}

function removeMatchingConstraints(
  e: Ast.BooleanExpr,
  properties: PropertyValueSet.PropertyValueSet,
  matchMissingIdentifiers: boolean,
  availableValues: ReadonlyMap<string, ReadonlyArray<number>>,
  comparer: PropertyValue.Comparer = PropertyValue.defaultComparer
): Ast.BooleanExpr | "matching" | "impossible" {
  switch (e.type) {
    case "AndExpr": {
      const result = e.children.map((child) =>
        removeMatchingConstraints(child, properties, matchMissingIdentifiers, availableValues, comparer)
      );
      if (result.some((r) => r === "impossible")) {
        return "impossible";
      }
      const newChildren = result.filter(
        (child): child is Ast.BooleanExpr => child !== "matching" && child !== "impossible"
      );
      return newChildren.length > 0 ? Ast.newAndExpr(newChildren) : "matching";
    }
    case "OrExpr": {
      const result = e.children.map((child) =>
        removeMatchingConstraints(child, properties, matchMissingIdentifiers, availableValues, comparer)
      );
      if (result.every((r) => r === "impossible")) {
        return "impossible";
      }
      return result.some((r) => r === "matching")
        ? "matching"
        : Ast.newOrExpr(
            result.filter((child): child is Ast.BooleanExpr => child !== "matching" && child !== "impossible")
          );
    }
    case "EqualsExpr": {
      // Handle match missing identifier
      if (matchMissingIdentifiers) {
        if (
          _isMissingIdent(e.leftValue, properties) ||
          e.rightValueRanges.filter(
            (vr: Ast.ValueRangeExpr) => _isMissingIdent(vr.min, properties) || _isMissingIdent(vr.max, properties)
          ).length > 0
        ) {
          return "matching";
        }
      }

      const left = evaluatePropertyValueExpr(e.leftValue, properties);
      for (const range of e.rightValueRanges) {
        const min = evaluatePropertyValueExpr(range.min, properties);
        const max = evaluatePropertyValueExpr(range.max, properties);

        // Match on NULL or inclusive in range
        if (
          ((max === null || min === null) && left === null) ||
          (left !== null &&
            min !== null &&
            max !== null &&
            PropertyValue.greaterOrEqualTo(left, min, comparer) &&
            PropertyValue.lessOrEqualTo(left, max, comparer))
        ) {
          return e.operationType === "equals" ? "matching" : removeUnavailbleValues(e, availableValues, properties);
        }
      }

      return e.operationType === "notEquals" ? "matching" : removeUnavailbleValues(e, availableValues, properties);
    }
    case "ComparisonExpr": {
      // Handle match missing identifier
      if (
        matchMissingIdentifiers &&
        (_isMissingIdent(e.leftValue, properties) || _isMissingIdent(e.rightValue, properties))
      ) {
        return "matching";
      }

      const left = evaluatePropertyValueExpr(e.leftValue, properties);
      if (left === null) {
        return e;
      }

      const right = evaluatePropertyValueExpr(e.rightValue, properties);
      if (right === null) {
        return e;
      }

      switch (e.operationType) {
        case "less":
          return PropertyValue.lessThan(left, right, comparer) ? "matching" : e;
        case "greater":
          return PropertyValue.greaterThan(left, right, comparer) ? "matching" : e;
        case "lessOrEqual":
          return PropertyValue.lessOrEqualTo(left, right, comparer) ? "matching" : e;
        case "greaterOrEqual":
          return PropertyValue.greaterOrEqualTo(left, right, comparer) ? "matching" : e;
        default:
          throw new Error(`Unknown comparisontype`);
      }
    }
    case "EmptyExpr": {
      return "matching";
    }
    default: {
      return exhaustiveCheck(e, true);
    }
  }
}

function removeUnavailbleValues(
  equalExpr: Ast.EqualsExpr,
  availableValues: ReadonlyMap<string, ReadonlyArray<number>>,
  properties: PropertyValueSet.PropertyValueSet
): Ast.EqualsExpr | "impossible" {
  const identifier = equalExpr.leftValue.type === "IdentifierExpr" ? equalExpr.leftValue.name : undefined;
  if (!identifier) {
    return equalExpr;
  }
  const values = identifier && availableValues.get(identifier);
  if (!values || values.length === 0) {
    return equalExpr;
  }
  const rightValueRanges = [];
  for (const range of equalExpr.rightValueRanges) {
    const min = evaluatePropertyValueExpr(range.min, properties);
    const max = evaluatePropertyValueExpr(range.max, properties);
    if (
      min?.type !== "integer" ||
      max?.type !== "integer" ||
      min.value !== max.value ||
      values.some((v) => v === min.value)
    ) {
      rightValueRanges.push(range);
    }
  }
  if (rightValueRanges.length === 0) {
    return "impossible";
  }
  return Ast.newEqualsExpr(equalExpr.leftValue, equalExpr.operationType, rightValueRanges);
}

function evaluatePropertyValueExpr(
  e: Ast.PropertyValueExpr,
  properties: PropertyValueSet.PropertyValueSet
): PropertyValue.PropertyValue | null {
  switch (e.type) {
    case "IdentifierExpr": {
      const pv = PropertyValueSet.get(e.name, properties);
      return pv || null;
    }
    case "ValueExpr": {
      return e.parsed;
    }
    case "NullExpr": {
      return null;
    }
    case "AddExpr": {
      const left = evaluatePropertyValueExpr(e.left, properties);
      const right = evaluatePropertyValueExpr(e.right, properties);
      if (!left) {
        return right;
      }
      if (!right) {
        return left;
      }
      if (left.type === "integer" && right.type === "integer") {
        if (e.operationType === "add") {
          return PropertyValue.fromInteger(left.value + right.value);
        } else {
          return PropertyValue.fromInteger(left.value - right.value);
        }
      } else if (left.type === "text" && right.type === "text") {
        if (e.operationType === "add") {
          return PropertyValue.fromText(left.value + right.value);
        } else {
          return null;
        }
      } else if (left.type === "amount" && right.type === "amount") {
        if (e.operationType === "add") {
          return PropertyValue.fromAmount(Amount.plus(left.value, right.value));
        } else {
          return PropertyValue.fromAmount(Amount.minus(left.value, right.value));
        }
      }
      return null;
    }
    case "MulExpr": {
      const left = evaluatePropertyValueExpr(e.left, properties);
      const right = evaluatePropertyValueExpr(e.right, properties);
      if (!left || !right) {
        return null;
      }
      if (left.type === "integer" && right.type === "integer") {
        if (e.operationType === "multiply") {
          return PropertyValue.fromInteger(left.value * right.value);
        } else {
          return PropertyValue.fromInteger(left.value / right.value);
        }
      } else if (left.type === "amount" && right.type === "integer") {
        if (e.operationType === "multiply") {
          return PropertyValue.fromAmount(Amount.times(left.value, right.value));
        } else {
          return PropertyValue.fromAmount(Amount.divide(left.value, right.value));
        }
      } else if (left.type === "integer" && right.type === "amount") {
        if (e.operationType === "multiply") {
          return PropertyValue.fromAmount(Amount.times(right.value, left.value));
        }
      }
      return null;
    }
    case "UnaryExpr": {
      const value = evaluatePropertyValueExpr(e.value, properties);
      if (!value || value.type === "text") {
        return null;
      }
      if (value.type === "integer") {
        return PropertyValue.fromInteger(-value.value);
      } else {
        return PropertyValue.fromAmount(Amount.neg(value.value));
      }
    }
    default: {
      return exhaustiveCheck(e, true);
    }
  }
}

function _isMissingIdent(e: Ast.PropertyValueExpr, properties: PropertyValueSet.PropertyValueSet): boolean {
  // If expression is an missing identifier it should match anything
  if (e.type === "IdentifierExpr") {
    if (!PropertyValueSet.hasProperty(e.name, properties)) {
      return true;
    }
  }
  return false;
}

function createFilterPrettyPrintMessages(
  translate: TranslateFn,
  getFieldFormat: GetFieldFormatFn,
  propertyNameTranslations: { readonly [k: string]: string },
  propertyValueTranslations: { readonly [propertyNameValue: string]: string },
  properties: ReadonlyArray<PropertySelectorDef>
): FilterPrettyPrintMessages {
  return {
    comparisionOperationMessage(op: Ast.ComparisonOperationType, left: string, right: string): string {
      return `${left} ${comparisonOperationTypeToString(op, translate)} ${right}`;
    },

    equalsOperationMessage(op: Ast.EqualsOperationType, left: string, right: string): string {
      return `${left} ${equalsOperationTypeToString(op, translate)} ${right}`;
    },

    rangeMessage(min: string, max: string): string {
      return `${translate(texts.pfp_between)} ${min} ${translate(texts.pfp_and)} ${max}`;
    },

    andMessage(): string {
      return translate(texts.pfp_and);
    },

    orMessage(): string {
      return translate(texts.pfp_or);
    },

    propertyNameMessage(propertyName: string): string {
      return propertyNameTranslations[propertyName] ?? propertyName;
    },
    propertyValueMessage(propertyName: string, propertyValue: PropertyValue.PropertyValue): string {
      switch (propertyValue.type) {
        case "amount": {
          const property = properties.find((p) => p.name === propertyName);
          const fieldName = property?.type === "Amount" ? property.fieldName : propertyName;
          const unit = propertyValue.value.unit as Unit.Unit<string>;
          const format = getFieldFormat(fieldName, unit.quantity);

          if (!format) {
            return `${propertyValue.value.value} ${getUnitLabel(propertyValue.value.unit.name)}`;
          }

          return `${Amount.valueAs(format.unit, propertyValue.value).toFixed(format.decimalCount)} ${getUnitLabel(
            format.unit.name
          )}`;
        }
        case "integer":
          return (
            propertyValueTranslations[`${propertyName}_${propertyValue.value}`] ?? PropertyValue.toString(propertyValue)
          );
        case "text":
          return propertyValue.value;
        default:
          return exhaustiveCheck(propertyValue);
      }
    },

    nullMessage(): string {
      return "null";
    },
  };
}

function comparisonOperationTypeToString(type: Ast.ComparisonOperationType, translate: TranslateFn): string {
  switch (type) {
    case "lessOrEqual":
      return translate(texts.pfp_less_or_equal);
    case "greaterOrEqual":
      return translate(texts.pfp_greater_or_equal);
    case "less":
      return translate(texts.pfp_less);
    case "greater":
      return translate(texts.pfp_greater);
    default:
      throw new Error("Unknown ComparisonOperationType ");
  }
}

function equalsOperationTypeToString(type: Ast.EqualsOperationType, translate: TranslateFn): string {
  switch (type) {
    case "equals":
      return translate(texts.pfp_equals);
    case "notEquals":
      return translate(texts.pfp_not_equals);
    default:
      throw new Error(`Unknown EqualsOperationType ${type}.`);
  }
}
