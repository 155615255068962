import { withTw } from "./with-tw";

export const PropertySelectorInputContainer = withTw("input", "form-input", ({ valid }: { readonly valid: boolean }) =>
  valid ? "" : "form-input-danger"
);

export const PropertySelectorTextAreaContainer = withTw(
  "textarea",
  "form-input-area",
  ({ valid }: { readonly valid: boolean }) => (valid ? "" : "form-input-danger")
);
