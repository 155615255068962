import { User } from "@ehb/shared";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { home } from "./home";

export type HttpFetchCmd<A> = FetchOne<A> | FetchMultiple<A> | Post<A>;

export type HttpResponseType = "text" | "json" | "blob";

export type HttpResponse = { readonly response: string | Uint8Array; readonly headers: Headers };

export interface FetchOne<A> {
  readonly home: typeof home;
  readonly type: "FetchOne";
  readonly headers: { readonly [header: string]: string };
  readonly url: string;
  readonly responseType: HttpResponseType;
  readonly onSuccess?: (response: HttpResponse) => A;
  readonly onError?: () => A;
}

export interface FetchMultiple<A> {
  readonly home: typeof home;
  readonly type: "FetchMultiple";
  readonly headers: { readonly [header: string]: string };
  readonly urls: readonly string[];
  readonly responseType: HttpResponseType;
  readonly onSuccess?: (responses: readonly HttpResponse[]) => A;
  readonly onError?: () => A;
}

export interface Post<A> {
  readonly home: typeof home;
  readonly type: "Post";
  readonly headers: { readonly [header: string]: string };
  readonly url: string;
  readonly contentType: "application/json";
  readonly body: string;
  readonly responseType: HttpResponseType;
  readonly onSuccess?: (response: HttpResponse) => A;
  readonly onError?: () => A;
}

export function fetchOne<A>(
  headers: { readonly [header: string]: string },
  url: string,
  responseType: HttpResponseType,
  onSuccess?: (response: HttpResponse) => A,
  onError?: () => A
): FetchOne<A> {
  return {
    home: "http-fetch",
    type: "FetchOne",
    headers,
    url,
    responseType,
    onSuccess,
    onError,
  };
}

export function fetchMultiple<A>(
  headers: { readonly [header: string]: string },
  urls: readonly string[],
  responseType: HttpResponseType,
  onSuccess?: (responses: readonly HttpResponse[]) => A,
  onError?: () => A
): FetchMultiple<A> {
  return {
    home: "http-fetch",
    type: "FetchMultiple",
    headers,
    urls,
    responseType,
    onSuccess,
    onError,
  };
}

export function post<A>(
  headers: { readonly [header: string]: string },
  url: string,
  responseType: HttpResponseType,
  contentType: "application/json",
  body: string,
  onSuccess?: (response: unknown) => A,
  onError?: () => A
): Post<A> {
  return {
    home: "http-fetch",
    type: "Post",
    headers,
    url,
    contentType,
    body,
    responseType,
    onSuccess,
    onError,
  };
}

export function postWithAuth(activeUser: User.ActiveUser): typeof post {
  const authHeaders = buildAuthHeaders(activeUser.accessToken);
  return <A>(
    headers: { readonly [header: string]: string },
    url: string,
    responseType: HttpResponseType,
    contentType: "application/json",
    body: string,
    onSuccess?: (response: unknown) => A,
    onError?: () => A
  ): Post<A> => post<A>({ ...authHeaders, ...headers }, url, responseType, contentType, body, onSuccess, onError);
}

export function mapCmd<A1, A2>(actionMapper: (a: A1) => A2, cmd: HttpFetchCmd<A1>): HttpFetchCmd<A2> {
  switch (cmd.type) {
    case "Post":
    case "FetchOne": {
      const onSuccess = cmd.onSuccess;
      const onError = cmd.onError;
      return {
        ...cmd,
        onSuccess: onSuccess && ((response: HttpResponse) => actionMapper(onSuccess(response))),
        onError: onError && (() => actionMapper(onError())),
      };
    }
    case "FetchMultiple": {
      const onSuccess = cmd.onSuccess;
      const onError = cmd.onError;
      return {
        ...cmd,
        onSuccess: onSuccess && ((responses: readonly HttpResponse[]) => actionMapper(onSuccess(responses))),
        onError: onError && (() => actionMapper(onError())),
      };
    }
    default: {
      return exhaustiveCheck(cmd, true);
    }
  }
}

function buildAuthHeaders(accessToken: string): Record<string, string> {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  };
}
