import React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as State from "./state";
import * as Main from "../main";
import { ErrorBoundary } from "./error-boundary";
import { Spinner } from "../elements";

interface Props {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
}

export function view({ state, dispatch }: Props): JSX.Element {
  if (state.type === "ErrorState") {
    return <div>{`Error: ${state.reason}`}</div>;
  }

  if (state.type === "LoadingDataState" || state.type === "LoadingUserSettingsState") {
    return <Spinner />;
  }

  if (state.type === "WaitingForUserSessionState") {
    return <div>LoginErrorState</div>;
  }

  if (state.type === "UserExpired") {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center flex-col gap-12">
        <div className="font-bold text-lg">Your account has been deactivated.</div>
        <div className="font-bold text-md">Please contact Veab for renewal</div>
        <button className="btn-primary" onClick={() => dispatch(State.Action.Logout())}>
          <div className="justify-center items-center">Logout</div>
        </button>
      </div>
    );
  }

  if (state.type === "UserNeedApproval") {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center flex-col gap-12">
        <div className="font-bold text-lg">Your account is waiting for approval</div>
        <button className="btn-primary" onClick={() => dispatch(State.Action.Logout())}>
          Logout
        </button>
      </div>
    );
  }

  if (state.type === "LoggedOutState") {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center flex-col gap-3">
        <div className="font-bold text-lg">You've been logged out</div>
        <button
          className="btn-primary"
          onClick={() => {
            window.location.assign(window.location.origin);
          }}
        >
          Sign in
        </button>
      </div>
    );
  }
  return (
    <div className="w-full">
      <ErrorBoundary>
        {state.mainState ? (
          <Main.View
            dispatch={Dispatch.map(State.Action.DispatchMain, dispatch)}
            state={state.mainState}
            sharedState={State.buildSharedState(state)}
          />
        ) : (
          <div />
        )}
      </ErrorBoundary>
    </div>
  );
}
