export { faArrowUpWideShort } from "@fortawesome/free-solid-svg-icons/faArrowUpWideShort";
export { faArrowDownShortWide } from "@fortawesome/free-solid-svg-icons/faArrowDownShortWide";
export { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
export { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
export { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
export { faRightToBracket } from "@fortawesome/free-solid-svg-icons/faRightToBracket";
export { faRightFromBracket } from "@fortawesome/free-solid-svg-icons/faRightFromBracket";
export { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
export { faUser } from "@fortawesome//free-solid-svg-icons/faUser";
export { faCopy } from "@fortawesome//free-solid-svg-icons/faCopy";
export { faRemove } from "@fortawesome//free-solid-svg-icons/faRemove";
export { faAdd } from "@fortawesome//free-solid-svg-icons/faAdd";

export type FasIconName =
  | "arrow-up-wide-short"
  | "farrow-down-short-wide"
  | "download"
  | "arrow-left"
  | "chevron-down"
  | "right-to-bracket"
  | "right-from-bracket"
  | "file-pdf"
  | "user"
  | "copy"
  | "remove"
  | "add";
