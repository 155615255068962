import React from "react";

export function useDebounce(
  func: () => void,
  delay: number = 500
): { readonly cancel: () => void; readonly debouncedFunc: () => void } {
  const timer = React.useRef<number | undefined>(undefined);
  const funcRef = React.useRef<() => void>(func);
  funcRef.current = func;
  const cancel = React.useCallback(() => timer.current && clearTimeout(timer.current), []);
  const debouncedFunc = React.useCallback(() => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => funcRef.current(), delay) as unknown as number;
  }, [delay]);
  return {
    cancel: cancel,
    debouncedFunc: debouncedFunc,
  };
}
