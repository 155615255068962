import * as R from "ramda";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { NonNull } from "../utils";
import { ProductVariantsRow, SearchResultColumn, SearchResultColumnTable } from "./query";
import * as Types from "./types";
import { Calculate, CalculatorFrtCoil, CalculatorResult, GetFieldFormatFn, Texts } from "..";
import { ResultValue, getSortableValue } from "../calculator-result";
import { ActiveUser, isInternalUser } from "../user";

export type ResultTable = {
  readonly columns: ReadonlyArray<ResultColumn>;
  readonly rows: ReadonlyArray<ResultRow>;
};

export type ResultColumn = {
  readonly key: string;
  readonly type: "meta" | "result" | "variant_column" | "price";
  readonly label: string;
  readonly fieldName: string | undefined;
  readonly intialSortOrder: SortOrder;
};

export type ResultRow = {
  readonly key: string;
  readonly model: string;
  readonly group: string;
  readonly productKey: string;
  readonly productId: string;
  readonly selectionKey: string;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly messages: ReadonlyArray<ResultMessage>;
  readonly values: ReadonlyArray<ResultColumnValue>;
};

export type ResultMessage = {
  readonly type: "warning" | "error";
  readonly text: string;
};

const metaColumnTypes = ["model", "warnings", "errors"] as const;
export type MetaColumnType = (typeof metaColumnTypes)[number];

export type ResultColumnValue =
  | {
      readonly sortNo: number | string;
      readonly type: "meta";
      readonly value: MetaColumnType;
    }
  | {
      readonly sortNo: number | string;
      readonly type: "result";
      readonly value: string;
    }
  | {
      readonly sortNo: number | string;
      readonly type: "variant_column";
      readonly value: string;
    }
  | {
      readonly sortNo: number | string;
      readonly type: "price";
      readonly value: string;
    };

export type SortOrder = "desc" | "asc";

export function createTableResults(
  translate: Texts.TranslateFn,
  getFieldFormat: GetFieldFormatFn,
  searchResultColumnTable: SearchResultColumnTable,
  result: Types.Result,
  calculationResult: Calculate.CalculationResultByKey,
  sortByColumnKey: string,
  sortOrder: SortOrder,
  activeUser: ActiveUser
): ResultTable {
  const colsMap = new Map<string, NonNull<SearchResultColumn>>();
  for (const match of result.matches) {
    const cols = searchResultColumnTable.filter((r) => PropertyFilter.isValid(match.variant, r.property_filter));
    cols.forEach((c) => colsMap.set(`${c.type}_${c.value}`, c));
  }
  const cols = [...colsMap.values()].filter((col) => isInternalUser(activeUser.claims) || col.type !== "price");

  const rows: Array<ResultRow> = [];
  for (let i = 0; i < result.matches.length; i++) {
    const match = result.matches[i];
    const res = calculationResult.get(match.selectionKey);
    const values = cols.map((col) => createColValue(getFieldFormat, match, col, res, i));
    const calcMessages = res?.type === "Ok" ? res.value.messages : res?.error.messages || [];
    const messages = calcMessages.map(
      (m): ResultMessage => ({
        type: m.type,
        text: translate(m.text, m.textFallback),
      })
    );
    rows.push({
      key: match.model,
      model: match.model,
      group: match.selection.pm_model,
      productKey: match.selection.productKey,
      productId: match.selection.productId,
      variant: match.variant,
      selectionKey: match.selectionKey,
      messages: messages,
      values: values,
    });
  }

  const columns = cols.map(
    (col): ResultColumn => ({
      key: col.value,
      type: col.type as "meta" | "result" | "variant_column" | "price",
      label: col.text_key ? translate(Texts.key(col.text_key)) : "",
      fieldName: col.field_name,
      intialSortOrder: col.sort_order === "desc" ? "desc" : "asc",
    })
  );

  const sortColIndex = columns.findIndex((col) => col.key === sortByColumnKey);
  const sortedRows =
    sortColIndex === -1
      ? rows
      : [...rows].sort((a, b) => {
          const aVal = a.values[sortColIndex].sortNo;
          const bVal = b.values[sortColIndex].sortNo;
          let res = 0;
          if (a.values[sortColIndex].value === "model" && b.values[sortColIndex].value === "model") {
            // Locale compare properly sorts texts in a natural order (handles numbers correctly)
            res = a.model.localeCompare(b.model, undefined, { numeric: true, sensitivity: "base" });
          } else if (typeof aVal === "number" && typeof bVal === "number") {
            res = aVal - bVal;
          } else if (typeof aVal === "string" && typeof bVal === "string") {
            res = aVal.localeCompare(bVal);
          }
          return res * (sortOrder === "desc" ? -1 : 1);
        });

  return {
    columns,
    rows: sortedRows,
  };
}

function createColValue(
  getFieldFormat: GetFieldFormatFn,
  match: Types.Match,
  col: NonNull<SearchResultColumn>,
  calcResult: CalculatorFrtCoil.CalculationResult | undefined,
  rowIndex: number
): ResultColumnValue {
  if (col.type === "meta" && validateMetaColumnType(col.value)) {
    return {
      type: "meta",
      sortNo: getSortableMetaValue(col.value, rowIndex),
      value: col.value,
    };
  } else if (col.type === "result") {
    const res = calcResult?.type === "Ok" ? calcResult.value : {};
    const value = R.path(col.value.split("."), res) as ResultValue;
    return {
      type: "result",
      sortNo: getSortableValue(getFieldFormat, col.field_name, value),
      value: CalculatorResult.renderValue(getFieldFormat, col.field_name, value),
    };
  } else if (col.type === "variant_column") {
    const value = match.productVariantRow[col.value as keyof ProductVariantsRow];
    return {
      type: "variant_column",
      sortNo: value || "",
      value: value?.toString() || "",
    };
  } else if (col.type === "price") {
    const res = calcResult?.type === "Ok" ? calcResult.value.price : {};

    const value = R.path(col.value.split("."), res) as number | undefined;
    return {
      type: "price",
      sortNo: value?.toString() || "0",
      value: value?.toString() || "",
    };
  } else {
    throw new Error(`mapValue: Invalid column result ${JSON.stringify(col)}`);
  }
}

function getSortableMetaValue(colType: MetaColumnType, rowIndex: number): string {
  if (colType === "model") {
    return rowIndex.toString();
  } else {
    return "";
  }
}

function validateMetaColumnType(type: string): type is MetaColumnType {
  if (metaColumnTypes.includes(type as MetaColumnType)) {
    return true;
  } else {
    throw new Error(`isMetaColumnType: Invalid meta column type '${type}'`);
  }
}
