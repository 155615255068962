import { unitLookup } from "uom-units";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as GQLOps from "../generated/generated-operations";
import { TranslateFn } from "../lang-texts";
import { ProductType } from "./types";
import { Texts } from "..";

export const projectIdQueryParam = "project_id";
export const itemIdQueryParam = "item_id";
export const projectQueryParams = [projectIdQueryParam, itemIdQueryParam];

export function getProjectName(translate: TranslateFn, project: Pick<GQLOps.Project, "name">): string {
  return project.name || translate(Texts.texts.untitled_project);
}

export function getItemProperties(item: Pick<GQLOps.Item, "properties">): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.fromString(item.properties, unitLookup);
}

export function getItemIdProperties(
  response: {
    readonly items: ReadonlyArray<Pick<GQLOps.ProjectResponse["items"][0], "id" | "type" | "product" | "properties">>;
  },
  productType: ProductType,
  productKey: string,
  itemId: string
): PropertyValueSet.PropertyValueSet | undefined {
  const item = response.items.find((i) => i.id === itemId);
  if (!item || (item.type !== productType && item.product === productKey)) {
    return undefined;
  }
  return getItemProperties(item);
}
