/* eslint-disable functional/prefer-readonly-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function memoize<F extends (...args: any[]) => any>(func: F): F {
  if (typeof func !== "function") {
    throw new TypeError("Can only memoize functions");
  }

  const string = func.toString().trim();
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const isAsync = !!string.match(/__async/);

  const cache: Map<any, any> = new Map();

  if (!isAsync) {
    return function (...args: ReadonlyArray<unknown>) {
      const key = JSON.stringify(args);

      if (cache.has(key)) {
        return cache.get(key);
      }

      const result = func(...args);
      cache.set(key, result);
      return result;
    } as F;
  }

  const promises: Map<any, any> = new Map();
  return async function (...args: ReadonlyArray<unknown>) {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      return cache.get(key);
    }

    if (promises.has(key)) {
      return promises.get(key);
    }
    const promise = func(...args);
    promises.set(key, promise);

    const resultUncached = await promise;

    cache.set(key, resultUncached);
    promises.delete(key);
    return resultUncached;
  } as F;
}
