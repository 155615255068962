import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GqlOps from "../generated/generated-operations";
import { EhbUnitsLookup } from "../units";
import { replaceCurly } from "./replace-curly";
import { KeyParams, PropertyDescriptionKey, PropertyNameKey, PropertyValueKey, TextKey, TextTypes } from "./texts";

export type LanguageTable = GqlOps.LangText_LanguageFragment;
export const defaultLanguage: LanguageCode = "en";
export type LanguageCode = "en" | "sv";
export type Translations = { readonly [productKey: string]: TextKeyTranslations };
// eslint-disable-next-line functional/prefer-readonly-type
type ProductTranslations = { [productKey: string]: TextKeyTranslations };
// eslint-disable-next-line functional/prefer-readonly-type
type TextKeyTranslations = { [textKey: string]: Array<Translation> };
type Translation = { readonly text: string; readonly propertyFilter?: PropertyFilter.PropertyFilter };

export function getLanguages(languageTable: LanguageTable): ReadonlyArray<LanguageCode> {
  return languageTable.language.map((l) => l.name as LanguageCode);
}

export function translationsFromQuery(getTextsQuery: GqlOps.TranslationsQuery): Translations {
  const texts: ProductTranslations = {};

  for (const product of getTextsQuery?.products || []) {
    const textsFragment = product.modules;

    if (!textsFragment) {
      return texts;
    }

    if (texts[product.key] === undefined) {
      texts[product.key] = {};
    }

    const productTexts = texts[product.key];

    const addText = (key: string, text: string, propertyFilter?: string): void => {
      if (productTexts[key] === undefined) {
        productTexts[key] = [];
      }
      productTexts[key].push({
        text,
        propertyFilter:
          propertyFilter !== undefined ? PropertyFilter.fromString(propertyFilter, EhbUnitsLookup) : undefined,
      });
    };

    const translatedTexts = textsFragment.texts.translatedTexts;
    const fallbackTexts = textsFragment.texts.fallbackTexts;

    const addedTextKeys = new Set();

    for (const x of [...translatedTexts, ...fallbackTexts]) {
      const textKey = `${x.name}_${x.property_filter}`;
      if (x.name !== null && x.name !== undefined && x.text !== null && !addedTextKeys.has(textKey)) {
        addText(x.name, x.text, x.property_filter === null ? undefined : x.property_filter);
        addedTextKeys.add(textKey);
      }
    }
  }

  return texts;
}

export type TextTranslateFn<TTextTypes> = (text: TTextTypes, defaultText?: string) => string;

export function translateTextKey(translations: Translations, text: TextTypes, defaultText?: string): string {
  switch (text.type) {
    case "TextKey":
      return translateText(translations, text, defaultText);
    case "PropertyName":
      return translatePropertyName(translations, text, defaultText);
    case "PropertyDescription":
      return translatePropertyDescription(translations, text, defaultText);
    case "PropertyValue":
      return translatePropertyValue(translations, text, defaultText);
    default:
      return exhaustiveCheck(text, true);
  }
}

function translateText(translations: Translations, text: TextKey, defaultText?: string): string {
  const rawTranslation = findTranslation(translations, text.productKey, text.key, text.variant);
  if (rawTranslation === undefined) {
    if (defaultText !== undefined) {
      return defaultText;
    } else {
      return getUndefinedTextPlaceholder(text.key, text.params);
    }
  } else {
    return replaceCurly(rawTranslation, text.params);
  }
}

function translatePropertyName(translations: Translations, text: PropertyNameKey, defaultText?: string): string {
  const rawTranslation = findTranslation(translations, text.productKey, `p_standard_${text.property}`);
  if (rawTranslation === undefined) {
    if (defaultText !== undefined) {
      return defaultText;
    } else {
      return `{${text.productKey}_${text.property}}`;
    }
  } else {
    return rawTranslation;
  }
}

function translatePropertyDescription(
  translations: Translations,
  text: PropertyDescriptionKey,
  defaultText?: string
): string {
  const rawTranslation = findTranslation(translations, text.productKey, `p_long_${text.property}`);
  if (rawTranslation === undefined) {
    if (defaultText !== undefined) {
      return defaultText;
    } else {
      return `{${text.productKey}_${text.property}_description}`;
    }
  } else {
    return rawTranslation;
  }
}

function translatePropertyValue(translations: Translations, text: PropertyValueKey, defaultText?: string): string {
  const value = PropertyValueSet.getInteger(text.property, text.variant);
  const rawTranslation = findTranslation(translations, text.productKey, `pv_${text.property}_${value}`);
  if (rawTranslation === undefined) {
    if (defaultText !== undefined) {
      return defaultText;
    } else {
      return `{${text.productKey}_${text.property}_${value}}`;
    }
  } else {
    return rawTranslation;
  }
}

function getUndefinedTextPlaceholder(key: string, params?: KeyParams): string {
  return params && Object.keys(params).length > 0 ? `{${key}, ${JSON.stringify(params)}}` : `{${key}}`;
}

function findTranslation(
  translations: Translations,
  productKey: string,
  textKey: string,
  variant?: PropertyValueSet.PropertyValueSet
): string | undefined {
  const texts = translations[productKey][textKey];
  if (!texts) {
    // eslint-disable-next-line no-console
    // console.warn("missing textkey", `${productKey}_${textKey}`);
  }
  const text = texts?.find((text) =>
    text.propertyFilter ? PropertyFilter.isValid(variant || PropertyValueSet.Empty, text.propertyFilter) : true
  );
  return text?.text;
}
