export interface Euler {
  readonly x: number;
  readonly y: number;
  readonly z: number;
}

export const eulerZero: Euler = {
  x: 0,
  y: 0,
  z: 0,
};

export function eulerCreate(x: number, y: number, z: number): Euler {
  return {
    x: x,
    y: y,
    z: z,
  };
}

export function eulerAdd(a: Euler, b: Euler): Euler {
  return {
    x: a.x + b.x,
    y: a.y + b.y,
    z: a.z + b.z,
  };
}

export function eulerToArray(e: Euler): readonly [number, number, number, string] {
  return [e.x, e.y, e.z, "ZYX"];
}

export function eulerEquals(a: Euler, b: Euler): boolean {
  return a.x === b.x && a.y === b.y && a.z === b.z;
}
