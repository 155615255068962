import { Vector3 } from "./vector3";
import { Color } from "./color";
import { Object3d } from "./object3d";

export interface Scene {
  readonly name: string;
  readonly ambientLight: Color;
  readonly objects: ReadonlyArray<Object3d>;
  readonly lights: ReadonlyArray<Light>;
}

export function sceneCreate(
  name: string,
  ambientLight: Color,
  objects: ReadonlyArray<Object3d>,
  lights: ReadonlyArray<Light>
): Scene {
  return {
    name: name,
    ambientLight: ambientLight,
    objects: objects,
    lights: lights,
  };
}

export interface Light {
  readonly position: Vector3;
  readonly lookAt: Vector3;
  readonly color: Color;
  readonly intensity: number;
}

export function lightCreate(position: Vector3, lookAt: Vector3, color: Color, intensity: number): Light {
  return {
    position: position,
    lookAt: lookAt,
    color: color,
    intensity: intensity,
  };
}
