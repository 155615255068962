import * as R from "ramda";
import log from "loglevel";
import { X3DModel, MagiCloudUrl, X3dMeshData, RequestStatistics } from "./types";
import { retryFetch } from "../fetch";

/**
 * 1.2  X3D web rotation
 *
 *  HTTP GET method for accessing X3D formatted geometry of products
 *  URL: https://[hostname]/api/geometry/{productId}/X3D
 *
 *  Request parameters:
 *  productId: Identifies the product or product variant.
 *  Response: X3D formatted geometry specification
 *  Content-Type: model/x3d+xml
 *  Authentication: No authenication required.
 *
 * NOTE: This is a special version of this API that is undocumented.
 * Using Product ID + VariantQpdID (this keeps always up to date with any changes of the product)
 * https://partnerapi.magicloud.com/api/geometry/70843b8d-5afe-42d9-b695-01b04fa4e8a7/VVKN-B-S-400/2D
 *
 */

const manufacturerId = "7eade239-9178-472e-a1bb-0759166ad802"; // VEAB
const sessionId = "2180a38e82d0fb00afdee4df7b1c5a6c";

type MagicloudId = ArticleNo | Guid;

interface ArticleNo {
  readonly type: "articleNo";
  readonly articleNo: string;
}

interface Guid {
  readonly type: "guid";
  readonly guid: string;
}

export async function getX3DModelByArticleNo(articleNo: string, serverUrl: string | undefined): Promise<X3DModel> {
  const badResponse = {
    magicloudId: articleNo,
    data: null,
    a3dMesh: null,
    dxfUrl: null,
    revitUrls: [],
    dimensionsUrl: null,
  };
  const alternatives = getAlternatives(articleNo);
  const promises = alternatives.map((a) => getX3dModelFromMagicloud({ type: "articleNo", articleNo: a }, serverUrl));
  const all = await Promise.all(promises);
  const best = R.findLast((a) => !!a, all);
  if (!best) {
    return badResponse;
  }
  return {
    ...best,
    magicloudId: articleNo,
  };
}

export async function getMagicloudUrlByArticleNo(articleNo: string): Promise<MagiCloudUrl> {
  const badResponse = {
    magicloudId: articleNo,
    url: null,
  };
  const alternatives = getAlternatives(articleNo);
  const promises = alternatives.map((a) => getMagiCloudUrl({ type: "articleNo", articleNo: a }));
  const all = await Promise.all(promises);
  const best = R.findLast((a) => !!a, all);
  if (!best) {
    return badResponse;
  }
  // eslint-disable-next-line no-console
  return {
    ...best,
    magicloudId: articleNo,
  };
}

export async function getX3DModelByGuid(guid: string, serverUrl: string | undefined): Promise<X3DModel> {
  const badResponse = {
    magicloudId: guid,
    a3dMesh: null,
    dxfUrl: null,
    revitUrls: [],
    dimensionsUrl: null,
  };
  const model = await getX3dModelFromMagicloud({ type: "guid", guid }, serverUrl);
  if (model) {
    return {
      ...model,
      magicloudId: guid,
    };
  } else {
    return badResponse;
  }
}

export async function getMagicloudUrlByGuid(guid: string): Promise<MagiCloudUrl> {
  const badResponse = {
    magicloudId: guid,
    url: null,
  };
  const url = await getMagiCloudUrl({ type: "guid", guid });
  if (url) {
    return {
      ...url,
      magicloudId: guid,
    };
  } else {
    return badResponse;
  }
}

export function createFileDownloadMcUrl(mcUrl: string, reqStats: RequestStatistics): string {
  const url = mcUrl.split("?")[0];
  const currentQuery = mcUrl.split("?")[1];
  const query = [
    ...(currentQuery ? [currentQuery] : []),
    ...(reqStats.fileType ? [`filetype=${encodeURIComponent(reqStats.fileType)}`] : []),
    ...(reqStats.orderingCode ? [`orderingcode=${encodeURIComponent(reqStats.orderingCode)}`] : []),
    ...(reqStats.itemNumber ? [`itemnumber=${encodeURIComponent(reqStats.itemNumber)}`] : []),
    ...(reqStats.market ? [`market=${encodeURIComponent(reqStats.market)}`] : []),
    ...(reqStats.source ? [`source=${encodeURIComponent(reqStats.source)}`] : []),
    ...(reqStats.fileName && !currentQuery.includes("filename=")
      ? [`filename=${encodeURIComponent(reqStats.fileName)}`]
      : []),
  ].join("&");
  return `${url}?${query}`;
}

export function createProxiedMagicloudUrl(url: string | undefined): string | undefined {
  if (!url) {
    return undefined;
  }
  const serverUrl = "https://mcp.systemair.com";
  return `${serverUrl || ""}/magicloud/${url}`;
}

function stringToBase64(raw: string): string {
  if (typeof window === "undefined") {
    return Buffer.from(raw).toString("base64");
  }

  return btoa(raw);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function getMagicloudJson(magicloudId: MagicloudId): Promise<any> {
  const productUrl =
    magicloudId.type === "articleNo"
      ? `https://cdn-partnerapi.magicloud.com/api/products/${manufacturerId}/base64/${stringToBase64(
          magicloudId.articleNo
        )}?applicationId=systemair&sessionId=${sessionId}`
      : `https://cdn-partnerapi.magicloud.com/api/products/${magicloudId.guid}`;
  const productResult = await retryFetch(productUrl, { redirect: "follow" }, { maxAttempts: 1 });
  if (!productResult.ok) {
    return undefined;
  }
  return productResult.json();
}

async function getMagiCloudUrl(magicloudId: MagicloudId): Promise<MagiCloudUrl | undefined> {
  try {
    const json = await getMagicloudJson(magicloudId);
    if (json === undefined) {
      return undefined;
    }
    const magicloudUrl = `${json.MagiCloudUrl}`;
    return {
      magicloudId: magicloudId.type === "articleNo" ? magicloudId.articleNo : magicloudId.guid,
      url: magicloudUrl,
    };
  } catch (e) {
    log.warn("Error when trying to fetch product url from magicloud: " + e.toString());
    return undefined;
  }
}

async function getX3dModelFromMagicloud(
  magicloudId: MagicloudId,
  _serverUrl: string | undefined
): Promise<X3DModel | undefined> {
  try {
    const json = await getMagicloudJson(magicloudId);
    if (json === undefined) {
      return undefined;
    }
    const x3dUrl = `${json.X3DUrl}&applicationId=systemair&sessionId=${sessionId}`;
    const x3dResult = await retryFetch(x3dUrl, { redirect: "follow" }, { maxAttempts: 1 });
    if (!x3dResult.ok) {
      return undefined;
    }
    const id = magicloudId.type === "articleNo" ? magicloudId.articleNo : magicloudId.guid;
    const data = await x3dResult.text();
    const a3dMesh = x3dMeshDataCreate(id, data);
    return {
      magicloudId: id,
      a3dMesh: a3dMesh,
      dxfUrl: createProxiedMagicloudUrl(json.DxfUrl),
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      revitUrls: json.Attachments.map((a: any) => ({
        name: a.Type,
        url: createProxiedMagicloudUrl(a.Uri),
      })),
      dimensionsUrl: json.DimensionsUrl,
    };
  } catch (e) {
    log.warn("Error when trying to fetch X3D from magicloud: " + e.toString());
    return undefined;
  }
}

function getAlternatives(articleNo: string): ReadonlyArray<string> {
  const parts = articleNo.split("&");
  return R.range(0, parts.length).map((i) => parts.slice(0, i + 1).join("+"));
}

function x3dMeshDataCreate(id: string, data: string): X3dMeshData {
  return {
    id: id,
    data: data,
  };
}
