import * as AbstractImage from "abstract-image";

export const primaryColor = "#cf003d";
export const primaryColor60 = "#7a8eb3";
export const primaryColor30 = "#bcc6d9";
export const primaryColor20 = "#d3d9e6";
export const primaryColor05 = "#F4F5F9";

export const secondaryColor = "#00A7D8";
export const highLightColor = "#2cb52c";

export const bandColor = "#004985";

export const disabledButton = "rgb(60, 60, 60)";
export const disabledText = "rgb(120, 120, 120)";

export const fadedText = "rgb(80, 80, 80)";

export const panelBackground = "#fafafa";
export const panelBorder = "1px solid #ccc";

export const blue30 = "#B2D9E4";
export const blue80 = "#15A4D5";
export const blue100 = "#084380";

export const gray10 = "#FAFAFA";
export const gray30 = "#E4E4E4";
export const gray50 = "#CCCCCC";
export const gray80 = "#888888";
export const gray100 = "#707070";

export const red50 = "#FF6666";

export const diagramAreaColor = hexToAbstractImageColor(blue30);
export const diagramSystemLineColor = hexToAbstractImageColor(blue100);
export const diagramDesiredPointColor = hexToAbstractImageColor(blue80);
export const diagramInvalidColor = hexToAbstractImageColor(red50);
export const diagramWorkPointColor = hexToAbstractImageColor(blue100);
export const diagramLineColor = hexToAbstractImageColor(blue100);
export const diagramGridMainColor = hexToAbstractImageColor(gray80);
export const diagramGridSubColor = hexToAbstractImageColor(gray50);
export const diagramBackground = hexToAbstractImageColor(gray10);
export const diagramHighLightColor = hexToAbstractImageColor(highLightColor);

export const multipleCurveColors: ReadonlyArray<string> = [
  "#D0D1E6",
  "#A6BDDB",
  "#74A9CF",
  "#3690C0",
  "#0570B0",
  "#034E7B",
];
export const multipleCurveColorsAIC: ReadonlyArray<AbstractImage.Color> = multipleCurveColors.map((c) =>
  hexToAbstractImageColor(c)
);

export const familyReportPrimaryColor = "#004985";
export const familyReportLightColor = "#808285";
export const familyReportTocBackground = "#cdecfd";

function hexToAbstractImageColor(hex: string): AbstractImage.Color {
  return AbstractImage.fromString("#FF" + hex.substring(1)) || AbstractImage.white;
}

export const mainTextColor = "#004985";
export const propertyNameColor = "#004985";
export const mainBackgroundColor = "#E8E8E8";
export const altBackgroundColor = "#CCDBE7";
export const linkColor = "#00A7D8";
export const linkHoverColor = "#99DCEF";
export const disabledColor = "#E5ECF3";
export const selectorBackGroundColor = "#FFFFFF";
export const expanderHeaderBackground = "#FFFFFF";
export const errorColor = "#E46F59";
export const tableBackgroundColor = "#FFFFFF";
export const radioGroupSelected = "#00a7d8";
export const radioGroupHover = "#99DCEF";
export const borderColor = "#e9eff5";
export const gradient = "systemair";
export const selectorFocusColor = "#00A7D8";
export const loaderColor = "#004985";
