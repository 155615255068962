import gql from "graphql-tag";

export const allProductsQuery = gql`
  query AllProducts {
    products {
      key
      id
    }
  }
`;
