import { Amount } from "uom";
import { Quantity } from "uom-units";
import { PropertyValueSet } from "@promaster-sdk/property";
import { FP, Search } from "..";
import { TextKey } from "../lang-texts";
import { CalculationCustomErrors, CalculationInputTable, Product } from "./query";
import { FritermOutput } from "../dll/friterm";
import { Price } from "../calculate-water-dx";

export type CalculationData = {
  readonly calculationInput: CalculationInputTable;
  readonly products: ReadonlyArray<Product>;
  readonly allowedMessages: ReadonlyArray<string>;
  readonly customCalculationErrors: CalculationCustomErrors;
};

export type CoilInput = {
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly selection: Search.Selection;
  readonly discount: number;
} & AirInput &
  AirflowInput &
  (DxCoilInput | WaterCoilInput);

export type CoilInputError = {
  readonly messages: ReadonlyArray<Message>;
};

export type CoilResult = {
  readonly atmosphericPressure: Amount.Amount<Quantity.Pressure>;
  readonly altitude: Amount.Amount<Quantity.Length>;

  readonly airDensity: Amount.Amount<Quantity.Density>;
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly airMassFlow: Amount.Amount<Quantity.MassFlow>;
  readonly airVelocity: Amount.Amount<Quantity.Velocity>;
  readonly airPressureDrop: Amount.Amount<Quantity.Pressure>;
  readonly airInletTemperature: Amount.Amount<Quantity.Temperature>;
  readonly airInletHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  readonly airOutletTemperature: Amount.Amount<Quantity.Temperature>;
  readonly airOutletHumidity: Amount.Amount<Quantity.RelativeHumidity>;

  readonly capacity: Amount.Amount<Quantity.Power>;
  readonly capacitySensible: Amount.Amount<Quantity.Power>;

  readonly fluidDensity: Amount.Amount<Quantity.Density>;
  readonly fluidFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly fluidMassFlow: Amount.Amount<Quantity.MassFlow>;
  readonly fluidPressureDrop: Amount.Amount<Quantity.Pressure>;
  readonly fluidVelocity: Amount.Amount<Quantity.Velocity>;
  readonly fluidMixtureRatio: Amount.Amount<Quantity.Dimensionless>;
  readonly fluidInletTemperature: Amount.Amount<Quantity.Temperature>;
  readonly fluidOutletTemperature: Amount.Amount<Quantity.Temperature>;
  readonly condensationTemperature: Amount.Amount<Quantity.Temperature>;
  readonly superheating: Amount.Amount<Quantity.Temperature>;
  readonly subcooling: Amount.Amount<Quantity.Temperature>;

  readonly tubeVolume: Amount.Amount<Quantity.Volume>;
  readonly tubeMaterial: TextKey;
  readonly finMaterial: TextKey;

  readonly weight: Amount.Amount<Quantity.Mass>;
  readonly messages: ReadonlyArray<Message>;

  readonly price: Price;
};

export type CoilErrorResult = {
  readonly messages: ReadonlyArray<Message>;
};

export type Message = {
  readonly type: "error" | "warning";
  readonly text: TextKey;
  readonly textFallback?: string;
};

export type AirInput = {
  readonly airInletTempC: number;
  readonly relativeHumidity: number;
};

export type AirflowInput = AirVolumetricInput | AirVelocityInput;
export type AirVolumetricInput = { readonly airflowInput: "volumetric"; readonly airFlowCmph: number };
export type AirVelocityInput = { readonly airflowInput: "velocity"; readonly airVelocityMps: number };

export type DxCoilInput = DxInput & DxModeInput;

export type DxInput = {
  readonly coilType: "dx";
  readonly frtFluidId: number;
  readonly superheatingTempC: number;
  readonly subcoolingTempK: number;
};

export type DxModeInput = DxHeatingInput | DxCoolingInput;
export type DxHeatingInput = {
  readonly mode: "heating";
  readonly condensingTempC: number;
};
export type DxCoolingInput = {
  readonly mode: "cooling";
  readonly evaporatingTempC: number;
};

export type WaterCoilInput = WaterInput & WaterCalculationMethod;

export type WaterInput = {
  readonly coilType: "water";
  readonly mode: "cooling" | "heating";
  readonly frtFluidId: number;
  readonly fluidMixturePercent: number;
  readonly fluidInletTempC: number;
};

export type WaterCalculationMethod = FluidOutletTempMethod | FluidFlowMethod;
export type FluidOutletTempMethod = {
  readonly calculationMethod: "outlet_fluid_temp";
  readonly fluidOutletTempC: number;
};
export type FluidFlowMethod = {
  readonly calculationMethod: "fluid_flow";
  readonly fluidFlowCmph: number;
};

export type CalculationResult = FP.Result<CoilErrorResult, CoilResult>;
export type CalculationResultRaw = FP.Result<CoilErrorResult, FritermOutput>;

export type CalculationResultByModel = ReadonlyMap<string, CalculationResult>;
