import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Texts } from "@ehb/shared";
import React from "react";
import { getProjectName, ProductType } from "@ehb/shared/src/project";
import * as GQLOps from "../generated/generated-operations";
import { State, Action } from "./state";
import { Button } from "../elements/button";
import { Group } from "../elements/group";

export function ProductProjectView({
  productKey,
  productType,
  properties,
  translate,
  dispatch,
  state,
  selectedItemId,
  makeSelectionUrl,
  makeItemUrl,
}: {
  readonly productKey: string;
  readonly productType: ProductType;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly selectedItemId: string | undefined;
  readonly makeSelectionUrl: (item: GQLOps.Item | undefined) => string;
  readonly makeItemUrl: (item: GQLOps.Item | undefined) => string;
}): JSX.Element | null {
  if (state.type !== "open" && state.type !== "busy") {
    return null;
  }
  const item = state.project.items.find((i) => i.id === selectedItemId && i.type === productType);
  return (
    <Group header={getProjectName(translate, state.project.project)}>
      <div className="flex flex-col gap-4">
        {item ? (
          <>
            <div>
              <Button
                iconLeft={"arrow-left"}
                label={translate(Texts.texts.back_to_selection)}
                href={makeSelectionUrl(item)}
              />
            </div>
            <div className="border-slate-100 border-b" />
            <div className="flex flex-row flex-wrap gap-2">
              <Button
                iconLeft={"remove"}
                label={translate(Texts.texts.remove_item)}
                onClick={() => selectedItemId && dispatch(Action.DeleteItem(selectedItemId, makeItemUrl(undefined)))}
              />
              <Button
                iconLeft={"copy"}
                label={translate(Texts.texts.duplicate_item)}
                onClick={() => selectedItemId && dispatch(Action.DuplicateItem(selectedItemId, makeItemUrl))}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row flex-wrap gap-2">
              <Button
                iconLeft={"add"}
                label={translate(Texts.texts.add_item)}
                onClick={() => dispatch(Action.CreateItem(productType, productKey, properties, makeItemUrl))}
              />
            </div>
          </>
        )}
      </div>
    </Group>
  );
}
