/* eslint-disable functional/no-class */
/* eslint-disable functional/no-this-expression */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React from "react";
import * as Sentry from "@sentry/react";
import * as State from "./state";
import { SvgReload } from "../elements";

interface Props {}

interface State {
  readonly hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount(): void {
    window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
      this.reportError(event.reason);
    });

    window.onerror = (_message: string, _source: string, _lineno: number, _colno: number, error: Error | undefined) => {
      this.reportError(error);
      return true;
    };
  }

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
    this.reportError(error);
    this.setState({ hasError: true }); // You can also log the error to an error reporting service
  }

  reportError(error: Error | undefined | null): void {
    if (this.state.hasError) {
      return;
    }

    // Handle the case where there is no error object
    // Error may be null or undefined if the error happens in a script loaded from external site because details
    // of errors in external scripts are secret
    if (!error) {
      // Error from outside React Compontent, for example an error in the javascript for the website menu,
      // these errors should not crash our application, just return and do not show an error
      return;
    }

    if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
      Sentry.captureException(error);
    }

    // eslint-disable-next-line no-console
    console.error(error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div className="max-w-screen-md mx-auto mt-104">
            <h1 className="text-5xl mt-6 mb-6">An error occurred</h1>
            <p className="text-lg mb-4">
              We have detected an error and reported it directly to the software development team. We are so sorry about
              the inconvenience.
            </p>
            <p className="text-lg">Press the button below to reload the page and try again.</p>
            <div>
              <button onClick={() => window.location.reload()} className="btn-primary">
                <SvgReload width={"20px"} className="decoration-white text-white mt-[3px]" />
                <p className="decoration-white text-white ml-2 font-bold">Reload</p>
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
