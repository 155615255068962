import { PropertyFilter } from "@promaster-sdk/property";
import { PP } from "..";
import { logWarn } from "../logger";
import { Mutable, mapPropertyFilter, nullToEmpty } from "../utils";
import * as Q from "./query";
import * as Types from "./types";
import { SearchQuery, SearchSingleQuery } from "../generated/generated-operations";

const isAllProducts = (r: SearchQuery | SearchSingleQuery): r is SearchQuery => Object.keys(r).includes("products");

export function createSearchData(response: SearchQuery | SearchSingleQuery): Types.SearchData {
  const searchProduct = response.searchProduct;
  if (!searchProduct) {
    throw new Error("createSearchData: search product not found");
  }
  const searchProductTables = searchProduct.modules.custom_tables;
  const products = [];
  if (isAllProducts(response)) {
    products.push(...response.products);
  } else {
    if (!response.product) {
      throw new Error("createSearchData: product not found");
    }
    products.push(response.product);
  }

  const searchProducts = searchProductTables["search_products"];
  const searchColumnProperties = mapPropertyFilter(searchProductTables["search_column_properties"]);
  const searchInput = mapPropertyFilter(searchProductTables["search_input"]);
  const searchCalculationLimits = mapPropertyFilter(searchProductTables["search_calculation_limits"]);
  const searchCalculationMargin = mapPropertyFilter(searchProductTables["search_calculation_margin"]);

  // Mapping between property variant table values to property values
  const propertyToSearchValue = new Map<string, string>();
  const searchToPropertyValue = new Map<string, number>();
  for (const row of searchColumnProperties) {
    const columnName = row.column_name;
    const propertyName = row.property;
    if (!Types.isSearchColumn(columnName) || !propertyName) {
      logWarn(`createSearchData: ${columnName} is not a search column`);
      continue;
    }
    const values = getColumnValues(products, columnName);
    for (let i = 0; i < values.length; i++) {
      const propertyValue = i;
      const value = values[i];
      propertyToSearchValue.set(`${propertyName}$${propertyValue.toString()}`, value);
      searchToPropertyValue.set(`${columnName}$${value}`, propertyValue);
    }
  }

  // Map selections to PropertyValueSet
  const refedProperties = [
    ...new Set(searchInput.flatMap((r) => PropertyFilter.getReferencedProperties(r.property_filter))),
  ];
  const properties = PP.mapProperties(searchProduct.modules.properties.property);
  const variants = PP.generateVariantsForProperties(properties, refedProperties);
  const selectionToProperties = [];
  const colsToIgnore = searchColumnProperties.map((r) => r.column_name);
  for (const variant of variants) {
    const selection: Partial<Mutable<Types.Selection>> = {};
    const inputs = searchInput.filter((r) => PropertyFilter.isValid(variant, r.property_filter));
    for (const input of inputs) {
      for (const col of Types.searchColumns) {
        if (colsToIgnore.includes(col)) {
          continue;
        }
        const value = input[col];
        if (!value) {
          continue;
        }
        selection[col] = value;
      }
    }
    selectionToProperties.push({ properties: variant, selection });
  }

  const searchResultColumns = mapPropertyFilter(searchProduct.modules.custom_tables.search_result_columns)
    .map(nullToEmpty)
    .filter((r) => r.value && r.type);

  return {
    searchProduct: isAllProducts(response) ? undefined : response,
    searchProducts,
    searchColumnProperties,
    searchInput,
    searchCalculationLimits,
    searchCalculationMargin,
    products,
    propertyToSearchValue,
    searchToPropertyValue,
    selectionToProperties,
    searchResultColumns,
  };
}

function getColumnValues(products: Q.Products, columnName: Types.SearchColumn): ReadonlyArray<string> {
  const values = new Set<string>();
  for (const product of products) {
    for (const row of product.modules.custom_tables.product_variants) {
      const value = (row[columnName] || "").toString();
      if (value) {
        values.add(value);
      }
    }
  }
  return [...values];
}
