import { A3D } from "@ehb/shared";
import * as React from "react";
import { RenderState } from "./viewer-3d";

type MouseButton = "Left" | "Middle" | "Right";

interface State {
  readonly oldMousePos: A3D.Vector2 | undefined;
  readonly cameraChanged: boolean;
}

export function useCameraControl(renderStateRef: React.MutableRefObject<RenderState>): {
  readonly onMouseMove: (e: React.MouseEvent) => void;
  readonly onMouseDown: (e: React.MouseEvent) => void;
  readonly onMouseUp: (e: React.MouseEvent) => void;
  readonly onContextMenu: (e: React.MouseEvent) => void;
} {
  const stateRef = React.useRef<State>({
    oldMousePos: undefined,
    cameraChanged: false,
  });

  const onMouseMove = React.useCallback((e: React.MouseEvent): void => {
    const button = getMouseMoveButton(e);
    const mousePos = getMousePos(e);

    const oldPos = stateRef.current.oldMousePos || mousePos;
    stateRef.current = {
      ...stateRef.current,
      oldMousePos: mousePos,
    };

    if (button === "Left") {
      const newOrbitCamera = handleCameraPositionMove(mousePos, oldPos, renderStateRef.current.orbitCamera);
      renderStateRef.current = {
        ...renderStateRef.current,
        orbitCamera: newOrbitCamera,
        shouldRerender: true,
      };
      stateRef.current = {
        ...stateRef.current,
        cameraChanged: true,
      };
    } else if (button === "Right") {
      const newOrbitCamera = handleCameraZoom(mousePos, oldPos, renderStateRef.current.orbitCamera);
      renderStateRef.current = {
        ...renderStateRef.current,
        orbitCamera: newOrbitCamera,
        shouldRerender: true,
      };
      stateRef.current = {
        ...stateRef.current,
        cameraChanged: true,
      };
    }
  }, []);

  const onMouseDown = React.useCallback((_e: React.MouseEvent): void => {
    stateRef.current = {
      ...stateRef.current,
      cameraChanged: false,
    };
  }, []);

  const onMouseUp = React.useCallback((e: React.MouseEvent): void => {
    if (stateRef.current.cameraChanged) {
      e.preventDefault();
      renderStateRef.current = {
        ...renderStateRef.current,
        shouldRerender: stateRef.current.cameraChanged,
      };
      renderStateRef.current.onCameraUpdate?.(renderStateRef.current.orbitCamera);
    }
  }, []);

  return { onMouseMove, onMouseDown, onMouseUp, onContextMenu: onMouseUp };
}

function handleCameraZoom(
  oldMousePos: A3D.Vector2,
  newMousePos: A3D.Vector2,
  oldCamera: A3D.OrbitCamera
): A3D.OrbitCamera {
  const diff = A3D.vec2Sub(newMousePos, oldMousePos);
  const min = Math.min(diff.x, diff.y);
  const max = Math.max(diff.x, diff.y);
  const percentChange = (Math.abs(min) > Math.abs(max) ? min : max) * 0.003;
  const oldZoom = oldCamera.zoom;
  const newCamera = A3D.orbitCameraCreate(
    oldCamera.position,
    oldCamera.target,
    oldZoom * (1 + percentChange),
    oldCamera.ortho,
    oldCamera.aspect,
    oldCamera.near,
    oldCamera.far
  );
  return newCamera;
}

function handleCameraPositionMove(
  oldMousePos: A3D.Vector2,
  newMousePos: A3D.Vector2,
  oldCamera: A3D.OrbitCamera
): A3D.OrbitCamera {
  const diffX = oldMousePos.x - newMousePos.x;
  const newCameraX = oldCamera.position.x - diffX * 0.01;
  const diffY = oldMousePos.y - newMousePos.y;
  const newCameraY = oldCamera.position.y - diffY * 0.01;
  const cameraY = Math.min(Math.PI - 0.00001, Math.max(0.00001, newCameraY));
  const newPosition = A3D.vec2Create(newCameraX, cameraY);
  const newCamera = A3D.orbitCameraCreate(
    newPosition,
    oldCamera.target,
    oldCamera.zoom,
    oldCamera.ortho,
    oldCamera.aspect,
    oldCamera.near,
    oldCamera.far
  );
  return newCamera;
}

function getMouseMoveButton(e: React.MouseEvent): MouseButton | undefined {
  if (e.buttons === 1) {
    return "Left";
  } else if (e.buttons === 2) {
    return "Right";
  } else if (e.buttons === 4) {
    return "Middle";
  }
  return undefined;
}

function getMousePos(e: React.MouseEvent): A3D.Vector2 {
  const target = e.currentTarget;
  const rect = target.getBoundingClientRect();
  const offsetX = e.clientX - rect.left;
  const offsetY = e.clientY - rect.top;
  const mousePos = A3D.vec2Create(offsetX, offsetY);
  return mousePos;
}
