import { logError } from "../logger";

const maxAttemptsDefault = 2;

export async function retryFetch(
  url: string | Request,
  init?: RequestInit | undefined,
  options?: {
    readonly returnResponseOnError?: boolean;
    readonly breakOnHTTPCodes?: ReadonlyArray<number>;
    readonly maxAttempts?: number;
  }
): Promise<Response> {
  const errorResponses: Array<string> = [];
  let response = undefined;
  const maxAttempts = options?.maxAttempts || maxAttemptsDefault;
  for (let i = 0; i < maxAttempts; ++i) {
    try {
      response = await fetch(url, init);
      if (response.ok) {
        return response;
      } else {
        const responseText = await response.text();
        errorResponses.push(`Attempt: ${i}, NotOk: ${responseText.slice(0, 100)}`);
        if (options?.breakOnHTTPCodes?.includes(response.status)) {
          break;
        }
      }
    } catch (e) {
      errorResponses.push(`Attempt: ${i}, Exception: ${e.message}`);
      logError("Failed fetch: ", e);
    }
  }
  if (options?.returnResponseOnError && response) {
    return response;
  } else {
    throw new Error(
      `Failed to fetch ${
        typeof url === "string" ? url : "-"
      } after ${maxAttempts} attempts (Error: ${errorResponses.join("\n")})`
    );
  }
}

export async function downloadFile<T extends ArrayBufferView>(url: string): Promise<T> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.statusText}`);
  }
  const arrayBuffer = await response.arrayBuffer();
  const result = new Uint8Array(arrayBuffer);
  return result as unknown as T;
}
