export interface Color {
  readonly r: number;
  readonly g: number;
  readonly b: number;
}

export function colorCreate(r: number, g: number, b: number): Color {
  return {
    r: r,
    g: g,
    b: b,
  };
}

export const white = colorCreate(1, 1, 1);
export const black = colorCreate(0, 0, 0);
