import gql from "graphql-tag";
import { DefaultFieldFormatFragment } from "../generated/generated-operations";

export const defaultFieldFormatFragment = gql`
  fragment defaultFieldFormat on Module_custom_tables {
    default_field_format {
      language
      field_name
      unit
      decimal_count
      type
    }
  }
`;

export type DefaultFieldFormatTable = DefaultFieldFormatFragment["default_field_format"];
