import { v4 as uuid } from "uuid";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { unitLookup } from "uom-units";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Cmd } from "@typescript-tea/core";
import { PropertyValue, PropertyValueSet } from "@promaster-sdk/property";
import { gql } from "graphql-tag";
import {
  inputFromPatch,
  patchResponse,
  SharedState,
  NavigationEffectManager as Navigation,
  Patch,
} from "@ehb/client-infra";
import { getItemProperties, itemIdQueryParam, projectIdQueryParam } from "@ehb/shared/src/project";
import { Calculator, logWarn, Project, Search, Texts } from "@ehb/shared";
import { ActiveUser } from "@ehb/shared/src/user";
import { productQuery } from "@ehb/shared/src/graphql-queries";
import { customItemsQueryParam, decodeCustomItemsFromPvs } from "@ehb/shared/src/product-utils";
import { CalculateRequest } from "@ehb/shared/src/calculator";
import * as GQLOps from "../generated/generated-operations";
import { clientConfig } from "../client-config";

const projectFragment = gql`
  fragment projectResponse on ProjectResponse {
    project {
      id
      name
      owner
      createdDate
      modifiedDate
    }
    items {
      id
      sortNo
      type
      product
      properties
      quantity
    }
  }
`;

const projectByIdQuery = gql`
  query projectStateById($projectId: ID!) {
    project(id: $projectId) {
      ...projectResponse
    }
  }
  ${projectFragment}
`;

const projectByItemQuery = gql`
  query projectStateByItem($itemId: ID!) {
    projectByItem(itemId: $itemId) {
      ...projectResponse
    }
  }
  ${projectFragment}
`;

const lastModifiedQuery = gql`
  query projectLastModified($projectId: ID!) {
    project(id: $projectId) {
      project {
        id
        modifiedDate
      }
    }
  }
`;

const createProjectMutation = gql`
  mutation projectCreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
    }
  }
`;

const updateProjectMutation = gql`
  mutation projectUpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      name
      owner
      createdDate
      modifiedDate
    }
  }
`;

const deleteProjectMutation = gql`
  mutation projectDeleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;

const updateItemFragment = gql`
  fragment projectUpdateItem on Item {
    id
    sortNo
    type
    product
    properties
    quantity
  }
`;

const createItemMutation = gql`
  mutation projectCreateItem($input: CreateItemInput!) {
    createItem(input: $input) {
      ...projectUpdateItem
    }
  }
  ${updateItemFragment}
`;

const updateItemMutation = gql`
  mutation projectUpdateItem($input: UpdateItemInput!) {
    updateItem(input: $input) {
      ...projectUpdateItem
    }
  }
  ${updateItemFragment}
`;

const updateItemPropertiesMutation = gql`
  mutation projectUpdateItemProperties($input: UpdatePropertiesInput!) {
    updateItemProperties(input: $input) {
      ...projectUpdateItem
    }
  }
  ${updateItemFragment}
`;

const deleteItemMutation = gql`
  mutation projectDeleteItem($id: ID!) {
    deleteItem(id: $id) {
      id
    }
  }
`;

type DataStateReceiving = {
  readonly type: "receiving";
  readonly electricalHeaterProduct: GQLOps.ProductQuery | undefined;
  readonly searchQuery: GQLOps.SearchQuery | undefined;
};

type DataStateLoaded = {
  readonly type: "loaded";
  readonly electricalHeaterProduct: GQLOps.ProductQuery;
  readonly searchData: Search.SearchData;
};

type ProductDataState = DataStateReceiving | DataStateLoaded;

type OpenState = {
  readonly type: "open" | "busy";
  readonly project: GQLOps.ProjectResponse;
  readonly data: ProductDataState;
};

type ClosedState = {
  readonly type: "closed";
};

type LoadingState = {
  readonly type: "loading";
  readonly id: ProjectId;
};

type CreatingState = {
  readonly type: "creating";
  readonly id: string;
  readonly name: string;
};

type ErrorState = {
  readonly type: "error";
  readonly id: ProjectId;
};

type ProjectId =
  | {
      readonly type: "project";
      readonly projectId: string;
    }
  | {
      readonly type: "item";
      readonly itemId: string;
      readonly projectIdFallback: string | undefined;
    };

export type State = OpenState | ClosedState | LoadingState | ErrorState | CreatingState;

export const Action = ctorsUnion({
  CreateProject: (name: string, makeUrl: (projectId: string) => string) => ({ name, makeUrl }),
  CreateProjectResponse: (query: GQLOps.ProjectCreateProjectMutation, makeUrl: (projectId: string) => string) => ({
    query,
    makeUrl,
  }),
  UpdateProject: (patch: Partial<GQLOps.Project>) => ({ patch }),
  UpdateProjectResponse: (response: GQLOps.ProjectUpdateProjectMutation) => ({ response }),
  DeleteProject: (url: string) => ({ url }),
  DeleteProjectResponse: (response: GQLOps.ProjectDeleteProjectMutation, url: string) => ({ response, url }),
  Close: () => ({}),
  OpenById: (projectId: string, url?: string) => ({ projectId, url }),
  CreateItem: (
    productType: Project.ProductType,
    productKey: string,
    properties: PropertyValueSet.PropertyValueSet,
    makeItemUrl: (item: GQLOps.Item) => string
  ) => ({
    productType,
    productKey,
    properties,
    makeItemUrl,
  }),
  CreateItemResponse: (response: GQLOps.ProjectCreateItemMutation, makeItemUrl: (item: GQLOps.Item) => string) => ({
    response,
    makeItemUrl,
  }),
  DuplicateItem: (itemId: string, makeItemUrl: (item: GQLOps.Item) => string) => ({ itemId, makeItemUrl }),
  DeleteItem: (itemId: string, url: string | undefined) => ({ itemId, url }),
  DeleteItemResponse: (response: GQLOps.ProjectDeleteItemMutation, url: string | undefined) => ({ response, url }),
  UpdateItem: (patch: Patch<GQLOps.UpdateItemInput>) => ({ patch }),
  UpdateItemProperties: (itemId: string, properties: PropertyValueSet.PropertyValueSet) => ({ itemId, properties }),
  UpdateItemResponse: (response: GQLOps.ProjectUpdateItemFragment | null) => ({ response }),
  QueryResponseById: (query: GQLOps.ProjectStateByIdQuery) => ({ query }),
  QueryResponseByItem: (query: GQLOps.ProjectStateByItemQuery) => ({ query }),
  CheckLastModified: () => ({}),
  CheckLastModifiedResponse: (query: GQLOps.ProjectLastModifiedQuery) => ({ query }),
  ProductDataResponse: (data: Partial<Omit<DataStateReceiving, "type">>) => ({ data }),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState,
  locationQuery: Record<string, string>
): readonly [State, Cmd<Action>?] {
  const itemId = locationQuery[itemIdQueryParam];
  const projectId = locationQuery[projectIdQueryParam] || sharedState.openProjectId;
  if (prevState?.type === "open") {
    let canReuseOldState = true;
    if (prevState.project.project.id !== projectId) {
      canReuseOldState = false;
    }
    if (itemId && !prevState.project.items.some((i) => i.id === itemId)) {
      canReuseOldState = false;
    }
    if (canReuseOldState) {
      return [prevState, createLastModifiedCmd(sharedState, prevState)];
    }
  }
  if (itemId) {
    return loadProject({ type: "item", itemId, projectIdFallback: projectId }, sharedState);
  } else if (projectId) {
    return loadProject({ type: "project", projectId }, sharedState);
  } else {
    return [{ type: "closed" }];
  }
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "CreateProject": {
      const id = uuid();
      const gqlCmd = sharedState.graphQLQuery<
        GQLOps.ProjectCreateProjectMutation,
        GQLOps.ProjectCreateProjectMutationVariables,
        Action
      >(createProjectMutation, { input: { id, name: action.name } }, (data) => {
        return Action.CreateProjectResponse(data, action.makeUrl);
      });
      return [{ type: "creating", id, name: action.name }, gqlCmd];
    }
    case "CreateProjectResponse": {
      if (state.type !== "creating" || action.query.createProject.id !== state.id) {
        return [state];
      }
      return [state, Navigation.replaceUrl<Action>(action.makeUrl(action.query.createProject.id), undefined, false)];
    }
    case "UpdateProject": {
      if (state.type !== "open") {
        return [state];
      }
      const input = inputFromPatch(state.project.project.id, action.patch);
      const gqlCmd = sharedState.graphQLQuery<
        GQLOps.ProjectUpdateProjectMutation,
        GQLOps.ProjectUpdateProjectMutationVariables,
        Action
      >(updateProjectMutation, { input }, (data) => {
        return Action.UpdateProjectResponse(data);
      });
      return [
        {
          ...state,
          project: {
            ...state.project,
            project: patchResponse(state.project.project, input),
          },
        },
        gqlCmd,
      ];
    }
    case "UpdateProjectResponse": {
      if (state.type !== "open" || action.response.updateProject?.id !== state.project.project.id) {
        return [state];
      }
      return [
        {
          ...state,
          project: {
            ...state.project,
            project: action.response.updateProject,
          },
        },
      ];
    }
    case "DeleteProject": {
      if (state.type !== "open") {
        return [state];
      }
      const gqlCmd = sharedState.graphQLQuery<
        GQLOps.ProjectDeleteProjectMutation,
        GQLOps.ProjectDeleteProjectMutationVariables,
        Action
      >(deleteProjectMutation, { id: state.project.project.id }, (data) =>
        Action.DeleteProjectResponse(data, action.url)
      );
      return [{ ...state, type: "busy" }, gqlCmd, SharedState.SharedStateAction.SetOpenProject(undefined)];
    }
    case "DeleteProjectResponse": {
      return [{ type: "closed" }, Navigation.replaceUrl<Action>(action.url, undefined, false)];
    }
    case "Close": {
      if (state.type !== "open") {
        return [state];
      }
      return [{ type: "closed" }, undefined, SharedState.SharedStateAction.SetOpenProject(undefined)];
    }
    case "OpenById": {
      if (state.type === "loading") {
        return [state];
      }
      return loadProject({ type: "project", projectId: action.projectId }, sharedState);
    }
    case "CreateItem": {
      if (state.type !== "open") {
        return [state];
      }
      const sortNo = Math.max(0, ...state.project.items.map((i) => i.sortNo)) + 1;
      const item: GQLOps.Item = {
        id: uuid(),
        sortNo: sortNo,
        type: action.productType,
        product: action.productKey,
        properties: PropertyValueSet.toString(action.properties),
        quantity: 1,
      };
      const gqlCmd = sharedState.graphQLQuery<
        GQLOps.ProjectCreateItemMutation,
        GQLOps.ProjectCreateItemMutationVariables,
        Action
      >(createItemMutation, { input: { ...item, projectId: state.project.project.id } }, (data) => {
        return Action.CreateItemResponse(data, action.makeItemUrl);
      });
      return [
        {
          ...state,
          type: "busy",
        },
        gqlCmd,
      ];
    }
    case "DuplicateItem": {
      if (state.type !== "open") {
        return [state];
      }
      const oldItem = state.project.items.find((i) => i.id === action.itemId);
      if (!oldItem) {
        return [state];
      }
      const item: GQLOps.Item = {
        ...oldItem,
        id: uuid(),
      };
      const gqlCmd = sharedState.graphQLQuery<
        GQLOps.ProjectCreateItemMutation,
        GQLOps.ProjectCreateItemMutationVariables,
        Action
      >(createItemMutation, { input: { ...item, projectId: state.project.project.id } }, (data) => {
        return Action.CreateItemResponse(data, action.makeItemUrl);
      });
      return [
        {
          ...state,
          type: "busy",
        },
        gqlCmd,
      ];
    }
    case "CreateItemResponse": {
      if (state.type !== "busy") {
        return [state];
      }
      return [state, Navigation.replaceUrl<Action>(action.makeItemUrl(action.response.createItem), undefined, false)];
    }
    case "DeleteItem": {
      if (state.type !== "open") {
        return [state];
      }
      const gqlCmd = sharedState.graphQLQuery<
        GQLOps.ProjectDeleteItemMutation,
        GQLOps.ProjectDeleteItemMutationVariables,
        Action
      >(deleteItemMutation, { id: action.itemId }, (data) => Action.DeleteItemResponse(data, action.url));
      return [{ ...state, type: "busy" }, gqlCmd];
    }
    case "DeleteItemResponse": {
      if (state.type !== "busy") {
        return [state];
      }
      return [
        {
          ...state,
          type: "open",
          project: {
            ...state.project,
            items: state.project.items.filter((i) => i.id !== action.response.deleteItem?.id),
          },
        },
        action.url ? Navigation.replaceUrl<Action>(action.url, undefined, false) : undefined,
      ];
    }
    case "UpdateItemProperties": {
      return updateItemProperties(sharedState, state, action.itemId, action.properties);
    }
    case "UpdateItem": {
      if (state.type !== "open") {
        return [state];
      }
      const oldItem = state.project.items.find((i) => i.id === action.patch.id);
      if (!oldItem) {
        return [state];
      }
      const input = inputFromPatch(action.patch.id, action.patch);
      const gqlCmd = sharedState.graphQLQuery<
        GQLOps.ProjectUpdateItemMutation,
        GQLOps.ProjectUpdateItemMutationVariables,
        Action
      >(updateItemMutation, { input }, (data) => {
        return Action.UpdateItemResponse(data.updateItem);
      });
      return [
        {
          ...state,
          project: {
            ...state.project,
            items: state.project.items.map((i) =>
              i.id === action.patch.id ? patchResponse(oldItem, action.patch) : i
            ),
          },
        },
        gqlCmd,
      ];
    }
    case "UpdateItemResponse": {
      if (state.type !== "open") {
        return [state];
      }
      const updatedItem = action.response;
      if (!updatedItem) {
        throw new Error("Failed to update item. Item removed?");
      }
      return [
        {
          ...state,
          project: {
            ...state.project,
            items: state.project.items.map((i) => (i.id === updatedItem.id ? updatedItem : i)),
          },
        },
      ];
    }
    case "QueryResponseById": {
      const projectId = getCurrentProjectId(state);
      if (!projectId) {
        return [state];
      }
      const project = action.query.project;
      if (!project) {
        return [{ type: "error", id: { type: "project", projectId } }];
      }
      const [data, dataCmd] = loadProductData(sharedState);
      return [
        { type: "open", project, data },
        dataCmd,
        SharedState.SharedStateAction.SetOpenProject(project.project.id),
      ];
    }
    case "QueryResponseByItem": {
      if (state.type !== "loading" || state.id.type !== "item") {
        return [state];
      }
      const project = action.query.projectByItem;
      if (!project) {
        if (state.id.projectIdFallback) {
          return loadProject({ type: "project", projectId: state.id.projectIdFallback }, sharedState);
        } else {
          return [{ type: "error", id: state.id }];
        }
      }
      const [data, dataCmd] = loadProductData(sharedState);
      return [
        { type: "open", project, data },
        dataCmd,
        SharedState.SharedStateAction.SetOpenProject(project.project.id),
      ];
    }
    case "CheckLastModified": {
      if (state.type !== "open") {
        return [state];
      }
      return [state, createLastModifiedCmd(sharedState, state)];
    }
    case "CheckLastModifiedResponse": {
      if (state.type !== "open") {
        return [state];
      }
      const response = action.query.project?.project;
      if (!response) {
        return [{ type: "closed" }];
      }
      if (state.project.project.id === response.id && state.project.project.modifiedDate !== response.modifiedDate) {
        return [
          { ...state, type: "busy" },
          sharedState.graphQLQuery<GQLOps.ProjectStateByIdQuery, GQLOps.ProjectStateByIdQueryVariables, Action>(
            projectByIdQuery,
            { projectId: state.project.project.id },
            (data) => {
              return Action.QueryResponseById(data);
            }
          ),
        ];
      } else {
        return [state];
      }
    }
    case "ProductDataResponse": {
      if (state.type !== "open" && state.type !== "busy") {
        return [state];
      }
      if (state.data.type !== "receiving") {
        return [state];
      }
      const newData: DataStateReceiving = {
        ...state.data,
        ...action.data,
      };
      if (newData.searchQuery && newData.electricalHeaterProduct) {
        const searchData = Search.createSearchData(newData.searchQuery);
        return [
          {
            ...state,
            data: {
              type: "loaded",
              electricalHeaterProduct: newData.electricalHeaterProduct,
              searchData,
            },
          },
        ];
      } else {
        return [
          {
            ...state,
            data: newData,
          },
        ];
      }
    }
    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

export function updateItemProperties(
  sharedState: SharedState.SharedState,
  state: State,
  itemId: string,
  properties: PropertyValueSet.PropertyValueSet
): readonly [State, Cmd<Action>?] {
  if (state.type !== "open") {
    return [state];
  }
  const oldItem = state.project.items.find((i) => i.id === itemId);
  if (!oldItem) {
    return [state];
  }
  const oldProperties = PropertyValueSet.fromString(oldItem.properties, unitLookup);
  const changes = getItemPropertiesDiff(oldProperties, properties);
  if (changes.length === 0) {
    return [state];
  }
  const gqlCmd = sharedState.graphQLQuery<
    GQLOps.ProjectUpdateItemPropertiesMutation,
    GQLOps.ProjectUpdateItemPropertiesMutationVariables,
    Action
  >(updateItemPropertiesMutation, { input: { id: itemId, properties: changes } }, (data) => {
    return Action.UpdateItemResponse(data.updateItemProperties);
  });
  return [
    {
      ...state,
      project: {
        ...state.project,
        items: state.project.items.map((i) =>
          i.id === itemId ? { ...i, properties: PropertyValueSet.toString(properties) } : i
        ),
      },
    },
    gqlCmd,
  ];
}

export type ItemData = {
  readonly name: string;
  readonly type: "electric" | "water" | "dx" | "unknown";
};

export function getItemData(
  translate: Texts.TranslateFn,
  activeUser: ActiveUser,
  state: State,
  itemId: string
): ItemData | undefined {
  if (state.type !== "open" && state.type !== "busy") {
    return undefined;
  }
  const data = state.data;
  if (data.type !== "loaded") {
    return undefined;
  }
  const item = state.project.items.find((i) => i.id === itemId);
  if (!item) {
    return undefined;
  }
  if (item.type === "water_dx") {
    const query: Search.Query = {
      company: activeUser.companyName,
      filter: Search.propertiesToSearchFilter(data.searchData, getItemProperties(item)),
      productKey: item.product,
      userCurrency: activeUser.claims.currency,
    };
    const result = Search.searchSingle(data.searchData, query);
    const variantRow = result?.match.productVariantRow;
    const name = variantRow?.model || `${translate(Texts.texts.unknown_name)} (${item.product})`;
    const type = variantRow?.pm_medium === "water" ? "water" : variantRow?.pm_medium === "dx" ? "dx" : "unknown";
    return { name, type };
  } else if (item.type === "electric") {
    const properties = getItemProperties(item);
    const ehCustomItems = decodeCustomItemsFromPvs(properties);
    const ehProperties = PropertyValueSet.removeProperty(customItemsQueryParam, properties);

    let calculateRequest: CalculateRequest | undefined = undefined;
    try {
      const res = Calculator.fromVariantToCalculateRequest(ehProperties);
      if (res.type === "Ok") {
        calculateRequest = res.value;
      } else {
        logWarn(`getItemName: ${res.error}`);
      }
    } catch {
      logWarn("getItemName: ERROR_SELECTION_NOT_POSSIBLE");
    }
    if (!calculateRequest) {
      return {
        name: `${translate(Texts.texts.unknown_name)} (${translate(
          Texts.texts.ERROR_SELECTION_NOT_POSSIBLE(data.electricalHeaterProduct.product?.key || "")
        )})`,
        type: "electric",
      };
    }

    const calculateResult = Calculator.calculate(
      calculateRequest,
      data.electricalHeaterProduct,
      translate,
      ehCustomItems,
      activeUser
    );
    if (calculateResult.type === "Err") {
      logWarn(`getItemName: failed to calculate electric heater, ${calculateResult.error.errorMessage}`);
      return {
        name: `${translate(Texts.texts.unknown_name)} (${calculateResult.error.errorMessage})`,
        type: "electric",
      };
    }

    return { name: calculateResult.value.supplierCode || item.product, type: "electric" };
  } else {
    return { name: translate(Texts.texts.unknown_name), type: "unknown" };
  }
}

export function getAllItemData(
  translate: Texts.TranslateFn,
  activeUser: ActiveUser,
  state: State
): ReadonlyMap<string, ItemData> | undefined {
  if (state.type !== "open" && state.type !== "busy") {
    return undefined;
  }
  const data = state.data;
  if (data.type !== "loaded") {
    return undefined;
  }
  const names = new Map();
  for (const i of state.project.items) {
    names.set(i.id, getItemData(translate, activeUser, state, i.id) || "-");
  }
  return names;
}

function createLastModifiedCmd(sharedState: SharedState.SharedState, state: State): Cmd<Action> | undefined {
  if (state.type !== "open") {
    return undefined;
  }
  return sharedState.graphQLQuery<GQLOps.ProjectLastModifiedQuery, GQLOps.ProjectStateByIdQueryVariables, Action>(
    lastModifiedQuery,
    { projectId: state.project.project.id },
    (data) => {
      return Action.CheckLastModifiedResponse(data);
    }
  );
}

export function getCurrentProjectId(state: State): string | undefined {
  switch (state.type) {
    case "open":
      return state.project.project.id;
    case "busy":
      return state.project.project.id;
    case "loading":
      if (state.id.type === "project") {
        return state.id.projectId;
      } else {
        return undefined;
      }
    case "creating":
      return undefined;
    case "closed":
      return undefined;
    case "error":
      return undefined;
    default:
      exhaustiveCheck(state, true);
      return undefined;
  }
}

function loadProject(id: ProjectId, sharedState: SharedState.SharedState): readonly [LoadingState, Cmd<Action>] {
  const state: LoadingState = {
    type: "loading",
    id: id,
  };
  const gqlCmd =
    id.type === "project"
      ? sharedState.graphQLQuery<GQLOps.ProjectStateByIdQuery, GQLOps.ProjectStateByIdQueryVariables, Action>(
          projectByIdQuery,
          { projectId: id.projectId },
          (data) => {
            return Action.QueryResponseById(data);
          }
        )
      : sharedState.graphQLQuery<GQLOps.ProjectStateByItemQuery, GQLOps.ProjectStateByItemQueryVariables, Action>(
          projectByItemQuery,
          { itemId: id.itemId },
          (data) => {
            return Action.QueryResponseByItem(data);
          }
        );
  return [state, gqlCmd];
}

function getItemPropertiesDiff(
  oldPvs: PropertyValueSet.PropertyValueSet,
  newPvs: PropertyValueSet.PropertyValueSet
): ReadonlyArray<GQLOps.UpdatePropertyInput> {
  const changes: Array<GQLOps.UpdatePropertyInput> = [];
  for (const property of new Set([
    ...PropertyValueSet.getPropertyNames(oldPvs),
    ...PropertyValueSet.getPropertyNames(newPvs),
  ])) {
    const oldValue = PropertyValueSet.getValue(property, oldPvs);
    const newValue = PropertyValueSet.getValue(property, newPvs);
    if (PropertyValueSet.hasProperty(property, oldPvs) && !PropertyValueSet.hasProperty(property, newPvs)) {
      changes.push({ property, value: null });
    } else if (!PropertyValueSet.hasProperty(property, oldPvs) && PropertyValueSet.hasProperty(property, newPvs)) {
      changes.push({ property, value: PropertyValue.toString(newValue) });
    } else if (!PropertyValue.equals(oldValue, newValue)) {
      changes.push({ property, value: PropertyValue.toString(newValue) });
    }
  }
  return changes;
}

function loadProductData(sharedState: SharedState.SharedState): readonly [ProductDataState, Cmd<Action>?] {
  const gqlCmdProduct = sharedState.graphQLProductQuery<GQLOps.ProductQuery, GQLOps.ProductQueryVariables, Action>(
    productQuery,
    { productId: clientConfig.promaster_eh_id, language: sharedState.selectedLanguage },
    (data) => {
      return Action.ProductDataResponse({ electricalHeaterProduct: data });
    }
  );
  const gqlCmdSearch = sharedState.graphQLProductQuery<GQLOps.SearchQuery, GQLOps.SearchQueryVariables, Action>(
    Search.searchProductQueryAll,
    { searchProductId: clientConfig.promaster_search_product_id },
    (data) => {
      return Action.ProductDataResponse({ searchQuery: data });
    }
  );
  return [
    {
      type: "receiving",
      electricalHeaterProduct: undefined,
      searchQuery: undefined,
    },
    Cmd.batch([gqlCmdProduct, gqlCmdSearch]),
  ];
}
