/* eslint-disable functional/prefer-readonly-type */
import { ShowdownExtension } from "showdown";

export function supsub(): ShowdownExtension[] {
  const superscript = {
    type: "lang",
    regex: /\^([^\r^]*)\^/g,
    replace: "<sup>$1</sup>",
  };
  const subscript = {
    type: "lang",
    regex: /~([^\r~]*)~/g,
    replace: "<sub>$1</sub>",
  };
  return [superscript, subscript];
}

export function excludeList(): ShowdownExtension[] {
  const noList = {
    type: "lang",
    regex: /^([0-9]+\..+)/gm,
    replace: "<p>$1</p>",
  };

  return [noList];
}
