import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Texts, Utils } from "@ehb/shared";
import { TranslateFn, texts } from "@ehb/shared/src/lang-texts";
import { CustomItem, CustomItems } from "@ehb/shared/src/calculator";
import { Icon, NumberField, Textfield, withTw } from "../../../elements";
import * as State from "../state";
import { Button } from "../../../elements/button";

const PaddingTd = withTw("td", "py-2");
const HeaderTh = withTw("th", "text-bold text-left p-2");
export function CustomItemList({
  customItems,
  dispatch,
  translate,
}: {
  readonly customItems: CustomItems;
  readonly dispatch: Dispatch<State.Action>;
  readonly translate: TranslateFn;
}): JSX.Element {
  return (
    <div className="flex flex-col items-center my-2">
      <p className="text-xl">{translate(texts.custom_items)}</p>
      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>

      <table className="">
        {customItems.length !== 0 ? (
          <thead className="sticky top-0 bg-white ">
            <tr>
              <HeaderTh>{translate(texts.item_name)}</HeaderTh>
              <HeaderTh className="w-32">{translate(texts.quantity)}</HeaderTh>

              <HeaderTh className="w-40">{translate(texts.price)}</HeaderTh>
              <HeaderTh className="w-32 text-right">{translate(texts.total_price)}</HeaderTh>
              <HeaderTh className="w-2"></HeaderTh>
            </tr>
            {/* Fix border when sticky */}
            <tr className="max-h-0 border-b-4 border-gray-100" />
          </thead>
        ) : null}
        <tbody>
          {customItems.map((item: CustomItem) => (
            <CustomItemRow
              key={item.id}
              item={item}
              discountEnabled={true}
              dispatch={dispatch}
              showImages={false}
              translate={translate}
            />
          ))}
        </tbody>
        <tbody>
          <tr>
            <td className="py-2">
              <Button
                disabled={false}
                label={translate(texts.add_custom_item)}
                onClick={() => {
                  dispatch(State.Action.AddCustomItem());
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function CustomItemRow({
  item,
  dispatch,
}: {
  readonly item: CustomItem;
  readonly dispatch: Dispatch<State.Action>;
  readonly translate: Texts.TranslateFn;
  readonly discountEnabled: boolean;
  readonly showImages: boolean;
}): JSX.Element {
  // const price = Price.calculateMaterialPrice(material);
  // const error = undefined;
  const isBusy = false;
  const isReadonly = false;
  return (
    <tr key={item.id} className=" items-center gap-4 ">
      <PaddingTd>
        <Textfield
          disabled={false}
          readOnly={false}
          className="mr-2"
          value={item.name}
          onChange={(v) => {
            dispatch(State.Action.UpdateCustomItem({ ...item, name: v }));
          }}
        />
      </PaddingTd>
      <PaddingTd className="p-0">
        <div className="flex flex-row items-center justify-between">
          {isReadonly ? (
            <span>{Utils.numberToString(2, 4)}</span>
          ) : (
            <NumberField
              className="max-w-52"
              disabled={false}
              readOnly={false}
              value={item.quantity}
              errorMessage={undefined}
              decimals={4}
              onChange={(v) => {
                dispatch(State.Action.UpdateCustomItem({ ...item, quantity: v }));
              }}
            />
          )}
        </div>
      </PaddingTd>

      <PaddingTd>
        <div className="flex flex-row space-x-4 ml-2 items-center">
          <NumberField
            disabled={isBusy}
            readOnly={isReadonly}
            value={item.singlePrice}
            onChange={(v) => {
              dispatch(State.Action.UpdateCustomItem({ ...item, singlePrice: v }));
            }}
          />
          <span className="">{getCurrencySymbol(item.currency)}</span>
        </div>
      </PaddingTd>
      {/* price */}
      {true && <td className="tablura-nums items-center text-right p-2">{item.totalPrice}</td>}
      {/* discount */}
      <td className="p-0">
        <Icon
          icon={"trash-alt"}
          disabled={false}
          onClick={() => {
            dispatch(State.Action.RemoveCustomItem(item.id));
          }}
          message={"Remove"}
          className="mr-2 text-red-600 transition duration-75 hover:scale-110 "
        />
      </td>
    </tr>
  );
}
function getCurrencySymbol(currency: string): string {
  return currency === "EUR" ? " €" : currency === "SEK" ? " kr" : ` ${currency}`;
}
