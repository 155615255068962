/* eslint-disable import/no-mutable-exports */
/* eslint-disable functional/no-let */
import * as Veab from "./veab";
import { Style } from "./types";

let style: Style = Veab;

function setStyle(newStyle: string | undefined): void {
  switch (newStyle) {
    default:
      style = Veab;
      break;
  }
  // style = newStyle !== undefined && newStyle === "panasonic" ? Panasonic : Systemair;
}

export { style, setStyle };
