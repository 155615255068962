import gql from "graphql-tag";
import { FrtCoilQuery } from "../generated/generated-operations";
import { TableWithPropertyFilter } from "../utils";

export const query = gql`
  query FrtCoil($searchProductId: ID!) {
    searchProduct: product(id: $searchProductId) {
      key
      modules {
        custom_tables {
          allowed_friterm_dll_messages {
            message_id
          }
          calculation_input {
            property_filter
            param
            value
          }
          custom_calculation_errors {
            property_filter
            result_path
            operation
            value
            unit
            type
            message
          }
        }
      }
    }
    products {
      key
      modules {
        custom_tables {
          product_variants {
            model
            pm_product_type
            pm_model
            pm_medium
            pm_cooling
            pm_heating
            pm_connection
            pm_size
            pm_capacity
            pm_speed
            frt_dll_geometry_id
            frt_dll_tube_thickness
            frt_dll_tube_id
            frt_dll_fin_fin_thickness
            frt_dll_fin_id
            frt_dll_number_of_tubes
            frt_dll_number_of_rows
            frt_dll_number_of_circuits
            frt_dll_fin_pitch
            frt_dll_finned_length
            frt_dll_manifold_material_id
            frt_dll_manifold_set_count
            frt_dll_manifold_inlet_diameter
            frt_dll_manifold_outlet_diameter
            weight
            fixed_airflow
            pm_controller_type
          }
        }
      }
    }
  }
`;

export type Query = FrtCoilQuery;
export type SearchProduct = Query["searchProduct"];
export type Products = Query["products"];
export type Product = Query["products"][0];
export type SearchProductTables = Exclude<Query["searchProduct"], null>["modules"]["custom_tables"];
export type CalculationInputTable = TableWithPropertyFilter<SearchProductTables["calculation_input"]>;
export type CalculationgAllowedMessages = SearchProductTables["allowed_friterm_dll_messages"];
export type CalculationCustomErrors = SearchProductTables["custom_calculation_errors"];
