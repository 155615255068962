import { PowerStepDistribution } from "./types";
import { roundValue } from "./util";

export function deltatToPower(temperatureC: number, airVolumeM3h: number): number {
  return (airVolumeM3h * 0.36 * temperatureC) / 1000;
}

export function calculateStepPowers(
  powerKw: number,
  steps: number,
  stepDistribution: PowerStepDistribution
): ReadonlyArray<number> {
  const stepCoefficients = [];
  for (let i = 0; i < steps; i++) {
    switch (stepDistribution) {
      case PowerStepDistribution.even: {
        stepCoefficients.push(1);
        break;
      }
      case PowerStepDistribution.geometric: {
        stepCoefficients.push(2 ** i);
        break;
      }
      case PowerStepDistribution.delayedGeometric: {
        const exponent = Math.max(0, i - 1);
        stepCoefficients.push(2 ** exponent);
        break;
      }
      default: {
        stepCoefficients.push(1);
      }
    }
  }
  const stepPowerKw = Math.round((powerKw / stepCoefficients.reduce((sum, x) => sum + x, 0)) * 100) / 100;
  const powerSteps = stepCoefficients.map((x) => x * stepPowerKw).map((x) => roundValue(x));
  return powerSteps;
}
