import { retryFetch } from "../fetch";
import { TextKey, texts } from "../lang-texts";

// TODO: password for EHB
const dllPassword = "4dsaDLX4xV0a1vzG16jw";

export interface FritermInput {
  readonly DllPassword: string;
  readonly Uspmode: boolean;
  readonly ModeFanDirection: number;
  readonly FanRowCount: number;
  readonly FanCountPerRow: number;
  readonly FanReferenceDensity: number;
  readonly FanPower: number;
  readonly FanVolt: number;
  readonly FanAmper: number;
  readonly FanAdditionalPressureDrop: number;
  readonly FanRPM: number;
  readonly ModeFanVolFlowRangeExtension: number;
  readonly FanMinTemp: number;
  readonly FanMaxTemp: number;
  readonly ErrorShowDialog: number;
  readonly ErrorThrowException: number | null;
  readonly LanguageID: number;
  readonly circulationRate: number;
  readonly siparismiktari: number;
  readonly FanLossRatio: number;
  readonly FanVolumetricFlowMin: number;
  readonly FanVolumetricFlowMax: number;
  readonly FanD: number;
  readonly DxTr: number;
  readonly FanA: number;
  readonly FanB: number;
  readonly FanC: number;
  readonly FanE: number;
  readonly FanF: number;
  readonly FanG: number;
  readonly FanH: number;
  readonly FanJ: number;
  readonly FanK: number;
  readonly FanL: number;
  readonly FanM: number;
  readonly FanN: number;
  readonly FanName: string | null;
  readonly FanDiameter: number;
  readonly FanID: number;
  readonly CasingPaint: number;
  readonly TRValue: number;
  readonly FanCount: number;
  readonly IsGrooved: boolean;
  readonly TRMaxForSelection: number;
  readonly TRMinForSelection: number;
  readonly CustomerTR: number;
  readonly useFriedelMethod_dP: number;
  readonly useHaraguchiMethod_dP: number;
  readonly useNozuMethod_dP: number;
  readonly usePierreMethod_dP: number;
  readonly useCollierSeperatedMethod_dP: number;
  readonly useJungAndRadermacher_dP: number;
  readonly useKuo_dP: number;
  readonly EmptyTube: number;
  readonly CavalliniTwoPhaseHTC2009CondenserGrooved: number;
  readonly UseZetaCalc: number;
  readonly HeatPipeInclineDegreeFromHorizontal: number;
  readonly IceThickness: number;
  readonly GlycolDeFrost: number;
  readonly EuroventHECertificateUnit: number;
  readonly LMTD_VDI_F_Calc: number;
  readonly LMTD_VDI_F_Calc_forCooler: number;
  readonly LMTD_Single_CrossCounter: boolean;
  readonly LMTD_TwoTimes_CrossCounter: boolean;
  readonly LMTD_Zeller: number;
  readonly Kedzierski_dP: number;
  readonly IsTurbulatorWireMatrix: number;
  readonly useSouzaTwoPhaseMultiplier: number;
  readonly CoolPadVelMax: number;
  readonly IsHide: number;
  readonly FanHood: number;
  readonly CoilPosition: number;
  readonly AddCoolPad: number;
  readonly AddCoolPadWithoutPamp: number;
  readonly CoolPadEffA: number;
  readonly CoolPadEffB: number;
  readonly CoolPadEffC: number;
  readonly CoolPadEffD: number;
  readonly CoolPaddPA: number;
  readonly CoolPaddPB: number;
  readonly CoolPaddPC: number;
  readonly CoolPaddPD: number;
  readonly CoolPaddPDropletA: number;
  readonly CoolPaddPDropletB: number;
  readonly CoolPaddPDropletC: number;
  readonly CoolPaddPDropletD: number;
  readonly CoolPaddPDropletE: number;
  readonly CoolPadVelMin: number;
  readonly CoolPadName: string | null;
  readonly IsTurbulatorTwistedTape: number;
  readonly LMTD_VDI_F_Calc_For_Veab: number;
  readonly IsCoverBottomWidthBendLength_Standard: number;
  readonly FldID: number;
  readonly FldMassFlow: number;
  readonly FldMixtureRatio: number;
  readonly FldTemperatureInlet: number;
  readonly FldTemperatureOutlet: number;
  readonly FldTemperatureEvaporation: number;
  readonly FldTemperatureCondensation: number;
  readonly FldTemperatureSuperHeating: number;
  readonly ModeLoad: number;
  readonly FldTemperatureSubCooling: number;
  readonly FldSteamPressure: number;
  readonly FldFoulingFactor: number;
  readonly ModeFluidFlow: number;
  readonly ModeSteam: number;
  readonly FldName: string | null;
  readonly IsExtraPolate: number;
  readonly IsWarmGlycol: number;
  readonly CircuitCount: number;
  readonly FldVolumetricFlowInlet: number;
  readonly ModeCalculateAirPressureDropDry: number;
  readonly ModeAtmosphericPressure: number;
  readonly ModeAirReferenceMassFlow: number;
  readonly AirReferenceTemperature: number;
  readonly AirReferenceHumidity: number;
  readonly AirReferenceTemperatureWetBulb: number;
  readonly AirReferenceDensity: number;
  readonly AirHumidityInlet: number;
  readonly AirHumidityOutlet: number;
  readonly AirMassFlowInlet: number;
  readonly AirTemperatureInlet: number;
  readonly AirTemperatureOutlet: number;
  readonly AirTemperatureWetbulbInlet: number;
  readonly AirVelocity: number;
  readonly AirVolumetricFlowInlet: number;
  readonly AirAltitude: number;
  readonly AirDensityInlet: number;
  readonly AirAtmosphericPressure: number;
  readonly AirFoulingFactor: number;
  readonly AdditionalPressureDrop: number;
  readonly ModeAirFlow: number;
  readonly ModeAirMoistureInlet: number;
  readonly CoilType: number;
  readonly CoilHeatPipe: number;
  readonly CoilHeatRecovery: number;
  readonly isLTHTwithFan: number;
  readonly ModeCircuitSingleOrDouble: number;
  readonly ModeLength: number;
  readonly ModeWidth: number;
  readonly ModeHeight: number;
  readonly TR: number;
  readonly LDMax: number;
  readonly HR: number;
  readonly LB: number;
  readonly FrameBackWidthBendLength: number;
  readonly CoverTopWidthBendLength: number;
  readonly CoverBottomWidthBendLength: number;
  readonly IsLB: number;
  readonly IsLB_Standard: number;
  readonly IsManifoldCoverPlate: number;
  readonly IsBendCoverPlate: number;
  readonly IsBendCoverPlateExtraDim_Stndart: number;
  readonly BendCoverPlateExtraLength: number;
  readonly IsFrameBackWidthBendLength_Standard: number;
  readonly IsCoverTopWidthBendLength_Standard: number;
  readonly ModePassOrCircuit: number;
  readonly AirflowDirection: number;
  readonly ModeManifold: number;
  readonly DistributorNozzleOrificeNumberID: string | null;
  readonly FinPitch: number;
  readonly FinMaterialID: number;
  readonly FinThickness: number;
  readonly GeometryID: number;
  readonly Length: number;
  readonly ManifoldMaterialID: number;
  readonly ManifoldInletDiameter: number;
  readonly ManifoldOutletDiameter: number;
  readonly ManifoldSetCount: number;
  readonly ManifoldConnectionPipeCount: number;
  readonly ManifoldConnectionLength: number;
  readonly ManifoldPosition: number;
  readonly PassCount: number;
  readonly RowCount: number;
  readonly SurfaceReserve: number;
  readonly TubeCount: number;
  readonly TubeMaterialID: number;
  readonly TubeThickness: number;
  readonly ModeDistributor: number;
  readonly DistributorTubeOuterDiameterID: string | null;
}

export interface FritermOutput {
  readonly coilCode: string;
  readonly tubeInnerVolume: number;
  readonly tubeOuterDiameter: number;
  readonly tubeNominalDiameter: number;
  readonly tubeThermalConductivity: number;
  readonly weight: number;
  readonly xL: number;
  readonly xT: number;
  readonly surfaceTemperatureInlet: number;
  readonly surfaceTemperatureOutlet: number;
  readonly surfaceReserve: number;
  readonly dxTr: number;
  readonly dxTrOne: number;
  readonly dxTrTwo: number;
  readonly dxTrThree: number;
  readonly errorDescription: string;
  readonly errorNumber: number;
  readonly warningDescription: string;
  readonly warningNumber: string;
  readonly tubeInnerDiameter: number;
  readonly manifoldOutletThickness: number;
  readonly tubeThickness: number;
  readonly tR: number;
  readonly height: number;
  readonly hR: number;
  readonly lDmax: number;
  readonly lDmaxDxTrOne: number;
  readonly lDmaxDxTrTwo: number;
  readonly lDmaxDxTrThree: number;
  readonly lH: number;
  readonly lR: number;
  readonly partloadAirHumidityOutlet: number;
  readonly logMeanTemperatureDifference: number;
  readonly manifoldDiameterInlet: number;
  readonly manifoldDiameterOutlet: number;
  readonly manifoldMaxVelocityInlet: number;
  readonly manifoldMaxVelocityOutlet: number;
  readonly manifoldMaterialID: number;
  readonly pedCategory: string;
  readonly passCount: number;
  readonly tH: number;
  readonly manifoldSetCount: number;
  readonly partLoadAirMoistureContentOutlet: number;
  readonly partloadCapacity: number;
  readonly partloadCapacityLatent: number;
  readonly coolPadMoistureContentOutlet: number;
  readonly coolPadPressureDrop: number;
  readonly coolPadSpecificHeatOutlet: number;
  readonly coolPadTemperatureOutlet: number;
  readonly coolPadTemperatureWetbulbOutlet: number;
  readonly coolPadThermalConductivityOutlet: number;
  readonly coolPadVelocityOutlet: number;
  readonly coolPadVolumetricFlowOutlet: number;
  readonly coolPadHumidityOutlet: number;
  readonly coolPadViscosityOutlet: number;
  readonly tubeCount: number;
  readonly connectionLength: number;
  readonly averageTwall: number;
  readonly tubeDensity: number;
  readonly fldName: string;
  readonly fldMixtureRatio: number;
  readonly dxTrOneAvailable: boolean;
  readonly dxTrTwoAvailable: boolean;
  readonly length: number;
  readonly heatTransferCoefficientOverall: number;
  readonly coolPadEnthalpyOutlet: number;
  readonly customerHR: number;
  readonly partloadCapacitySensible: number;
  readonly partloadCapacitySpare: number;
  readonly partloadFldPressureDrop: number;
  readonly partloadRowCountTheoretical: number;
  readonly partloadSurfaceSpare: number;
  readonly partloadFldTemperatureOutlet: number;
  readonly partloadFldVolumetricFlowInlet: number;
  readonly partloadFldVelocityTubeLiquid: number;
  readonly coolPadDensityOutlet: number;
  readonly partloadFldMassFlow: number;
  readonly partloadLogMeanTemperatureDifference: number;
  readonly partloadHeatTransferCoefficientOverall: number;
  readonly connectionInletDiameter: number;
  readonly maxNRForSelection: number;
  readonly tRMaxForSelection: number;
  readonly tRMinForSelection: number;
  readonly tRMinSelected: number;
  readonly customerTR: number;
  readonly partloadSurfaceTheoretical: number;
  readonly coilCodeForDLL: string;
  readonly coilPosition: number;
  readonly geometryName: string;
  readonly airTemperatureWetbulbOutlet: number;
  readonly airThermalConductivityInlet: number;
  readonly airVelocity: number;
  readonly airVelocityOutlet: number;
  readonly airViscosityInlet: number;
  readonly airVolumetricFlowInlet: number;
  readonly airVolumetricFlowOutlet: number;
  readonly referenceAirTemperature: number;
  readonly airTemperatureWetbulbInlet: number;
  readonly referenceAirHumidity: number;
  readonly referenceAirMoistureContent: number;
  readonly referenceAirMassFlow: number;
  readonly referenceAirVolumetricFlow: number;
  readonly referenceAirVelocity: number;
  readonly modeAirReferenceMassFlow: number;
  readonly fanPowerConsumption: number;
  readonly lwA: number;
  readonly euroventWetFactor: number;
  readonly referenceAirDensity: number;
  readonly fldFoulingFactor: number;
  readonly airTemperatureOutlet: number;
  readonly airReynoldNumberInlet: number;
  readonly airFoulingFactor: number;
  readonly airAltitude: number;
  readonly airAtmosphericPressure: number;
  readonly airDensityInlet: number;
  readonly airDensityOutlet: number;
  readonly airEnthalpyInlet: number;
  readonly airEnthalpyOutlet: number;
  readonly airSpecificHeatInlet: number;
  readonly airHumidityInlet: number;
  readonly airMassFlowInlet: number;
  readonly airMoistureContentInlet: number;
  readonly airMoistureContentOutlet: number;
  readonly airPrandtlNumberInlet: number;
  readonly airPressureDrop: number;
  readonly airPressureDropWithZetaFactor: number;
  readonly fFactor: number;
  readonly airPressureDropDry: number;
  readonly airHumidityOutlet: number;
  readonly dxTrThreeAvailable: boolean;
  readonly fldDensityInlet: number;
  readonly fldEnthalpyOutlet: number;
  readonly capacitySensible: number;
  readonly condensatedFlow: number;
  readonly depth: number;
  readonly distributorInletDiameter: number;
  readonly distributorNozzleNumber: number;
  readonly distributorPressureDrop: number;
  readonly distributorTubeDiameter: number;
  readonly e: number;
  readonly capacityRatio: number;
  readonly euroventCertified: number;
  readonly exchangeSurfaceRatio: number;
  readonly exchangeSurfaceWithoutReserve: number;
  readonly finThermalConductivity: number;
  readonly finThickness: number;
  readonly frostCapacity: number;
  readonly frostMass: number;
  readonly geometryCode: string;
  readonly geometryDiameter: string;
  readonly exchangeSurface: number;
  readonly fldEnthalpyInlet: number;
  readonly capacityLatent: number;
  readonly circuitCount: number;
  readonly fldFreezingTemperature: number;
  readonly fldMassFlow: number;
  readonly fldPrandtlNumberInlet: number;
  readonly fldPressureDrop: number;
  readonly fldPressureDropPrint: number;
  readonly fldPressureDropKelvin: number;
  readonly fldPressureDropManifold: number;
  readonly fldReynoldNumberInlet: number;
  readonly fldSpecificHeatInlet: number;
  readonly capacity: number;
  readonly fldTemperatureInlet: number;
  readonly fldThermalConductivityInlet: number;
  readonly fldVelocityTubeLiquid: number;
  readonly fldVelocityTubeVapor: number;
  readonly fldVelocityManifoldInlet: number;
  readonly fldVelocityManifoldOutlet: number;
  readonly fldViscosityInlet: number;
  readonly fldVolumetricFlowInlet: number;
  readonly fldHeatTransferCoefficient: number;
  readonly fldTemperatureOutlet: number;
}

export function createFullInput(partialInput: Partial<FritermInput>): Omit<FritermInput, "DllPassword"> {
  return {
    Uspmode: false,
    ModeFanDirection: 0,
    FanRowCount: 0,
    FanCountPerRow: 0,
    FanReferenceDensity: 0,
    FanPower: 0,
    FanVolt: 0,
    FanAmper: 0,
    FanAdditionalPressureDrop: 0,
    FanRPM: 0,
    ModeFanVolFlowRangeExtension: 0,
    FanMinTemp: 0,
    FanMaxTemp: 0,
    ErrorShowDialog: 0,
    ErrorThrowException: null,
    LanguageID: 0,
    circulationRate: 0,
    siparismiktari: 0,
    FanLossRatio: 0,
    FanVolumetricFlowMin: 0,
    FanVolumetricFlowMax: 0,
    FanD: 0,
    DxTr: 0,
    FanA: 0,
    FanB: 0,
    FanC: 0,
    FanE: 0,
    FanF: 0,
    FanG: 0,
    FanH: 0,
    FanJ: 0,
    FanK: 0,
    FanL: 0,
    FanM: 0,
    FanN: 0,
    FanName: null,
    FanDiameter: 0,
    FanID: 0,
    CasingPaint: 0,
    TRValue: 0,
    FanCount: 0,
    IsGrooved: false,
    TRMaxForSelection: 0,
    TRMinForSelection: 0,
    CustomerTR: 0,
    useFriedelMethod_dP: 0,
    useHaraguchiMethod_dP: 0,
    useNozuMethod_dP: 0,
    usePierreMethod_dP: 0,
    useCollierSeperatedMethod_dP: 0,
    useJungAndRadermacher_dP: 0,
    useKuo_dP: 0,
    EmptyTube: 0,
    CavalliniTwoPhaseHTC2009CondenserGrooved: 0,
    UseZetaCalc: 0,
    HeatPipeInclineDegreeFromHorizontal: 0,
    IceThickness: 0,
    GlycolDeFrost: 0,
    EuroventHECertificateUnit: 0,
    LMTD_VDI_F_Calc: 0,
    LMTD_VDI_F_Calc_forCooler: 0,
    LMTD_Single_CrossCounter: false,
    LMTD_TwoTimes_CrossCounter: false,
    LMTD_Zeller: 0,
    Kedzierski_dP: 0,
    IsTurbulatorWireMatrix: 0,
    useSouzaTwoPhaseMultiplier: 0,
    CoolPadVelMax: 0,
    IsHide: 0,
    FanHood: 0,
    CoilPosition: 0,
    AddCoolPad: 0,
    AddCoolPadWithoutPamp: 0,
    CoolPadEffA: 0,
    CoolPadEffB: 0,
    CoolPadEffC: 0,
    CoolPadEffD: 0,
    CoolPaddPA: 0,
    CoolPaddPB: 0,
    CoolPaddPC: 0,
    CoolPaddPD: 0,
    CoolPaddPDropletA: 0,
    CoolPaddPDropletB: 0,
    CoolPaddPDropletC: 0,
    CoolPaddPDropletD: 0,
    CoolPaddPDropletE: 0,
    CoolPadVelMin: 0,
    CoolPadName: null,
    IsTurbulatorTwistedTape: 0,
    LMTD_VDI_F_Calc_For_Veab: 0,
    IsCoverBottomWidthBendLength_Standard: 0,
    FldID: 0,
    FldMassFlow: 0,
    FldMixtureRatio: 0,
    FldTemperatureInlet: 0,
    FldTemperatureOutlet: 0,
    FldTemperatureEvaporation: 0,
    FldTemperatureCondensation: 0,
    FldTemperatureSuperHeating: 0,
    ModeLoad: 0,
    FldTemperatureSubCooling: 0,
    FldSteamPressure: 0,
    FldFoulingFactor: 0,
    ModeFluidFlow: 0,
    ModeSteam: 0,
    FldName: null,
    IsExtraPolate: 0,
    IsWarmGlycol: 0,
    CircuitCount: 0,
    FldVolumetricFlowInlet: 0,
    ModeCalculateAirPressureDropDry: 0,
    ModeAtmosphericPressure: 0,
    ModeAirReferenceMassFlow: 0,
    AirReferenceTemperature: 0,
    AirReferenceHumidity: 0,
    AirReferenceTemperatureWetBulb: 0,
    AirReferenceDensity: 0,
    AirHumidityInlet: 0,
    AirHumidityOutlet: 0,
    AirMassFlowInlet: 0,
    AirTemperatureInlet: 0,
    AirTemperatureOutlet: 0,
    AirTemperatureWetbulbInlet: 0,
    AirVelocity: 0,
    AirVolumetricFlowInlet: 0,
    AirAltitude: 0,
    AirDensityInlet: 0,
    AirAtmosphericPressure: 0,
    AirFoulingFactor: 0,
    AdditionalPressureDrop: 0,
    ModeAirFlow: 0,
    ModeAirMoistureInlet: 0,
    CoilType: 0,
    CoilHeatPipe: 0,
    CoilHeatRecovery: 0,
    isLTHTwithFan: 0,
    ModeCircuitSingleOrDouble: 0,
    ModeLength: 0,
    ModeWidth: 0,
    ModeHeight: 0,
    TR: 0,
    LDMax: 0,
    HR: 0,
    LB: 0,
    FrameBackWidthBendLength: 0,
    CoverTopWidthBendLength: 0,
    CoverBottomWidthBendLength: 0,
    IsLB: 0,
    IsLB_Standard: 0,
    IsManifoldCoverPlate: 0,
    IsBendCoverPlate: 0,
    IsBendCoverPlateExtraDim_Stndart: 0,
    BendCoverPlateExtraLength: 0,
    IsFrameBackWidthBendLength_Standard: 0,
    IsCoverTopWidthBendLength_Standard: 0,
    ModePassOrCircuit: 0,
    AirflowDirection: 0,
    ModeManifold: 0,
    DistributorNozzleOrificeNumberID: null,
    FinPitch: 0,
    FinMaterialID: 0,
    FinThickness: 0,
    GeometryID: 0,
    Length: 0,
    ManifoldMaterialID: 0,
    ManifoldInletDiameter: 0,
    ManifoldOutletDiameter: 0,
    ManifoldSetCount: 0,
    ManifoldConnectionPipeCount: 0,
    ManifoldConnectionLength: 0,
    ManifoldPosition: 0,
    PassCount: 0,
    RowCount: 0,
    SurfaceReserve: 0,
    TubeCount: 0,
    TubeMaterialID: 0,
    TubeThickness: 0,
    ModeDistributor: 0,
    DistributorTubeOuterDiameterID: null,
    ...partialInput,
  };
}

export function getMaterialText(id: number): TextKey {
  const materials: { [id: number]: TextKey } = {
    1: texts.fri_material_aluminum,
    2: texts.fri_material_copper,
    3: texts.fri_material_epoxy_coated_aluminum,
    4: texts.fri_material_hydrophilic_coated_aluminum,
    5: texts.fri_material_steel,
    6: texts.fri_material_stainless_steel,
    7: texts.fri_material_galvanized_steel,
    10: texts.fri_material_cupronickel,
    13: texts.fri_material_al_mg,
  };

  return materials[id] || texts.unknown_material;
}

export async function compute(input: Omit<FritermInput, "DllPassword">): Promise<FritermOutput | undefined> {
  try {
    const result = await retryFetch(
      "https://design-dllcal.systemair.com/api/fritermfrtcoils/Compute",
      //"http://localhost:5000/Compute",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ DllPassword: dllPassword, ...input }),
      },
      { breakOnHTTPCodes: [422], returnResponseOnError: true, maxAttempts: 1 }
    );
    if (result.ok) {
      const json = await result.json();
      return json;
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}
