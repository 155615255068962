import { PropertyValueSet } from "@promaster-sdk/property";
import {
  CalculateRequest,
  CalculateResult,
  Connection,
  Currency,
  CustomItems,
  Model,
  IP_Class,
  Type,
} from "../../calculator";
import { PriceAccessories } from "../../calculator/calc-price";
import { key, texts, TranslateFn } from "../../lang-texts";
import { getUnitLabel } from "../../units/unit-labels";
import { QuoteData, QuoteDataRow, QuoteReportQueryParams } from "./types";

export function createQuoteData(props: QuoteReportQueryParams, internalUser: boolean, endCustomer: boolean): QuoteData {
  const {
    translate,
    calculateRequest,
    calculateResult,
    configurationLink,
    priceAccessories,
    customItems,
    productKey,
    properties: pvs,
  } = props;

  const quoteDate = new Date().toISOString().slice(0, 10);

  const hasAccessories = Object.keys(priceAccessories.accessories).length > 0;
  const quoteData: Array<QuoteDataRow> = [];
  // ORDER IS IMPORTANT.
  quoteData.push({
    key: "project",
    translatedKey: translate(texts.project),
    value: calculateResult.project,
  });
  quoteData.push({
    key: "reference",
    translatedKey: translate(texts.reference),
    value: calculateResult.reference,
  });
  quoteData.push({
    key: "quote_date",
    translatedKey: translate(texts.quote_date),
    value: quoteDate,
  });
  quoteData.push({
    key: "configuration_link",
    translatedKey: translate(texts.configuration_link),
    value: configurationLink,
  });
  quoteData.push({ key: "model", translatedKey: translate(texts.model), value: Model[calculateResult.model] });
  quoteData.push({ key: "type", translatedKey: translate(texts.type), value: Type[calculateResult.type] });
  quoteData.push({
    key: "power",
    translatedKey: translate(texts.power),
    value: `${calculateResult.powerKw}${getUnitLabel("KiloWatt")}`,
  });
  quoteData.push({
    key: "voltage",
    translatedKey: translate(texts.voltage),
    value: `${calculateResult.voltage.toString().charAt(0)}x${calculateResult.voltage
      .toString()
      .slice(1)}${getUnitLabel("Volt")}`,
  });
  quoteData.push({
    key: "current",
    translatedKey: translate(texts.current),
    value: `${calculateResult.current.toFixed(2)}${getUnitLabel("Ampere")}`,
  });
  quoteData.push({
    key: "width",
    translatedKey: translate(texts.width),
    value: `${calculateResult.widthMm}${getUnitLabel("Millimeter")}`,
  });
  quoteData.push({
    key: "height",
    translatedKey: translate(texts.height),
    value: `${calculateResult.heightMm}${getUnitLabel("Millimeter")}`,
  });

  quoteData.push({
    key: "depth",
    translatedKey: translate(texts.depth),
    value: `${calculateResult.depthMm}${getUnitLabel("Millimeter")}`,
  });

  calculateResult.connection === Connection.Circular
    ? quoteData.push({
        key: "circular_connection",
        translatedKey: translate(texts.circular_connection),
        value: `Ø ${calculateRequest.diameter}${getUnitLabel("Millimeter")}`,
      })
    : quoteData.push({
        key: "circular_connection",
        translatedKey: translate(texts.circular_connection),
        value: translate(texts.no),
      });

  quoteData.push({
    key: "weight",
    translatedKey: translate(texts.weight),
    value: `${calculateResult.weightKg}${getUnitLabel("Kilogram")}`,
  });

  quoteData.push({
    key: "material",
    translatedKey: translate(texts.material),
    value: translate(texts.material_value(productKey, pvs)),
  });
  quoteData.push({
    key: "protection",
    translatedKey: translate(texts.protection),
    value: IP_Class[calculateResult.ipClass],
  });

  quoteData.push({
    key: "supplier_code",
    translatedKey: translate(texts.supplier_code),
    value: calculateResult.supplierCode,
  });

  if (internalUser) {
    quoteData.push({
      key: "production_time",
      translatedKey: translate(texts.production_time),
      value:
        calculateRequest.productionTime <= 0
          ? translate(texts.production_time_message)
          : calculateRequest.productionTime.toString() + " " + translate(texts.workingdays),
    });
    quoteData.push({
      key: "quote_payment_terms",
      translatedKey: translate(texts.quote_payment_terms),
      value: translate(texts.paymentterms(productKey, pvs)),
    });
    quoteData.push({
      key: "quote_delivery_terms",
      translatedKey: translate(texts.quote_delivery_info),
      value: translate(texts.deliveryterms(productKey, pvs)),
    });
  }

  if (calculateRequest.includePrice === 1 && !endCustomer) {
    quoteData.push({
      key: "price",
      translatedKey: translate(texts.price),
      value: priceValue(
        calculateRequest,
        calculateResult,
        translate,
        internalUser,
        hasAccessories,
        customItems,
        productKey
      ),
    });
  }

  if (calculateRequest.notes.length !== 0) {
    quoteData.push({ key: "notes", translatedKey: translate(texts.notes), value: calculateRequest.notes });
  }

  if (internalUser && customItems.length !== 0) {
    quoteData.push({
      key: "custom_items",
      translatedKey: translate(texts.custom_items),
      value: customItemsValue(customItems),
    });
  }

  if (hasAccessories && priceAccessories && calculateRequest.includePrice === 1 && !endCustomer) {
    quoteData.push({
      key: "accessories",
      translatedKey: translate(texts.accessories),
      value: accessoriesFullValue(calculateRequest, priceAccessories, translate, productKey, pvs),
      style: { spaceBetweenRow: true },
    });
  } else if ((hasAccessories && calculateRequest.includePrice === 0) || (hasAccessories && endCustomer)) {
    quoteData.push({
      key: "accessories",
      translatedKey: translate(texts.accessories),
      value: accessoriesOnlyTextValue(priceAccessories, translate, productKey, pvs),
      style: { spaceBetweenRow: true },
    });
  } else if (!hasAccessories) {
    quoteData.push({
      key: "no_accessories",
      translatedKey: "",
      value: "",
      style: { spaceBetweenRow: true },
    });
  }
  quoteData.push({
    key: "quote_stomme",
    translatedKey: translate(texts.quote_stomme),
    value: "\n" + translate(texts.quote_stomme_value(productKey, pvs)),
  });
  quoteData.push({
    key: "quote_controlling",
    translatedKey: translate(texts.quote_controlling),
    value: "\n" + translate(texts.quote_controlling_value(productKey, pvs)),
  });
  quoteData.push({
    key: "quote_temperature_info",
    translatedKey: translate(texts.quote_temperature_info),
    value:
      "\n" +
      translate(
        texts.quote_temperature_info_value(
          calculateResult.temperatureIncreaseC,
          Math.round(calculateResult.airVolumeM3h),
          calculateResult.velocityMs,
          calculateResult.pressureDropPa,
          productKey
        )
      ),
  });
  quoteData.push({
    key: "quote_delivery",
    translatedKey: translate(texts.quote_delivery_info),
    value: "\n" + translate(texts.quote_delivery_info_value(productKey)),
  });
  return quoteData;
}

export function priceValue(
  calculateRequest: CalculateRequest,
  calculateResult: CalculateResult,
  translate: TranslateFn,
  isInternalUser: boolean,
  hasAccessories: boolean,
  customItems: CustomItems | undefined,
  productKey: string
): string {
  const discount =
    calculateRequest.discount && calculateRequest.discount > 0 && calculateRequest.includeDiscount
      ? `(${translate(texts.discount(productKey))}: ${calculateRequest.discount} %)`
      : "";

  const totalPriceCustomItems = customItems?.map((item) => item.totalPrice);

  if (hasAccessories && totalPriceCustomItems && isInternalUser) {
    return (
      calculateResult.totalPrice +
      Currency[calculateResult.currency] +
      discount +
      " " +
      translate(
        texts.excl_accessories_below(calculateResult.heaterPrice, Currency[calculateResult.currency], productKey)
      )
    );
  } else if (hasAccessories && !totalPriceCustomItems && isInternalUser) {
    return (
      `${calculateResult.heaterPrice}${Currency[calculateResult.currency]} ` +
      discount +
      " " +
      translate(
        texts.excl_accessories_below(calculateResult.heaterPrice, Currency[calculateResult.currency], productKey)
      )
    );
  } else {
    return `${calculateResult.heaterPrice}${Currency[calculateResult.currency]} ` + discount + " ";
  }
}

export function accessoriesFullValue(
  calculateRequest: CalculateRequest,
  priceAccessories: PriceAccessories,
  translate: TranslateFn,
  productKey: string,
  pvs: PropertyValueSet.PropertyValueSet
): string {
  const value = Object.values(priceAccessories.accessories)
    .map((accessory) =>
      translate(
        key(
          accessory.text_key,
          { price: accessory.value, currency: Currency[calculateRequest.currency] },
          productKey,
          pvs
        )
      )
    )
    .join("\n");

  return "\n" + value;
}

export function accessoriesOnlyTextValue(
  priceAccessories: PriceAccessories,
  translate: TranslateFn,
  productKey: string,
  pvs: PropertyValueSet.PropertyValueSet
): string {
  const value = Object.values(priceAccessories.accessories)
    .map((accessory) => translate(key(accessory.text_key, { price: "", currency: "" }, productKey, pvs)))
    .join("\n")
    .replace(",", "");

  return "\n" + value;
}

function customItemsValue(customItems: CustomItems): string {
  const value = customItems.map((item) => `${item.name}`).join("\n");
  return "\n" + value;
}
