import gql from "graphql-tag";
import { LangText_ModulesTextsFragment } from "./translations";
import {
  propertyFieldNameFragment,
  propertyImageFragment,
  propertyInfoFragment,
  propertyModulesFragment,
  propertyOverridable,
  propertySelectorType,
  propertyValidationFragment,
} from "./property-fragments";

export const circuitDiagramModulesFragment = gql`
  fragment circuitDiagramModules on Modules {
    custom_tables {
      ...circuitDiagramTable
    }
  }

  fragment circuitDiagramTable on Module_custom_tables {
    circuit_diagram {
      property_filter
      amp_from
      amp_to
      document
      file_name
    }
  }
`;

export const weightModulesFragment = gql`
  fragment weightModules on Modules {
    custom_tables {
      ...weightTable
    }
  }

  fragment weightTable on Module_custom_tables {
    weight {
      property_filter
      min_amp
      max_amp
      terminal_block
      contactors
      switches
      cable
    }
  }
`;

export const accessoriesModulesFragment = gql`
  fragment accessoriesModules on Modules {
    custom_tables {
      ...accessoriesTable
    }
  }

  fragment accessoriesTable on Module_custom_tables {
    accessories {
      property_filter
      name
      sek
      eur
      include_in_heaterprice
      text_key
    }
  }
`;

export const electricalHeaterVolatagesModulesFragment = gql`
  fragment voltageModules on Modules {
    custom_tables {
      ...voltageTable
    }
  }

  fragment voltageTable on Module_custom_tables {
    voltage {
      property_filter
      voltage
      phases
      frequency
    }
  }
`;

export const elementModulesFragment = gql`
  fragment elementModules on Modules {
    custom_tables {
      ...elementTable
    }
  }

  fragment elementTable on Module_custom_tables {
    element {
      property_filter
      power
      voltage
      resistance
      length
      artnr
      type
      comment
      yteffekt
    }
  }
`;

export const glandEntrySizeModulesFragment = gql`
  fragment glandEntrySizeModules on Modules {
    custom_tables {
      ...glandEntrySizeTable
    }
  }

  fragment glandEntrySizeTable on Module_custom_tables {
    gland_entry_size {
      from_amp
      to_amp
      diameter
    }
  }
`;

export const priceRiModulesFragment = gql`
  fragment priceRiModules on Modules {
    custom_tables {
      ...priceRiTable
    }
  }

  fragment priceRiTable on Module_custom_tables {
    ri {
      property_filter
      effect_from
      effect_to
      sek
      eur
    }
  }
`;

export const priceAreaModulesFragment = gql`
  fragment priceAreaModules on Modules {
    custom_tables {
      ...priceAreaTable
    }
  }

  fragment priceAreaTable on Module_custom_tables {
    area {
      property_filter
      area_from
      area_to
      sek
      eur
    }
  }
`;

export const priceModelsModulesFragment = gql`
  fragment priceModelsModules on Modules {
    custom_tables {
      ...priceModelsTable
    }
  }

  fragment priceModelsTable on Module_custom_tables {
    models {
      property_filter
      power
      sek
      eur
    }
  }
`;

export const orderingCodeModulesFragment = gql`
  fragment orderingCodeModules on Modules {
    ordering_code {
      ...orderingCode
    }
  }

  fragment orderingCode on Module_ordering_code {
    code {
      sort_no
      property_filter
      type
      code
    }
  }
`;

export const waterDxAccessories = gql`
  fragment accessoriesWaterDxModules on Modules {
    custom_tables {
      ...accessoriesWaterDxTable
    }
  }

  fragment accessoriesWaterDxTable on Module_custom_tables {
    accessories_water_dx {
      name
      art_nr
      price_sek
      price_eur
      category
      kv
      text_key
    }
  }
`;

export const productAccessoryLink = gql`
  fragment productAccessoryLinkModules on Modules {
    custom_tables {
      ...productAccessoryLinkTable
    }
  }

  fragment productAccessoryLinkTable on Module_custom_tables {
    product_accessory_link {
      product_art_nr
      accessory_art_nr
    }
  }
`;

export const productWebsiteUrl = gql`
  fragment productWebsiteUrlModules on Modules {
    custom_tables {
      ...productWebsiteUrlTable
    }
  }

  fragment productWebsiteUrlTable on Module_custom_tables {
    website_product_url {
      product
      language
      url
    }
  }
`;

export const productQuery = gql`
  query Product($productId: ID!, $language: String!) {
    # Add language variable here
    product(id: $productId) {
      key
      modules {
        images {
          image {
            property_filter
            image
            file_name
            name
            type
          }
        }
        ...LangText_ModulesTexts
        ...voltageModules
        ...priceAreaModules
        ...priceRiModules
        ...priceModelsModules
        ...orderingCodeModules
        ...propertySelectorTypeModule
        ...propertyOverridableModule
        ...propertyImageModule
        ...propertyInfoModule
        ...propertyFieldNameModule
        ...propertyValidationModule
        documents {
          document {
            sort_no
            property_filter
            type
            name
            language
            document
            file_name
          }
        }

        ...elementModules
        ...accessoriesModules
        ...circuitDiagramModules
        ...glandEntrySizeModules
        ...weightModules
        ...accessoriesWaterDxModules
        ...productAccessoryLinkModules
        ...productWebsiteUrlModules
        properties {
          property {
            ...propertyModule
          }
        }
      }
    }
  }
  ${propertyModulesFragment}
  ${electricalHeaterVolatagesModulesFragment}
  ${orderingCodeModulesFragment}
  ${priceAreaModulesFragment}
  ${priceModelsModulesFragment}
  ${priceRiModulesFragment}
  ${elementModulesFragment}
  ${accessoriesModulesFragment}
  ${circuitDiagramModulesFragment}
  ${weightModulesFragment}
  ${LangText_ModulesTextsFragment}
  ${propertySelectorType}
  ${propertyImageFragment}
  ${propertyOverridable}
  ${propertyInfoFragment}
  ${glandEntrySizeModulesFragment}
  ${propertyFieldNameFragment}
  ${propertyValidationFragment}
  ${waterDxAccessories}
  ${productAccessoryLink}
  ${productWebsiteUrl}
`;
