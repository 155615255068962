import { DocumentNode, print, ExecutionResult } from "graphql";
import { Cmd } from "@typescript-tea/core";
import { GraphQlUtils, User } from "@ehb/shared";
import * as HttpFetch from "../effect-managers/http-fetch";

function buildAuthHeaders(accessToken: string): Record<string, string> {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  };
}
// Runs queries to our own ehb endpoint to get projects/data
export const graphQLQueryWithAuth =
  (activeUser: User.ActiveUser) =>
  <TData, TVariables, A>(
    query: DocumentNode,
    variables: TVariables,
    successActionCreator: (response: TData) => A
  ): Cmd<A> => {
    const gqlBody = JSON.stringify({ query: print(query), variables });

    const fetchOneCmd = HttpFetch.post(
      buildAuthHeaders(activeUser.accessToken),
      "/graphql?",
      "json",
      "application/json",
      gqlBody,
      (result: ExecutionResult) => {
        if (!result.data || result.errors) {
          throw new Error(`GraphQL query failed: ${gqlBody}, Errors: ${JSON.stringify(result.errors)}`);
        }
        return successActionCreator(result.data as TData);
      },
      () => {
        throw new Error(`GraphQL query failed (HTTP error): ${gqlBody}`);
      }
    );
    return fetchOneCmd;
  };

// Runs mutations to our own rvs endpoint to update data
// eslint-disable-next-line functional/no-let
let gqlMutationLog: ReadonlyArray<{
  readonly id: number;
  readonly time: number;
  readonly gqlBody: string;
  readonly completed: boolean;
}> = [];
// eslint-disable-next-line functional/no-let
let gqlMutationLogNextId = 0;
export const graphQLMutationWithAuth =
  (activeUser: User.ActiveUser) =>
  <TData, TVariables, A>(
    mutation: DocumentNode,
    variables: TVariables,
    onSuccess?: (response: TData) => A,
    onError?: () => A
  ): Cmd<A> => {
    const gqlBody = JSON.stringify({ query: print(mutation), variables });
    const logEntry = {
      id: gqlMutationLogNextId++,
      time: Date.now(),
      gqlBody,
      completed: false,
    };
    gqlMutationLog = [logEntry, ...gqlMutationLog.filter((e, i) => !e.completed || i < 10)];

    const fetchOneCmd = HttpFetch.post(
      buildAuthHeaders(activeUser.accessToken),
      "/graphql?",
      "json",
      "application/json",
      gqlBody,
      onSuccess &&
        ((result: ExecutionResult) => {
          if (!result.data || result.errors) {
            throw new Error(
              `GraphQL errors, id:${logEntry.id}: '${JSON.stringify(result.errors)}'. Mutation log:\n${gqlMutationLog
                .map((e, i) => `${i} (${e.time}, id:${e.id}, completed:${e.completed}): ${e.gqlBody}`)
                .join("\n")}`
            );
          }
          gqlMutationLog = gqlMutationLog.map((e) => (e.id === logEntry.id ? { ...e, completed: true } : e));
          return onSuccess(result.data as TData);
        }),
      () => {
        if (onError) {
          return onError();
        } else {
          throw new Error(
            `GraphQL mutation failed (HTTP error, id:${logEntry.id}). Mutation log:\n${gqlMutationLog
              .map((e, i) => `${i} (${e.time}, id:${e.id}, completed:${e.completed}): ${e.gqlBody}`)
              .join("\n")}`
          );
        }
      }
    );
    return fetchOneCmd;
  };

// Runs queries against Promaster API to get Promaster data
export const graphQLProductQueryWithAuth =
  (activeUser: User.ActiveUser, marker: string) =>
  <TData, TVariables, A>(
    query: DocumentNode,
    variables: TVariables,
    successActionCreator: (response: TData) => A
  ): Cmd<A> => {
    const gqlBody = JSON.stringify({ query: print(query), variables });
    const fetchOneCmd = HttpFetch.post(
      buildAuthHeaders(activeUser.accessToken),
      GraphQlUtils.createApiUrl(marker),
      "json",
      "application/json",
      gqlBody,
      (result: ExecutionResult) => {
        if (!result.data || result.errors) {
          throw new Error(`GraphQL query failed: ${gqlBody}, Errors: ${JSON.stringify(result.errors)}`);
        }
        return successActionCreator(result.data as TData);
      }
    );
    return fetchOneCmd;
  };
