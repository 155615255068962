// import { log } from "../logger";
import { CalculateDepthResult, calc_depth, getDepthForVFLVFLPG } from "./calc-depth";
import { VeabConstants } from "./constants";
import { GetDimensionsInput, Model, Type } from "./types";
import { FP } from "..";

export function getWidth(model: Model, type: Type, widthMm: number): number {
  let faceWidthMm = widthMm;
  if (model === Model["VRA-FU"]) {
    faceWidthMm =
      widthMm -
      (type === Type.M || type === Type.ML
        ? VeabConstants.VRA_Type_Width_Without_Regulator
        : VeabConstants.VRA_Type_Width_With_Regulator);
  }
  // VTL width subtraction
  else if (model === Model.VTL) {
    faceWidthMm = widthMm - VeabConstants.VTL_Type_Width;
  }

  return faceWidthMm;
}

export function getHeight(model: Model, heightMm: number): number {
  let faceHeightMm = heightMm;
  if (model === Model["VRA-FU"]) {
    faceHeightMm = heightMm - VeabConstants.VRA_Type_Height;
  }
  // adapt for VTL
  else if (model === Model.VTL) {
    faceHeightMm = heightMm - VeabConstants.VTL_Type_Height;
  }

  return faceHeightMm;
}

export function getDepth(input: GetDimensionsInput): FP.Result<string, CalculateDepthResult> {
  const {
    widthMm,
    heightMm,
    powerKw,
    voltageV,
    phases,
    powerStepsKw,
    model,
    type,
    elementTable,
    connection,
    //extraDepthTable,
    current,
  } = input;
  const calcDepthResult = calc_depth(
    widthMm,
    heightMm,
    powerKw,
    voltageV,
    phases,
    powerStepsKw,
    type,
    elementTable,
    model
  );

  let depthMm = calcDepthResult?.depthMm;

  if (calcDepthResult && model !== Model["VRA-FU"] && model !== Model.VTL) {
    depthMm = getDepthForVFLVFLPG(
      widthMm,
      type,
      calcDepthResult.depthMm,
      heightMm,
      current,
      connection,
      powerKw * 1000,
      voltageV,
      calcDepthResult.electricalElements
    );
  }

  if (!depthMm) {
    return FP.Err("No depth.");
  }

  // if (connection === Connection.Circular) {
  //   const extraDepth = extraDepthTable.find(
  //     (row) => (row.ampere === "under_25" && current < 25) || (row.ampere === "over_25" && current >= 25)
  //   );

  //   if (extraDepth) {
  //     depthMm += extraDepth.extra_depth;
  //   }
  // }
  return FP.Ok({
    depthMm: depthMm,
    electricalElements: calcDepthResult ? calcDepthResult.electricalElements : 0,
    articleNumber: calcDepthResult ? calcDepthResult.articleNumber : "",
  });
}
