import { IconName } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { Icon } from "./icon";
import { withTw } from "./with-tw";

export interface ButtonProps {
  readonly label: string;
  readonly title?: string;
  readonly type?: "primary" | "secondary" | "danger" | "nav";
  readonly onClick?: (e: React.MouseEvent) => void;
  readonly href?: string;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly iconLeft?: IconName;
  readonly iconRight?: IconName;
  readonly spinnerIcon?: boolean;
  readonly large?: boolean;
}

export type StyledButtonProps = {
  readonly buttontype: "primary" | "secondary" | "danger" | "nav";
  readonly disabled: boolean;
  readonly large: boolean;
};

export const StyledButton = withTw(
  "button",
  "cursor-pointer button flex",
  ({ large }: StyledButtonProps) => (large ? "text-sm" : "text-xs btn-tiny"),
  ({ buttontype }: StyledButtonProps) => {
    switch (buttontype) {
      case "primary":
        return "btn-primary";
      case "secondary":
        return "btn-primary";
      case "danger":
        return "btn-primary";
      case "nav":
        return "button gap-2";
      default:
        return "";
    }
  },
  ({ disabled }: StyledButtonProps) => (disabled ? "btn-disabled hover:cursor-default hover:clear-none" : "")
);

export function Button({
  label,
  title,
  type = "primary",
  onClick,
  href,
  disabled = false,
  className,
  iconLeft,
  iconRight,
  spinnerIcon,
  large = false,
}: ButtonProps): JSX.Element {
  const styledButton = (
    <StyledButton
      title={title}
      buttontype={type}
      disabled={disabled}
      large={large}
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
    >
      {iconLeft && (
        <Icon colorClass={" "} prefix="fas" className={spinnerIcon ? "animate-spin" : "font-normal"} icon={iconLeft} />
      )}
      {label && <span className="whitespace-nowrap">{label}</span>}
      {iconRight && (
        <Icon colorClass={" "} prefix="fas" className={spinnerIcon ? "animate-spin" : ""} icon={iconRight} />
      )}
    </StyledButton>
  );
  return href ? (
    <a className={className} href={href}>
      {styledButton}
    </a>
  ) : (
    <div className={className}>{styledButton}</div>
  );
}
