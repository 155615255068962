export function calculateRows(powerkW: number, heightMm: number, widthMm: number): number {
  if (heightMm < 0 || widthMm < 0 || heightMm > 2520 || widthMm > 2520) {
    return 0;
  }
  const powerOverArea = powerkW / ((heightMm / 1000) * (widthMm / 1000) * 20);

  if (powerOverArea <= 1.02) {
    return 1;
  } else if (powerOverArea <= 2.03) {
    return 2;
  } else if (powerOverArea <= 3.04) {
    return 3;
  } else if (powerOverArea <= 4.04) {
    return 4;
  } else if (powerOverArea <= 5.06) {
    return 5;
  } else if (powerOverArea <= 6.07) {
    return 6;
  } else if (powerOverArea <= 7.08) {
    return 7;
  } else if (powerOverArea <= 8.09) {
    return 8;
  } else if (powerOverArea <= 9.1) {
    return 9;
  } else {
    const wholeNumberString = powerOverArea.toString().split(".")[0];

    const wholeNumber = parseInt(wholeNumberString, 10);

    const decimalNumberString = powerOverArea.toString().split(".")[1];

    const decimalNumber = parseInt(decimalNumberString, 10);

    const firstDecimalZeroCheck = parseInt(decimalNumberString, 2);

    if (!decimalNumber || decimalNumber <= 1 || firstDecimalZeroCheck === 0) {
      return wholeNumber;
    } else {
      return wholeNumber + 1;
    }
  }
}
