import { exhaustiveCheck } from "ts-exhaustive-check";
import { home } from "./home";

export interface UserManagerSettings {
  readonly authority?: string;
  readonly client_id: string;
  readonly response_type?: string;
  readonly scope: string;
  readonly redirect_uri?: string;
  readonly filterProtocolClaims: boolean;
  readonly loadUserInfo?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly silent_redirect_uri?: any;
  readonly automaticSilentRenew: boolean;
  readonly monitorSession: boolean;
  readonly post_logout_redirect_uri?: string;
}

export type OidcClientCmd<A> = Login<A> | ProcessSigninCallback<A> | SilentRenew<A> | Logout<A>;

export interface Login<_ignored_A> {
  readonly home: typeof home;
  readonly type: "Login";
  readonly settings: UserManagerSettings;
  // If we are redirected to the identity provider, this state that we can beack afterwards (usually the originally requested url)
  readonly redirectState: {};
}

export function login<A>(settings: UserManagerSettings, redirectState: {}): Login<A> {
  return {
    home: "oidc-client",
    type: "Login",
    settings,
    redirectState,
  };
}

export interface ProcessSigninCallback<_ignored_A> {
  readonly home: typeof home;
  readonly type: "ProcessSigninCallback";
  readonly settings: UserManagerSettings;
  // readonly onSuccess: (redirectUrl: string | undefined) => A;
}

export function processSigninCallback<A>(
  settings: UserManagerSettings
  // onSuccess: (redirectUrl: string | undefined) => A
): ProcessSigninCallback<A> {
  return {
    home: "oidc-client",
    type: "ProcessSigninCallback",
    settings,
    // onSuccess,
  };
}

export interface SilentRenew<_ignored_A> {
  readonly home: typeof home;
  readonly type: "SilentRenew";
  readonly settings: UserManagerSettings;
}

export function silentRenew<A>(settings: UserManagerSettings): SilentRenew<A> {
  return {
    home: "oidc-client",
    type: "SilentRenew",
    settings: settings,
  };
}

export interface Logout<_ignored_A> {
  readonly home: typeof home;
  readonly type: "Logout";
}

export function logout<A>(): Logout<A> {
  return {
    home: "oidc-client",
    type: "Logout",
  };
}

export function mapCmd<A1, A2>(_actionMapper: (a: A1) => A2, cmd: OidcClientCmd<A1>): OidcClientCmd<A2> {
  switch (cmd.type) {
    case "ProcessSigninCallback":
    case "SilentRenew":
    case "Login":
    case "Logout": {
      return cmd;
    }
    default: {
      return exhaustiveCheck(cmd, true);
    }
  }
}
