import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Unit } from "uom/lib-cjs/unit";

export function propertyAmountAs(
  toUnit: Unit<unknown>,
  propertyName: string,
  variant: PropertyValueSet.PropertyValueSet
): number | undefined {
  const property = PropertyValueSet.getAmount(propertyName, variant);
  return property && Amount.valueAs(toUnit, property);
}
