import React from "react";

export function useWindowScroll(): boolean {
  const [isScrolling, setIsScrolling] = React.useState(false);
  const handleScroll = (): void => {
    setIsScrolling(!isScrolling);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolling]);

  return isScrolling;
}
