import { VeabConstants } from "./constants";
import { ElementTableRows } from "./get-tables";

export type LengthTable = {
  readonly power: number;
  readonly voltage: number;
  readonly length: number;
  readonly article_number: string;
};

export function getBendLengthsRegulator(
  elementVoltage: 400 | 230,
  widthMm: number,
  minPowerW: number,
  maxPowerW: number,
  elementTable: ReadonlyArray<ElementTableRows>
): ReadonlyArray<LengthTable> | undefined {
  const maxlength = widthMm <= 1000 ? 3100 : undefined;
  const filteredTable: Array<LengthTable> = elementTable
    .filter(
      (t) =>
        t.voltage === elementVoltage &&
        t.artNr !== "808126" &&
        t.artNr !== "808127" &&
        t.artNr !== "808128" &&
        t.artNr !== "808129" &&
        t.artNr !== "808149" &&
        t.type === "Elbatt" &&
        (t.power ?? 0) >= minPowerW &&
        (t.power ?? 0) <= maxPowerW &&
        (maxlength ? (t.length ?? 0) <= maxlength : true)
    )
    .map((row) => ({
      power: row.power ?? 0,
      voltage: row.voltage ?? 0,
      length: row.length ?? 0,
      article_number: row.artNr ?? "",
    }));
  return filteredTable.sort((a, b) => (a.power > b.power ? -1 : 1));
}

export function getBendLengthsWithoutRegulator(
  elementVoltage: 400 | 230,
  widthMm: number,
  elementTable: ReadonlyArray<ElementTableRows>
): ReadonlyArray<LengthTable> | undefined {
  const lengthTable: Array<LengthTable> = elementTable
    .filter((r) => r.type === "Elbatt")
    .map((row) => ({
      power: row.power ?? 0,
      voltage: row.voltage ?? 0,
      length: row.length ?? 0,
      article_number: row.artNr ?? "",
    }));

  if (elementVoltage === 230) {
    if (widthMm < VeabConstants.Width_Min) {
      //Går ej göra
      // TODO- Let code run. Might change later on => Nya EHB kan inte räkna VFL-M med breddmått under 450mm. Samma parametrar men modell VFL-ML går att räkna.
      // return undefined;
    } else if (widthMm > 1750 && widthMm <= 1950) {
      return lengthTable
        .filter(
          (t) =>
            (t.length ?? 0 >= 3500) &&
            t.voltage === elementVoltage &&
            t.article_number !== "808149" &&
            t.article_number !== "808126"
        )
        .sort((a, b) => (a.power > b.power ? -1 : 1));
    } else if (widthMm > 1950 && widthMm <= VeabConstants.Width_Max) {
      return lengthTable
        .filter(
          (t) =>
            (t.length ?? 0 >= 5000) &&
            t.voltage === elementVoltage &&
            t.article_number !== "808149" &&
            t.article_number !== "808126"
        )
        .sort((a, b) => (a.power > b.power ? -1 : 1));
    } else if (widthMm > VeabConstants.Width_Max) {
      // TODO- Let code run. Might change later on.
    }
  }

  return lengthTable
    .filter(
      (t) =>
        t.voltage === elementVoltage &&
        t.article_number !== "808126" &&
        t.article_number !== "808127" &&
        t.article_number !== "808128" &&
        t.article_number !== "808129" &&
        t.article_number !== "808149"
    )
    .sort((a, b) => (a.power > b.power ? -1 : 1));
}
