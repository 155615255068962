import gql from "graphql-tag";
import {
  propertyFieldNameFragment,
  propertyImageFragment,
  propertyInfoFragment,
  propertyModulesFragment,
  propertyOverridable,
  propertySelectorType,
  propertyValidationFragment,
} from "./property-fragments";

export const specialCalculationProductQuery = gql`
  query SpecialCalculationProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        ...propertySelectorTypeModule
        ...propertyOverridableModule
        ...propertyImageModule
        ...propertyInfoModule
        ...propertyFieldNameModule
        ...propertyValidationModule
        properties {
          property {
            ...propertyModule
          }
        }
      }
    }
  }
  ${propertyModulesFragment}
  ${propertySelectorType}
  ${propertyImageFragment}
  ${propertyOverridable}
  ${propertyInfoFragment}
  ${propertyFieldNameFragment}
  ${propertyValidationFragment}
`;
