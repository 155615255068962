import { Units } from "uom-units";
import { Amount } from "uom";
import { LayoutFoundation, ParagraphStyle } from "abstract-document/lib/abstract-document";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as React from "react";
import { AbstractDocJsx as ADX } from "abstract-document";
import * as Common from "../common";
import { CreatorProps, CreatorType, DataRow } from "./types";
import { TranslateFn, texts } from "../../lang-texts";
import { roundTo } from "../../utils";
import { propertyAmountAs } from "../../utils/property-amount-as";

const { AbstractDoc, Section, Group, Table, TableRow, TableCell, Paragraph, TextRun, Image, HyperLink, render } = ADX;

export const create: CreatorType = (props) => {
  const { pageProps, productImage, variant } = props;
  const { translate } = pageProps;
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);

  const quoteData = renderQuoteData(props);

  const productImageResource = productImage ? Common.createImageResource(productImage) : undefined;
  const quoteNumber = PropertyValueSet.getText("quotationnumber", variant);

  const isWater = PropertyValueSet.getInteger("medium", variant) === 1;

  const doc = render(
    <AbstractDoc styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.cataloguePage(pageProps)}>
        <Paragraph style={Common.createParagraphStyle({ alignment: "Center" })}>
          <TextRun
            text={translate(isWater ? texts.quote_header_water : texts.quote_header_dx)}
            styleName="H1"
          ></TextRun>
        </Paragraph>
        <Paragraph style={Common.createParagraphStyle({ alignment: "Center" })}>
          <TextRun text={quoteNumber || ""} styleName="Strong"></TextRun>
        </Paragraph>
        <Paragraph
          style={ParagraphStyle.create({
            position: "absolute",
            margins: LayoutFoundation.create({ left: 250, top: 30 }),
          })}
        >
          {productImageResource && <Image imageResource={productImageResource} width={250} height={200} />}
        </Paragraph>
        {quoteData}
      </Section>
    </AbstractDoc>
  );

  return doc;
};

function renderQuoteData(props: CreatorProps): JSX.Element {
  const { pageProps, variant, internalUser, calculationResult, searchProductKey, productKey } = props;
  const { translate } = pageProps;

  const project = PropertyValueSet.getText("project", variant);
  const reference = PropertyValueSet.getText("reference", variant);
  const notes = PropertyValueSet.getText("notes", variant);
  const productiontime = propertyAmountAs(Units.Day, "productiontime", variant);

  const quoteDate = new Date().toISOString().slice(0, 10);

  const projectData: DataRow[] = [];
  projectData.push(
    { textKey: texts.project, value: project },
    { textKey: texts.reference, value: reference },
    { textKey: texts.notes, value: notes }
  );

  const productData: DataRow[] = [];
  productData.push(
    { textKey: texts.quote_date, value: quoteDate },
    { textKey: texts.configuration_link, value: props.configurationLink },
    { textKey: texts.model, value: props.model }
    // { textKey: texts.type, value: "TODO" }, // type is included in model
  );

  if (internalUser) {
    productData.push(
      { textKey: texts.production_time, value: `${productiontime || 0} ${translate(texts.workingdays)}` },
      {
        textKey: texts.quote_payment_terms,
        value: translate(texts.property_value("paymentterms", variant, searchProductKey)),
      },
      {
        textKey: texts.quote_delivery_info,
        value: translate(texts.property_value("deliveryterms", variant, searchProductKey)),
      },
      {
        textKey: texts.price,
        value: calculationResult.price.grossPrice
          ? `${roundTo(calculationResult.price.grossPrice, 2)} ${
              calculationResult.price.priceType === "price_eur" ? "EUR" : "SEK"
            }`
          : "-",
      }
    );
  }

  const accessories = props.accessories.map((acc) => (
    <Paragraph key={acc.articleNumber}>
      <TextRun text={`${acc.articleNumber} - ${acc.name}`} />
    </Paragraph>
  ));

  const temperatureInfo = {
    textKey: texts.quote_temperature_info,
    value:
      "\n" +
      translate(
        texts.quote_temperature_info_value(
          roundTo(
            Amount.valueAs(Units.Celsius, calculationResult.airOutletTemperature) -
              Amount.valueAs(Units.Celsius, calculationResult.airInletTemperature),
            1
          ),
          roundTo(Amount.valueAs(Units.CubicMeterPerHour, calculationResult.airFlow), 2),
          roundTo(Amount.valueAs(Units.MeterPerSecond, calculationResult.airVelocity), 2),
          roundTo(Amount.valueAs(Units.Pascal, calculationResult.airPressureDrop), 1),
          searchProductKey
        )
      ),
  };
  const deliveryInfo = {
    textKey: texts.quote_delivery_info,
    value: "\n" + translate(texts.quote_delivery_info_value(searchProductKey)),
  };

  const renderData = (data: DataRow): JSX.Element => renderDataRow(data, translate);

  const spacer = (topMargin: number): JSX.Element => (
    <Paragraph style={ParagraphStyle.create({ margins: LayoutFoundation.create({ top: topMargin }) })} />
  );

  return (
    <Group>
      <Table columnWidths={[250]}>
        <TableRow>
          <TableCell>
            {spacer(20)}
            {projectData.map(renderData)}
            {spacer(20)}
            {productData.map(renderData)}
          </TableCell>
        </TableRow>
      </Table>

      {accessories.length > 0 ? (
        <Group>
          {spacer(20)}
          <Paragraph>
            <TextRun text={translate(texts.accessories)} styleName="Strong" />
          </Paragraph>
          {accessories}
        </Group>
      ) : (
        []
      )}
      {spacer(30)}
      <Group>
        <Paragraph>
          <TextRun text={translate(texts.product_information_title)} styleName="Strong" />
        </Paragraph>
        <Paragraph>
          <TextRun text={translate(texts.product_information(productKey))} />
        </Paragraph>
      </Group>
      {spacer(20)}
      {renderDataRow(temperatureInfo, translate)}
      {spacer(20)}
      {renderDataRow(deliveryInfo, translate)}
    </Group>
  );
}

function renderDataRow(dataRow: DataRow, translate: TranslateFn): JSX.Element {
  return (
    <Paragraph key={dataRow.textKey.key}>
      <TextRun text={`${translate(dataRow.textKey)}: `} styleName="Strong" />
      {dataRow.textKey === texts.configuration_link ? (
        <HyperLink
          target={dataRow.value || ""}
          text={translate(texts.link)}
          style={Common.createTextStyle({ fontSize: 10, color: "blue" })}
        />
      ) : (
        <TextRun text={dataRow.value || ""} />
      )}
    </Paragraph>
  );
}
