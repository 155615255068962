import { Vector3 } from "./vector3";

export interface Ray3 {
  readonly start: Vector3;
  readonly direction: Vector3;
}

export function ray3Create(start: Vector3, direction: Vector3): Ray3 {
  return {
    start: start,
    direction: direction
  };
}
