import { exhaustiveCheck } from "ts-exhaustive-check";
import { LangText_ModulesLanguagesFragment } from "@ehb/shared/src/graphql-queries";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { SharedState } from "@ehb/client-infra";
import { Cmd } from "@typescript-tea/core";
import gql from "graphql-tag";
import { Images, Texts } from "@ehb/shared";
import * as GQLOps from "../generated/generated-operations";
import { clientConfig } from "../client-config";

const query = gql`
  query headerMetaProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        ...LangText_ModulesLanguages
        ...imageModules
      }
    }
  }
  ${LangText_ModulesLanguagesFragment}
  ${Images.imageModulesFragment}
`;

const projectByIdQuery = gql`
  query headerProject($projectId: ID!) {
    project(id: $projectId) {
      project {
        id
        name
      }
      items {
        quantity
      }
    }
  }
`;

export interface State {
  readonly metaProduct: GQLOps.HeaderMetaProductQuery | undefined;
  readonly project: GQLOps.HeaderProjectQuery["project"] | undefined;
}

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  if (prevState) {
    return [prevState];
  }

  const state: State = {
    metaProduct: undefined,
    project: undefined,
  };

  const projectCmd = updateProjectData(sharedState);

  const gqlCmdProduct = sharedState.graphQLProductQuery<
    GQLOps.HeaderMetaProductQuery,
    GQLOps.HeaderMetaProductQueryVariables,
    Action
  >(query, { productId: clientConfig.promaster_meta_id }, (data) => {
    return Action.MetaProductRecieved(data);
  });

  return [state, Cmd.batch([gqlCmdProduct, projectCmd])];
}

export const Action = ctorsUnion({
  ChangeLanguage: (newLang: Texts.LanguageCode) => ({ newLang }),
  Logout: () => ({}),
  MetaProductRecieved: (response: GQLOps.HeaderMetaProductQuery) => ({
    response,
  }),
  QueryResponseById: (query: GQLOps.HeaderProjectQuery) => ({ query }),
  UpdateProjectData: () => ({}),
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "Logout": {
      return [state, undefined, SharedState.SharedStateAction.Logout()];
    }
    case "ChangeLanguage": {
      return [state, undefined, SharedState.SharedStateAction.SetLanguage(action.newLang)];
    }
    case "MetaProductRecieved": {
      return [{ ...state, metaProduct: action.response }];
    }
    case "QueryResponseById": {
      const projectId = action.query.project?.project.id;
      return [
        { ...state, project: action.query.project },
        undefined,
        SharedState.SharedStateAction.SetOpenProject(projectId || undefined),
      ];
    }
    case "UpdateProjectData": {
      return [state, updateProjectData(sharedState)];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function updateProjectData(sharedState: SharedState.SharedState): Cmd<Action> | undefined {
  if (!sharedState.openProjectId) {
    return undefined;
  }
  return sharedState.graphQLQuery<GQLOps.HeaderProjectQuery, GQLOps.HeaderProjectQueryVariables, Action>(
    projectByIdQuery,
    { projectId: sharedState.openProjectId },
    (data) => {
      return Action.QueryResponseById(data);
    }
  );
}
