import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { TranslateFn } from "../../lang-texts";
import * as Common from "../common";
import { TextRun } from "abstract-document/lib/abstract-document-jsx";

const { Table, TableRow, TableCell, Paragraph, Group, Image, render } = ADX;

export function createHeader(
  logo: AD.ImageResource.ImageResource | undefined,
  _translate: TranslateFn,
  _title: string | undefined | null,
  headerImages: ReadonlyArray<Common.BitmapImage>
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  const columns = [Infinity, ...headerImages.map(() => Infinity)];
  return [
    render(
      <Group>
        <Table
          columnWidths={[Infinity, Infinity]}
          style={AD.TableStyle.create({
            margins: AD.LayoutFoundation.create({ top: 30, bottom: 20 }),
            cellStyle: AD.TableCellStyle.create({
              borders: AD.LayoutFoundation.create({ bottom: 1 }),
            }),
          })}
        >
          <TableRow>
            <TableCell>
              <Paragraph
                style={AD.ParagraphStyle.create({
                  alignment: "Start",
                  margins: AD.LayoutFoundation.create({ top: 10, bottom: 10 }),
                })}
              >
                {logo && <Image imageResource={logo} width={150} height={40} />}
              </Paragraph>
            </TableCell>
            <TableCell>
              <Table columnWidths={columns}>
                <TableRow>
                  <TableCell>
                    <Paragraph>
                      <TextRun text="" />
                    </Paragraph>
                  </TableCell>

                  {headerImages.map((h) => (
                    <TableCell key={h.file_name}>
                      <Group>
                        <Paragraph
                          style={AD.ParagraphStyle.create({
                            alignment: "End",
                            margins: AD.LayoutFoundation.create({ top: 10, bottom: 10 }),
                          })}
                        >
                          <Image imageResource={Common.createImageResource(h)} width={55} height={40} />
                        </Paragraph>
                      </Group>
                    </TableCell>
                  ))}
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>
        </Table>
      </Group>
    ),
  ];
}
