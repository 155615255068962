import { PropertyValueSet } from "@promaster-sdk/property";
import { Calculate, CalculatorFrtCoil, Search } from "../..";
import { QueryGenerator, graphQLProductQuery } from "../../query";
import { ActiveUser, isInternalUser } from "../../user";
import { BitmapImage, getPageProps, imageToBitmapImage } from "../common";
import { ReportQueryParams } from "../types";
import { AdapterType, Response } from "./types";
import * as GQLOps from "../../generated/generated-operations";
import { createSearchData } from "../../search";
import { imageQueryByProductId } from "../common/queries";
import { fetchImageData } from "../../images";
import { createHeaderImages } from "../technical-data-sheet";

export function* query({
  clientConfig,
  productByKey,
  productKey,
  imageServiceUrl,
  user,
  properties,
}: ReportQueryParams): QueryGenerator<Response> {
  const searchQueryData = yield* graphQLProductQuery<GQLOps.SearchSingleQuery, GQLOps.SearchSingleQueryVariables>(
    Search.searchProductQuerySingle,
    {
      searchProductId: clientConfig.promaster_search_product_id,
      productId: productByKey[productKey].id,
    }
  );

  const fritermCoilData = yield* graphQLProductQuery<GQLOps.FrtCoilQuery, GQLOps.FrtCoilQueryVariables>(
    CalculatorFrtCoil.query,
    { searchProductId: clientConfig.promaster_search_product_id }
  );

  const searchProductImages = yield* imageQueryByProductId(clientConfig.promaster_search_product_id);

  const productImages = yield* imageQueryByProductId(productByKey[productKey].id);
  const productImage = productImages?.modules.images.image.find((i) => i.name === "product_image");

  const imageData = productImage
    ? yield* fetchImageData([productImage], imageServiceUrl, 300, 300, 1, "jpeg")
    : undefined;

  const searchResult = Search.searchSingle(
    createSearchData(searchQueryData),
    createSearchQuery(user, createSearchData(searchQueryData), properties, productKey)
  );
  const match = searchResult?.match;
  if (!match) {
    throw new Error("Failed to search");
  }

  const headerImages = yield* createHeaderImages(searchQueryData, match, searchProductImages, imageServiceUrl);

  return { searchData: searchQueryData, fritermCoilData, productImage: imageData && imageData[0], headerImages };
}

export const execute: AdapterType = async (reportParams, common, response) => {
  const { searchData, fritermCoilData, headerImages: headerImagesData } = response;
  const { user, productKey, properties } = reportParams;
  const pageProps = await getPageProps(reportParams, common);
  const productImage = response.productImage && (await imageToBitmapImage(500, 500, response.productImage));
  const query = createSearchQuery(user, createSearchData(searchData), properties, productKey);
  const calcData = CalculatorFrtCoil.mapQuery(fritermCoilData);
  const searchResult = Search.searchSingle(
    createSearchData(searchData),
    createSearchQuery(user, createSearchData(searchData), properties, productKey)
  );
  const match = searchResult?.match;
  if (!match) {
    throw new Error("Failed to search");
  }

  const calculationResult = await Calculate.calculateProduct(
    calcData,
    properties,
    match?.selection,
    match?.productVariantRow,
    query,
    reportParams.waterAccessories
  );

  const internalUser = isInternalUser(user.claims);

  if (
    !calculationResult ||
    calculationResult.type === "Err" ||
    searchData.searchProduct === null ||
    searchData.product === null
  ) {
    throw new Error("Failed to calculate");
  }

  const headerImages = [
    headerImagesData?.density && (await imageToBitmapImage(500, 500, headerImagesData.density)),
    headerImagesData?.tuv && (await imageToBitmapImage(500, 500, headerImagesData.tuv)),
    headerImagesData?.ce && (await imageToBitmapImage(500, 500, headerImagesData.ce)),
  ].filter((h): h is BitmapImage => !!h);

  return {
    pageProps: { ...pageProps, headerImages },
    calculationResult: calculationResult.value,
    variant: properties,
    model: match.model,
    productById: reportParams.productById,
    productImage,
    configurationLink: reportParams.configurationLink,
    searchProductKey: searchData.searchProduct.key,
    productKey: searchData.product.key,
    internalUser,
    accessories: reportParams.waterAccessories,
  };
};

function createSearchQuery(
  activeUser: ActiveUser,
  searchData: Search.SearchData,
  variant: PropertyValueSet.PropertyValueSet,
  productKey: string
): Search.Query {
  return {
    company: activeUser.companyName,
    filter: Search.propertiesToSearchFilter(searchData, variant),
    productKey: productKey,
    userCurrency: activeUser.claims.currency,
  };
}
