import { Vector2, vec2Create } from "./vector2";
import { Vector3, vec3Zero, vec3Add, vec3Create } from "./vector3";
import { Camera, cameraCreatePerspective, cameraCreateOrtho } from "./camera";
import { matrix4CreateRotationX, matrix4CreateRotationZ, matrix4Multiply, matrix4Transform } from "./matrix4";

export interface OrbitCamera {
  readonly position: Vector2;
  readonly target: Vector3;
  readonly zoom: number;
  readonly ortho: boolean;
  readonly aspect: number;
  readonly near: number;
  readonly far: number;
  readonly fov: number;
}

export interface OrbitCameraView {
  readonly name: string;
  readonly camera: OrbitCamera;
}

export function orbitCameraViewCreate(name: string, camera: OrbitCamera): OrbitCameraView {
  return {
    name,
    camera,
  };
}

const defaultPosition = vec2Create(Math.PI * 0.3, Math.PI * 0.3);

export function orbitCameraDefault(zoom: number): OrbitCamera {
  return orbitCameraCreate(defaultPosition, vec3Zero, zoom, false, 16 / 9, 0.1, 5000);
}

export function orbitCameraCreateCovering(target: Vector3, targetRadius: number): OrbitCamera {
  const fovAdjustmentFactor = 1.2; // This needs to be adjusted if FOV is changed
  const cameraFov = 75;
  const fov = cameraFov * (Math.PI / 180);
  const cameraz = (targetRadius / Math.tan(fov * 0.5)) * fovAdjustmentFactor;
  return orbitCameraCreate(
    defaultPosition,
    vec3Create(target.x, target.y, target.x),
    cameraz,
    false,
    16 / 9,
    cameraz * 0.01,
    cameraz * 10,
    cameraFov
  );
}

export function orbitCameraCreate(
  position: Vector2,
  target: Vector3,
  zoom: number,
  ortho: boolean,
  aspect: number = 16 / 9,
  near: number = 0.1,
  far: number = 50000,
  fov: number = 75
): OrbitCamera {
  return {
    position: position,
    target: target,
    zoom: zoom,
    ortho: ortho,
    aspect: aspect,
    near: near,
    far: far,
    fov: fov,
  };
}

export function orbitCameraToCamera(orbitCamera: OrbitCamera): Camera {
  const rotX = matrix4CreateRotationX(orbitCamera.position.y);
  const rotZ = matrix4CreateRotationZ(orbitCamera.position.x);
  const matrix = matrix4Multiply(rotZ, rotX);
  const localPosition = matrix4Transform(vec3Create(0, 0, orbitCamera.zoom), matrix);
  const position = vec3Add(localPosition, orbitCamera.target);
  if (orbitCamera.ortho) {
    const left = -orbitCamera.zoom * orbitCamera.aspect;
    const right = orbitCamera.zoom * orbitCamera.aspect;
    const top = orbitCamera.zoom;
    const bottom = -orbitCamera.zoom;
    return cameraCreateOrtho(
      position,
      orbitCamera.target,
      orbitCamera.aspect,
      -orbitCamera.far,
      orbitCamera.far,
      left,
      right,
      top,
      bottom
    );
  } else {
    return cameraCreatePerspective(
      position,
      orbitCamera.target,
      orbitCamera.aspect,
      orbitCamera.near,
      orbitCamera.far,
      orbitCamera.fov
    );
  }
}

export function getOrbitCameraViews(center: Vector3): ReadonlyArray<OrbitCameraView> {
  return [
    {
      name: "front",
      camera: orbitCameraCreate(vec2Create(0, Math.PI * 0.5), center, 800, true),
    },
    {
      name: "back",
      camera: orbitCameraCreate(vec2Create(Math.PI, Math.PI * 0.5), center, 800, true),
    },
    {
      name: "top",
      camera: orbitCameraCreate(vec2Create(0, 0.00001), center, 800, true),
    },
    {
      name: "bottom",
      camera: orbitCameraCreate(vec2Create(0, Math.PI - 0.00001), center, 800, true),
    },
    {
      name: "left",
      camera: orbitCameraCreate(vec2Create(-Math.PI * 0.5, Math.PI * 0.5), center, 800, true),
    },
    {
      name: "right",
      camera: orbitCameraCreate(vec2Create(Math.PI * 0.5, Math.PI * 0.5), center, 800, true),
    },
    {
      name: "south_west",
      camera: orbitCameraCreate(vec2Create(-Math.PI * 0.25, Math.PI * 0.25), center, 800, true),
    },
    {
      name: "south_east",
      camera: orbitCameraCreate(vec2Create(Math.PI * 0.25, Math.PI * 0.25), center, 800, true),
    },
    {
      name: "north_west",
      camera: orbitCameraCreate(vec2Create(-Math.PI * 0.75, Math.PI * 0.25), center, 800, true),
    },
    {
      name: "north_east",
      camera: orbitCameraCreate(vec2Create(Math.PI * 0.75, Math.PI * 0.25), center, 800, true),
    },
  ];
}
