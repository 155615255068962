import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import ReactGA from "react-ga4";
import { createRoute, NoParams, UrlMatch } from "../route";
import { clientConfig } from "../client-config";

/**
 * How the routing works:
 *
 * The basic building block is a "Route" object which only has to pure functions:
 * - parseUrl(string) => Location
 * - buildUrl(Location) => string
 * Location is a union type of all locations available in the application.
 * Each location type is a data-only object that represents a location within the application.
 * Each location can have its own params which are url-segments or query params.
 *
 * As the application grows, having global routes is not practical becuse we need to
 * match all locations in all parts of the application. Therefore we nest the locations
 * so a root location may have child locations. A nested location has a location prop that
 * will contain the child location. So the root location object will be a wrapper for the
 * child location object. However the Route objects are not nested but they can create a nested location.
 * The URLs are always matched as root urls by the parseUrl/buildUrl functions in the Route
 * but the function can produce a nested/wrapped location.
 * The nested location object helps in the fractal style of nested in of views/state so a parent
 * view can pass the location inside the nested location down to a child view.
 * TL;DR: The locations are nested but the Routes are NOT nested.
 *
 * The parsing and the building of the URL can be generalized (but the parameters handling cannot
 * since the parameters are different for every route).
 * So we only need to provide functions that maps Params->Location and vice versa.
 *
 * Only the top-level init() and update(UrlChanged) should handle the url as a string and parse it.
 * Once it is parsed the rest of the application will only see it as a Location.
 * The location can be passed down in child init() functions and be unwrapped if nested.
 * The child location should not be stored in the child state becuase then we don't have a single source of truth.
 * Instead the location can be stored in sharedstate and each child can have a function to retrieve its location.
 *
 * When the url is changed (regardless of how it was changed) this occurs:
 * 1. The url that is being navigated to is parsed into a Location.
 * 2. The location is stored in state.
 * 3. The view renders based on location stored in state.
 * When you want to build an Url for a location, eg. for use in a <a href="xxx">
 * you call the buildUrl() function passing the location you want.
 *
 */

if (clientConfig.gaTargetId) {
  ReactGA.initialize(clientConfig.gaTargetId);
}

export const RootLocation = ctorsUnion({
  LoginCallback: () => ({}),
  LoggedOut: () => ({}),
  MainLocation: (location: MainLocation) => ({ location }),
});
export type RootLocation = CtorsUnion<typeof RootLocation>;

export const MainLocation = ctorsUnion({
  StartPage: () => ({}),
  ProductSelect: (query: { readonly [param: string]: string }) => ({
    query,
  }),
  ProductSearch: (query: { readonly [param: string]: string }) => ({
    query,
  }),
  ProductCalculate: (query: { readonly [param: string]: string }, productKey: string) => ({
    query,
    productKey,
  }),
  SpecialCalculation: (query: { readonly [param: string]: string }) => ({
    query,
  }),
  Projects: (query: { readonly [param: string]: string }) => ({ query }),
});
export type MainLocation = CtorsUnion<typeof MainLocation>;

export const ProductSelectLocation = ctorsUnion({
  Select: (variant: string) => ({ params: { variant } }),
  Root: () => ({}),
});

export type ProductSelectLocation = CtorsUnion<typeof ProductSelectLocation>;

// This object cannot have an explicit type becuse then type inference for each key is lost
// Each key in this map contains a "Route" which is just a parse and buildUrl function for that route
const rootRoutes = {
  LoginCallback: createRoute("/login-callback", RootLocation.LoginCallback, NoParams),
  LoggedOut: createRoute("/logged-out", RootLocation.LoggedOut, NoParams),
  MainLocation: createRoute(
    ":rest(.*)",
    (params) => {
      // The rest of the url should be parsed by the main parser....
      const match = parseMainUrl(params["rest"]);
      return match?.location && RootLocation.MainLocation(match.location);
    },
    (location) => {
      const mainUrl = buildMainUrl(location.location);
      return { rest: mainUrl };
    },
    (a) => {
      return a;
    },
    false
  ),
};

// This object cannot have an explicit type becuse then type inference for each key is lost
// Each key in this map contains a "Route" which is just a parse and buildUrl function for that route
const mainRoutes = {
  ProductSelect: createRoute(
    "/product-select:query(.*)",
    (params) => MainLocation.ProductSelect(parseQueryString(params.query)),
    (location) => ({ query: createQueryString(location.query) }),
    (a) => a
  ),
  ProductSearch: createRoute(
    "/product-search:query(.*)",
    (params) => MainLocation.ProductSearch(parseQueryString(params.query)),
    (location) => ({ query: createQueryString(location.query) }),
    (a) => a
  ),
  SpecialCalculation: createRoute(
    "/special-calculation:query(.*)",
    (params) => MainLocation.SpecialCalculation(parseQueryString(params.query)),
    (location) => ({ query: createQueryString(location.query) }),
    (a) => a
  ),
  ProductCalculate: createRoute(
    "/product-calculate/:productKey([^/|^?]+):query(.*)",
    (params) => MainLocation.ProductCalculate(parseQueryString(params.query), params.productKey),
    (location) => ({ query: createQueryString(location.query), productKey: location.productKey }),
    (a) => a
  ),
  Projects: createRoute(
    "/projects:query(.*)",
    (params) => MainLocation.Projects(parseQueryString(params.query)),
    (location) => ({ query: createQueryString(location.query) }),
    (a) => a
  ),
  StartupPage: createRoute("/", MainLocation.StartPage, NoParams),
};

function parseMainUrl(url: string): UrlMatch<MainLocation> | undefined {
  for (const p of Object.values(mainRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

function parseQueryString(queryString: string): { readonly [param: string]: string } {
  return Object.fromEntries(
    (/([^?]*)\?(.*$)/g.exec(queryString || "")?.[2] || "")
      .split("&")
      .map((p) => {
        const parts = p.split("=");
        return [decodeURIComponent(parts[0] || ""), decodeURIComponent(parts[1] || "")];
      })
      .filter((p) => !!p[0])
  );
}

export function createQueryString(query: { readonly [param: string]: string }): string {
  const params = Object.entries(query)
    .map(([param, value]) => `${param}=${encodeURIComponent(value)}`)
    .join("&");
  return `?${params}`;
}

export function parseUrl(url: string): UrlMatch<RootLocation> | undefined {
  for (const p of Object.values(rootRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

export function buildUrl(location: RootLocation): string {
  switch (location.type) {
    case "LoggedOut":
      return rootRoutes.LoggedOut.buildUrl(location);
    case "LoginCallback":
      return rootRoutes.LoginCallback.buildUrl(location);
    case "MainLocation":
      return rootRoutes.MainLocation.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}

export function buildMainUrl(location: MainLocation): string {
  switch (location.type) {
    case "StartPage":
      return mainRoutes.StartupPage.buildUrl(location);
    case "ProductSelect":
      return mainRoutes.ProductSelect.buildUrl(location);
    case "ProductSearch":
      return mainRoutes.ProductSearch.buildUrl(location);
    case "ProductCalculate":
      return mainRoutes.ProductCalculate.buildUrl(location);
    case "SpecialCalculation":
      return mainRoutes.SpecialCalculation.buildUrl(location);
    case "Projects":
      return mainRoutes.Projects.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}
