import gql from "graphql-tag";

export const propertyModulesFragment = gql`
  fragment propertyModule on Property {
    defaults {
      property_filter
      sort_no
      value
    }
    group
    image
    name
    quantity
    sort_no
    validation_filter
    values {
      description
      image
      property_filter
      sort_no
      value
    }
    visibility_filter
  }
`;

export const propertiesModulesFragment = gql`
  fragment propertiesModules on Modules {
    properties {
      property {
        ...propertyModule
      }
    }
  }
  ${propertyModulesFragment}
`;

export const propertySelectorType = gql`
  fragment propertySelectorTypeModule on Modules {
    custom_tables {
      ...propertySelectorType
    }
  }

  fragment propertySelectorType on Module_custom_tables {
    property_selector_type {
      property_filter
      property
      selector
    }
  }
`;

export const propertyOverridable = gql`
  fragment propertyOverridableModule on Modules {
    custom_tables {
      ...propertyOverridable
    }
  }

  fragment propertyOverridable on Module_custom_tables {
    property_overridable {
      property
      overridable
    }
  }
`;

export const propertyImageFragment = gql`
  fragment propertyImageModule on Modules {
    custom_tables {
      ...propertyImage
    }
  }

  fragment propertyImage on Module_custom_tables {
    property_image {
      property_filter
      property
      position
      image
      file_name
    }
  }
`;

export const propertyInfoFragment = gql`
  fragment propertyInfoModule on Modules {
    custom_tables {
      ...property_info
    }
  }

  fragment property_info on Module_custom_tables {
    property_info {
      property_filter
      property
      text_key
    }
  }
`;

export const propertyFieldNameFragment = gql`
  fragment propertyFieldNameModule on Modules {
    custom_tables {
      ...property_field_name
    }
  }

  fragment property_field_name on Module_custom_tables {
    property_field_name {
      property
      field_name
    }
  }
`;

export const propertyValidationFragment = gql`
  fragment propertyValidationModule on Modules {
    custom_tables {
      ...property_validation
    }
  }

  fragment property_validation on Module_custom_tables {
    property_validation {
      property_filter
      property
      validation_filter
    }
  }
`;
