/* eslint-disable no-restricted-properties */

export function calculatePressureDropPa(
  faceVelocityMs: number,
  faceAreaM2: number,
  powerkW: number,
  rows: number
): number {
  const coverage = (powerkW / (faceAreaM2 * rows)) * 1.5;
  const coverage_calc = coverage * 0.01 + 1;
  return Math.round(
    0.45 * (faceVelocityMs * faceVelocityMs * coverage_calc * coverage_calc - faceVelocityMs * faceVelocityMs) * rows
  );
}

export function calculateAirVelocity(areaM2: number, airVolumeM3h: number): number {
  return Math.round((airVolumeM3h / 3600 / areaM2) * 10) / 10;
}
