import { AirConstants } from "./constants";

export function calculateTemperatureIncrese(powerKw: number, airVolumeM3h: number): number {
  const temperatureIncrese = (powerKw * 1000) / 0.36 / airVolumeM3h;
  return Math.floor(temperatureIncrese);
}

export function calculateTemperatureIncreaseForAppliedPowerToAir(airFlowKgs: number, powerKw: number): number {
  return powerKw / (airFlowKgs * AirConstants.cpa_SI);
}
