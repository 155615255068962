import { vec3Create, vec3Zero, Vector3 } from "./vector3";

export interface Bounds3 {
  readonly minimum: Vector3;
  readonly maximum: Vector3;
}

export function bounds3Create(minimum: Vector3, maximum: Vector3): Bounds3 {
  return {
    minimum: minimum,
    maximum: maximum,
  };
}

export function bounds3CreateFromBoxes(boxes: ReadonlyArray<Bounds3>): Bounds3 {
  const okBoxes = boxes.filter(
    (b) =>
      Number.isFinite(b.maximum.x) &&
      Number.isFinite(b.maximum.y) &&
      Number.isFinite(b.maximum.z) &&
      Number.isFinite(b.maximum.x) &&
      Number.isFinite(b.maximum.y) &&
      Number.isFinite(b.maximum.z)
  );
  if (okBoxes.length === 0) {
    return {
      minimum: vec3Zero,
      maximum: vec3Zero,
    };
  }
  const minX = Math.min(...okBoxes.map((b) => b.minimum.x));
  const maxX = Math.max(...okBoxes.map((b) => b.maximum.x));
  const minY = Math.min(...okBoxes.map((b) => b.minimum.y));
  const maxY = Math.max(...okBoxes.map((b) => b.maximum.y));
  const minZ = Math.min(...okBoxes.map((b) => b.minimum.z));
  const maxZ = Math.max(...okBoxes.map((b) => b.maximum.z));
  return {
    minimum: {
      x: minX,
      y: minY,
      z: minZ,
    },
    maximum: {
      x: maxX,
      y: maxY,
      z: maxZ,
    },
  };
}

export function bounds3CreateFromVertices(vertices: ReadonlyArray<Vector3>): Bounds3 {
  if (vertices.length === 0) {
    return bounds3Create(vec3Zero, vec3Zero);
  }
  const minX = Math.min(...vertices.map((b) => b.x));
  const maxX = Math.max(...vertices.map((b) => b.x));
  const minY = Math.min(...vertices.map((b) => b.y));
  const maxY = Math.max(...vertices.map((b) => b.y));
  const minZ = Math.min(...vertices.map((b) => b.z));
  const maxZ = Math.max(...vertices.map((b) => b.z));
  return {
    minimum: {
      x: minX,
      y: minY,
      z: minZ,
    },
    maximum: {
      x: maxX,
      y: maxY,
      z: maxZ,
    },
  };
}

export function bounds3GetMid(bounds: Bounds3): Vector3 {
  const midX = (bounds.maximum.x + bounds.minimum.x) * 0.5;
  const midY = (bounds.maximum.y + bounds.minimum.y) * 0.5;
  const midZ = (bounds.maximum.z + bounds.minimum.z) * 0.5;
  return vec3Create(midX, midY, midZ);
}

export function bounds3Overlaps(b1: Bounds3, b2: Bounds3): boolean {
  return !(
    b1.maximum.x < b2.minimum.x ||
    b2.maximum.x < b1.minimum.x ||
    b1.maximum.y < b2.minimum.y ||
    b2.maximum.y < b1.minimum.y ||
    b1.maximum.z < b2.minimum.z ||
    b2.maximum.z < b1.minimum.z
  );
}

export function bounds3GetIntersectionBounds(b1: Bounds3, b2: Bounds3): Bounds3 | undefined {
  if (bounds3Overlaps(b1, b2)) {
    return undefined;
  }
  const minX = Math.max(b1.minimum.x, b2.minimum.x);
  const maxX = Math.min(b1.maximum.x, b2.maximum.x);
  const minY = Math.max(b1.minimum.y, b2.minimum.y);
  const maxY = Math.min(b1.maximum.y, b2.maximum.y);
  const minZ = Math.max(b1.minimum.z, b2.minimum.z);
  const maxZ = Math.min(b1.maximum.z, b2.maximum.z);
  return {
    minimum: {
      x: minX,
      y: minY,
      z: minZ,
    },
    maximum: {
      x: maxX,
      y: maxY,
      z: maxZ,
    },
  };
}
