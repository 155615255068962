import { ReportResponse } from ".";
import { ReportType, ReportModule, CreatorProps, reportSections } from "./types";

const reports = reportSections.map((module) => ({ ...module }));

export function getReportModule(name: ReportType): ReportModule<CreatorProps, ReportResponse> {
  const report = reports.find((x) => x.Name === name);
  if (!report) {
    throw new Error(`could not find Module: ${name.toString()}`);
  }
  return report;
}
