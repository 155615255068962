/* eslint-disable @typescript-eslint/prefer-for-of */
export function createMap<T, K extends string | number | symbol = string, V = T>(
  list: ReadonlyArray<T>,
  keySelector: (e: T, i: number) => K,
  valueSelector: (e: T, i: number) => V = (e: T) => e as unknown as V
): Record<string, V> {
  const map: Record<K, V> = {} as Record<K, V>;
  for (let i = 0; i < list.length; i++) {
    const e = list[i]!;
    map[keySelector(e, i)] = valueSelector(e, i);
  }
  return map;
}

export function createMapNested<T, S, V = S>(
  list: ReadonlyArray<T>,
  innerListSelector: (outer: T) => ReadonlyArray<S>,
  keySelector: (inner: S, outer: T) => string | number,
  valueSelector: (inner: S, outer: T, outerIndex: number) => V = (e: S) => e as unknown as V
): Record<string, V> {
  const map: Record<string, V> = {};
  for (let o = 0; o < list.length; o++) {
    const outer = list[o]!;
    const innerList = innerListSelector(outer);
    for (let i = 0; i < innerList.length; i++) {
      const inner = innerList[i]!;
      map[keySelector(inner, outer)] = valueSelector(inner, outer, o);
    }
  }
  return map;
}

export function createMapFromSet<T, V = T>(
  list: ReadonlySet<T>,
  keySelector: (i: T) => string | number,
  valueSelector: (i: T) => V = (i: T) => i as unknown as V
): Record<string, V> {
  const map: Record<string, V> = {};
  for (const item of list) {
    map[keySelector(item)] = valueSelector(item);
  }
  return map;
}

export function createMapFilter<T, V = T>(
  list: ReadonlyArray<T>,
  filterSelector: (e: T, i: number) => boolean,
  keySelector: (e: T, i: number) => string | number,
  valueSelector: (e: T, i: number) => V = (e: T) => e as unknown as V
): Record<string, V> {
  const map: Record<string, V> = {};
  for (let i = 0; i < list.length; i++) {
    const e = list[i]!;
    if (filterSelector(e, i)) {
      map[keySelector(e, i)] = valueSelector(e, i);
    }
  }
  return map;
}
