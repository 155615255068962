import {
  Connection,
  Currency,
  Insulation,
  Material,
  Type,
  IP_Class,
  CustomItems,
  ErrorResult,
  CalculateRequest,
} from "./types";
import {
  PriceTableAccessoriesRows,
  PriceTableAreaRows,
  PriceTableInsulationRows,
  PriceTables,
  PriceTablesModelsRows,
} from "./get-tables";
import { FP } from "..";
import { createErrorResult } from "./util";

// import { log } from "../logger";

function isMorML(type: Type): boolean {
  return type === Type.M || type === Type.ML;
}

export function getPrice(
  protection: IP_Class,
  _connection: Connection,
  depthMm: number,
  material: Material,
  areaM2: number,
  powerW: number,
  type: Type,
  currency: Currency,
  insulation: Insulation,
  powerStepsKw: ReadonlyArray<number>,
  voltage: number,
  phases: number,
  maxOutputTemp: number,
  priceTables: PriceTables,
  discount: number,
  customItems: CustomItems,
  request: CalculateRequest
): FP.Result<ErrorResult, number> {
  const priceArea = getPriceArea(areaM2, currency, priceTables.priceArea);
  if (priceArea.type === "Err") {
    return priceArea;
  }

  // Discount, only allow 0-100
  if (discount > 100) {
    discount = 0;
  }
  const priceProtection = getPriceProtection(protection, priceArea.value);

  const priceMaterial = isMorML(type) ? 0 : getPriceMaterial(material, priceArea.value); // if m/ml do one calculation for material and depthprice in getpricedepth

  const priceInsulation = getPriceInsulation(insulation, powerW, areaM2, currency, priceTables.priceInsulation);
  if (priceInsulation.type === "Err") {
    return priceInsulation;
  }
  const priceTypePower = getPriceTypePower(
    type,
    powerW,
    voltage,
    phases,
    powerStepsKw,
    currency,
    priceTables.priceModels
  );
  if (priceTypePower.type === "Err") {
    return priceTypePower;
  }
  const priceDepth = getPriceDepth(type, depthMm, priceArea.value, material);
  const priceOutTemp = getPriceOutTemp(powerW, maxOutputTemp, currency, priceTables.priceInsulation);
  if (priceOutTemp.type === "Err") {
    return priceOutTemp;
  }
  const includedAccessories = priceTables.priceAccessories
    .filter((row) => row.incluedInHeaterPrice)
    .reduce((acc, curr) => (currency === Currency.SEK ? acc + curr.sek : acc + curr.eur), 0);

  const inletMeshPrice = request.meshInlet === 1 ? (priceArea.value + priceMaterial) * 0.25 : 0;

  const outletMeshPrice = request.meshOutlet === 1 ? (priceArea.value + priceMaterial) * 0.25 : 0;

  // const priceOneMesh =
  //   getPriceArea(areaM2, currency, priceTables.priceArea) + getPriceMaterial(material, priceArea.value) * 0.25;

  return FP.Ok(
    Math.round(
      (inletMeshPrice +
        outletMeshPrice +
        priceProtection +
        priceMaterial +
        priceInsulation.value +
        priceTypePower.value +
        priceDepth +
        priceOutTemp.value +
        includedAccessories +
        customItems.reduce((acc, curr) => acc + curr.totalPrice, 0)) *
        (1 - discount / 100)
    )
  );
}

function getPriceDepth(type: Type, depthMm: number, priceArea: number, material: Material): number {
  if (type !== Type.ML && type !== Type.M) {
    return priceArea;
  }

  const materialPrice = getPriceMaterialMlOrM(material, priceArea);

  if (depthMm <= 500) {
    return materialPrice;
  } else if (depthMm > 500 && depthMm <= 1000) {
    return materialPrice * 2;
  }
  // >= 1001
  return materialPrice * 3;
}

function getPriceOutTemp(
  powerW: number,
  outPutTemp: number,
  currency: Currency,
  priceInsulationTable: ReadonlyArray<PriceTableInsulationRows>
): FP.Result<ErrorResult, number> {
  const powerKw = powerW / 1000;
  if (outPutTemp !== 50) {
    const row = priceInsulationTable
      .filter((r) => r.effect_from! <= powerKw && r.effect_to! >= powerKw)
      .sort((a, b) => a.effect_from! - b.effect_from!)[0];
    if (!row) {
      return createErrorResult("No price possible for selected variant. Please contact Veab.", "CONTACT-VEAB");
    }

    return FP.Ok(currency === Currency.SEK ? row.sek : row.eur);
  }
  return FP.Ok(0);
}

function getPriceInsulation(
  insulation: Insulation,
  powerKw: number,
  faceAreaM2: number,
  currency: Currency,
  priceRiTable: ReadonlyArray<PriceTableInsulationRows>
): FP.Result<ErrorResult, number> {
  if (insulation === Insulation.RI) {
    const row = priceRiTable
      .filter((r) => r.effect_from <= powerKw && r.effect_to >= powerKw)
      .sort((a, b) => a.effect_from - b.effect_from)[0];
    if (!row) {
      return createErrorResult("No price possible for selected variant. Please contact Veab.", "CONTACT-VEAB");
    }
    const price = currency === Currency.SEK ? row.sek : row.eur;
    const stompris = faceAreaM2 * 0.5;
    return FP.Ok(Math.round(price! + stompris));
  } else {
    return FP.Ok(0);
  }
}

function getPriceMaterial(material: Material, priceArea: number): number {
  if (material === Material.S) {
    const finalPrice = priceArea * 2.5;
    return Math.round(finalPrice - priceArea);
  } else if (material === Material.SA) {
    const finalPrice = priceArea * 3.5;
    return Math.round(finalPrice - priceArea);
  } else {
    return 0;
  }
}

function getPriceMaterialMlOrM(material: Material, priceArea: number): number {
  if (material === Material.S) {
    return priceArea * 2.5;
  } else if (material === Material.SA) {
    return priceArea * 3.5;
  } else {
    return priceArea;
  }
}

export type PriceAccessories = {
  readonly totalPrice: number;
  readonly accessories: Record<string, { readonly value: number; readonly text_key: string }>;
};
export function getPriceAccessories(
  currency: Currency,
  priceAccessoriesTable: ReadonlyArray<PriceTableAccessoriesRows>,
  discount: number
): PriceAccessories {
  const onlyExcludedAccesssories = priceAccessoriesTable.filter((row) => !row.incluedInHeaterPrice);
  const totalAccessoriesPrice =
    currency === Currency.SEK
      ? onlyExcludedAccesssories.reduce((sum, current) => sum + current.sek, 0)
      : onlyExcludedAccesssories.reduce((sum, current) => sum + current.eur, 0);
  const accessories = FP.createMap(
    onlyExcludedAccesssories,
    (row) => row.name,
    (row) => ({
      value:
        currency === Currency.SEK
          ? Math.round(row.sek * (1 - discount / 100))
          : Math.round(row.eur * (1 - discount / 100)),
      text_key: row.text_key,
    })
  );
  return {
    totalPrice: Math.round(totalAccessoriesPrice * (1 - discount / 100)),
    accessories,
  };
}

function getPriceTypePower(
  type: Type,
  powerW: number,
  voltage: number,
  phases: number,
  powerSteps: ReadonlyArray<number>,
  currency: Currency,
  priceModelsTable: ReadonlyArray<PriceTablesModelsRows>
): FP.Result<ErrorResult, number> {
  // const groups = [step1, step2, step3, step4, step5, step6];
  const stepArray: Array<number> = [];
  const powerKw = powerW / 1000;
  let price = 0;
  if (type === Type.M || type === Type.ML) {
    powerSteps.forEach((step) => {
      if (step > 43) {
        const stepCalc = step / 43;
        if (stepCalc > 1 && stepCalc < 2) {
          const stepDiv = Math.ceil(step / 2);
          stepArray.push(stepDiv, stepDiv);
        } else if (stepCalc > 2 && stepCalc < 3) {
          const stepDiv = Math.ceil(step / 3);
          stepArray.push(stepDiv, stepDiv, stepDiv);
        }
      } else if (step !== 0) {
        stepArray.push(step);
      }
    });

    for (const step of stepArray) {
      let calcEffect = 0;
      if (voltage === 400 && phases === 3) {
        calcEffect = step <= 4 ? 4 : step;
      } else {
        calcEffect = step;
      }
      const row = priceModelsTable.filter((r) => r.power >= calcEffect).sort((a, b) => (a.power < b.power ? -1 : 1))[0];
      if (!row) {
        return createErrorResult("No price possible for selected variant. Please contact Veab.", "CONTACT-VEAB");
      }

      price += currency === Currency.SEK ? row.sek : row.eur;
    }

    return FP.Ok(price);
  }
  let calcEffect = powerKw;
  if (voltage === 400 && phases === 3 && powerKw <= 6) {
    calcEffect = 6;
  } else {
    calcEffect = powerKw;
  }

  const row = priceModelsTable.filter((r) => r.power >= calcEffect).sort((a, b) => (a.power < b.power ? -1 : 1))[0];
  if (!row) {
    return createErrorResult("No price possible for selected variant. Please contact Veab.", "CONTACT-VEAB");
  }
  return FP.Ok(currency === Currency.SEK ? row.sek : row.eur);
}

function getPriceArea(
  areaM2: number,
  currency: Currency,
  priceAreaTable: ReadonlyArray<PriceTableAreaRows>
): FP.Result<ErrorResult, number> {
  // $result = mysqli_query($con,"SELECT * from prislista_area WHERE area_from <= ".$area." AND area_to >= ".$area);
  // const row = priceAreaTable
  //   .filter((r) => r.area_from >= faceAreaM2 && r.area_to >= faceAreaM2)
  //   .sort((a, b) => (a.area_from < b.area_from ? -1 : 1))[0];
  const roundedAreaM2 = Math.round(areaM2 * 100) / 100;
  const row = priceAreaTable.filter((r) => r.area_from <= roundedAreaM2 && r.area_to >= roundedAreaM2)[0];
  if (!row) {
    return createErrorResult("No price possible for selected variant. Please contact Veab.", "CONTACT-VEAB");
  }

  return FP.Ok(currency === Currency.SEK ? row.sek : row.eur);
}

function getPriceProtection(protection: IP_Class, priceArea: number): number {
  if (protection === IP_Class.IP55) {
    return Math.round(priceArea * 0.5);
  } else if (protection === IP_Class.IP65) {
    return Math.round(priceArea * 1);
  } else {
    return 0;
  }
}
