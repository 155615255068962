import { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

export interface IconProps {
  readonly onClick?: () => void;
  readonly icon?: IconName;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly colorClass?: string;
  readonly message?: string;
  readonly size?: SizeProp;
  readonly prefix?: "fal" | "fas" | "far";
  readonly imageIconProps?: ImageIconProps;
}
export interface ImageIconProps {
  readonly src: string;
  readonly alt?: string;
  readonly width?: string;
  readonly height?: string;
  readonly className?: string;
}

export function Icon({
  disabled,
  onClick,
  colorClass,
  className,
  message,
  icon,
  size = "lg",
  prefix = "fal",
  imageIconProps,
}: IconProps): React.ReactElement<IconProps> {
  const divClassName = `
    flex items-center
    ${disabled ? "text-gray-400" : onClick ? "cursor-pointer" : ""} 
    ${colorClass ?? ""} 
    ${className ?? ""}`;
  return (
    <div
      className={divClassName}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      title={message}
    >
      {imageIconProps ? (
        <ImageIcon {...imageIconProps} />
      ) : (
        <FontAwesomeIcon icon={[prefix, icon ?? "i"]} size={size} />
      )}
    </div>
  );
}

type SvgIconProps = {
  readonly fill?: string;
  readonly width?: string;
  readonly height?: string;
  readonly className?: string;
};

export const SvgAngleDown = ({ width, height, className }: SvgIconProps): JSX.Element => (
  <svg viewBox="0 0 330 330" fill="currentColor" className={className} width={width} height={height}>
    <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
  </svg>
);

export const SvgCross = ({ fill, width, height, className }: SvgIconProps): JSX.Element => (
  <svg viewBox="0 0 94.926 94.926" fill={fill} className={className} width={width} height={height}>
    <path d="M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0 c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096 c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476 c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62 s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z" />
  </svg>
);
export const SvgQuestionMark = ({ fill, width, height, className }: SvgIconProps): JSX.Element => (
  // <svg viewBox="0 0 94.926 94.926" fill={fill} className={className} width={width} height={height}>
  //   <path d="M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0 c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096 c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476 c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62 s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z" />
  // </svg>
  <svg viewBox="0 0 20 20" fill={fill} className={className} width={width} height={height}>
    <path
      fillRule="evenodd"
      d="M11 11H9v-.148c0-.876.306-1.499 1-1.852.385-.195 1-.568 1-1a1.001 1.001 0 00-2 0H7c0-1.654 1.346-3 3-3s3 1 3 3-2 2.165-2 3zm-2 4h2v-2H9v2zm1-13a8 8 0 100 16 8 8 0 000-16z"
      fill={fill}
    />
  </svg>
);

export function ImageIcon({ src, alt, width, height, className }: ImageIconProps): JSX.Element {
  return <img src={src} alt={alt} width={width} height={height} className={className} />;
}

export const SvgReload = ({ width, height, className }: SvgIconProps): JSX.Element => (
  <svg viewBox="0 0 512 512" className={className} width={width} height={height}>
    <path
      fillRule="evenodd"
      d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"
      fill="#ffffff"
    />
  </svg>
);
