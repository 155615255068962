import * as React from "react";
import { ImageTableRows } from "@ehb/shared/src/calculator";
import * as Markdown from "@ehb/shared/src/markdown";
import { withTw } from "./with-tw";
import { Icon } from "./icon";

export interface ComboboxProps {
  readonly description: string;
  readonly images: ReadonlyArray<ImageTableRows>;
  readonly createBlobUrl: (hash: string, fileType?: string) => string;
}

const InfoIconContainer = withTw("div", "flex ml-2.5 mr-2.5");

export function ImageCombobox(props: ComboboxProps): React.ReactElement<ComboboxProps> {
  const [showDescription, setshowDescription] = React.useState(false);

  // Logic to move the popup to the left a bit if it overflows the right window side
  const selectElement = React.useRef<HTMLDivElement>(null);
  const optionsElement = React.useRef<HTMLDivElement>(null);
  // React.useEffect(() => {
  //   if (optionsElement.current && selectElement.current) {
  //     const optionsRect = optionsElement.current.getBoundingClientRect();
  //     const selectRect = selectElement.current.getBoundingClientRect();
  //     // const rightPos = selectRect.x - optionsRect.width;
  //     // const xOverflow = Math.max(0, rightPos - window.innerWidth);

  //     const diff = optionsRect.width - selectRect.x;

  //     optionsElement.current.style.left = `${-optionsRect.width + diff}px`;
  //   }
  // }, [showDescription]);

  return (
    <div
      className={"relative flex justify-between"}
      onMouseEnter={() => setshowDescription(true)}
      onMouseLeave={() => setshowDescription(false)}
    >
      <div ref={selectElement}>
        <InfoIconContainer>
          <Icon icon="info-circle"></Icon>
        </InfoIconContainer>
      </div>
      <div
        ref={optionsElement}
        className={`bg-white border border-black border-opacity-5 absolute z-[1500] -right-60 top-5 w-80 flex flex-col text-sm font-bold text-center pt-8 pb-8 ${
          showDescription ? "visible" : "invisible"
        }`}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: Markdown.makeHtml(props.description, (url) => {
              const image = props.images.find((i) => i.name === url);
              if (!image) {
                return url;
              }
              return props.createBlobUrl(image.image, image.file_name);
            }),
          }}
        ></div>
      </div>
    </div>
  );
}
