/* eslint-disable no-labels */
/* eslint-disable no-restricted-syntax */
//import { PropertyValueSet } from "@promaster-sdk/property";
import { Utils, logWarn } from "..";
import { selectionToProperties } from "./properties";
import * as Q from "./query";
import * as Types from "./types";

type Product = {
  readonly productId: string;
  readonly productKey: string;
  readonly productVariants: Q.ProductVariantsTable;
};

export function searchSingle(
  data: Types.SearchData,
  query: Types.Query,
  productId?: string
): Types.SingleResult | undefined {
  const result = search(data, query, productId);
  if (result.matches.length === 0) {
    logWarn(`searchSingle: Nothing found for ${JSON.stringify(query)}`);
    return undefined;
  } else if (result.matches.length > 1) {
    logWarn(`searchSingle: Too many results for ${JSON.stringify(query)}, expected 1, got ${result.matches.length}`);
    return undefined;
  } else {
    return {
      query: result.query,
      match: result.matches[0],
      attributes: result.attributes,
    };
  }
}

export function search(data: Types.SearchData, query: Types.Query, productId?: string): Types.Result {
  const companyHasProducts = query.company && data.searchProducts.some((r) => r.company === query.company);
  const company = companyHasProducts ? query.company : "";
  const products = data.searchProducts
    .filter((p) => (p.company || "") === company)
    .filter((p) => (productId === undefined ? true : p.product === productId))
    .map(({ product: productId }): Product | undefined => {
      const product = data.products.find((p) => p.id === productId);
      if (!product) {
        return undefined;
      }
      return {
        productId: product.id || "",
        productKey: product.key || "",
        productVariants: product.modules.custom_tables.product_variants || [],
      };
    })
    .filter(Utils.isDefined);

  const valuesByColumn = new Map<Types.SearchColumn, Set<string>>();
  for (const col of Types.searchColumns) {
    valuesByColumn.set(col, new Set());
  }

  const matchingProducts = [];
  for (const p of products) {
    matchingProducts.push({
      ...p,
      productVariants: filterVariantsTable(p.productVariants, query, Types.searchColumns, valuesByColumn),
    });
  }

  type PmSelection = Omit<Types.Selection, "productKey" | "productId">;
  const matches: Array<Types.Match> = [];
  for (const product of matchingProducts) {
    for (const row of product.productVariants) {
      const pmSelection: PmSelection = Types.searchColumns.reduce(
        (prev, column) => ({ ...prev, [column]: row[column]?.toString() || "" }),
        {} as PmSelection
      );
      const selection: Types.Selection = {
        productKey: product.productKey,
        productId: product.productId,
        ...pmSelection,
      };
      const variant = selectionToProperties(data.searchColumnProperties, data.selectionToProperties, selection);
      if (!variant) {
        logWarn(`No variant for selection: ${JSON.stringify(selection)}`);
        continue;
      }

      matches.push({
        model: row.model || "-",
        selectionKey: createSelectionKey(selection),
        selection: selection,
        variant: variant,
        productVariantRow: row,
      });
    }
  }

  const attributes = Types.searchColumns.map(
    (col): Types.Attribute => ({
      name: col,
      values: [...(valuesByColumn.get(col) || new Set())].map((v) => ({ value: v })),
    })
  );

  return {
    query,
    matches,
    attributes,
  };
}

export function filterRows<T extends { readonly [key in Types.SearchColumn]: string | null | number }>(
  selection: Types.FilterSelection,
  rows: ReadonlyArray<T>
): ReadonlyArray<T> {
  const res: Array<T> = [];
  outer: for (const r of rows) {
    for (const c of Types.searchColumns) {
      if ((r[c]?.toString() || "") !== selection[c]) {
        continue outer;
      }
    }
    res.push(r);
  }
  return res;
}

function filterVariantsTable(
  table: Q.ProductVariantsTable,
  query: Types.Query,
  searchColumns: ReadonlyArray<Types.SearchColumn>,
  // eslint-disable-next-line functional/prefer-readonly-type
  valuesByColumn: Map<Types.SearchColumn, Set<string>>
): Q.ProductVariantsTable {
  const result = [];
  outer: for (const row of table) {
    for (const col of searchColumns) {
      const rv = (row[col] || "").toString();
      valuesByColumn.get(col)?.add(rv);
      const qv = query.filter[col];
      if (!qv) {
        continue;
      }
      if (qv !== rv) {
        continue outer;
      }
    }
    result.push(row);
  }
  return result;
}

function createSelectionKey(selection: Types.Selection): string {
  return Object.values(selection).join("$");
}
