/* eslint-disable yoda */
/* eslint-disable no-cond-assign */
import { Vector3, vec3Zero, vec3Equals } from "./vector3";
import { Vector2 } from "./vector2";
import { Face3, face3Equals } from "./face3";
import { Material } from "./material";
import { Bounds3, bounds3Create, bounds3CreateFromVertices } from "./bounds3";

export type Mesh = X3dMesh | ManualMesh | TextMesh | StaticTextMesh;

export function meshBoundingBox(mesh: Mesh): Bounds3 {
  switch (mesh.type) {
    case "X3dMesh":
      return bounds3CreateFromX3dMesh(mesh.data);
    case "ManualMesh":
      return bounds3CreateFromVertices(mesh.geometry.vertices);
    default:
      return bounds3Create(vec3Zero, vec3Zero);
  }
}

export type X3dMeshAlignment = "Center" | "Bottom" | "Front" | "Top";

export interface X3dMesh {
  readonly type: "X3dMesh";
  readonly alignment: X3dMeshAlignment;
  readonly data: X3dMeshData;
}

export function x3dMeshCreate(alignment: X3dMeshAlignment, data: X3dMeshData): X3dMesh {
  return {
    type: "X3dMesh",
    alignment: alignment,
    data: data,
  };
}

export interface X3dMeshData {
  readonly id: string;
  readonly data: string;
}

export function x3dMeshDataCreate(id: string, data: string): X3dMeshData {
  return {
    id: id,
    data: data,
  };
}

export interface ManualMesh {
  readonly type: "ManualMesh";
  readonly geometry: Geometry;
  readonly material: Material;
}

export function manualMeshCreate(geometry: Geometry, material: Material): ManualMesh {
  return {
    type: "ManualMesh",
    geometry: geometry,
    material: material,
  };
}

export interface TextMesh {
  readonly type: "TextMesh";
  readonly text: string;
  readonly textColor: string;
  readonly backgroundColor: string;
  readonly fontScale: number;
}

export function textMeshCreate(
  text: string,
  textColor: string,
  backgroundColor: string,
  fontScale: number = 1.0
): TextMesh {
  return {
    type: "TextMesh",
    text: text,
    textColor: textColor,
    backgroundColor: backgroundColor,
    fontScale: fontScale,
  };
}

export interface StaticTextMesh {
  readonly type: "StaticTextMesh";
  readonly text: string;
  readonly textColor: string;
  readonly backgroundColor: string;
  readonly fontScale: number;
}

export function staticTextMeshCreate(
  text: string,
  textColor: string,
  backgroundColor: string,
  fontScale: number = 1.0
): StaticTextMesh {
  return {
    type: "StaticTextMesh",
    text: text,
    textColor: textColor,
    backgroundColor: backgroundColor,
    fontScale: fontScale,
  };
}

export interface Geometry {
  readonly vertices: ReadonlyArray<Vector3>;
  readonly faces: ReadonlyArray<Face3>;
  readonly faceVertexUvs: ReadonlyArray<ReadonlyArray<ReadonlyArray<Vector2>>>;
}

export function geometryCreate(
  vertices: ReadonlyArray<Vector3>,
  faces: ReadonlyArray<Face3>,
  faceVertexUvs: ReadonlyArray<ReadonlyArray<ReadonlyArray<Vector2>>> = []
): Geometry {
  return {
    vertices: vertices,
    faces: faces,
    faceVertexUvs: faceVertexUvs,
  };
}

export function geometryEquals(a: Geometry, b: Geometry): boolean {
  if (a === b) {
    return true;
  }
  if (
    a.vertices.length !== b.vertices.length ||
    a.faces.length !== b.faces.length ||
    a.faceVertexUvs.length !== b.faceVertexUvs.length
  ) {
    return false;
  }
  for (let i = 0; i < a.vertices.length; ++i) {
    if (!vec3Equals(a.vertices[i], b.vertices[i])) {
      return false;
    }
  }
  for (let i = 0; i < a.faces.length; ++i) {
    if (!face3Equals(a.faces[i], b.faces[i])) {
      return false;
    }
  }
  return true;
}
// eslint-disable-next-line no-useless-escape
const vector3Regex = new RegExp(/([\d\.\+\-e]+)\s+([\d\.\+\-e]+)\s+([\d\.\+\-e]+)/g);
const shapeRegex = new RegExp(/<Shape>(.|\n)*?<\/Shape>/g);
const coordinateRegex = new RegExp(/<Coordinate(.|\n)*?<\/Coordinate>/g);
function bounds3CreateFromX3dMesh(mesh: X3dMeshData): Bounds3 {
  const shapes = mesh.data.match(shapeRegex);
  if (shapes === null) {
    return bounds3Create(vec3Zero, vec3Zero);
  }

  let parts: ReadonlyArray<string> | null;
  const points: Array<Vector3> = [];

  for (const shape of shapes) {
    const coordinates = shape.match(coordinateRegex);
    if (!coordinates) {
      continue;
    }
    while (null !== (parts = vector3Regex.exec(coordinates[0]))) {
      points.push({
        x: parseFloat(parts[1]),
        y: parseFloat(parts[2]),
        z: parseFloat(parts[3]),
      });
    }
  }

  return bounds3CreateFromVertices(points);
}
