import gql from "graphql-tag";
import { httpBlobQueryMultiple, HttpResponse, QueryGenerator } from "../query";
import * as GQLOps from "../generated/generated-operations";

export const imageModulesFragment = gql`
  fragment imageModules on Modules {
    images {
      image {
        image
        type
        name
        file_name
      }
    }
  }
`;

export type ProductImage = {
  readonly image: string | null;
  readonly type?: string | null;
  readonly name?: string | null;
  readonly file_name: string | null;
};

export type ImageData = {
  readonly image: ProductImage;
  readonly imageData: HttpResponse;
};

export type PropertyImageData = {
  readonly image: GQLOps.PropertyImageFragment["property_image"][number];
  readonly imageData: HttpResponse;
};

const tenantId = "44838c9e-8da4-44b0-9d94-bdf9dea8e02c";

export function* fetchImageData(
  imageData: GQLOps.ImageModulesFragment["images"]["image"] | undefined,
  imageServiceUrl: string,
  maxWidth: number | undefined,
  maxHeight: number | undefined,
  scale?: number,
  format?: "jpeg" | "png",
  background?: string
): QueryGenerator<ReadonlyArray<ImageData>> {
  if (imageData === undefined || imageData.length === 0) {
    return [];
  }
  const publishUrls = imageData.map((image) =>
    publishToServiceUrl(
      imageServiceUrl,
      tenantId,
      image.image || "",
      image.file_name || "",
      scale,
      maxWidth,
      maxHeight,
      format,
      background
    )
  ) || [""];

  const response = yield* httpBlobQueryMultiple(publishUrls);
  const imageResponseData = response.map((res, index) => {
    return { image: imageData[index], imageData: res };
  });
  return imageResponseData;
}

export function* fetchPropertyImageData(
  imageData: GQLOps.PropertyImageFragment["property_image"] | undefined,
  imageServiceUrl: string,
  maxWidth: number | undefined,
  maxHeight: number | undefined
): QueryGenerator<ReadonlyArray<PropertyImageData>> {
  if (imageData === undefined) {
    return [];
  }
  const publishUrls = imageData.map((image) =>
    publishToServiceUrl(
      imageServiceUrl,
      tenantId,
      image.image || "",
      image.file_name || "",
      undefined,
      maxWidth,
      maxHeight,
      undefined,
      undefined
    )
  ) || [""];

  const response = yield* httpBlobQueryMultiple(publishUrls);
  const imageResponseData = response.map((res, index) => {
    return { image: imageData[index], imageData: res };
  });
  return imageResponseData;
}

export function publishToServiceUrl(
  imageServiceUrl: string, // Specified in config
  tenantId: string,
  blobId: string,
  fileName: string,
  scale?: number,
  maxWidth?: number,
  maxHeight?: number,
  format?: "jpeg" | "png",
  background?: string
): string {
  const { fileNoExt, extension } = parseUrl(fileName);

  const params = [];
  if (scale) {
    params.push(`scale=${scale}`);
  }
  if (maxWidth) {
    params.push(`maxWidth=${maxWidth}`);
  }
  if (maxHeight) {
    params.push(`maxHeight=${maxHeight}`);
  }
  if (format) {
    params.push(`format=${format}`);
  }
  if (background) {
    params.push(`background=${background.startsWith("#") ? background.slice(1) : background}`);
  }
  const paramsPart = params.join("&");
  const resultingExtension = format || extension;
  return encodeURI(
    `${imageServiceUrl}?${paramsPart ? `${paramsPart}&` : ""}tenantId=${tenantId}&blob=${blobId}${
      fileNoExt ? `?file=${encodeURIComponent(fileNoExt)}${resultingExtension ? `.${resultingExtension}` : ""}` : ""
    }`
  );
}

interface ParsedUrl {
  readonly fileNoExt: string | undefined;
  readonly extension: string | undefined;
}

function parseUrl(fileName: string): ParsedUrl {
  const fileParts = fileName.split(".");
  const extension = fileParts ? fileParts[fileParts.length - 1] : undefined;
  const fileNoExt = extension && fileName ? fileName.slice(0, -(extension.length + 1)) : fileName;
  return { fileNoExt, extension };
}
