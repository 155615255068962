export const AirConstants = {
  cpa_SI: 1.005, // specific heat capacity of air at temp -50 - 40 deg C, kJ/(kg°K)

  atmosphericPressurePa: 101325,
};

export const VeabConstants = {
  //Minimum distance allowed between the first element per row from the edge. (Height measurements included).
  // Justerings_Varde_Avstand_Forsta_Element_Till_Kant
  Distance_First_Element_To_Edge: 45,

  //The distance used between each row of elements (Depth dimension counted).
  //Justerings_Varde_Avstand_Mellan_Varje_Element
  Distance_Between_Each_Element: 28,

  //Size of bending (Height measurement calculated).
  //Justerings_Varde_CC_Matt_Per_Bockning
  CC_Measurement_Per_Bend: 29,

  //A fixed value for what a C/C 30 bend takes from the length of the element.
  //Justerings_Varde_Materialatgang_Av_Element_CC_Bockning
  Material_Usage_Of_Element_CC_Bend: 42,

  //The value that defines the "C Dimension", i.e. the distance between the bend and the upper/lower edge of the width dimension in the channel.
  //Justerings_Varde_C_Matt_Element

  C_Measurement_Element: 35,

  //The value that the element nipple "takes" from the length of the element. Times 2.
  //Justerings_Varde_Element_Nippel

  Element_Nipple: 15,

  //Minimum Width value for what can be calculated via the element selection script automatically.
  //Justerings_Varde_Bredd_Min
  Width_Min: 430,

  //Maximum width value for what can be calculated via the element selection script automatically.
  //Justerings_Varde_Bredd_Max
  Width_Max: 2700,

  //The distance used between each row of elements (Depth dimension counted).
  //Justerings_Varde_Avstand_Mellan_Varje_ElementRad
  Distance_Between_Each_ElementRow: 30,

  //Minimum allowable depth measurement to start counting
  //Justerings_Varde_Minsta_Start_Djupmatt_UtanStyr
  Minimum_Start_Deep_Measurement_Without_Regulator: 100,

  //Minimum permitted Depth measurement that may be published with Regulator.
  //Justerings_Varde_Minsta_Publicerade_Djupmatt_MedStyr
  Minimum_Published_Deep_Measurement_With_Regulator: 300,

  //Minimum allowable depth measurement that may be published for Without Styre.
  //Justerings_Varde_Minsta_Publicerade_Djupmatt_UtanStyr
  Minimum_Published_Deep_Measurement_Without_Regulator: 200,

  //Size Small Cooling Profile takes on the Depth dimension
  //Justerings_Varde_Kylprofil_Liten
  CoolingProfile_Small: 50,

  //Size Large Cooling Profile takes on the Depth dimension.
  //Justerings_Varde_Kylprofil_Stor
  CoolingProfile_Big: 100,

  //Inlet before the first element/cooling profile may be placed on the Depth Gauge
  //Justerings_Varde_Inlopp
  Inlet: 50,

  //Outlet before the last element may be placed on the Depth Gauge.
  //Justerings_Varde_Utlopp
  Outlet: 50,

  //Distance between bridges when there are more than 1.
  //Justerings_Varde_Komponent_Brygga_Mellanrum
  Component_Bridge_SpaceBetween: 130,

  //Distance from the wall to the last bridge, opposite side from the customer's connection.
  //Justerings_Varde_Komponent_Brygga_Kundanslutning_Motsatt_Sida
  Component_Bridge_Customer_Connection_Opposite_side: 60,

  //The customer's connection side to the terminal.
  //Justerings_Varde_Komponent_Brygga_Kundanslutning
  Component_Bridge_Customer_Connection: 100,

  //Mått på bryggan.
  //Justerings_Varde_Komponent_Brygga_Bredd
  //Justerings_Varde_Komponent_Bryggas_Bredd
  Component_Bridge_Width: 58,

  //Fixed measure that is subtracted from the Height measure in, for example, VRA calculations.
  //Justerings_Varde_Typ_VRA_Hojd
  VRA_Type_Height: 40,

  //Fixed measurement that is subtracted from the Width measurement in the case of, for example, VRA calculations WITH Regulator.
  //Justerings_Varde_Typ_VRA_Bredd_MedStyr
  VRA_Type_Width_With_Regulator: 220,

  //Fixed measurement that is subtracted from the Width measurement for eg VRA calculations WITHOUT Steering.
  //Justerings_Varde_Typ_VRA_Bredd_UtanStyr
  VRA_Type_Width_Without_Regulator: 170,

  //Minimum distance between each component.
  //Justerings_Varde_Komponent_Avstand
  Component_Minimum_Spacing: 5,

  //Value for Contactors.
  //Justerings_Varde_Komponent_Kontaktor
  Component_Contactors: 45,

  //Value for Signal Converter.
  //Justerings_Varde_Komponent_Signalomvandlare
  Component_Signal_Converter: 48,

  //Value for Component Transformer.
  //Justerings_Varde_Komponent_Transformator
  Component_Transformer: 35,

  //Value for Component Step Switch.
  //Justerings_Varde_Komponent_Stegomkopplare
  Component_Step_Switch: 105,

  //Value for Automatic fuses.
  //Justerings_Varde_Komponent_Automatsakring
  Component_Automatic_Fuses: 18,

  //Value for M8 - M12 Ground Bolts.
  //Justerings_Varde_Komponent_M8M12Jord
  Component_M8M12_Ground_Bolts: 40,

  //Value for J16 and JG16 terminals.
  //Justerings_Varde_Komponent_JJG16
  Component_JJG16: 12,

  //Value for J35 and JG35 terminals.
  //Justerings_Varde_Komponent_JJG35
  Component_JJG35: 16,

  //Value for J70 terminals.
  //Justerings_Varde_Komponent_J70
  Component_J70: 21,

  //Value for VH95 terminals.
  //Justerings_Varde_Komponent_VH95
  Component_VH95: 32,

  //Value for VH150 terminals.
  //Justerings_Varde_Komponent_VH150
  Component_VH150: 42,

  //Value for VH240 terminals.
  //Justerings_Varde_Komponent_VH240
  Component_VH240: 42,

  //Place touch guards on either side of the bridge for a VH95 terminal block. (*2 in the calculation.)
  //Justerings_Varde_Komponent_VH95_Skydd
  Component_VH95_Protection: 55,

  //Place contact protection on either side of the bridge for a VH150 and VH240 terminal block. (*2 in the calculation.)
  //Justerings_Varde_Komponent_VH150240_Skydd
  Component_VH150240_Protection: 65,

  //Extra space for J/JG16 and J/JG35 terminals to add to calculations.
  //Justerings_Varde_Komponent_JJG_Plint
  Component_JJG_Terminal: 10,

  //Extra space for VH95, VH150 and VH240 terminals to add to the calculations.
  //Justerings_Varde_Komponent_VH95VH150VH240_Plint
  Component_VH95VH150VH240_Terminal: 20,

  //Place where 8 connection strip terminals + the grounding for them pick up.
  //Justerings_Varde_Komponent_Brygga_Sockerbit_Plint_Jordning
  Component_Bridge_Connection_Terminal_Grounding: 120,

  //Fixed value that is always added for those cases where you use multiple bridges for margin.
  //Justerings_Varde_Komponent_Brygga_Fast_Extra_Varde
  Component_Bridge_Constant_Extra_Value: 65,

  //Fixed measure that is subtracted from the Height measure in, for example, VTL calculations.

  VTL_Type_Height: 30,

  //Fixed measure that is subtracted from the Width measure in, for example, VTL calculations.

  VTL_Type_Width: 15,
};
