import React from "react";
import { GetFieldFormatFn, GetFieldFormatsFn } from "@ehb/shared";
import { PropertyValueSet } from "@promaster-sdk/property";
import { ActiveUser } from "@ehb/shared/src/user";
import { Amount } from "uom";
import { Tooltip, TooltipType } from "../tooltip";
import { FieldFormatSelector, SetFieldFormatFn } from "../field-format-selector";
import * as PropertyDef from "./property-selector-def";
import { AmountInputBox } from "../amount-input-box";

export function AmountSelector({
  property,
  pvs,
  disabled,
  onChange,
  getFieldFormat,
  getFieldFormats,
  setFieldFormat,
  errorMessage,
  activeUser,
}: {
  readonly pvs: PropertyValueSet.PropertyValueSet;
  readonly property: PropertyDef.AmountPropertyDef;
  readonly disabled: boolean;
  readonly onChange: (newAmount: Amount.Amount<unknown> | undefined) => void;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly setFieldFormat: SetFieldFormatFn;
  readonly errorMessage?: string;
  readonly activeUser: ActiveUser;
}): JSX.Element {
  const format = getFieldFormat(property.fieldName);
  return (
    <div className="flex items-center gap-2  text-ellipsis">
      <div className="basis-1/2">
        <Tooltip
          className="w-full"
          tooltipType={TooltipType.Warning}
          text={errorMessage}
          content={
            <AmountInputBox
              value={PropertyValueSet.getAmount(property.name, pvs)}
              unit={format.unit}
              decimalCount={format.decimalCount}
              notNumericMessage={""}
              isRequiredMessage={""}
              errorMessage={errorMessage || ""}
              readOnly={disabled}
              onValueChange={onChange}
              debounceTime={350}
            />
          }
        />
      </div>
      <div className="w-1/2">
        <FieldFormatSelector
          fieldName={property.fieldName}
          getFieldFormat={getFieldFormat}
          getFieldFormats={getFieldFormats}
          setFieldFormat={setFieldFormat}
          activeUser={activeUser}
        />
      </div>
    </div>
  );
}
