import { Search, Texts } from "@ehb/shared";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { DiscretePropertyDef, PropertySelectorDef, PropertyValueDef } from "./property-selector-def";

const texts = Texts.texts;

export type SearchProperties = {
  readonly propertyDefs: ReadonlyArray<PropertySelectorDef>;
  readonly filterToPvs: (filter: Search.Filter) => PropertyValueSet.PropertyValueSet;
  readonly pvsToFilter: (properties: PropertyValueSet.PropertyValueSet) => Search.Filter;
};

export function propertyDefsFromSearchResult(
  translate: Texts.TranslateFn,
  result: Pick<Search.Result, "attributes" | "query">
): SearchProperties {
  const defs: Array<PropertySelectorDef> = [];
  const pvsToAttribute = new Map<string, string>();
  const attributeToPvs = new Map<string, number>();
  for (let sortNo = 0; sortNo < result.attributes.length; sortNo++) {
    const attribute = result.attributes[sortNo];

    let valueSortNo = 0;
    const values: Array<PropertyValueDef> = [];

    values.push({
      sortNo: valueSortNo++,
      image: undefined,
      value: { type: "integer", value: -1 },
      validationFilter: PropertyFilter.Empty,
      text: translate(texts.search_all),
    });

    // Always add the selected value
    const queryValue = result.query.filter[attribute.name];
    if (queryValue && attribute.values.every((v) => v.value !== queryValue)) {
      const invalidValue = -2;
      values.push({
        sortNo: valueSortNo++,
        image: undefined,
        value: { type: "integer", value: invalidValue },
        validationFilter: PropertyFilter.fromStringOrEmpty("1=0", () => undefined),
        text: translate(texts.search_attribute_value(attribute.name, queryValue), queryValue),
      });
      pvsToAttribute.set(`${attribute.name}_${invalidValue}`, queryValue);
      attributeToPvs.set(`${attribute.name}_${queryValue}`, invalidValue);
    }

    // Available values to choose from
    for (let i = 0; i < attribute.values.length; i++) {
      const v = attribute.values[i];
      values.push({
        sortNo: valueSortNo++,
        image: undefined,
        value: { type: "integer", value: i },
        validationFilter: PropertyFilter.Empty,
        text: translate(texts.search_attribute_value(attribute.name, v.value), v.value),
      });
      pvsToAttribute.set(`${attribute.name}_${i}`, v.value);
      attributeToPvs.set(`${attribute.name}_${v.value}`, i);
    }

    const def: DiscretePropertyDef = {
      sortNo: sortNo,
      name: attribute.name,
      group: "selection",
      quantity: "",
      translation: translate(texts.search_attribute(attribute.name)),
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      defaultValue: "-1",
      propertyImage: undefined,
      readOnly: false,
      overridable: false,
      propInfo: {
        exists: false,
        textKey: "",
      },
      type: "Discrete",
      selectorType: "dropdown",
      items: values,
    };
    defs.push(def);
  }

  const pvsToFilter = (pvs: PropertyValueSet.PropertyValueSet): Search.Filter => {
    const filter: { [column: string]: string } = {};
    for (const a of result.attributes) {
      const pv = PropertyValueSet.getInteger(a.name, pvs);
      if (pv === undefined) {
        continue;
      }
      const fv = pvsToAttribute.get(`${a.name}_${pv}`);
      if (fv === undefined) {
        continue;
      }
      filter[a.name] = fv;
    }
    return filter;
  };

  const filterToPvs = (filter: Search.Filter): PropertyValueSet.PropertyValueSet => {
    let pvs = PropertyValueSet.Empty;
    for (const a of result.attributes) {
      const fv = filter[a.name];
      if (!fv) {
        pvs = PropertyValueSet.setInteger(a.name, -1, pvs);
        continue;
      }
      const pv = attributeToPvs.get(`${a.name}_${fv}`);
      if (pv === undefined) {
        pvs = PropertyValueSet.setInteger(a.name, -1, pvs);
        continue;
      }
      pvs = PropertyValueSet.setInteger(a.name, pv, pvs);
    }
    return pvs;
  };

  return {
    propertyDefs: defs,
    filterToPvs: filterToPvs,
    pvsToFilter: pvsToFilter,
  };
}
