import React, { useLayoutEffect } from "react";

export function useSelectPositioning(
  expanded: boolean,
  width: number,
  height: number,
  buttonRef: React.MutableRefObject<HTMLButtonElement>,
  ulRef: React.MutableRefObject<HTMLUListElement>
): void {
  useLayoutEffect(() => {
    if (expanded) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const ulRect = ulRef.current.getBoundingClientRect();
      ulRef.current.style.minWidth = `${buttonRect.width}px`;

      if (buttonRect.left + ulRect.width > width && buttonRect.right - ulRect.width > 0) {
        ulRef.current.style.left = `${width - ulRect.width - 2}px`;
      } else {
        ulRef.current.style.left = `${buttonRect.left}px`;
      }

      if (buttonRect.bottom + ulRect.height > height && buttonRect.top - ulRect.height > 0) {
        ulRef.current.style.top = `${buttonRect.top - ulRect.height}px`;
      } else {
        ulRef.current.style.top = `${buttonRect.bottom + 1}px`;
      }
    }
  }, [expanded, width, height]);
}
