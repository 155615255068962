import * as React from "react";
import { ParagraphStyle, TableCellStyle, TableStyle, TextStyle } from "abstract-document/lib/abstract-document";
import { AbstractDocJsx as ADX } from "abstract-document";
import { createTextStyle } from "./standard-style";

const { Table, TableRow, TableCell, Paragraph, TextRun, Group } = ADX;

export function h1(text: string): JSX.Element {
  return (
    <Paragraph styleName={"H1"}>
      <TextRun text={text} />
    </Paragraph>
  );
}

export function h2(text: string): JSX.Element {
  return (
    <Paragraph styleName={"H2"}>
      <TextRun text={text} />
    </Paragraph>
  );
}

export function textRun(
  text: string,
  options?: {
    readonly bold?: boolean;
    readonly italic?: boolean;
    readonly fontSize?: number;
    readonly color?: string;
    readonly underline?: boolean;
    readonly mediumBold?: boolean;
  }
): JSX.Element {
  const { bold, italic, fontSize, color, underline, mediumBold } = options || {};
  return (
    <Paragraph>
      <TextRun style={createTextStyle({ bold: bold, italic, fontSize, color, underline, mediumBold })} text={text} />
    </Paragraph>
  );
}

export function boldText(text: string): JSX.Element {
  return (
    <Paragraph
      style={ParagraphStyle.create({
        textStyle: TextStyle.create({ bold: true }),
        margins: { top: 10, bottom: 0, right: 0, left: 0 },
      })}
    >
      <TextRun text={text} />
    </Paragraph>
  );
}

type CellValue = string | number | undefined;
export type Cell = {
  readonly value: CellValue;
};

type TextOptions = {
  readonly bold?: boolean;
  readonly italic?: boolean;
  readonly colSpan?: number;
};

export const textCell = (text: CellValue, options?: TextOptions): JSX.Element => (
  <TableCell columnSpan={options?.colSpan}>
    <Paragraph>
      <TextRun
        text={text?.toString() || ""}
        style={TextStyle.create({ bold: options?.bold, italic: options?.italic })}
      />
    </Paragraph>
  </TableCell>
);

export const table = (header: ReadonlyArray<Cell>, rows: ReadonlyArray<ReadonlyArray<Cell>>): JSX.Element => {
  const columns = Math.max(header.length, Math.max(...rows.map((r) => r.length))) || 0;
  const columnWidths = Array(columns).fill(Infinity);
  const tableStyle = TableStyle.create({
    margins: { bottom: 10, left: 0, top: 5, right: 0 },
    cellStyle: TableCellStyle.create({
      borderColor: "#D1D1D1",
      borders: { bottom: 1, left: 1, top: 1, right: 1 },
      padding: { bottom: -1, left: 6, top: 7, right: 1 },
    }),
  });
  return (
    <Group keepTogether={true}>
      <Table columnWidths={columnWidths} style={tableStyle}>
        {header.length > 0 ? (
          <TableRow>
            {header.map((h, index) =>
              textCell(h.value, { bold: true, colSpan: index === header.length - 1 ? columns - index : 1 })
            )}
          </TableRow>
        ) : (
          []
        )}
        {rows.length > 0
          ? rows.map((row, index) => (
              <TableRow key={index}>
                {row.map((cell, index) =>
                  textCell(cell.value, { colSpan: index === row.length - 1 ? columns - index : 1 })
                )}
              </TableRow>
            ))
          : []}
      </Table>
    </Group>
  );
};

export const row = (...values: readonly CellValue[]): readonly Cell[] => {
  return values?.map((v) => ({ value: v }));
};
