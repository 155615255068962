export interface Vector3 {
  readonly x: number;
  readonly y: number;
  readonly z: number;
}

export const vec3Zero: Vector3 = {
  x: 0,
  y: 0,
  z: 0,
};

export const vec3Down: Vector3 = {
  x: 0,
  y: 0,
  z: -1,
};

export const vec3Up: Vector3 = {
  x: 0,
  y: 0,
  z: 1,
};

export function vec3Create(x: number, y: number, z: number): Vector3 {
  return {
    x: x,
    y: y,
    z: z,
  };
}

export function vec3Equals(a: Vector3, b: Vector3): boolean {
  return a.x === b.x && a.y === b.y && a.z === b.z;
}

export function vec3ToString(v: Vector3): string {
  return JSON.stringify(v);
}

export function vec3Add(a: Vector3, b: Vector3): Vector3 {
  return {
    x: a.x + b.x,
    y: a.y + b.y,
    z: a.z + b.z,
  };
}

export function vec3Sub(a: Vector3, b: Vector3): Vector3 {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
    z: a.z - b.z,
  };
}

export function vec3Cross(a: Vector3, b: Vector3): Vector3 {
  return {
    x: a.y * b.z - a.z * b.y,
    y: a.z * b.x - a.x * b.z,
    z: a.x * b.y - a.y * b.x,
  };
}

export function vec3Dot(a: Vector3, b: Vector3): number {
  return a.x * b.x + a.y * b.y + a.z * b.z;
}

export function vec3Scale(s: number, v: Vector3): Vector3 {
  return {
    x: v.x * s,
    y: v.y * s,
    z: v.z * s,
  };
}

export function vec3Flip(v: Vector3): Vector3 {
  return {
    x: -v.x,
    y: -v.y,
    z: -v.z,
  };
}

export function vec3Length(v: Vector3): number {
  return Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
}

export function vec3LengthSq(v: Vector3): number {
  return v.x * v.x + v.y * v.y + v.z * v.z;
}

export function vec3Distance(v1: Vector3, v2: Vector3): number {
  return Math.sqrt((v2.x - v1.x) ** 2 + (v2.y - v1.y) ** 2 + (v2.z - v1.z) ** 2);
}

export function vec3Normalize(v: Vector3): Vector3 {
  const l = vec3Length(v);
  if (l === 0.0) {
    return vec3Create(0, 0, 0);
  }
  const div = 1.0 / l;
  return {
    x: v.x * div,
    y: v.y * div,
    z: v.z * div,
  };
}

export function vec3RotateAroundAxis(angle: number, axis: Vector3, vector: Vector3): Vector3 {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const t = (axis.x * vector.x + axis.y * vector.y + axis.z * vector.z) * (1 - cos);
  return {
    x: axis.x * t + vector.x * cos + (-axis.z * vector.y + axis.y * vector.z) * sin,
    y: axis.y * t + vector.y * cos + (axis.z * vector.x - axis.x * vector.z) * sin,
    z: axis.z * t + vector.z * cos + (-axis.y * vector.x + axis.x * vector.y) * sin,
  };
}

export function vec3Interpolate(a: Vector3, b: Vector3, factor: number): Vector3 {
  return vec3Add(vec3Scale(factor, a), vec3Scale(1 - factor, b));
}

export function vec3Project(normalizedTarget: Vector3, vector: Vector3): Vector3 {
  return vec3Scale(vec3Dot(normalizedTarget, vector), normalizedTarget);
}

export function vec3ProjectPlane(planeNormal: Vector3, vector: Vector3): Vector3 {
  return vec3Sub(vector, vec3Project(planeNormal, vector));
}

export function vec3ToArray(v: Vector3): readonly [number, number, number] {
  return [v.x, v.y, v.z];
}
