import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { TranslateFn, texts } from "../../lang-texts";
import { textRun } from "./elements";

const { Group, Table, TableRow, TableCell, Image, Paragraph, render } = ADX;

export function createFooter(
  _logo: AD.ImageResource.ImageResource | undefined,
  translate: TranslateFn
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  const cellStyle = AD.TableCellStyle.create({
    borders: AD.LayoutFoundation.create({
      top: 1,
    }),
    padding: AD.LayoutFoundation.create({
      top: 10,
    }),
    verticalAlignment: "Top",
  });

  const normal = { fontSize: 7 };
  const bold = { bold: true, fontSize: 7 };

  return [
    render(
      <Group>
        <Group>
          <Table
            columnWidths={[Infinity, Infinity, Infinity, Infinity]}
            style={AD.TableStyle.create({ alignment: "Center", cellStyle: cellStyle })}
          >
            <TableRow>
              <TableCell>
                {textRun(translate(texts.report_footer_company), bold)}
                {textRun(translate(texts.report_footer_postal_code), normal)}
                {textRun(translate(texts.report_footer_country), normal)}
              </TableCell>
              <TableCell>
                {textRun(translate(texts.report_footer_visitor_address_text), normal)}
                {textRun(translate(texts.report_footer_visitor_address), bold)}
                {textRun(translate(texts.report_footer_delivery_address_text), normal)}
                {textRun(translate(texts.report_footer_delivery_address), bold)}
              </TableCell>
              <TableCell>
                {textRun(translate(texts.report_footer_phone_text), normal)}
                {textRun(translate(texts.report_footer_phone), normal)}
              </TableCell>
              <TableCell>
                {textRun(translate(texts.report_footer_email), bold)}
                {textRun(translate(texts.report_footer_site), bold)}
              </TableCell>
            </TableRow>
          </Table>
        </Group>
      </Group>
    ),
  ];
}

export function createFooterFirst(
  logo: AD.ImageResource.ImageResource | undefined,
  _translate: TranslateFn
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  return [
    render(
      <Group>
        <Group>
          <Paragraph
            style={AD.ParagraphStyle.create({
              alignment: "End",
            })}
          >
            {logo && <Image imageResource={logo} width={190} height={50} />}
          </Paragraph>
        </Group>
      </Group>
    ),
  ];
}
