import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Routes, SharedState } from "@ehb/client-infra";
import { Texts } from "@ehb/shared";
import { getProjectName, itemIdQueryParam, projectIdQueryParam } from "@ehb/shared/src/project";
import { MainLocation } from "@ehb/client-infra/src/routes";
import { ActiveUser } from "@ehb/shared/src/user";
import { CenterMessage, Icon, Spinner, SpinnerIcon, withTw } from "../../elements";
import { State, Action, Column, SortSetting, ListEntry } from "./state";
import * as GQLOps from "../../generated/generated-operations";
import * as ProjectState from "../../project-state";
import { Button } from "../../elements/button";
import { TextInput } from "../../elements/text-input";
import { Group } from "../../elements/group";

const PageColumn = withTw("div", "text-xs flex flex-col gap-8 basis-1/2");
const LabelTh = withTw("th", "group h-[25px] cursor-pointer hover:text-red-600 select-none");
const StickyHead = withTw("thead", "bg-white z-50 sticky top-0");
const TableHeadRow = withTw("tr", "[&>*]:py-2");
const TableTr = withTw("tr", "hover:bg-[#fff8f8] h-[50px] border-t border-[#e5e7eb]");
const TableTd = withTw("td", "");
const ModelLink = withTw("a", "font-bold cursor-pointer");

export function View({
  state,
  sharedState,
  dispatch,
}: {
  readonly state: State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const { activeUser, translate } = sharedState;
  const { projectList, sortSetting, projectState } = state;

  const dispatchProject = Dispatch.map(Action.DispatchProject, dispatch);

  return (
    <div className="flex flex-col items-stretch max-w-[1920px] mx-auto">
      <h1 className="mt-2 mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {translate(Texts.texts.projects)}
      </h1>
      <div className="flex justify-center items-stretch">
        <PageColumn>
          <Group>
            <div className="flex flex-row gap-4">
              <Button
                label={translate(Texts.texts.create_project)}
                onClick={() =>
                  dispatchProject(
                    ProjectState.Action.CreateProject(translate(Texts.texts.project_default_name), (newProjectId) =>
                      Routes.buildMainUrl(MainLocation.Projects({ [projectIdQueryParam]: newProjectId }))
                    )
                  )
                }
              />
            </div>
          </Group>
          {projectList ? (
            <ProjectTable
              translate={translate}
              dispatch={dispatch}
              sortSetting={sortSetting}
              projectList={projectList}
              openProjectId={sharedState.openProjectId}
            />
          ) : (
            <Spinner />
          )}
        </PageColumn>
        <div className="border-l border-[#e5e7eb] mx-8" />
        <PageColumn>
          <ProjectData dispatch={dispatch} translate={translate} projectState={projectState} />
          <ProjectItems activeUser={activeUser} dispatch={dispatch} translate={translate} projectState={projectState} />
        </PageColumn>
      </div>
    </div>
  );
}

function ProjectData({
  translate,
  dispatch,
  projectState,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly translate: Texts.TranslateFn;
  readonly projectState: ProjectState.State;
}): JSX.Element | null {
  const dispatchProject = Dispatch.map(Action.DispatchProject, dispatch);

  const [infoClosed, setInfoClosed] = React.useState(false);
  if (projectState.type !== "open" && projectState.type !== "busy") {
    return null;
  }

  return (
    <Group
      header={getProjectName(translate, projectState.project.project)}
      closed={infoClosed}
      onToggleClosed={() => setInfoClosed(!infoClosed)}
    >
      <div className="flex flex-col gap-2">
        <InfoRow
          label={translate(Texts.texts.project_name)}
          value={
            <TextInput
              value={projectState.project.project.name}
              onChange={(v) => dispatchProject(ProjectState.Action.UpdateProject({ name: v || "" }))}
              disabled={projectState.type === "busy"}
              debounceTime={500}
            />
          }
        />
        <InfoRow label={translate(Texts.texts.project_owner)} value={projectState.project.project.owner} />
        <InfoRow
          label={translate(Texts.texts.project_created)}
          value={formatDateTime(projectState.project.project.createdDate)}
        />
        <InfoRow
          label={translate(Texts.texts.project_last_modified)}
          value={formatDateTime(projectState.project.project.modifiedDate)}
        />
        <div>
          <Button
            disabled={projectState.type !== "open"}
            label={translate(Texts.texts.delete_project)}
            onClick={() =>
              dispatchProject(ProjectState.Action.DeleteProject(Routes.buildMainUrl(MainLocation.Projects({}))))
            }
          />
        </div>
      </div>
    </Group>
  );
}

function InfoRow({
  label,
  value,
}: {
  readonly label: React.ReactNode | string;
  readonly value: React.ReactNode | string;
}): JSX.Element {
  return (
    <div className="flex flex-row justify-between items-center h-8">
      <div className="basis-1/3">{typeof label === "string" ? <div>{label}</div> : label}</div>
      <div className="basis-2/3">{typeof value === "string" ? <div>{value}</div> : value}</div>
    </div>
  );
}

function ProjectItems({
  activeUser,
  translate,
  dispatch,
  projectState,
}: {
  readonly activeUser: ActiveUser;
  readonly dispatch: Dispatch<Action>;
  readonly translate: Texts.TranslateFn;
  readonly projectState: ProjectState.State;
}): JSX.Element {
  const dispatchProject = Dispatch.map(Action.DispatchProject, dispatch);

  if (projectState.type === "loading") {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <SpinnerIcon small={true} />
      </div>
    );
  } else if (projectState.type === "closed") {
    return <CenterMessage message={translate(Texts.texts.msg_no_project_open)} />;
  } else if (projectState.type === "error") {
    return (
      <CenterMessage
        message={`${translate(Texts.texts.msg_failed_to_load_project)} (${
          projectState.id.type === "project"
            ? `project: ${projectState.id.projectId}`
            : `item: ${projectState.id.itemId}`
        })`}
      />
    );
  } else if (projectState.type === "creating") {
    return <Spinner />;
  } else if (projectState.project.items.length === 0) {
    return <CenterMessage message={translate(Texts.texts.msg_project_is_empty)} />;
  }

  const itemData = React.useMemo(
    () => ProjectState.getAllItemData(translate, activeUser, projectState),
    [projectState.project.project.modifiedDate, projectState.data.type]
  );
  if (!itemData) {
    return <SpinnerIcon small={true} />;
  }

  return (
    <table className="w-full">
      <StickyHead>
        <TableHeadRow>
          <ItemColumn translate={translate} column={"type"} />
          <ItemColumn translate={translate} column={"name"} />
          <LabelTh />
        </TableHeadRow>
      </StickyHead>
      <tbody>
        {projectState.project.items.map((i) => (
          <TableTr key={i.id}>
            <TableTd className="p-1 text-left">
              {translate(Texts.texts.item_type(itemData.get(i.id)?.type || "unknown"))}
            </TableTd>
            <TableTd className="p-1 text-left" style={{ minWidth: "200px" }}>
              <ItemLink itemData={itemData} item={i} />
            </TableTd>
            <TableTd>
              <div className="inline-block">
                <Icon
                  icon={"trash-alt"}
                  disabled={false}
                  onClick={() => dispatchProject(ProjectState.Action.DeleteItem(i.id, undefined))}
                  message={translate(Texts.texts.project_remove_item)}
                  className="mr-2 text-red-600 transition duration-75 origin-center hover:scale-110"
                />
              </div>
            </TableTd>
          </TableTr>
        ))}
      </tbody>
    </table>
  );
}

function ProjectTable({
  dispatch,
  translate,
  sortSetting,
  projectList,
  openProjectId,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly translate: Texts.TranslateFn;
  readonly sortSetting: SortSetting | undefined;
  readonly projectList: ReadonlyArray<ListEntry>;
  readonly openProjectId: string | undefined;
}): JSX.Element {
  if (projectList.length === 0) {
    return <CenterMessage message={translate(Texts.texts.msg_no_projects)} />;
  }
  return (
    <table className="w-full">
      <StickyHead>
        <TableHeadRow>
          <ListColumn translate={translate} dispatch={dispatch} sortSetting={sortSetting} column={"name"} />
          <ListColumn translate={translate} dispatch={dispatch} sortSetting={sortSetting} column={"owner"} />
          <ListColumn translate={translate} dispatch={dispatch} sortSetting={sortSetting} column={"createdDate"} />
          <ListColumn translate={translate} dispatch={dispatch} sortSetting={sortSetting} column={"modifiedDate"} />
        </TableHeadRow>
      </StickyHead>
      <tbody>
        {projectList.map(({ project: p }) => (
          <TableTr
            key={p.id}
            className={openProjectId === p.id ? "font-bold" : ""}
            onClick={() => dispatch(Action.SetOpenProject(p.id))}
          >
            <TableTd className="p-1 text-left" style={{ minWidth: "200px" }}>
              <ModelLink
                href={Routes.buildUrl(
                  Routes.RootLocation.MainLocation(Routes.MainLocation.Projects({ [projectIdQueryParam]: p.id }))
                )}
              >
                {p.name || "Untitled"}
              </ModelLink>
            </TableTd>
            <TableTd className="p-1 text-left">{p.owner}</TableTd>
            <TableTd className="p-1 text-left tabular-nums">{formatDate(p.createdDate)}</TableTd>
            <TableTd className="p-1 text-left tabular-nums">{formatDate(p.modifiedDate)}</TableTd>
          </TableTr>
        ))}
      </tbody>
    </table>
  );
}

function ItemLink({
  itemData,
  item,
}: {
  readonly itemData: ReadonlyMap<string, ProjectState.ItemData>;
  readonly item: GQLOps.Item;
}): JSX.Element {
  return (
    <ModelLink
      href={
        item.type === "electric"
          ? Routes.buildMainUrl(MainLocation.ProductSelect({ [itemIdQueryParam]: item.id }))
          : Routes.buildMainUrl(MainLocation.ProductCalculate({ [itemIdQueryParam]: item.id }, item.product))
      }
    >
      {itemData.get(item.id)?.name || item.product}
    </ModelLink>
  );
}
function ListColumn({
  translate,
  dispatch,
  sortSetting,
  column,
}: {
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<Action>;
  readonly sortSetting: SortSetting | undefined;
  readonly column: Column;
}): JSX.Element {
  const setting = sortSetting?.column === column ? sortSetting : undefined;
  return (
    <LabelTh
      key={column}
      onClick={() => {
        dispatch(Action.SetSortOrder(column, setting?.order === "desc" ? "asc" : "desc"));
      }}
    >
      <div className={`p-1 space-x-1 text-left flex flex-row`}>
        <div>{translate(Texts.texts.project_column(column))}</div>
        {/*   <SortIcon columnOrder={setting?.order} column={column} /> */}
      </div>
    </LabelTh>
  );
}

function ItemColumn({
  translate,
  column,
}: {
  readonly translate: Texts.TranslateFn;
  readonly column: string;
}): JSX.Element {
  return (
    <LabelTh>
      <div className={`p-1 space-x-1 text-left flex flex-row`}>
        <div>{translate(Texts.texts.project_item_col(column))}</div>
      </div>
    </LabelTh>
  );
}

// function SortIcon({
//   columnOrder,
//   column,
// }: {
//   readonly columnOrder: Order | undefined;
//   readonly column: Column;
// }): JSX.Element {
//   const sortOrder = columnOrder || initialSortOrders[column];
//   const className = ` w-4 ${
//     columnOrder === undefined ? "text-gray-500 invisible group-hover:visible" : "text-gray-500"
//   }`;
//   if (sortOrder === "asc") {
//     return <Icon className={className} prefix="fas" icon="arrow-down-short-wide" />;
//   } else if (sortOrder === "desc") {
//     return <Icon className={className} prefix="fas" icon="arrow-up-wide-short" />;
//   } else {
//     exhaustiveCheck(sortOrder, true);
//   }
// }

export function formatDate(dateValue: string): string {
  const date = new Date(dateValue);
  // sv-SE to format the date as yyyy-mm-dd
  const isoDateValue = date.toLocaleDateString("sv-SE");
  if (Number.isFinite(date.getTime())) {
    return isoDateValue;
  } else {
    return "";
  }
}

export function formatDateTime(dateValue: string): string {
  const date = new Date(dateValue);
  const isoDateValue = date.toLocaleTimeString("sv-SE");
  if (Number.isFinite(date.getTime())) {
    return `${formatDate(dateValue)} ${isoDateValue}`;
  } else {
    return "";
  }
}
