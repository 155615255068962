import { PropertyFilter as PF, PropertyValue as PV } from "@promaster-sdk/property";
import * as Types from "./types";
import * as Gql from "../generated/generated-operations";
import { EhbUnitsLookup } from "..";
import { AnyQuantity } from "../uom";

export function mapProperties(
  propertyQuery: Gql.PropertiesModulesFragment["properties"]["property"]
): Types.ProductProperties {
  return propertyQuery.map((propertyRow) => {
    const propertyName = propertyRow.name ?? "";
    const property: Types.ProductProperty = {
      sortNo: propertyRow.sort_no ?? 0,
      name: propertyName,
      group: propertyRow.group ?? "",
      quantity: (propertyRow.quantity || "") as unknown as AnyQuantity,
      validationFilter: PF.fromStringOrEmpty(propertyRow.validation_filter ?? "", EhbUnitsLookup),
      visibilityFilter: PF.fromStringOrEmpty(propertyRow.visibility_filter ?? "", EhbUnitsLookup),
      defValues: propertyRow.defaults
        .map(
          (df): Types.ProductPropertyDefValue => ({
            value: PV.fromString(df.value ?? "", EhbUnitsLookup)!,
            propertyFilter: PF.fromStringOrEmpty(df.property_filter ?? "", EhbUnitsLookup),
          })
        )
        .filter((df) => !!df.value),
      values: propertyRow.values.map((v): Types.ProductPropertyValue => {
        const value = parseInt(v.value || "", 10);
        return {
          sortNo: v.sort_no ?? 0,
          value: value,
          image: v.image ?? "",
          propertyFilter: PF.fromString(v.property_filter ?? "", EhbUnitsLookup)!,
        };
      }),
    };
    return property;
  });
}
