import React from "react";
import { SharedState, createBlobUrlCreator } from "@ehb/client-infra";
import { isInternalUser } from "@ehb/shared/src/user";
import { texts } from "@ehb/shared/src/lang-texts";
import { Icon } from "../../elements/icon";
import elsymbol from "../../assets/elsymbol.jpg";
import dx from "../../assets/dx.png";
import coolwater from "../../assets/coolwater.jpg";
import heatwater from "../../assets/heatwater.jpg";
import * as State from "./state";
import { withTw } from "../../elements";

const Container = withTw("div", "bg-white py-2 sm:py-4");
const MaxWidth = withTw("div", "mx-auto max-w-7xl px-6 lg:px-8");
const CenterText = withTw("div", "mx-auto max-w-2xl lg:text-center");

export function View({
  state,
  sharedState,
}: {
  readonly state: State.State | undefined;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const { translate } = sharedState;
  const images = state?.metaProduct?.product?.modules.images.image;
  const electricalHeater = images?.find((i) => i.name === "electrical_heater");
  const waterDX = images?.find((i) => i.name === "water_dx");
  const special = images?.find((i) => i.name === "special");

  const electricalHeaterImage =
    electricalHeater && electricalHeater.image && electricalHeater.file_name
      ? createBlobUrlCreator(sharedState.activeUser.accessToken)(electricalHeater.image, electricalHeater.file_name)
      : undefined;
  const waterDXImage =
    waterDX && waterDX.image && waterDX.file_name
      ? createBlobUrlCreator(sharedState.activeUser.accessToken)(waterDX.image, waterDX.file_name)
      : undefined;
  const specialImage =
    special && special.image && special.file_name
      ? createBlobUrlCreator(sharedState.activeUser.accessToken)(special.image, special.file_name)
      : undefined;

  return (
    <Container>
      <MaxWidth>
        <CenterText>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {translate(texts.start_page_welcome)}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">{translate(texts.start_page_welcome_subheading)}</p>
          <p className="mt-1.5 text-lg leading-8 text-gray-600">{translate(texts.start_page_select_category)}</p>
        </CenterText>
        <div className="flex justify-center mt-10">
          <div className="flex justify-center  gap-x-8 ">
            <div className="grid flex-col rounded-md border border-red-200 pt-2  h-[250px]">
              <div className="relative border border-red-200 rounded-md mx-2">
                <Icon
                  className="absolute min-w-[24px] top-[100%] left-[50%] translate-y-[-50%]  translate-x-[-50%]"
                  imageIconProps={{
                    src: elsymbol,
                    alt: "electrical heater",
                    width: "32px",
                    height: "32px",
                  }}
                />
                <a href="/product-select" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                  <img src={electricalHeaterImage} className="h-[120px] bg-cover w-full bg-center rounded-md" />
                </a>
              </div>
              <p className="text-lg leading-8 text-gray-600 text-center w-full place-self-end font-bold">
                {translate(texts.start_page_heaters)}
              </p>
              <div className="flex justify-center bg-gray-100 place-self-end w-full h-fit rounded-br-md rounded-bl-md border-t border-solid">
                <p className="text-lg leading-8 text-gray-600 font-bold">{translate(texts.start_page_electrical)}</p>
              </div>
            </div>

            <div className="grid flex-col rounded-md border border-red-200 pt-2 h-[250px]">
              <div className="relative border border-red-200 rounded-md mx-2">
                <div className="absolute flex gap-2 top-[100%] left-[50%] translate-y-[-50%]  translate-x-[-50%]">
                  <Icon
                    className=""
                    imageIconProps={{
                      src: heatwater,
                      alt: "fluid heater",
                      width: "32px",
                      height: "32px",
                    }}
                  />
                  <Icon
                    className=""
                    imageIconProps={{
                      src: coolwater,
                      alt: "fluid cooler",
                      width: "32px",
                      height: "32px",
                    }}
                  />
                  <Icon
                    className=""
                    imageIconProps={{
                      src: dx,
                      alt: "direct expansion",
                      width: "32px",
                      height: "32px",
                    }}
                  />
                </div>
                <a href="/product-search" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                  <img src={waterDXImage} className="h-[120px] bg-cover w-full bg-center rounded-md" />
                </a>
              </div>
              <p className="text-lg leading-8 text-gray-600 text-center w-full place-self-end font-bold">
                {translate(texts.start_page_heaters_coolers)}
              </p>
              <div className="flex justify-center bg-gray-100 place-self-end w-full h-fit rounded-br-md rounded-bl-md border-t border-solid">
                <p className="text-lg leading-8 text-gray-600 font-bold">{translate(texts.start_page_water_dx)}</p>
              </div>
            </div>

            {isInternalUser(sharedState.activeUser.claims) && (
              <div className="grid flex-col rounded-md border border-red-200 pt-2 h-[250px]">
                <div className="relative border border-red-200 rounded-md mx-2">
                  <div className="absolute flex gap-2 top-[100%] left-[50%] translate-y-[-50%]  translate-x-[-50%]">
                    <Icon
                      className=""
                      imageIconProps={{
                        src: heatwater,
                        alt: "fluid heater",
                        width: "32px",
                        height: "32px",
                      }}
                    />
                    <Icon
                      className=""
                      imageIconProps={{
                        src: coolwater,
                        alt: "fluid cooler",
                        width: "32px",
                        height: "32px",
                      }}
                    />
                    <Icon
                      className=""
                      imageIconProps={{
                        src: dx,
                        alt: "direct expansion",
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </div>
                  <a
                    href="/special-calculation"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    <img src={specialImage} className="h-[120px] w-[180px] bg-cover bg-center rounded-md" />

                    {/* <div className="flex text-lg leading-8 text-gray-600 font-bold h-[120px] w-[166px] bg-cover bg-center rounded-md items-center justify-center">
                      {translate(texts.start_page_special)}
                    </div> */}
                  </a>
                </div>
                <p className="text-lg leading-8 text-gray-600 text-center w-full place-self-end font-bold">
                  {translate(texts.start_page_heaters_coolers)}
                </p>
                <div className="flex justify-center bg-gray-100 place-self-end w-full h-fit rounded-br-md rounded-bl-md border-t border-solid">
                  <p className="text-lg leading-8 text-gray-600 font-bold">{translate(texts.start_page_water_dx)}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </MaxWidth>
    </Container>
  );
}
