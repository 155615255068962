import { DocumentNode } from "graphql";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryGenerator<TResponse> = Generator<Query, TResponse, any>;
export type Query =
  | GraphQLProductQuery<unknown, unknown>
  | GraphQLQuery<unknown, unknown>
  | HttpBlobQuery
  | HttpBlobQueryMultiple
  | PromiseQuery;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface GraphQLProductQuery<_TData, TVariables> {
  readonly type: "GraphQLProductQuery";
  readonly query: DocumentNode;
  readonly variables: TVariables;
}

export function* graphQLProductQuery<TData, TVariables>(
  query: DocumentNode,
  variables: TVariables
): QueryGenerator<TData> {
  return yield { type: "GraphQLProductQuery", query, variables };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface GraphQLQuery<_TData, TVariables> {
  readonly type: "GraphQLQuery";
  readonly query: DocumentNode;
  readonly variables: TVariables;
}

export function* graphQLQuery<TData, TVariables>(query: DocumentNode, variables: TVariables): QueryGenerator<TData> {
  return yield { type: "GraphQLQuery", query, variables };
}

export type HttpResponse = { readonly response: Uint8Array; readonly headers: Headers };

export interface HttpBlobQuery {
  readonly type: "HttpBlobQuery";
  readonly url: string;
}

export function* httpBlobQuery(url: string): QueryGenerator<HttpResponse> {
  return yield { type: "HttpBlobQuery", url };
}

export interface HttpBlobQueryMultiple {
  readonly type: "HttpBlobQueryMultiple";
  readonly url: ReadonlyArray<string>;
}

export function* httpBlobQueryMultiple(url: ReadonlyArray<string>): QueryGenerator<ReadonlyArray<HttpResponse>> {
  return yield { type: "HttpBlobQueryMultiple", url };
}

export interface PromiseQuery {
  readonly type: "PromiseQuery";
  readonly promise: Promise<unknown>;
}

export function* promiseQuery<TData>(promise: Promise<TData>): QueryGenerator<TData> {
  return yield { type: "PromiseQuery", promise };
}
