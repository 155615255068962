/* eslint-disable functional/no-this-expression */
import * as React from "react";
import { debounce } from "lodash";
import { twClass } from "./with-tw";

interface Props {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly onKeyDown?: (keyCode: number) => void;
  readonly onFocus?: () => void;
  readonly onBlur?: (value: string) => void;
  readonly autoFocus?: boolean;
  readonly placeholder?: string;
  readonly label?: string;
  readonly multiLine?: boolean;
  readonly debounce?: boolean;
  readonly debounceTime?: number;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
  readonly alwaysUpdate?: boolean;
  readonly isRequiredMessage?: string;
  readonly errorMessage?: string;
  readonly className?: string;
  readonly classNameInner?: string;
}

interface LocalState {
  readonly value: string;
  readonly hasFocus: boolean;
}

const inputClass = twClass`${(props: Props) => (props.disabled ? "form-input-disabled" : "")} ${(props: Props) =>
  props.errorMessage || (props.isRequiredMessage && props.value === "") ? "form-input-danger" : ""}`;

// eslint-disable-next-line functional/no-class
export class Textfield extends React.Component<Props, LocalState> {
  // eslint-disable-next-line functional/prefer-readonly-type
  textInput: HTMLInputElement | HTMLTextAreaElement | null = null;

  readonly _update = debounce(() => {
    this.onChange(this.state.value);
  }, this.props.debounceTime || 1000);

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
      hasFocus: false,
    };
  }

  cancelDebounce(): void {
    if (this.props.disabled || this.props.readOnly) {
      return;
    }
    this._update.cancel();
    this.onChange(this.state.value);
  }

  onChange(value: string): void {
    if (this.props.disabled || this.props.readOnly) {
      return;
    }
    this.props.onChange(value);
  }

  onChangeLocal(value: string): void {
    this._update();
    this.setState(() => ({ value: value }));
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const { debounce = true, alwaysUpdate = false } = this.props;
    if (alwaysUpdate || !debounce || !this.state.hasFocus) {
      this.setState(() => ({
        value: nextProps.value,
      }));
    }
  }

  componentDidMount(): void {
    if (this.props.autoFocus && this.textInput) {
      this.textInput.focus();
      this.textInput.select();
    }
  }

  render(): JSX.Element {
    const { className, label } = this.props;
    if (label) {
      return (
        <div className={`flex flex-row items-center ${className || ""}`}>
          <label className="block">{label}</label>
          {this.renderInput()}
        </div>
      );
    } else {
      return <div className={`flex flex-row items-center ${className || ""}`}>{this.renderInput()}</div>;
    }
  }

  renderInput(): JSX.Element {
    const {
      onKeyDown,
      placeholder,
      onFocus,
      onBlur,
      multiLine,
      debounce = true,
      disabled,
      readOnly,
      classNameInner,
    } = this.props;
    if (readOnly) {
      return <span>{this.props.value}</span>;
    } else {
      return multiLine ? (
        <textarea
          title={(this.props.value === "" ? this.props.isRequiredMessage : undefined) || this.props.errorMessage}
          className={`form-input-area ${inputClass(this.props)} ${classNameInner}`}
          ref={(e) => (this.textInput = e)}
          placeholder={placeholder}
          value={this.state.value + "AAAA"}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            debounce ? this.onChangeLocal(e.currentTarget.value) : this.onChange(e.currentTarget.value)
          }
          onKeyDown={(e: React.KeyboardEvent) => onKeyDown && onKeyDown(e.keyCode)}
          onBlur={() => {
            this.setState({ hasFocus: false });
            if (debounce) {
              this.cancelDebounce();
            }
            if (onBlur) {
              onBlur(this.state.value);
            }
          }}
          onFocus={() => {
            this.setState({ hasFocus: true });
            if (onFocus) {
              onFocus();
            }
          }}
          rows={5}
          readOnly={readOnly}
          disabled={disabled}
        />
      ) : (
        <input
          title={(this.props.value === "" ? this.props.isRequiredMessage : undefined) || this.props.errorMessage}
          className={`form-input ${inputClass(this.props)} ${classNameInner}`}
          ref={(e) => (this.textInput = e)}
          placeholder={placeholder}
          value={this.state.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            debounce ? this.onChangeLocal(e.currentTarget.value) : this.onChange(e.currentTarget.value)
          }
          onBlur={() => {
            this.setState({ hasFocus: false });
            if (debounce) {
              this.cancelDebounce();
            }
            if (onBlur) {
              this._update.cancel();

              onBlur(this.state.value);
            }
          }}
          onFocus={() => {
            this.setState({ hasFocus: true });
            if (onFocus) {
              onFocus();
            }
          }}
          readOnly={readOnly}
          disabled={disabled}
        />
      );
    }
  }
}
