import gql from "graphql-tag";

export const LangText_ModulesLanguagesFragment = gql`
  fragment LangText_ModulesLanguages on Modules {
    languages {
      ...LangText_Language
    }
  }

  fragment LangText_Language on Module_languages {
    language {
      name
    }
  }
`;

export const LangText_ModulesTextsFragment = gql`
  fragment LangText_ModulesTexts on Modules {
    texts {
      translatedTexts: text(language: $language) {
        ...LangTexts_Texts
      }
      fallbackTexts: text(language: "en") {
        ...LangTexts_Texts
      }
    }
  }

  fragment LangTexts_Texts on Texts_Text {
    property_filter
    name
    text
  }
`;

export const translationQuery = gql`
  query Translations($language: String!) {
    products: products {
      key
      modules {
        ...LangText_ModulesTexts
      }
    }
  }
  ${LangText_ModulesTextsFragment}
`;
