import { PropertyFilter } from "@promaster-sdk/property";
import { unitLookup } from "uom-units";

export type RowWithPropertyFilter<T> = Omit<T, "property_filter"> & {
  readonly property_filter: PropertyFilter.PropertyFilter;
};

export type TableWithPropertyFilter<T extends readonly unknown[]> = ReadonlyArray<RowWithPropertyFilter<T[0]>>;

export function mapPropertyFilter<T extends { readonly property_filter: string | null }>(
  rows: ReadonlyArray<T>
): ReadonlyArray<RowWithPropertyFilter<T>> {
  return rows.map((r) => {
    const property_filter = r.property_filter
      ? PropertyFilter.fromStringOrEmpty(r.property_filter, unitLookup)
      : PropertyFilter.Empty;
    return {
      ...r,
      property_filter,
    };
  });
}
