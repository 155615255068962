import React from "react";
import { withTw } from "./with-tw";

export function Tooltip({
  text,
  tooltipType,
  content,
  className,
  divProps,
}: {
  readonly text?: string;
  readonly tooltipType?: TooltipType;
  readonly content: JSX.Element | undefined;
  readonly htmlElementType?: "a" | "div";
  readonly customTooltipContainerWidth?: number;
  readonly className?: string;
  readonly divProps?: React.ComponentPropsWithoutRef<"div">;
}): JSX.Element {
  const tooltipTextRef = React.useRef<HTMLSpanElement>(undefined!);
  return (
    <TooltipContainer
      onPointerEnter={(e: React.PointerEvent<HTMLDivElement>) => {
        if (tooltipTextRef.current) {
          const containerRect = e.currentTarget.getBoundingClientRect();

          const tooltipTextRect = tooltipTextRef.current.getBoundingClientRect();
          tooltipTextRef.current.style.top = `${containerRect.top - tooltipTextRect.height - 10}px`;
          tooltipTextRef.current.style.left = `${
            containerRect.left + (containerRect.width - tooltipTextRect.width) / 2
          }px`;
        }
      }}
      // workaround to get tooltip to show with tailwind.
      className={"tooltip " + className}
      {...divProps}
    >
      {text && (
        <TooltipText elementref={tooltipTextRef} tooltipType={tooltipType}>
          {text}
        </TooltipText>
      )}
      {content}
    </TooltipContainer>
  );
}

export enum TooltipType {
  Information = 0,
  Warning = 1,
}

export interface TooltipTextProps {
  readonly tooltipType?: TooltipType;
}

export const TooltipText = withTw(
  "span",
  "fixed py-[5px] px-[10px] justify-center items-center flex cursor-default inline-block rounded pointer-events-none invisible text-white text-center whitespace-normal break-words opacity-0",
  ({ tooltipType }: TooltipTextProps) => toolTipType(tooltipType),
  "after:content[''] after:absolute after:-ml-1 after:border-[4px]",
  "after:top-[100%] after:left-[50%] after:visible after:opacity-100",
  "fixed h-fit max-w-[500px] w-fit"
);

const toolTipType = (tooltiptype: TooltipType | undefined): string => {
  switch (tooltiptype) {
    case TooltipType.Warning:
      return `bg-red-400 after:border-t-red-400 z-[1500]`;
    case TooltipType.Information:
    default:
      return `bg-sky-500 after:border-t-sky-500`;
  }
};

const TooltipContainer = withTw(
  "div",
  "after:hover:no-underline after:hover:block ",
  "hover:no-underline hover:visible hover:[&>span]:opacity-100 [&>*:hover]:visible"
);
