import { Vector3, vec3Normalize, vec3Sub, vec3Cross, vec3Create } from "./vector3";
import { Euler } from "./euler";

export interface Matrix4 {
  readonly m11: number;
  readonly m12: number;
  readonly m13: number;
  readonly m14: number;
  readonly m21: number;
  readonly m22: number;
  readonly m23: number;
  readonly m24: number;
  readonly m31: number;
  readonly m32: number;
  readonly m33: number;
  readonly m34: number;
}

export const matrix4Identity = {
  m11: 1,
  m12: 0,
  m13: 0,
  m14: 0,
  m21: 0,
  m22: 1,
  m23: 0,
  m24: 0,
  m31: 0,
  m32: 0,
  m33: 1,
  m34: 0,
};

export function matrix4Create(
  m11: number,
  m12: number,
  m13: number,
  m14: number,
  m21: number,
  m22: number,
  m23: number,
  m24: number,
  m31: number,
  m32: number,
  m33: number,
  m34: number
): Matrix4 {
  return {
    m11: m11,
    m12: m12,
    m13: m13,
    m14: m14,
    m21: m21,
    m22: m22,
    m23: m23,
    m24: m24,
    m31: m31,
    m32: m32,
    m33: m33,
    m34: m34,
  };
}

export function matrix4CreateLookAt(position: Vector3, lookAt: Vector3, up: Vector3): Matrix4 {
  const z = vec3Sub(position, lookAt);
  const x = vec3Cross(up, z);
  const y = vec3Cross(z, x);
  return matrix4CreateFromVectors(x, y, z, position);
}

export function matrix4CreateTransform(translate: Vector3, rotate: Euler, scale: number = 1): Matrix4 {
  const translation = matrix4CreateTranslation(translate);
  const rotation = matrix4CreateFromEuler(rotate);
  if (scale !== 1) {
    const scaling = matrix4CreateScale(scale, scale, scale);
    return matrix4Multiply(translation, matrix4Multiply(rotation, scaling));
  }
  return matrix4Multiply(translation, rotation);
}

export function matrix4CreateTranslation(t: Vector3): Matrix4 {
  return {
    m11: 1,
    m12: 0,
    m13: 0,
    m14: t.x,
    m21: 0,
    m22: 1,
    m23: 0,
    m24: t.y,
    m31: 0,
    m32: 0,
    m33: 1,
    m34: t.z,
  };
}

export function matrix4CreateFromEuler(rotation: Euler): Matrix4 {
  const rotationX = matrix4CreateRotationX(rotation.x);
  const rotationY = matrix4CreateRotationY(rotation.y);
  const rotationZ = matrix4CreateRotationZ(rotation.z);
  return matrix4Multiply(matrix4Multiply(rotationZ, rotationY), rotationX);
}

export function matrix4ResetTranslation(matrix: Matrix4): Matrix4 {
  return {
    ...matrix,
    m14: 0,
    m24: 0,
    m34: 0,
  };
}

export function matrix4CreateRotationX(angle: number): Matrix4 {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  return {
    m11: 1,
    m12: 0,
    m13: 0,
    m14: 0,
    m21: 0,
    m22: cos,
    m23: -sin,
    m24: 0,
    m31: 0,
    m32: sin,
    m33: cos,
    m34: 0,
  };
}

export function matrix4CreateRotationY(angle: number): Matrix4 {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  return {
    m11: cos,
    m12: 0,
    m13: sin,
    m14: 0,
    m21: 0,
    m22: 1,
    m23: 0,
    m24: 0,
    m31: -sin,
    m32: 0,
    m33: cos,
    m34: 0,
  };
}

export function matrix4CreateRotationZ(angle: number): Matrix4 {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  return {
    m11: cos,
    m12: -sin,
    m13: 0,
    m14: 0,
    m21: sin,
    m22: cos,
    m23: 0,
    m24: 0,
    m31: 0,
    m32: 0,
    m33: 1,
    m34: 0,
  };
}

export function matrix4CreateScale(x: number, y: number, z: number): Matrix4 {
  return {
    m11: x,
    m12: 0,
    m13: 0,
    m14: 0,
    m21: 0,
    m22: y,
    m23: 0,
    m24: 0,
    m31: 0,
    m32: 0,
    m33: z,
    m34: 0,
  };
}

export function matrix4CreateFromVectors(x: Vector3, y: Vector3, z: Vector3, translation: Vector3): Matrix4 {
  const nx = vec3Normalize(x);
  const ny = vec3Normalize(y);
  const nz = vec3Normalize(z);
  return {
    m11: nx.x,
    m12: ny.x,
    m13: nz.x,
    m14: translation.x,
    m21: nx.y,
    m22: ny.y,
    m23: nz.y,
    m24: translation.y,
    m31: nx.z,
    m32: ny.z,
    m33: nz.z,
    m34: translation.z,
  };
}

export function matrix4Transform(v: Vector3, m: Matrix4): Vector3 {
  return {
    x: m.m11 * v.x + m.m12 * v.y + m.m13 * v.z + m.m14,
    y: m.m21 * v.x + m.m22 * v.y + m.m23 * v.z + m.m24,
    z: m.m31 * v.x + m.m32 * v.y + m.m33 * v.z + m.m34,
  };
}

export function matrix4Multiply(a: Matrix4, b: Matrix4): Matrix4 {
  return {
    m11: a.m11 * b.m11 + a.m12 * b.m21 + a.m13 * b.m31,
    m12: a.m11 * b.m12 + a.m12 * b.m22 + a.m13 * b.m32,
    m13: a.m11 * b.m13 + a.m12 * b.m23 + a.m13 * b.m33,
    m14: a.m11 * b.m14 + a.m12 * b.m24 + a.m13 * b.m34 + a.m14,
    m21: a.m21 * b.m11 + a.m22 * b.m21 + a.m23 * b.m31,
    m22: a.m21 * b.m12 + a.m22 * b.m22 + a.m23 * b.m32,
    m23: a.m21 * b.m13 + a.m22 * b.m23 + a.m23 * b.m33,
    m24: a.m21 * b.m14 + a.m22 * b.m24 + a.m23 * b.m34 + a.m24,
    m31: a.m31 * b.m11 + a.m32 * b.m21 + a.m33 * b.m31,
    m32: a.m31 * b.m12 + a.m32 * b.m22 + a.m33 * b.m32,
    m33: a.m31 * b.m13 + a.m32 * b.m23 + a.m33 * b.m33,
    m34: a.m31 * b.m14 + a.m32 * b.m24 + a.m33 * b.m34 + a.m34,
  };
}

export function matrix4getXAxis(m: Matrix4): Vector3 {
  return vec3Create(m.m11, m.m21, m.m31);
}

export function matrix4getYAxis(m: Matrix4): Vector3 {
  return vec3Create(m.m12, m.m22, m.m32);
}

export function matrix4getZAxis(m: Matrix4): Vector3 {
  return vec3Create(m.m13, m.m23, m.m33);
}
