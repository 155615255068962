import { Calculator, Reports } from "@ehb/shared";
import React from "react";
import { Currency, Model, Type } from "@ehb/shared/src/calculator/types";
import { texts, TranslateFn } from "@ehb/shared/src/lang-texts";
import { Dispatch } from "@typescript-tea/core";
import { getUnitLabel } from "@ehb/shared/src/units/unit-labels";
import { getFilteredPriceTables } from "@ehb/shared/src/calculator";
import { SharedState } from "@ehb/client-infra";
import { getPriceAccessories } from "@ehb/shared/src/calculator/calc-price";
import { isInternalUser } from "@ehb/shared/src/user";
import { Button } from "../../elements/button";
import * as State from "./state";
import { withTw } from "../../elements";

const ResultRowContainer = withTw("div", "flex justify-between items-center");
const ResultLabel = withTw("label", "font-bold text-lg");
const ResultText = withTw("p", "text-md");

export interface ResultViewProps {
  readonly state: State.State;
  readonly sharedState: SharedState.SharedState;
  readonly translate: TranslateFn;
  readonly className?: string;
  readonly dispatch: Dispatch<State.Action>;
}

export function ResultView(props: ResultViewProps): JSX.Element {
  const { translate, className, dispatch, sharedState, state } = props;
  const { calculateResult } = state;
  React.useEffect(() => {
    switch (state.printing.type) {
      case "Loading":
        {
          if (!calculateResult || calculateResult.type === "Err") {
            return;
          }
          const selectedProperties = State.getSelectedProperties(state);
          const customItems = State.getSelectedCustomItems(state);
          const calculateRequest = Calculator.fromVariantToCalculateRequest(selectedProperties);
          if (!calculateRequest || calculateRequest.type === "Err") {
            throw Error(calculateRequest?.error);
          }
          const priceTables = getFilteredPriceTables(state.electricalHeaterProduct, selectedProperties);
          if (!priceTables || priceTables.type === "Err") {
            throw Error(priceTables?.error);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const pdfKit = (window as any).PDFDocument;
          const priceAccessories = getPriceAccessories(
            calculateRequest.value.currency,
            priceTables.value.priceAccessories,
            calculateRequest.value.discount
          );

          const reportParams = State.createReportParams(
            sharedState,
            state,
            calculateResult.value,
            calculateRequest.value,
            priceAccessories,
            customItems
          );
          const date = new Date().toISOString().split("T")[0];
          const fileName = `offert_gen_${date}.pdf`;

          Reports.createPrintoutClient2(
            state.printing.response,
            [reportParams],
            fileName,
            pdfKit,
            state.documents,
            state.circuitDiagrams,
            () => dispatch(State.Action.QuoteFinishedLoading())
          );
        }
        break;
      default:
        break;
    }
  }, [state.printing]);

  if (calculateResult === undefined) {
    return <div className={props.className}>{translate(texts.fill_in_values)}</div>;
  }

  if (calculateResult.type === "Err") {
    return (
      <div className={className}>
        <div>
          <h1 className="text-lg">{translate(texts.err_not_possible)} </h1>
          <h2 className="text-lg text-red-500">{calculateResult.error.errorMessage}</h2>
        </div>
        {calculateResult.error.errorStatus === "CONTACT-VEAB" ? (
          <Button
            className="mt-2.5"
            iconRight={
              state.requestQuoteStatus === "sending"
                ? "circle-notch"
                : state.requestQuoteStatus === "sent_ok"
                ? "circle-check"
                : state.requestQuoteStatus === "sent_error"
                ? "circle-xmark"
                : undefined
            }
            spinnerIcon={state.requestQuoteStatus === "sending" ? true : false}
            label={
              state.requestQuoteStatus === "sent_ok"
                ? translate(texts.request_quote_sent)
                : state.requestQuoteStatus === "sent_error"
                ? translate(texts.request_quote_failed)
                : translate(texts.request_quote)
            }
            onClick={() => {
              setTimeout(() => {
                dispatch(State.Action.RequestQuote());
              }, 1500);
            }}
          />
        ) : null}
      </div>
    );
  }

  const weight =
    calculateResult.value.resultStatus === "OK"
      ? "~" + calculateResult.value.weightKg + getUnitLabel("Kilogram")
      : translate(texts.contact_VEAB);
  const depth =
    calculateResult.value.resultStatus === "OK" || !Number.isNaN(Number(state.manualDepth))
      ? calculateResult.value.depthMm + getUnitLabel("Millimeter")
      : translate(texts.contact_VEAB);
  const width = calculateResult.value.widthMm + getUnitLabel("Millimeter");
  const height = calculateResult.value.heightMm + getUnitLabel("Millimeter");
  const power = calculateResult.value.powerKw + getUnitLabel("KiloWatt");
  const electricalElements = `${calculateResult.value.numberElectricalElements} (${calculateResult.value.articleNumberUsed})`;
  const powerSteps = calculateResult.value.powerStepsKwText.join("+") + getUnitLabel("KiloWatt");
  const voltage = `${calculateResult.value.voltage.toString().charAt(0)}x${calculateResult.value.voltage
    .toString()
    .slice(1)}${getUnitLabel("Volt")}`;
  const current = calculateResult.value.current.toFixed(2) + getUnitLabel("Ampere");
  const airspeed = calculateResult.value.velocityMs + getUnitLabel("MeterPerSecond");
  const pressureDrop = calculateResult.value.pressureDropPa + getUnitLabel("Pascal");
  const tempincrease = calculateResult.value.temperatureIncreaseC + getUnitLabel("Celsius");
  const heatingRod = calculateResult.value.heatingRodSurfaceTemperatureC + getUnitLabel("Celsius");

  const price = `${calculateResult.value.heaterPrice} ${Currency[calculateResult.value.currency]}`;

  return (
    <div className={className}>
      <div className="flex flex-col w-full">
        <ResultRow label={translate(texts.model)} text={Model[calculateResult.value.model]} />
        <ResultRow label={translate(texts.type)} text={Type[calculateResult.value.type]} />
        <ResultRow label={translate(texts.width)} text={width} />
        <ResultRow label={translate(texts.height)} text={height} />
        <ResultRow label={translate(texts.depth)} text={depth} />
        <ResultRow label={translate(texts.weight)} text={weight} />
        <ResultRow label={translate(texts.power)} text={power} />
        {isInternalUser(sharedState.activeUser.claims) && (
          <ResultRow label={translate(texts.electrical_elements)} text={electricalElements} />
        )}
        {(calculateResult.value.type === Type.M || calculateResult.value.type === Type.ML) && (
          <ResultRow label={translate(texts.powerstepsdistribution)} text={powerSteps} />
        )}
        <ResultRow label={translate(texts.voltage)} text={voltage} />
        <ResultRow label={translate(texts.current)} text={current} />
        <ResultRow label={translate(texts.airspeed)} text={airspeed} />
        <ResultRow label={translate(texts.pressuredrop)} text={pressureDrop} />
        <ResultRow label={translate(texts.tempincrease)} text={tempincrease} />
        <ResultRow label={translate(texts.heating_rod_surface_temp)} text={heatingRod} />
        {sharedState.activeUser.claims.user_type !== "endcustomer" && (
          <ResultRow label={translate(texts.price)} text={price} />
        )}

        {calculateResult.value.resultStatus === "CONTACT-VEAB" && sharedState.activeUser.claims.responsible_email ? (
          <Button
            className="mt-2.5"
            iconRight={
              state.requestQuoteStatus === "sending"
                ? "circle-notch"
                : state.requestQuoteStatus === "sent_ok"
                ? "circle-check"
                : state.requestQuoteStatus === "sent_error"
                ? "circle-xmark"
                : undefined
            }
            spinnerIcon={state.requestQuoteStatus === "sending" ? true : false}
            label={
              state.requestQuoteStatus === "sent_ok"
                ? translate(texts.request_quote_sent)
                : state.requestQuoteStatus === "sent_error"
                ? translate(texts.request_quote_failed)
                : translate(texts.request_quote)
            }
            onClick={() => {
              dispatch(State.Action.RequestQuote());
            }}
          />
        ) : calculateResult.value.resultStatus === "OK" ? (
          <Button
            spinnerIcon={true}
            className="mt-2.5 flex justify-end"
            label={translate(texts.pdf_quote)}
            onClick={() => {
              dispatch(State.Action.GenerateQuote());
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

function ResultRow({ label, text }: { readonly label: string; readonly text: string }): JSX.Element {
  return (
    <ResultRowContainer>
      <ResultLabel>{label}:</ResultLabel>
      <ResultText>{text} </ResultText>
    </ResultRowContainer>
  );
}
