import * as React from "react";
import { withTw } from "./with-tw";

export interface CheckboxProps {
  readonly label?: string | React.ReactNode;
  readonly title?: string;
  readonly checked: boolean;
  readonly onClick?: (checked: boolean) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly name?: string;
  readonly id?: string;
  readonly error?: string;
  readonly children?: React.ReactNode; // TODO
  readonly align?: string; // TODO
  readonly fullSize?: boolean; // TODO
}

interface WrapperProps {
  readonly checked: boolean;
}

const CheckboxWrapper = withTw("div", "form-checkbox-border top-auto", ({ checked }: WrapperProps) =>
  checked ? "form-checkbox-checked" : ""
);

interface LabelProps {
  readonly isValid: boolean;
}
const Label = withTw("label", "flex flex-row items-center form-checkbox p-0 w-1/2", ({ isValid }: LabelProps) =>
  isValid ? "" : "border-danger-dark"
);

export const CheckboxInput = withTw(
  "input",
  "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600",
  "hover:cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
);

export function Checkbox(props: CheckboxProps): React.ReactElement<CheckboxProps> {
  let className = "";
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <Label title={props.error || props.title} isValid={!props.error}>
      <CheckboxInput
        id={props.id}
        name={props.name}
        className={className}
        checked={props.checked}
        type="checkbox"
        disabled={props.disabled}
        onChange={() => {
          if (!props.disabled && props.onClick) {
            props.onClick(!props.checked);
          }
        }}
      />
      <CheckboxWrapper checked={props.checked}></CheckboxWrapper>
      {props.label && (
        <div className="ml-4 flex items-center">
          {typeof props.label === "string" ? <span>{props.label}</span> : props.label}
        </div>
      )}
    </Label>
  );
}
