import * as THREE from "three";
import { Vector3 } from "./vector3";
import { Euler } from "./euler";
import { Bounds3, bounds3Create } from "./bounds3";
import { matrix4CreateTransform, matrix4Transform } from "./matrix4";
import { Mesh, meshBoundingBox } from "./mesh";

export type OnBeforeRender = (
  renderer: THREE.WebGLRenderer,
  scene: THREE.Scene,
  camera: THREE.Camera,
  geometry: THREE.Geometry | THREE.BufferGeometry,
  material: THREE.Material,
  group: THREE.Group
) => void;

export interface Object3d {
  readonly id: string;
  readonly position: Vector3;
  readonly rotation: Euler;
  readonly scale: number;
  readonly mesh: Mesh;
  readonly visible: boolean;
  readonly clickable: boolean;
  readonly onBeforeRender: OnBeforeRender | undefined;
}

export function object3dCreate(
  id: string,
  position: Vector3,
  rotation: Euler,
  scale: number,
  mesh: Mesh,
  visible: boolean,
  clickable: boolean,
  onBeforeRender: OnBeforeRender | undefined = undefined
): Object3d {
  return {
    id: id,
    position: position,
    rotation: rotation,
    scale: scale,
    mesh: mesh,
    visible: visible,
    clickable: clickable,
    onBeforeRender: onBeforeRender,
  };
}

export function object3dBoundingBox(object3d: Object3d): Bounds3 {
  const boundingBox = meshBoundingBox(object3d.mesh);
  const matrix = matrix4CreateTransform(object3d.position, object3d.rotation);
  return bounds3Create(matrix4Transform(boundingBox.minimum, matrix), matrix4Transform(boundingBox.maximum, matrix));
}
