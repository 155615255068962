// This is a fixed version of the parser in three-x3d-loader. It has been
// changed to not used "global this", other than that it's identical

function praseX3d(THREE, x3dXml, useImageTexture) {
  const state = {
    // for IndexedFaceSet support
    isRecordingPoints: false,
    isRecordingFaces: false,
    points: [],
    indexes: [],

    // for Background support
    isRecordingAngles: false,
    isRecordingColors: false,
    angles: [],

    recordingFieldname: null,
  };

  useImageTexture = useImageTexture === false ? false : true;
  let scene = new THREE.Scene();
  let defines = {};
  let float_pattern = /(\b|\-|\+)([\d\.e]+)/;
  let float2_pattern = /([\d\.\+\-e]+)\s+([\d\.\+\-e]+)/g;
  let float3_pattern = /([\d\.\+\-e]+)\s+([\d\.\+\-e]+)\s+([\d\.\+\-e]+)/g;

  /**
   * Interpolates colors a and b following their relative distance
   * expressed by t.
   *
   * @param float a
   * @param float b
   * @param float t
   * @returns {Color}
   */
  let interpolateColors = function (a, b, t) {
    let deltaR = a.r - b.r;
    let deltaG = a.g - b.g;
    let deltaB = a.b - b.b;

    let c = new THREE.Color();

    c.r = a.r - t * deltaR;
    c.g = a.g - t * deltaG;
    c.b = a.b - t * deltaB;

    return c;
  };

  /**
   * Vertically paints the faces interpolating between the
   * specified colors at the specified angels. This is used for the Background
   * node, but could be applied to other nodes with multiple faces as well.
   *
   * When used with the Background node, default is directionIsDown is true if
   * interpolating the skyColor down from the Zenith. When interpolationg up from
   * the Nadir i.e. interpolating the groundColor, the directionIsDown is false.
   *
   * The first angle is never specified, it is the Zenith (0 rad). Angles are specified
   * in radians. The geometry is thought a sphere, but could be anything. The color interpolation
   * is linear along the Y axis in any case.
   *
   * You must specify one more color than you have angles at the beginning of the colors array.
   * This is the color of the Zenith (the top of the shape).
   *
   * @param geometry
   * @param radius
   * @param angles
   * @param colors
   * @param boolean directionIsDown Whether to work bottom up or top down.
   */
  let paintFaces = function (geometry, radius, angles, colors, directionIsDown) {
    let f, n, p, vertexIndex, color;

    let direction = directionIsDown ? 1 : -1;

    let faceIndices = ["a", "b", "c", "d"];

    let coord = [],
      aColor,
      bColor,
      t = 1,
      A = {},
      B = {},
      applyColor = false,
      colorIndex;

    for (var k = 0; k < angles.length; k++) {
      let vec = {};

      // push the vector at which the color changes
      vec.y = direction * (Math.cos(angles[k]) * radius);

      vec.x = direction * (Math.sin(angles[k]) * radius);

      coord.push(vec);
    }

    // painting the colors on the faces
    for (var i = 0; i < geometry.faces.length; i++) {
      f = geometry.faces[i];

      n = f instanceof THREE.Face3 ? 3 : 4;

      for (var j = 0; j < n; j++) {
        vertexIndex = f[faceIndices[j]];

        p = geometry.vertices[vertexIndex];

        for (var index = 0; index < colors.length; index++) {
          // linear interpolation between aColor and bColor, calculate proportion
          // A is previous point (angle)
          if (index === 0) {
            A.x = 0;
            A.y = directionIsDown ? radius : -1 * radius;
          } else {
            A.x = coord[index - 1].x;
            A.y = coord[index - 1].y;
          }

          // B is current point (angle)
          B = coord[index];

          if (undefined !== B) {
            // p has to be between the points A and B which we interpolate
            applyColor = directionIsDown ? p.y <= A.y && p.y > B.y : p.y >= A.y && p.y < B.y;

            if (applyColor) {
              bColor = colors[index + 1];

              aColor = colors[index];

              // below is simple linear interpolation
              t = Math.abs(p.y - A.y) / (A.y - B.y);

              // to make it faster, you can only calculate this if the y coord changes, the color is the same for points with the same y
              color = interpolateColors(aColor, bColor, t);

              f.vertexColors[j] = color;
            }
          } else if (undefined === f.vertexColors[j]) {
            colorIndex = directionIsDown ? colors.length - 1 : 0;
            f.vertexColors[j] = colors[colorIndex];
          }
        }
      }
    }
  };

  let renderNode = function (data, parent) {
    // console.log( data );

    if (typeof data === "string") {
      if (/USE/.exec(data)) {
        let defineKey = /USE\s+?(\w+)/.exec(data)[1];

        if (undefined == defines[defineKey]) {
          console.warn(defineKey + " is not defined.");
        } else {
          if (/appearance/.exec(data) && defineKey) {
            parent.material = defines[defineKey].clone();
          } else if (/geometry/.exec(data) && defineKey) {
            parent.geometry = defines[defineKey].clone();

            // the solid property is not cloned with clone(), is only needed for VRML loading, so we need to transfer it
            if (undefined !== defines[defineKey].solid && defines[defineKey].solid === false) {
              parent.geometry.solid = false;
              parent.material.side = THREE.DoubleSide;
            }
          } else if (defineKey) {
            let currentObject = defines[defineKey].clone();
            parent.add(currentObject);
          }
        }
      }

      return;
    }

    let currentObject = parent;

    if ("viewpoint" === data.nodeType) {
      let p = data.position;
      parent.cameraPosition = { x: p.x, y: p.y, z: p.z };

      let r = data.orientation;
      parent.cameraOrientation = { xyz: new THREE.Vector3(r.x, r.y, r.z), v: r.v };

      parent.cameraFieldOfView = data.fieldOfView;
    } else if ("transform" === data.nodeType || "group" === data.nodeType) {
      currentObject = new THREE.Object3D();

      if (/DEF/.exec(data.string)) {
        currentObject.name = /DEF\s+(\w+)/.exec(data.string)[1];
        defines[currentObject.name] = currentObject;
      }

      if (undefined !== data["translation"]) {
        let t = data.translation;

        currentObject.position.set(t.x, t.y, t.z);
      }

      if (undefined !== data.rotation) {
        let r = data.rotation;

        currentObject.quaternion.setFromAxisAngle(new THREE.Vector3(r.x, r.y, r.z), r.w);
      }

      if (undefined !== data.scale) {
        let s = data.scale;

        currentObject.scale.set(s.x, s.y, s.z);
      }

      parent.add(currentObject);
    } else if ("shape" === data.nodeType) {
      currentObject = new THREE.Mesh();

      if (/DEF/.exec(data.string)) {
        currentObject.name = /DEF\s+(\w+)/.exec(data.string)[1];

        defines[currentObject.name] = currentObject;
      }

      parent.add(currentObject);
    } else if ("background" === data.nodeType) {
      let segments = 20;

      // sky (full sphere):

      let radius = 2e4;

      let skyGeometry = new THREE.SphereGeometry(radius, segments, segments);
      let skyMaterial = new THREE.MeshBasicMaterial({ fog: false, side: THREE.BackSide });

      if (data.skyColor.length > 1) {
        paintFaces(skyGeometry, radius, data.skyAngle, data.skyColor, true);

        skyMaterial.vertexColors = THREE.VertexColors;
      } else {
        let color = data.skyColor[0];
        skyMaterial.color.setRGB(color.r, color.b, color.g);
      }

      scene.add(new THREE.Mesh(skyGeometry, skyMaterial));

      // ground (half sphere):

      if (data.groundColor !== undefined) {
        radius = 1.2e4;

        let groundGeometry = new THREE.SphereGeometry(
          radius,
          segments,
          segments,
          0,
          2 * Math.PI,
          0.5 * Math.PI,
          1.5 * Math.PI
        );
        let groundMaterial = new THREE.MeshBasicMaterial({
          fog: false,
          side: THREE.BackSide,
          vertexColors: THREE.VertexColors,
        });

        paintFaces(groundGeometry, radius, data.groundAngle || [], data.groundColor, false);

        scene.add(new THREE.Mesh(groundGeometry, groundMaterial));
      }
    } else if (/geometry/.exec(data.string)) {
      if ("box" === data.nodeType) {
        let s = data.size;
        if (s) {
          parent.geometry = new THREE.BoxGeometry(s.x, s.y, s.z);
        } else {
          parent.geometry = new THREE.BoxGeometry(1.0, 1.0, 1.0);
        }
      } else if ("cylinder" === data.nodeType) {
        parent.geometry = new THREE.CylinderGeometry(data.radius, data.radius, data.height);
      } else if ("cone" === data.nodeType) {
        parent.geometry = new THREE.CylinderGeometry(data.topRadius, data.bottomRadius, data.height);
      } else if ("sphere" === data.nodeType) {
        parent.geometry = new THREE.SphereGeometry(data.radius);
      } else if ("indexedfaceset" === data.nodeType) {
        let geometry = new THREE.Geometry();

        let indexes, uvIndexes, uvs;

        for (var i = 0, j = data.children.length; i < j; i++) {
          let child = data.children[i];

          let vec;

          if ("texturecoordinate" === child.nodeType) {
            uvs = child.points;
          }

          if ("coordinate" === child.nodeType) {
            if (child.points) {
              for (var k = 0, l = child.points.length; k < l; k++) {
                let point = child.points[k];

                vec = new THREE.Vector3(point.x, point.y, point.z);

                geometry.vertices.push(vec);
              }
            }

            if (child.string.indexOf("DEF") > -1) {
              let name = /DEF\s+(\w+)/.exec(child.string)[1];

              defines[name] = geometry.vertices;
            }

            if (child.string.indexOf("USE") > -1) {
              let defineKey = /USE\s+(\w+)/.exec(child.string)[1];

              geometry.vertices = defines[defineKey];
            }
          }
        }

        let skip = 0;

        // some shapes only have vertices for use in other shapes
        if (data.coordIndex) {
          // read this: http://math.hws.edu/eck/cs424/notes2013/16_Threejs_Advanced.html
          for (var i = 0, j = data.coordIndex.length; i < j; i++) {
            indexes = data.coordIndex[i];
            //if ( data.texCoordIndex )
            //	uvIndexes = data.texCoordIndex[ i ];
            uvIndexes = data.coordIndex[i];

            // vrml support multipoint indexed face sets (more then 3 vertices). You must calculate the composing triangles here
            skip = 0;

            // Face3 only works with triangles, but IndexedFaceSet allows shapes with more then three vertices, build them of triangles
            while (indexes.length >= 3 && skip < indexes.length - 2) {
              let face = new THREE.Face3(
                indexes[0],
                indexes[skip + (data.ccw ? 1 : 2)],
                indexes[skip + (data.ccw ? 2 : 1)],
                null // normal, will be added later
                // todo: pass in the color, if a color index is present
              );

              if (uvs && uvIndexes) {
                geometry.faceVertexUvs[0].push([
                  new THREE.Vector2(uvs[uvIndexes[0]].x, uvs[uvIndexes[0]].y),
                  new THREE.Vector2(
                    uvs[uvIndexes[skip + (data.ccw ? 1 : 2)]].x,
                    uvs[uvIndexes[skip + (data.ccw ? 1 : 2)]].y
                  ),
                  new THREE.Vector2(
                    uvs[uvIndexes[skip + (data.ccw ? 2 : 1)]].x,
                    uvs[uvIndexes[skip + (data.ccw ? 2 : 1)]].y
                  ),
                ]);
              }

              skip++;

              geometry.faces.push(face);
            }
          }
        } else {
          // do not add dummy mesh to the scene
          parent.parent.remove(parent);
        }

        if (false === data.solid) {
          parent.material.side = THREE.DoubleSide;
        }

        // we need to store it on the geometry for use with defines
        geometry.solid = data.solid;

        geometry.computeFaceNormals();
        geometry.computeVertexNormals(); // does not show
        geometry.computeBoundingSphere();

        // see if it's a define
        if (/DEF/.exec(data.string)) {
          geometry.name = /DEF (\w+)/.exec(data.string)[1];
          defines[geometry.name] = geometry;
        }

        parent.geometry = geometry;
        //parent.geometry = geometry;
      }

      return;
    } else if (/appearance/.exec(data.string)) {
      for (var i = 0; i < data.children.length; i++) {
        let child = data.children[i];

        if ("material" === child.nodeType) {
          let material = new THREE.MeshPhongMaterial();

          if (undefined !== child.diffuseColor) {
            let d = child.diffuseColor;

            //  if (d.r === 0 && d.g === 0 && d.b === 0) {
            //    material.color.setRGB(0.0001, 0.0001, 0.0001);
            //  } else {
            material.color.setRGB(d.r, d.g, d.b);
            //            }
          }

          if (undefined !== child.emissiveColor) {
            let e = child.emissiveColor;

            material.emissive.setRGB(e.r, e.g, e.b);
          }

          if (undefined !== child.specularColor) {
            let s = child.specularColor;

            material.specular.setRGB(s.r, s.g, s.b);
          }

          if (undefined !== child.transparency) {
            let t = child.transparency;

            // transparency is opposite of opacity
            material.opacity = Math.abs(1 - t);

            material.transparent = true;
          }

          if (/DEF/.exec(data.string)) {
            material.name = /DEF (\w+)/.exec(data.string)[1];

            defines[material.name] = material;
          }

          parent.material = material;
        }

        if ("imagetexture" === child.nodeType && useImageTexture) {
          //var tex = THREE.ImageUtils.loadTexture("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABYSURBVDhPxc9BCsAgDERRj96j9WZpyI+CYxCKlL6VJfMXbfbSX8Ed8mOmAdMr8M5DNwVj2gJvaYqANXbBuoY0B4FbG1m7s592fh4Z7zx0GqCcog42vg7MHh1jhetTOqUmAAAAAElFTkSuQmCC");
          let tex = THREE.ImageUtils.loadTexture(child.url);
          tex.wrapS = THREE.RepeatWrapping;
          tex.wrapT = THREE.RepeatWrapping;

          parent.material.map = tex;
        }
      }

      return;
    }

    for (var i = 0, l = data.children.length; i < l; i++) {
      let child = data.children[i];

      renderNode(data.children[i], currentObject);
    }
  };

  let getTree = function (x3dXml) {
    let tree = { string: "Scene", children: [] };

    for (var i = 0; i < x3dXml.documentElement.childNodes.length; i++) {
      if (x3dXml.documentElement.childNodes[i].nodeName === "Scene") {
        parseChildren(x3dXml.documentElement.childNodes[i], tree);
        return tree;
      }
    }
    console.error("Unable to find Scene element in X3D document");
  };

  let parseChildren = function (parentNode, parentResult) {
    for (var i = 0; i < parentNode.childNodes.length; i++) {
      let currentNode = parentNode.childNodes[i];
      if (currentNode.nodeType !== 3) {
        let nodeAttr = currentNode.attributes[0] || {};
        let newChild = {
          nodeType: currentNode.nodeName.toLocaleLowerCase(),
          string:
            getNodeGroup(currentNode.nodeName) +
            " " +
            currentNode.nodeName.toLocaleLowerCase() +
            " " +
            nodeAttr.nodeName +
            " " +
            nodeAttr.nodeValue,
          parent: parentResult,
          children: [],
        };
        parentResult.children.push(newChild);
        for (var attributesCounter = 0; attributesCounter < currentNode.attributes.length; attributesCounter++) {
          parseAttribute(
            newChild,
            currentNode.attributes[attributesCounter].name,
            currentNode.attributes[attributesCounter].value
          );
        }

        if (currentNode.childNodes.length > 0) {
          parseChildren(currentNode, newChild);
        }
      }
    }
  };

  let getNodeGroup = function (nodeName) {
    let group = null;

    switch (nodeName.toLowerCase()) {
      case "box":
      case "cylinder":
      case "cone":
      case "sphere":
      case "indexedfaceset":
        group = "geometry";
        break;

      case "material":
      case "imagetexture":
        group = "appearance";
        break;

      case "coordinate":
        group = "coord";
        break;

      default:
        //group = nodeName.toLowerCase();
        group = "";
        break;
    }

    return group;
  };

  let parseAttribute = function (node, attributeName, value) {
    let parts = [],
      part,
      property = {},
      fieldName;
    let valuePattern = /[^\s,\[\]]+/g;
    let point, index, angles, colors;

    fieldName = attributeName;
    parts.push(attributeName);
    while (null != (part = valuePattern.exec(value))) {
      parts.push(part[0]);
    }

    // trigger several recorders
    switch (fieldName) {
      case "skyAngle":
      case "groundAngle":
        state.recordingFieldname = fieldName;
        state.isRecordingAngles = true;
        state.angles = [];
        break;
      case "skyColor":
      case "groundColor":
        state.recordingFieldname = fieldName;
        state.isRecordingColors = true;
        state.colors = [];
        break;
      case "point":
        state.recordingFieldname = fieldName;
        state.isRecordingPoints = true;
        state.points = [];
        break;
      case "coordIndex":
      case "texCoordIndex":
        state.recordingFieldname = fieldName;
        state.isRecordingFaces = true;
        state.indexes = [];
    }

    if (state.isRecordingFaces) {
      if (parts.length > 0) {
        index = [];
        for (var ind = 0; ind < parts.length; ind++) {
          // the part should either be positive integer or -1
          if (!/(-?\d+)/.test(parts[ind])) {
            continue;
          }

          // end of current face
          if (parts[ind] === "-1") {
            if (index.length > 0) {
              state.indexes.push(index);
            }

            // start new one
            index = [];
          } else {
            index.push(parseInt(parts[ind]));
          }
        }
      }

      state.isRecordingFaces = false;
      node[state.recordingFieldname] = state.indexes;
    } else if (state.isRecordingPoints) {
      if (node.nodeType == "coordinate") {
        while (null !== (parts = float3_pattern.exec(value))) {
          state.points.push({
            x: parseFloat(parts[1]),
            y: parseFloat(parts[2]),
            z: parseFloat(parts[3]),
          });
        }
      }

      if (node.nodeType == "texturecoordinate") {
        while (null !== (parts = float2_pattern.exec(value))) {
          state.points.push({
            x: parseFloat(parts[1]),
            y: parseFloat(parts[2]),
          });
        }
      }

      state.isRecordingPoints = false;
      node.points = state.points;
    } else if (state.isRecordingAngles) {
      if (parts.length > 0) {
        for (var ind = 0; ind < parts.length; ind++) {
          if (!float_pattern.test(parts[ind])) {
            continue;
          }

          state.angles.push(parseFloat(parts[ind]));
        }
      }

      state.isRecordingAngles = false;
      node[state.recordingFieldname] = state.angles;
    } else if (state.isRecordingColors) {
      while (null !== (parts = float3_pattern.exec(value))) {
        state.colors.push({
          r: parseFloat(parts[1]),
          g: parseFloat(parts[2]),
          b: parseFloat(parts[3]),
        });
      }

      state.isRecordingColors = false;
      node[state.recordingFieldname] = state.colors;
    } else if (parts[parts.length - 1] !== "NULL" && fieldName !== "children") {
      switch (fieldName) {
        case "diffuseColor":
        case "emissiveColor":
        case "specularColor":
        case "color":
          if (parts.length != 4) {
            console.warn("Invalid color format detected for " + fieldName);
            break;
          }

          property = {
            r: parseFloat(parts[1]),
            g: parseFloat(parts[2]),
            b: parseFloat(parts[3]),
          };

          break;

        case "translation":
        case "scale":
        case "size":
        case "position":
          if (parts.length != 4) {
            console.warn("Invalid vector format detected for " + fieldName);
            break;
          }

          property = {
            x: parseFloat(parts[1]),
            y: parseFloat(parts[2]),
            z: parseFloat(parts[3]),
          };

          break;

        case "radius":
        case "topRadius":
        case "bottomRadius":
        case "height":
        case "transparency":
        case "shininess":
        case "ambientIntensity":
        case "creaseAngle":
        case "fieldOfView":
          if (parts.length != 2) {
            console.warn("Invalid single float value specification detected for " + fieldName);
            break;
          }

          property = parseFloat(parts[1]);
          break;

        case "rotation":
        case "orientation":
          if (parts.length != 5) {
            console.warn("Invalid quaternion format detected for " + fieldName);
            break;
          }

          property = {
            x: parseFloat(parts[1]),
            y: parseFloat(parts[2]),
            z: parseFloat(parts[3]),
            w: parseFloat(parts[4]),
          };
          break;

        case "ccw":
        case "solid":
        case "colorPerVertex":
        case "convex":
          if (parts.length != 2) {
            console.warn("Invalid format detected for " + fieldName);
            break;
          }

          property = parts[1] === "TRUE" ? true : false;
          break;

        case "url":
          if (parts.length >= 3) {
            property = parts[1] + "," + parts[2];
          } else {
            property = parts[1];
          }
          break;
      }

      node[fieldName] = property;
    }

    return property;
  };

  renderNode(getTree(x3dXml), scene);

  return scene;
}

module.exports = {
  praseX3d: praseX3d,
};
