import { PropertyValueSet } from "@promaster-sdk/property";
import { ReportModule } from "../types";
import * as Common from "../common";
import * as GQLOps from "../../generated/generated-operations";
import { CoilResult } from "../../calculator-frt-coil";
import { TextKey } from "../../lang-texts";
import { Accessories } from "../..";
import { ImageData } from "../../images";

export const Name = "quote-page-water";
export type Response = {
  readonly searchData: GQLOps.SearchSingleQuery;
  readonly fritermCoilData: GQLOps.FrtCoilQuery;
  readonly productImage: ImageData | undefined;
  readonly headerImages:
    | {
        readonly density: ImageData | undefined;
        readonly tuv: ImageData | undefined;
        readonly ce: ImageData | undefined;
      }
    | undefined;
};

type Product = GQLOps.AllProductsQuery["products"][number];

export type CreatorProps = {
  readonly pageProps: Common.CataloguePageProps;
  readonly calculationResult: CoilResult;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly model: string;
  readonly productById: Record<string, Product>;
  readonly productImage: Common.BitmapImage | undefined;
  readonly configurationLink: string;
  readonly searchProductKey: string;
  readonly productKey: string;
  readonly internalUser: boolean;
  readonly accessories: ReadonlyArray<Accessories.Accessory>;
};

export type ReportType = ReportModule<CreatorProps, Response>;
export type AdapterType = ReportType["execute"];
export type CreatorType = ReportType["create"];

export type DataRow = {
  readonly textKey: TextKey;
  readonly value: string | undefined;
};
