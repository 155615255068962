import { PropertyValueSet } from "@promaster-sdk/property";
import { Accessories, CalculatorFrtCoil } from "..";
import * as Search from "../search";

export async function calculateProduct(
  calcData: CalculatorFrtCoil.CalculationData,
  properties: PropertyValueSet.PropertyValueSet,
  selection: Search.Selection,
  productVariantRow: Search.ProductVariantsRow,
  query: Search.Query,
  accessories?: ReadonlyArray<Accessories.Accessory>
): Promise<CalculatorFrtCoil.CalculationResult> {
  const input = CalculatorFrtCoil.mapInput(calcData, properties, selection);
  if (input.type === "Err") {
    return input;
  }
  return CalculatorFrtCoil.calculate(calcData, input.value, productVariantRow, query, accessories || []);
}
