import { FP } from "..";
import { TranslateFn, key } from "../lang-texts";
import { CalculatorErrorCodes, CustomItem, ErrorResult, ErrorStatus } from "./types";

export function roundValue(value: number): number {
  if (value < 1) {
    return Math.round(value * 1000) / 1000; // 3 decimals
  } else if (value < 10) {
    return Math.round(value * 100) / 100; // 2 decimals
  } else if (value < 100) {
    return Math.round(value * 10) / 10; // 1 decimals
  }
  return value;
}

export function createErrorResult(
  error_key: string,
  errorStatus: ErrorStatus,
  translate?: TranslateFn,
  productKey?: string
): FP.Result<ErrorResult, never> {
  // Convert the key string to the corresponding enum value

  // If the enum value is valid, return the corresponding CalculatorError

  const errorCode = CalculatorErrorCodes[error_key];
  if (!errorCode) {
    return FP.Err({
      errorCode: CalculatorErrorCodes.ERROR_IN_CALCULATION,
      errorMessage: translate ? translate(key(error_key, {}, productKey)) : error_key,
      errorStatus: errorStatus,
    });
  }

  return FP.Err({
    errorCode: errorCode,
    errorMessage: translate ? translate(key(error_key, {}, productKey)) : error_key,
    errorStatus: errorStatus,
  });
}
export function createCustomItem(item: CustomItem): CustomItem {
  const totalPrice = item.quantity * item.singlePrice;

  return { ...item, totalPrice: totalPrice };
}
