import React from "react";
import { GetFieldFormatFn, GetFieldFormatsFn, SelectableFormat } from "@ehb/shared";
import { ActiveUser } from "@ehb/shared/src/user";
import { getUnitLabel } from "@ehb/shared/src/units/unit-labels";
import { DropdownInput } from "./dropdown-input";

export type SetFieldFormatFn = (format: SelectableFormat) => void;

export function FieldFormatSelector({
  getFieldFormat,
  getFieldFormats,
  fieldName,
  setFieldFormat,
  alwaysShowDropdown = false,
  align = "left",
  activeUser,
}: {
  readonly getFieldFormat: GetFieldFormatFn;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly fieldName: string;
  readonly setFieldFormat: SetFieldFormatFn;
  readonly classNameOverride?: string;
  readonly containerClassNameOverride?: string;
  readonly alwaysShowDropdown?: boolean;
  readonly align?: "right" | "left";
  readonly activeUser: ActiveUser;
}): JSX.Element {
  if (fieldName === "currency") {
    const currency = activeUser.claims.currency.toUpperCase();
    return <div className={"form-input-readonly"}>{currency === "SEK" ? "SEK" : "EUR"}</div>;
  }

  const fieldFormats = getFieldFormats(fieldName);
  const format = getFieldFormat(fieldName);
  const options = fieldFormats.map((f) => ({
    label: getUnitLabel(f.unit.name),
    key: f.unit.name,
    value: f,
    valid: true,
  }));
  const selected = options.find((o) => o.key === format.unit.name);

  return alwaysShowDropdown || fieldFormats.length > 1 ? (
    <DropdownInput value={selected} options={options} align={align} onChange={(o) => setFieldFormat(o.value)} />
  ) : (
    <div className={"form-input-readonly"}>{getUnitLabel(format.unit.name)}</div>
  );
}
