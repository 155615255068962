import React from "react";
import { PropertyFilter, PropertyValue, PropertyValueSet } from "@promaster-sdk/property";
import { CreateBlobUrl } from "@ehb/client-infra";
import { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import { Tooltip, TooltipType } from "../tooltip";
import * as PropertyDef from "./property-selector-def";
import { DropdownInput, Option } from "../dropdown-input";
import { Checkbox } from "../checkbox";

export function DiscreteSelector({
  property,
  pvs,
  disabled,
  onChange,
  errorMessage,
  createBlobUrl,
  parentScrollToggle,
  filterPrettyPrint,
}: {
  readonly pvs: PropertyValueSet.PropertyValueSet;
  readonly property: PropertyDef.DiscretePropertyDef;
  readonly disabled: boolean;
  readonly onChange: (value: PropertyValue.PropertyValue) => void;
  readonly errorMessage?: string;
  readonly createBlobUrl: CreateBlobUrl;
  readonly parentScrollToggle: boolean | undefined;
  readonly filterPrettyPrint: FilterPrettyPrint;
}): JSX.Element {
  if (property.selectorType === "dropdown") {
    return (
      <DropdownSelector
        pvs={pvs}
        property={property}
        disabled={disabled}
        onChange={onChange}
        errorMessage={errorMessage}
        createBlobUrl={createBlobUrl}
        parentScrollToggle={parentScrollToggle}
        filterPrettyPrint={filterPrettyPrint}
      />
    );
  } else {
    return (
      <CheckBoxSelector
        pvs={pvs}
        property={property}
        disabled={disabled}
        onChange={(newValue) => onChange(PropertyValue.fromInteger(newValue))}
        errorMessage={errorMessage}
      />
    );
  }
}

function DropdownSelector({
  property,
  pvs,
  disabled,
  onChange,
  errorMessage,
  createBlobUrl,
  parentScrollToggle,
  filterPrettyPrint,
}: {
  readonly pvs: PropertyValueSet.PropertyValueSet;
  readonly property: PropertyDef.DiscretePropertyDef;
  readonly disabled: boolean;
  readonly onChange: (value: PropertyValue.PropertyValue) => void;
  readonly errorMessage?: string;
  readonly createBlobUrl: CreateBlobUrl;
  readonly parentScrollToggle: boolean | undefined;
  readonly filterPrettyPrint: FilterPrettyPrint;
}): JSX.Element {
  const options: ReadonlyArray<Option<PropertyDef.PropertyValueDef>> = property.items.map((i) => ({
    key: i.value ? PropertyValue.toString(i.value) : "",
    label: i.text,
    value: i,
    valid: PropertyFilter.isValid(pvs, i.validationFilter),
    imageUrl: i.image && createBlobUrl(i.image),
    item: i,
  }));
  const pv = PropertyValueSet.getValue(property.name, pvs);
  const selectedOption = pv && options.find((o) => o.value.value && PropertyValue.equals(o.value.value, pv));
  const optionMessage =
    selectedOption && !selectedOption.valid ? filterPrettyPrint(selectedOption.value.validationFilter) : "";
  return (
    <div className="flex items-center gap-2 text-ellipsis">
      <Tooltip
        className="w-full"
        tooltipType={TooltipType.Warning}
        text={optionMessage || errorMessage}
        content={
          <DropdownInput
            options={options}
            value={selectedOption}
            disabled={disabled}
            onChange={(o) => o.value.value && onChange(o.value.value)}
            parentScrollToggle={parentScrollToggle}
          />
        }
      />
    </div>
  );
}

function CheckBoxSelector({
  pvs,
  property,
  disabled,
  errorMessage,
  onChange,
}: {
  readonly pvs: PropertyValueSet.PropertyValueSet;
  readonly property: PropertyDef.DiscretePropertyDef;
  readonly disabled: boolean;
  readonly errorMessage?: string;
  readonly onChange: (value: number) => void;
}): JSX.Element {
  const checked = PropertyValueSet.getInteger(property.name, pvs) === 1;
  return (
    <div className="flex items-center">
      <Tooltip
        className="w-full"
        tooltipType={TooltipType.Warning}
        text={errorMessage}
        content={<Checkbox checked={checked} onClick={() => onChange(checked ? 0 : 1)} disabled={disabled} />}
      />
    </div>
  );
}
