import { PDFDocument } from "pdf-lib";
import { CalculateResult, CircuitDiagramTableRows, Type } from "../../calculator";
import { downloadFile } from "../../fetch";
import { createBlobUrlCreator } from "../../utils";
import { ActiveUser } from "../../user";

export async function generateCircuitDiagram(
  circuitDiagrams: ReadonlyArray<CircuitDiagramTableRows>,
  calculateResult: CalculateResult,
  user: ActiveUser
): Promise<PDFDocument | undefined> {
  if (calculateResult.type === Type.M || calculateResult.type === Type.ML) {
    return undefined;
  }
  // Circuit

  const circuitDiagram = circuitDiagrams.filter(
    (r) => r.amp_from <= calculateResult.current && r.amp_to >= calculateResult.current
  )[0];
  const circuitPdfUrl = createBlobUrlCreator(user.accessToken)(circuitDiagram.document, circuitDiagram.file_name);
  const circuitPdf = await downloadFile(circuitPdfUrl);
  const ciruitDiagramDoc = await PDFDocument.load(new Uint8Array(circuitPdf.buffer));
  return ciruitDiagramDoc;
}
