const pipeDiameterMM = 8.5;
const inactivePipePartmm = 60;
const ambientTempC = 50;
const zeroKelvin = 273;

const ambientTempTopowerOf4 = (ambientTempC + zeroKelvin) ** 4;

export function calculateHeatingRodSurfaceTemp(
  powerW: number,
  lengthMm: number,
  faceVelocityMs: number,
  voltage: number
): number {
  const nominalResistance = (voltage * voltage) / powerW;

  const resistanceHigh = nominalResistance * 1.09; // +9%

  const powerHigh = roundToSignificant((voltage * voltage) / resistanceHigh); // round to closest ten, check excel, more logic
  

  const surfacePowerHigh =
    powerHigh / (((Math.PI * pipeDiameterMM) / 10) * ((lengthMm - inactivePipePartmm - inactivePipePartmm) / 10));

  const coolingFactor = faceVelocityMs + 1;

  const rodArea = Math.PI * pipeDiameterMM * 0.001 * (lengthMm - inactivePipePartmm - inactivePipePartmm) * 0.001;

  const nominalSurfacePower =
    powerW / (((Math.PI * pipeDiameterMM) / 10) * ((lengthMm - inactivePipePartmm - inactivePipePartmm) / 10));
  const epsilon = nominalSurfacePower < 1.1 ? 2 : nominalSurfacePower < 1.5 ? 1.5 : nominalSurfacePower < 1.8 ? 1.2 : 1;

  const gamma =
    faceVelocityMs < 1.5
      ? 0.85
      : faceVelocityMs < 2.5
      ? 0.9
      : faceVelocityMs < 3.5
      ? 0.95
      : faceVelocityMs < 4.5
      ? 1
      : faceVelocityMs < 5.5
      ? 1.08
      : faceVelocityMs < 6.5
      ? 1.2
      : faceVelocityMs < 7.5
      ? 1.28
      : faceVelocityMs < 8.5
      ? 1.35
      : faceVelocityMs < 9.5
      ? 1.4
      : faceVelocityMs > 9.5
      ? 1.42
      : 0;

  const kTo4 = powerW / 0.0000000567 / (epsilon * gamma) / rodArea / coolingFactor + ambientTempTopowerOf4;

  const k = Math.sqrt(Math.sqrt(kTo4)) + surfacePowerHigh * surfacePowerHigh * surfacePowerHigh;

  const surfaceTemp = k - zeroKelvin;

  return roundToSignificant(surfaceTemp);
}

function roundToSignificant(value: number): number {
  if (value > 1000) {
    return Math.round(value * 10) / 10;
  } else if (value > 100) {
    return Math.round(value);
  } else if (value > 10) {
    // round to one decimal
    return round(value, 1);
  } else if (value > 1) {
    // round to two decimals
    return round(value, 2);
  } else {
    // Round to three decimals
    return round(value, 3);
  }
}

function round(value: number, precision: number): number {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
}
