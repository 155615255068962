import { ParagraphStyle } from "abstract-document/lib/abstract-document";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import * as React from "react";
import { AbstractDocJsx as ADX } from "abstract-document";
import * as Common from "../common";
import { CreatorType } from "./types";
import { Cell, row, table } from "../common/elements";
import { TextKey, texts } from "../../lang-texts";
import { CalculatorResult } from "../..";
import { getUnitLabel } from "../../units/unit-labels";

const { AbstractDoc, Section, Group, Paragraph, Image, render } = ADX;

export const create: CreatorType = ({
  pageProps,
  calculationResult: result,
  variant,
  model,
  productById,
  dimensionsImage,
}) => {
  const { getFieldFormat, translate, clientConfig } = pageProps;
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);

  const medium = PropertyValueSet.getInteger("medium", variant);
  const product = productById[clientConfig.promaster_search_product_id];

  const fluidName = translate(texts.property_value("fluid_type", variant, product.key));

  const unit = (fieldName: string): string => getUnitLabel(getFieldFormat(fieldName).unit.name);
  const value = (value: Amount.Amount<unknown>, fieldName: string): string =>
    CalculatorResult.renderValue(getFieldFormat, fieldName, value);

  const valueRow = (text: TextKey, resultValue: Amount.Amount<unknown>, fieldName: string): readonly Cell[] =>
    row(translate(text), unit(fieldName), value(resultValue, fieldName));

  const project = PropertyValueSet.getText("project", variant);
  const reference = PropertyValueSet.getText("reference", variant);
  const notes = PropertyValueSet.getText("notes", variant);

  const meta = table(
    [],
    [
      row(translate(texts.project), project),
      row(translate(texts.reference), reference),
      row(translate(texts.notes), notes),
    ]
  );

  const performance = table(row(translate(texts.performance_data)), [
    row(translate(texts.model), model),
    valueRow(texts.capacity, result.capacity, "power"),
  ]);

  const airSide = table(row(translate(texts.air_side_data)), [
    row(
      `${translate(texts.atmospheric_pressure)} / ${translate(texts.altitude)}`,
      `${unit("atmospheric_pressure")} / ${unit("altitude")}`,
      `${value(result.atmosphericPressure, "atmospheric_pressure")} / ${value(result.altitude, "altitude")}`
    ),

    valueRow(texts.volumetric_flow, result.airFlow, "air_flow"),
    valueRow(texts.mass_flow, result.airMassFlow, "air_mass_flow"),
    valueRow(texts.density, result.airDensity, "air_density"),
    valueRow(texts.surface_velocity, result.airVelocity, "air_velocity"),
    valueRow(texts.air_inlet_temperature, result.airInletTemperature, "air_temp"),
    valueRow(texts.inlet_humidity, result.airInletHumidity, "air_humidity"),
    valueRow(texts.air_outlet_temperature, result.airOutletTemperature, "air_temp"),
    valueRow(texts.outlet_humidity, result.airOutletHumidity, "air_humidity"),
    valueRow(texts.pressure_drop, result.airPressureDrop, "air_pressure_drop"),
  ]);

  // medium 1 = Water, medium 2 = DX
  const fluidSide =
    medium === 1
      ? table(row(translate(texts.fluid_side_data)), [
          row(translate(texts.fluid), "-", fluidName),
          valueRow(texts.mixture_rate, result.fluidMixtureRatio, "fluid_mixture_rate"),
          valueRow(texts.volumetric_flow, result.fluidFlow, "fluid_flow"),
          row(
            translate(texts.inlet_outlet_temperature),
            unit("fluid_temp"),
            `${value(result.fluidInletTemperature, "fluid_temp")} / ${value(
              result.fluidOutletTemperature,
              "fluid_temp"
            )}`
          ),

          valueRow(texts.mass_flow, result.fluidMassFlow, "fluid_mass_flow"),
          valueRow(texts.density, result.fluidDensity, "fluid_density"),
          valueRow(texts.velocity, result.fluidVelocity, "fluid_velocity"),
          valueRow(texts.pressure_drop, result.fluidPressureDrop, "fluid_pressure_drop"),
        ])
      : table(row(translate(texts.fluid_side_data)), [
          row(translate(texts.fluid), "-", fluidName),
          valueRow(texts.condensation_temperature, result.condensationTemperature, "fluid_temp"),
          valueRow(texts.superheating, result.superheating, "dx_temp"),
          valueRow(texts.subcooling, result.subcooling, "dx_temp"),
          valueRow(texts.pressure_drop, result.fluidPressureDrop, "fluid_pressure_drop"),
          valueRow(texts.mass_flow, result.fluidMassFlow, "fluid_mass_flow"),
        ]);

  const exchanger = table(row(translate(texts.heat_exchanger_data)), [
    valueRow(texts.tube_volume, result.tubeVolume, "tube_volume"),
    row(translate(texts.tube_material), "-", translate(result.tubeMaterial)),
    row(translate(texts.fin_material), "-", translate(result.finMaterial)),
  ]);

  const dimensions = table(row(translate(texts.dimensions)), []);

  const dimensionsImageResource = dimensionsImage ? Common.createImageResource(dimensionsImage) : undefined;

  const doc = render(
    <AbstractDoc styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.cataloguePage(pageProps)}>
        {meta}
        {performance}
        {airSide}
        {fluidSide}
        {exchanger}
        <Group keepTogether={true}>
          {dimensions}
          <Paragraph style={ParagraphStyle.create({})}>
            {dimensionsImageResource && <Image imageResource={dimensionsImageResource} width={300} height={300} />}
          </Paragraph>
        </Group>
      </Section>
    </AbstractDoc>
  );

  return doc;
};
