import { Vector3, vec3Dot, vec3Cross, vec3Sub, vec3Scale, vec3Normalize } from "./vector3";

export interface Plane3 {
  readonly normal: Vector3;
  readonly d: number;
}

export function plane3Create(normal: Vector3, d: number): Plane3 {
  return {
    normal: normal,
    d: d,
  };
}

export function plane3FromPointAndNormal(point: Vector3, normal: Vector3): Plane3 {
  const d = vec3Dot(normal, point);
  return {
    normal: normal,
    d: d,
  };
}

export function plane3FromPointAndDirections(point: Vector3, dir1: Vector3, dir2: Vector3): Plane3 {
  const normal = vec3Normalize(vec3Cross(dir1, dir2));
  const d = vec3Dot(normal, point);
  return {
    normal: normal,
    d: d,
  };
}

export function plane3FromTriangle(v0: Vector3, v1: Vector3, v2: Vector3): Plane3 {
  const d0 = vec3Sub(v1, v0);
  const d1 = vec3Sub(v2, v0);
  const normal = vec3Cross(d0, d1);
  const d = vec3Dot(normal, v0);
  return {
    normal: normal,
    d: d,
  };
}

export function plane3Side(p: Vector3, plane: Plane3): boolean {
  return vec3Dot(p, plane.normal) > plane.d;
}

export function plane3DistanceToPoint(p: Vector3, plane: Plane3): number {
  return Math.abs(vec3Dot(p, plane.normal) - plane.d);
}

export function plane3Flip(plane: Plane3): Plane3 {
  return {
    normal: vec3Scale(-1, plane.normal),
    d: -plane.d,
  };
}
