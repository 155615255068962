import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import * as Common from "../common";
import { CreatorType, QuoteDataRow } from "./types";
import { TranslateFn, texts } from "../../lang-texts";
import { createParagraphStyle } from "../common/standard-style";
import { createImageResource } from "../common";

const { AbstractDoc, Section, Paragraph, TextRun, Table, TableRow, TableCell, Image, HyperLink, Group, render } = ADX;

export const create: CreatorType = ({ pageProps, calculateRequest, quoteData, heaterImage, cableInletImage }) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const { translate } = pageProps;
  const heaterImageRes = heaterImage ? createImageResource(heaterImage) : undefined;
  const cableInletImageRes = cableInletImage ? createImageResource(cableInletImage) : undefined;
  const doc = render(
    <AbstractDoc styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.cataloguePage(pageProps)}>
        <Group keepTogether={true}>
          <Paragraph style={createParagraphStyle({ alignment: "Center" })}>
            <TextRun text={translate(texts.quote_header)} styleName="H1"></TextRun>
          </Paragraph>
          <Paragraph style={createParagraphStyle({ alignment: "Center" })}>
            <TextRun text={calculateRequest.quotationNumber} styleName="Strong"></TextRun>
          </Paragraph>
          <Paragraph
            style={AD.ParagraphStyle.create({
              position: "absolute",
              alignment: "Start",
              margins: AD.LayoutFoundation.create({ top: 30, left: 210 }),
            })}
          >
            {heaterImageRes && <Image imageResource={heaterImageRes} width={275} height={275} />}
          </Paragraph>
          <Paragraph
            style={AD.ParagraphStyle.create({
              position: "absolute",
              alignment: "Start",
              margins: AD.LayoutFoundation.create({ top: 225, left: 335 }),
            })}
          >
            {cableInletImageRes && <Image imageResource={cableInletImageRes} width={160} height={160} />}
          </Paragraph>

          <Table
            columnWidths={[500, Infinity]}
            style={AD.TableStyle.create({
              margins: AD.LayoutFoundation.create({ top: 50, right: 0, left: 0 }),

              position: "relative",
            })}
          >
            {quoteData.flatMap((row) => createQuoteRows(row, translate))}
          </Table>
        </Group>
      </Section>
    </AbstractDoc>
  );

  return doc;
};

function createQuoteRows(quoteDataRow: QuoteDataRow, translate?: TranslateFn): ReadonlyArray<JSX.Element> {
  const rowContent =
    quoteDataRow.key === "configuration_link" ? (
      <Paragraph>
        <TextRun text={quoteDataRow.translatedKey + ": "} styleName="Strong" />
        <HyperLink
          target={quoteDataRow.value}
          text={translate ? translate(texts.link) : "link"}
          style={Common.createTextStyle({ fontSize: 10, color: "blue" })}
        />
      </Paragraph>
    ) : (
      <Paragraph style={createParagraphStyle({ fontSize: 9, spacingBefore: 0, spacingAfter: 4, alignment: "Start" })}>
        {quoteDataRow.key === "no_accessories" ? (
          <TextRun text={quoteDataRow.translatedKey + " "} styleName="Strong" />
        ) : (
          <TextRun text={quoteDataRow.translatedKey + ": "} styleName="Strong" />
        )}
        <TextRun text={quoteDataRow.value} styleName="Normal" />
      </Paragraph>
    );

  const normalRow = (
    <TableRow>
      <TableCell
        style={{
          padding: { top: 0, bottom: 0, left: 0, right: 0 },
          type: "TableCellStyle",
          borders: { top: 0, bottom: 0, left: 0, right: 0 },
        }}
      >
        {rowContent}
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );

  if (quoteDataRow.style?.spaceBetweenRow) {
    const spacerRow = (
      <TableRow>
        <TableCell
          style={{
            padding: { top: 50, bottom: 0, left: 0, right: 0 },
            type: "TableCellStyle",
            borders: { top: 0, bottom: 0, left: 0, right: 0 },
          }}
        />
      </TableRow>
    );
    return [normalRow, spacerRow];
  } else {
    return [normalRow];
  }
}
