import { WeightTableRow } from "./get-tables";
import { GetWeightInput, GetWeightOutput, Model } from "./types";

export function calc_weight(
  widthMm: number,
  heightMm: number,
  depthMm: number,
  power: number,
  amperage: number,
  model: Model,
  weightTable: ReadonlyArray<WeightTableRow>
): number {
  const plateWeight = calc_weight_plate(widthMm, heightMm, depthMm);

  // TODO - VRA doesn't use weight correctly. API uses it correctly but not the client app.
  // const isClientSide = typeof window !== "undefined" ? true : false; IGNORE do same for client and api
  const VRAWRONGWEIGHT = model === Model["VRA-FU"];
  // fix for weight. Amperege is using ceil in calculation. New ehb show the result with 2 decimals. for.example 39.90
  const tempAmperage = Math.ceil(amperage);

  const weight = VRAWRONGWEIGHT
    ? weightTable[0]
    : weightTable.find((w) => w.minAmp < tempAmperage && tempAmperage < w.maxAmp) ||
      weightTable[weightTable.length - 1];

  let terminalWeight = 0;

  if (weight.terminalBlock === 16) {
    terminalWeight = 0.15;
  } else if (weight.terminalBlock === 35) {
    terminalWeight = 0.3;
  } else if (weight.terminalBlock === 70) {
    terminalWeight = 0.66;
  } else if (weight.terminalBlock === 95) {
    terminalWeight = 0.51;
  } else if (weight.terminalBlock === 150) {
    terminalWeight = 0.78;
  } else if (weight.terminalBlock === 240) {
    terminalWeight = 1;
  }

  //Kontaktvikt
  const kontaktorvikt = weight.contactors * 0.48;

  //Dvärgbrytarvikt
  const dbrytarvikt = weight.switches * 0.113;

  //Elementvikt
  const elementvikt = power * 0.38;

  //Kabelvikt
  const kabelvikt = weight.cable;

  //Påslagsvikt
  const paslag = terminalWeight + kontaktorvikt + dbrytarvikt + elementvikt + kabelvikt + 2;

  const totalvikt = plateWeight + paslag;

  return Math.ceil(totalvikt);
}

function calc_weight_plate(widthMm: number, heightMm: number, depthMm: number): number {
  return Math.ceil(
    (widthMm * 0.001 * (depthMm * 0.001) * 4 +
      heightMm * 0.001 * (depthMm * 0.001) * 3 +
      heightMm * 0.001 * 0.2 * 2 +
      depthMm * 0.001 * 0.2 * 3) *
      8
  );
}

export function getWeight(input: GetWeightInput): GetWeightOutput {
  const { widthMm, heightMm, depthMm, power, amperage, model } = input;
  const weightKg = calc_weight(widthMm, heightMm, depthMm, power, amperage, model, input.weightTable);
  return { weightKg };
}
