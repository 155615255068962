import { KeyParams } from "./texts";

/**
 * {paramName}
 */
export function replaceCurly(text: string, keyParams: KeyParams): string {
  return text.replace(/({.+?})/g, (curly) => {
    const curlyInner = curly.substring(1, curly.length - 1);
    const replacement = keyParams[curlyInner];
    if (replacement === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return `{MISSING TEXT PARAMETER: '${curlyInner}'}`;
    }
    return replacement.toString();
  });
}
