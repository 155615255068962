import React from "react";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Tooltip, TooltipType } from "../tooltip";
import * as PropertyDef from "./property-selector-def";
import { TextInput } from "../text-input";

export function TextSelector({
  property,
  pvs,
  disabled,
  onChange,
  errorMessage,
  notNumericMessage,
  isRequiredMessage,
}: {
  readonly pvs: PropertyValueSet.PropertyValueSet;
  readonly property: PropertyDef.TextPropertyDef;
  readonly disabled: boolean;
  readonly onChange: (value: string) => void;
  readonly errorMessage?: string;
  readonly notNumericMessage?: string;
  readonly isRequiredMessage?: string;
}): JSX.Element {
  const message = errorMessage || notNumericMessage || isRequiredMessage;
  return (
    <div>
      <Tooltip
        className="w-full"
        tooltipType={TooltipType.Warning}
        text={message}
        content={
          <TextInput
            value={PropertyValueSet.getText(property.name, pvs)}
            valid={!message}
            disabled={disabled}
            multiline={property.selectorType === "multiline"}
            onChange={onChange}
            debounceTime={350}
          />
        }
      />
    </div>
  );
}
