export interface X3DModel {
  readonly magicloudId: string;
  readonly a3dMesh: X3dMeshData | null | undefined;
  readonly dxfUrl: string | null | undefined;
  readonly revitUrls: ReadonlyArray<RevitUrl>;
  readonly dimensionsUrl: string | null | undefined;
}

export interface X3dMeshData {
  readonly id: string;
  readonly data: string;
}

export interface MagiCloudUrl {
  readonly magicloudId: string;
  readonly url: string | null | undefined;
}

export interface RevitUrl {
  readonly name: string;
  readonly url: string;
}

export interface RequestStatistics {
  readonly orderingCode?: string;
  readonly itemNumber?: string;
  readonly market?: string;
  readonly source?: string;
  readonly fileName?: string;
  readonly fileType?: string;
}
