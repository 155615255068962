import * as R from "ramda";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import * as Types from "./types";
import { FP, logDebug, logWarn } from "..";

export function generateVariantsForProperties(
  productProperties: Types.ProductProperties,
  generateProperties: ReadonlyArray<string>
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const propertyMap = FP.createMap(
    productProperties,
    (p) => p.name,
    (p) => p
  );

  const generatePropertiesMap = R.fromPairs(
    generateProperties.map((p) => [p, true] as R.KeyValuePair<string, boolean>)
  );
  const propertyValuesMap = R.fromPairs(
    productProperties.map(
      (p) => [p.name, p.values] as R.KeyValuePair<string, ReadonlyArray<Types.ProductPropertyValue>>
    )
  );
  const referencesMap = getPropertyReferencesMap(productProperties);
  const missingProperties = generateProperties.filter((p) => !propertyValuesMap[p]);
  if (missingProperties.length > 0) {
    logWarn("Generate properties that are not in properties: ", missingProperties);
    return [];
  }

  let properties = [...generateProperties];
  let newProperties = [...generateProperties];
  do {
    properties = newProperties;
    newProperties = [];
    for (const property of properties) {
      const references = referencesMap[property];
      if (references === undefined) {
        logWarn(property + " has no references", { productProperties });
        return [];
      }
      newProperties.push(property, ...references);
    }
    newProperties = R.uniq(newProperties);
  } while (newProperties.length > properties.length);

  let variants: Array<PropertyValueSet.PropertyValueSet> = [PropertyValueSet.Empty];
  while (properties.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    const propertyName = properties.find((p) => !referencesMap[p].find((pr) => !!properties.find((p2) => p2 === pr)));
    if (!propertyName) {
      logDebug("Circular dependencies in properties", properties);
      return [];
    }
    properties = properties.filter((p) => p !== propertyName);
    const property = propertyMap[propertyName];
    const values = propertyValuesMap[propertyName];
    if (values.length === 0) {
      continue;
    }
    const newVariants: Array<PropertyValueSet.PropertyValueSet> = [];
    for (const variant of variants) {
      // At this point, the only properties that can be missing from 'variant' are the global properties
      const validValues = values.filter((v) => PropertyFilter.isValid(variant, v.propertyFilter));
      const propertyValidForVariant = PropertyFilter.isValid(variant, property.visibilityFilter);
      if (property && propertyValidForVariant && generatePropertiesMap[propertyName]) {
        for (const value of validValues) {
          newVariants.push(PropertyValueSet.setInteger(propertyName, value.value, variant));
        }
      } else {
        const value =
          validValues.find((v) => property.defValues.find((d) => d.value.value === v.value)) ||
          validValues.find(() => true);
        if (value !== undefined) {
          newVariants.push(PropertyValueSet.setInteger(propertyName, value.value, variant));
        } else {
          newVariants.push(variant);
        }
      }
    }
    variants = newVariants;
  }
  return variants;
}

function getPropertyReferencesMap(productProperties: Types.ProductProperties): Record<string, ReadonlyArray<string>> {
  const referencesMap = R.fromPairs(
    productProperties.map(
      (p) =>
        [
          p.name,
          R.uniq([
            ...PropertyFilter.getReferencedProperties(p.visibilityFilter),
            ...R.unnest(p.values.map((v) => PropertyFilter.getReferencedProperties(v.propertyFilter))),
          ]),
        ] as R.KeyValuePair<string, Array<string>>
    )
  );
  return referencesMap;
}
