import { Routes, SharedState } from "@ehb/client-infra";
import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { getFilteredImagesTable } from "@ehb/shared/src/calculator";
import { isInternalUser } from "@ehb/shared/src/user";
import { PropertyValueSet as PVS } from "@promaster-sdk/property";
import { texts } from "@ehb/shared/src/lang-texts";
import {
  decodeCustomItemsFromPvs,
  encodeCustomItemsToPvs,
  encodeVariantPropertyValueSet,
} from "@ehb/shared/src/product-utils";
import { MainLocation } from "@ehb/client-infra/src/routes";
import { getItemProperties, itemIdQueryParam } from "@ehb/shared/src/project";
import { PropertiesSelector, Spinner, SvgQuestionMark, withTw } from "../../elements";
import {
  createValidationFilterForAirVolume,
  mapPropertiesToSelector,
} from "../../elements/properties-selector/property-selector-def";
import { State, Action, getSelectedProperties, getSelectedCustomItems, electricHeaterProductKey } from "./state";
import { ResultView } from "./result-view";
import { CustomItemList } from "./parts";
import { ProductProjectView } from "../../project-state/product-view";

const InfoOptionsDataParts = withTw(
  "div",
  "flex justify-center relative gap-4 block gap-10 text-xs flex-col lg:flex-row max-w-[1200px] ml-auto mr-auto"
);

const InfoPart = withTw("div", " mt-10");
const InfoSticky = withTw("div", "flex-row shrink relative lg:sticky top-4");
const InfoPartInner = withTw(
  "div",
  "flex-row top-4 lg:flex-col rounded border-slate-100 border mb-6 p-4 row-span-1 min-w-80"
);

const OptionPart = withTw("div", "min-w-[400px] w-full");

const DataPart = withTw("div", "items-center lg:sticky top-4");

export function View({
  state,
  sharedState,
  dispatch,
}: {
  readonly state: State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const { translate, activeUser, getFieldFormat, getFieldFormats } = sharedState;

  const dispatchProject = Dispatch.map(Action.DispatchProject, dispatch);

  const selectedProperties = getSelectedProperties(state);
  const customItems = getSelectedCustomItems(state);

  const propertySelectorDefs = React.useMemo(
    () =>
      mapPropertiesToSelector(
        activeUser,
        state.electricalHeaterProduct,
        selectedProperties,
        translate,
        createValidationFilterForAirVolume(selectedProperties)
      ),
    [state.electricalHeaterProduct, sharedState.selectedLanguage, PVS.toString(selectedProperties)]
  );

  const fullProperties = encodeCustomItemsToPvs(selectedProperties, customItems, sharedState.activeUser);

  if (!state.electricalHeaterProduct?.product?.key) {
    return <Spinner />;
  }

  const selectedItemId = state.productSelection.type === "project" ? state.productSelection.itemId : undefined;

  return (
    <InfoOptionsDataParts>
      <InfoPart>
        <InfoSticky>
          <InfoPartInner>
            <dt className="text-base font-semibold text-gray-900 flex flex-row gap-4 items-center">
              <SvgQuestionMark width={"40px"} fill={"#b2122d"} className="text-red-600" />
              {translate(texts.start_page_manual_heading)}
            </dt>
            <dd className="mt-2 text-base text-gray-600 min-w-[12rem] max-w-[24rem] w-full ">
              {translate(texts.start_page_manual)}
            </dd>
          </InfoPartInner>
          <ProductProjectView
            productKey={electricHeaterProductKey}
            productType={"electric"}
            properties={fullProperties}
            translate={translate}
            dispatch={dispatchProject}
            state={state.projectState}
            selectedItemId={selectedItemId}
            makeSelectionUrl={(i) => {
              if (i) {
                const itemPvs = getItemProperties(i);
                const itemCustomItems = decodeCustomItemsFromPvs(itemPvs);
                const query = encodeVariantPropertyValueSet(itemPvs, itemCustomItems, sharedState.activeUser);
                return Routes.buildMainUrl(MainLocation.ProductSelect(query));
              } else {
                return Routes.buildMainUrl(MainLocation.ProductSelect({}));
              }
            }}
            makeItemUrl={(i) => Routes.buildMainUrl(MainLocation.ProductSelect(i ? { [itemIdQueryParam]: i.id } : {}))}
          />
        </InfoSticky>
      </InfoPart>
      <OptionPart>
        <h1 className="text-lg text-center">{translate(texts.options)}</h1>
        <div className="min-w-[400px] w-full">
          <PropertiesSelector
            selectedProperties={selectedProperties}
            properties={propertySelectorDefs}
            onChange={(newSelectedProperties: PVS.PropertyValueSet, _propertyNames: ReadonlyArray<string>) => {
              dispatch(Action.SetSelectedPropertiesAndCalculate(newSelectedProperties, _propertyNames));
            }}
            onPropertyFormatChanged={(fieldName, unit, decimalCount) => {
              dispatch(Action.SetSelectedFormat(fieldName, { unit, decimalCount }));
            }}
            onPropertyFormatCleared={(fieldName) => {
              dispatch(Action.ClearFieldUnit(fieldName));
            }}
            activeUser={activeUser}
            productImages={getFilteredImagesTable(state.electricalHeaterProduct, selectedProperties, false)}
            translate={sharedState.translate}
            productKey={state.electricalHeaterProduct.product.key}
            getFieldFormat={getFieldFormat}
            getFieldFormats={getFieldFormats}
          />
        </div>
        {isInternalUser(activeUser.claims) ? (
          <CustomItemList customItems={customItems} dispatch={dispatch} translate={translate} />
        ) : null}
      </OptionPart>

      <div className="relative">
        <DataPart>
          <h1 className="text-lg text-center">{translate(texts.data)}</h1>
          <ResultView
            className="items-center gap-[5px] mt-[12px] w-[25rem] rounded border-slate-100 border p-4 shrink"
            translate={translate}
            dispatch={dispatch}
            state={state}
            sharedState={sharedState}
          ></ResultView>
          <div className="flex mt-4 ml-auto mr-auto lg:w-80 items-center rounded border-slate-100 border p-4">
            <div className="flex flex-col items-center w-full justify-center space-y-4">
              <h1 className="text-lg">{translate(texts.existing_code)}</h1>
              <div className="flex w-full space-x-4 items-center">
                <input
                  type="text"
                  className="form-input"
                  placeholder={translate(texts.existing_code) + "..."}
                  onChange={(e) => dispatch(Action.OnExsistingCodeValueChange(e.target.value))}
                />
                <button className="btn-primary" onClick={() => dispatch(Action.LookUpVariant())}>
                  {translate(texts.load)}
                </button>
              </div>

              <h1 className="text-lg text-danger">{state.errorExistingCode}</h1>
            </div>
          </div>

          {isInternalUser(activeUser.claims) ? (
            <div className="flex mt-4 ml-auto mr-auto w-96 md:w-80 lg:w-80 items-center rounded border-slate-100 border p-4">
              <div className="flex flex-col items-center w-full justify-center space-y-4 ">
                <h1 className=" text-lg">{translate(texts.manual_depth)}</h1>
                <div className="flex w-full p-2 space-x-4 items-center">
                  <input
                    min="0"
                    type="number"
                    className="form-input"
                    placeholder={translate(texts.manual_depth)}
                    onChange={(e) => dispatch(Action.OnExistingDepthValueChange(e.target.value))}
                    value={state.manualDepth}
                  />
                  <button className="btn-primary" onClick={() => dispatch(Action.ClearManualDepth())}>
                    {translate(texts.button_clear)}
                  </button>
                  <button className="btn-primary" onClick={() => dispatch(Action.SetManualDepth())}>
                    {translate(texts.button_set)}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </DataPart>
      </div>
    </InfoOptionsDataParts>
  );
}
