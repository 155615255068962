import { Dispatch, EffectManager } from "@typescript-tea/core";
import { home } from "./home";

// Based on https://package.elm-lang.org/packages/elm/core/latest/Task

export function createEffectManager(): EffectManager {
  return {
    home,
    mapCmd,
    mapSub,
    setup: () => () => undefined,
    onEffects,
    onSelfAction: (state) => state,
  };
}

// -- STATE

export interface State {}

// -- COMMANDS

export type Result<TError, TValue> = Ok<TValue> | Err<TError>;
export type Ok<TValue> = { readonly type: "Ok"; readonly value: TValue };
export type Err<TError> = { readonly type: "Err"; readonly error: TError };

export type MyCmd<A> = PromiseEffect<A, unknown, unknown>;

export type PromiseEffect<A, TError, TValue> = {
  readonly home: typeof home;
  readonly type: "Promise2";
  readonly promise: Promise<Result<TError, TValue>>;
  readonly gotResult: (result: Result<TError, TValue>) => A;
};

// Perform a promise that can never fail
export function perform<A, TValue>(
  resolved: (value: TValue) => A,
  promise: Promise<Result<never, TValue>>
): PromiseEffect<A, never, TValue> {
  return {
    home: "promise",
    type: "Promise2",
    promise,
    gotResult: (result: Result<never, TValue>) => {
      if (result.type === "Err") {
        throw new Error(
          `A promise effect with error of type never has failed. This should never happen. The result was: ${JSON.stringify(
            result
          )}`
        );
      }
      return resolved(result.value);
    },
  };
}

// Attempt a promise that can fail
export function attempt<A, TError, TValue>(
  gotResult: (result: Result<TError, TValue>) => A,
  promise: Promise<Result<TError, TValue>>
): PromiseEffect<A, TError, TValue> {
  return {
    home: "promise",
    type: "Promise2",
    promise,
    gotResult,
  };
}

// export function mapCmd<A1, A2>(actionMapper: (a: A1) => A2, cmd: MyCmd<A1>): MyCmd<A2> {
//   const { onfulfilled, onrejected } = cmd;
//   return {
//     ...cmd,
//     onfulfilled: (results) => actionMapper(onfulfilled(results)),
//     onrejected: (error) => actionMapper(onrejected(error)),
//   };
// }

export function mapCmd<A1, A2>(actionMapper: (a: A1) => A2, cmd: MyCmd<A1>): MyCmd<A2> {
  const { gotResult } = cmd;
  return {
    ...cmd,
    gotResult: (results) => actionMapper(gotResult(results)),
  };
}

// -- SUBSCRIPTIONS

export function mapSub<A1, A2>(__: (a: A1) => A2, _: never): never {
  throw new Error("Not implemented.");
}

// -- MANAGER

// export function onEffects<ActionApp>(
//   dispatchApp: Dispatch<ActionApp>,
//   _dispatchSelf: Dispatch<never>,
//   cmds: ReadonlyArray<MyCmd<ActionApp>>,
//   _: ReadonlyArray<never>,
//   state: State
// ): State {
//   for (const cmd of cmds) {
//     cmd.promise.then(
//       (value) => dispatchApp(cmd.onfulfilled(value)),
//       (reason) => cmd.onrejected(reason)
//     );
//   }
//   return state;
// }

export function onEffects<ActionApp>(
  dispatchApp: Dispatch<ActionApp>,
  _dispatchSelf: Dispatch<never>,
  cmds: ReadonlyArray<MyCmd<ActionApp>>,
  _: ReadonlyArray<never>,
  state: State
): State {
  for (const cmd of cmds) {
    cmd.promise.then((value) => dispatchApp(cmd.gotResult(value)));
  }
  return state;
}
