import { compile, Key } from "path-to-regexp";
import { PathParams } from "./parse-url";

export type BuildUrl<TLocation> = (location: TLocation) => string;

export type ParamsFromLocation<TLocation> = (location: TLocation) => PathParams;

export function createBuildUrl<TLocation>(
  path: string,
  paramsFromLocation: ParamsFromLocation<TLocation>,
  encode: (value: string, token: Key) => string = encodeURIComponent,
  validate: boolean = true
): BuildUrl<TLocation> {
  const toUrl = compile(path, { encode, validate });
  return (location: TLocation) => toUrl(paramsFromLocation(location)).replace(/\?$/, "");
}

// export function combineBuildUrl<TLocation>(urlBuilders: readonly BuildUrl<TLocation>[]): BuildUrl<TLocation> {
//   return (location: TLocation) => {
//     let totalUrl = "";
//     for (const ub of urlBuilders) {
//       totalUrl += ub(location as TLocation);
//     }
//     return totalUrl;
//   };
// }
