import { Vector3, vec3Create, vec3Add, vec3Sub, vec3Normalize, vec3Length } from "./vector3";
import { Scene } from "./scene";
import { bounds3CreateFromBoxes, bounds3GetMid } from "./bounds3";
import { object3dBoundingBox } from "./object3d";
import { Vector2 } from "./vector2";
import { matrix4CreateLookAt, matrix4Transform } from "./matrix4";
import { Ray3, ray3Create } from "./ray3";
import { Frustum3, frustum3Create } from "./frustum3";
import { Plane3, plane3FromPointAndDirections } from "./plane3";

export type Camera = PerspectiveCamera | OrthoCamera;

export interface PerspectiveCamera {
  readonly type: "PerspectiveCamera";
  readonly up: Vector3;
  readonly position: Vector3;
  readonly lookAt: Vector3;
  readonly aspect: number;
  readonly near: number;
  readonly far: number;
  readonly fov: number;
}

export interface OrthoCamera {
  readonly type: "OrthoCamera";
  readonly up: Vector3;
  readonly position: Vector3;
  readonly lookAt: Vector3;
  readonly aspect: number;
  readonly near: number;
  readonly far: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly bottom: number;
}

export function cameraCreatePerspective(
  position: Vector3,
  lookAt: Vector3,
  aspect: number,
  near: number,
  far: number,
  fov: number
): Camera {
  return {
    type: "PerspectiveCamera",
    up: vec3Create(0, 0, 1),
    position: position,
    lookAt: lookAt,
    aspect: aspect,
    near: near,
    far: far,
    fov: fov,
  };
}

export function cameraCreateOrtho(
  position: Vector3,
  lookAt: Vector3,
  aspect: number,
  near: number,
  far: number,
  left: number,
  right: number,
  top: number,
  bottom: number
): Camera {
  return {
    type: "OrthoCamera",
    up: vec3Create(0, 0, 1),
    position: position,
    lookAt: lookAt,
    aspect: aspect,
    near: near,
    far: far,
    left: left,
    right: right,
    top: top,
    bottom: bottom,
  };
}

export function cameraCreateRay(point: Vector2, camera: Camera): Ray3 {
  const x = 2 * point.x - 1;
  const y = 2 * (1 - point.y) - 1;
  const cameraMatrix = matrix4CreateLookAt(camera.position, camera.lookAt, vec3Create(0, 0, 1));
  switch (camera.type) {
    case "PerspectiveCamera": {
      const dx = x * Math.tan(((camera.fov / 2) * Math.PI) / 180) * camera.aspect;
      const dy = y * Math.tan(((camera.fov / 2) * Math.PI) / 180);
      const dir = vec3Create(dx, dy, -1);
      const direction = vec3Sub(matrix4Transform(dir, cameraMatrix), camera.position);
      const start = vec3Add(camera.position, direction);
      return ray3Create(start, direction);
    }
    case "OrthoCamera": {
      const px = x < 0 ? -x * camera.left : x * camera.right;
      const py = y < 0 ? -y * camera.bottom : y * camera.top;
      const pos = vec3Create(px, py, 0);
      const position = matrix4Transform(pos, cameraMatrix);
      const camDir = vec3Normalize(vec3Sub(camera.lookAt, camera.position));
      return ray3Create(position, camDir);
    }
    default:
      throw new Error("Unknown camera type");
  }
}

export function cameraCreateFrustum(points: ReadonlyArray<Vector2>, camera: Camera): Frustum3 {
  const planes: Array<Plane3> = [];
  for (let i = 0; i < points.length; ++i) {
    const p0 = points[i];
    const p1 = points[i === points.length - 1 ? 0 : i + 1];
    const ray0 = cameraCreateRay(p0, camera);
    const ray1 = cameraCreateRay(p1, camera);
    const dir1 = ray0.direction;
    const dir2 = vec3Sub(ray1.start, ray0.start);
    planes.push(plane3FromPointAndDirections(ray0.start, dir1, dir2));
  }
  return frustum3Create(planes);
}

export function cameraCreateOrthoFront(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingWidth = boundingBox.maximum.x - boundingBox.minimum.x;
  const boundingHeight = boundingBox.maximum.z - boundingBox.minimum.z;
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x, lookAt.y - distance, lookAt.z);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingWidth, boundingHeight);
}

export function cameraCreateOrthoBack(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingWidth = boundingBox.maximum.x - boundingBox.minimum.x;
  const boundingHeight = boundingBox.maximum.z - boundingBox.minimum.z;
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x, lookAt.y + distance, lookAt.z);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingWidth, boundingHeight);
}

export function cameraCreateOrthoTop(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number
): Camera {
  const corners = scene.objects.filter((o) => !o.id.startsWith("corner"));
  const boundingBox = bounds3CreateFromBoxes(corners.map((o) => object3dBoundingBox(o)));
  const boundingWidth = boundingBox.maximum.x - boundingBox.minimum.x;
  const boundingHeight = boundingBox.maximum.y - boundingBox.minimum.y;
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x, lookAt.y - 0.00001, lookAt.z + distance);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingWidth, boundingHeight);
}

export function cameraCreateOrthoBottom(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingWidth = boundingBox.maximum.x - boundingBox.minimum.x;
  const boundingHeight = boundingBox.maximum.y - boundingBox.minimum.y;
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x, lookAt.y - 0.00001, lookAt.z - distance);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingWidth, boundingHeight);
}

export function cameraCreateOrthoLeft(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingWidth = boundingBox.maximum.y - boundingBox.minimum.y;
  const boundingHeight = boundingBox.maximum.z - boundingBox.minimum.z;
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x + distance, lookAt.y, lookAt.z);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingWidth, boundingHeight);
}

export function cameraCreateOrthoRight(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingWidth = boundingBox.maximum.y - boundingBox.minimum.y;
  const boundingHeight = boundingBox.maximum.z - boundingBox.minimum.z;
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x - distance, lookAt.y, lookAt.z);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingWidth, boundingHeight);
}

export function cameraCreateOrthoSouthWest(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number,
  height: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingSize = vec3Length(vec3Sub(boundingBox.maximum, boundingBox.minimum));
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x - distance, lookAt.y - distance, lookAt.z + height);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingSize, boundingSize);
}

export function cameraCreateOrthoSouthEast(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number,
  height: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingSize = vec3Length(vec3Sub(boundingBox.maximum, boundingBox.minimum));
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x + distance, lookAt.y - distance, lookAt.z + height);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingSize, boundingSize);
}

export function cameraCreateOrthoNorthWest(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number,
  height: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingSize = vec3Length(vec3Sub(boundingBox.maximum, boundingBox.minimum));
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x - distance, lookAt.y + distance, lookAt.z + height);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingSize, boundingSize);
}

export function cameraCreateOrthoNorthEast(
  scene: Scene,
  near: number,
  far: number,
  aspect: number | undefined,
  distance: number,
  height: number
): Camera {
  const boundingBox = bounds3CreateFromBoxes(
    scene.objects.filter((o) => !o.id.startsWith("corner")).map((o) => object3dBoundingBox(o))
  );
  const boundingSize = vec3Length(vec3Sub(boundingBox.maximum, boundingBox.minimum));
  const lookAt = bounds3GetMid(boundingBox);
  const position = vec3Create(lookAt.x + distance, lookAt.y + distance, lookAt.z + height);
  return cameraCreateCoveringOrtho(near, far, aspect, position, lookAt, boundingSize, boundingSize);
}

export function cameraCreateCoveringOrtho(
  near: number,
  far: number,
  aspect: number | undefined,
  position: Vector3,
  lookAt: Vector3,
  boundingWidth: number,
  boundingHeight: number
): Camera {
  if (aspect === undefined) {
    return cameraCreateOrtho(
      position,
      lookAt,
      boundingWidth / boundingHeight,
      near,
      far,
      -boundingWidth * 0.5,
      boundingWidth * 0.5,
      boundingHeight * 0.5,
      -boundingHeight * 0.5
    );
  } else {
    const boundingAspect = boundingWidth / boundingHeight;
    const frustumWidth = boundingAspect > aspect ? boundingWidth : boundingHeight * aspect;
    const frustumHeight = boundingAspect < aspect ? boundingHeight : boundingWidth / aspect;
    return cameraCreateOrtho(
      position,
      lookAt,
      aspect,
      near,
      far,
      -frustumWidth * 0.5,
      frustumWidth * 0.5,
      frustumHeight * 0.5,
      -frustumHeight * 0.5
    );
  }
}
