export interface Vector2 {
  readonly x: number;
  readonly y: number;
}

export const vec2Zero: Vector2 = {
  x: 0,
  y: 0,
};

export function vec2Create(x: number, y: number): Vector2 {
  return {
    x: x,
    y: y,
  };
}

export function vec2Add(a: Vector2, b: Vector2): Vector2 {
  return {
    x: a.x + b.x,
    y: a.y + b.y,
  };
}

export function vec2Sub(a: Vector2, b: Vector2): Vector2 {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
  };
}

export function vec2Scale(a: Vector2, s: number): Vector2 {
  return {
    x: a.x * s,
    y: a.y * s,
  };
}

export function vec2Perp(a: Vector2): Vector2 {
  return {
    x: -a.y,
    y: a.x,
  };
}

export function vec2Flip(v: Vector2): Vector2 {
  return {
    x: -v.x,
    y: -v.y,
  };
}

export function vec2Dot(a: Vector2, b: Vector2): number {
  return a.x * b.x + a.y * b.y;
}

export function vec2Length(a: Vector2): number {
  return Math.sqrt(a.x * a.x + a.y * a.y);
}

export function vec2LengthSq(a: Vector2): number {
  return a.x * a.x + a.y * a.y;
}

export function vec2Normalize(a: Vector2): Vector2 {
  const length = vec2Length(a);
  if (length === 0) {
    return { x: 0, y: 0 };
  }
  return { x: a.x / length, y: a.y / length };
}

export function vec2Dist(a: Vector2, b: Vector2): number {
  return vec2Length(vec2Sub(a, b));
}

export function vec2Rotate(a: Vector2, angle: number): Vector2 {
  const sin = Math.sin(angle);
  const cos = Math.cos(angle);
  const x = a.x * cos - a.y * sin;
  const y = a.x * sin + a.y * cos;
  return vec2Create(x, y);
}
