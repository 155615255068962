import { EhbUnits } from "./units";

export const unitLabels: Record<keyof typeof EhbUnits, string> = {
  // Original EHB units
  Ampere: "A",
  Bar: "bar",
  Celsius: "°C",
  CubicMeter: "m³",
  CubicMeterPerHour: "m³/h",
  CubicMeterPerSecond: "m³/s",
  DecibelLw: "dB",
  Degrees: "°",
  GramPerKilogram: "g/kg",
  Hertz: "Hz",
  Hour: "h",
  Integer: "",
  Kelvin: "K",
  Kilogram: "kg",
  KilogramPerCubicMeter: "kg/m³",
  KilogramPerHour: "kg/h",
  KilogramPerKilogram: "kg/kg",
  KilogramPerSecond: "kg/s",
  KiloPascal: "kPa",
  KiloWatt: "kW",
  KiloWattHour: "kWh",
  KiloWattPerCubicMeterPerSecond: "kW/(m³/s)",
  LiterPerHour: "l/h",
  LiterPerSecond: "l/s",
  Meter: "m",
  MeterPerSecond: "m/s",
  Millimeter: "mm",
  NewtonMeter: "Nm",
  One: "",
  Pascal: "Pa",
  Percent: "%",
  PercentHumidity: "%",
  Radian: "Rad",
  RevolutionsPerMinute: "rpm",
  SquareMeter: "m²",
  StandardCubicMeterPerSecond: "Sm³/s",
  Text: "",
  Volt: "V",
  Watt: "W",
  WattPerCubicMeterPerSecond: "W/(m³/s)",
  Year: "Y",

  // All other units
  Becquerel: "Bq",
  Bit: "bit",
  Btu: "BTU",
  BtuPerCubicFeet: "BTU/ft³",
  BtuPerHour: "BTU/h",
  BtuPerHourPerFeetPerFahrenheit: "BTU/h/ft/°F",
  BtuPerHourPerSquareFeet: "BTU/h/ft²",
  BtuPerHourPerSquareFeetPerFahrenheit: "BTU/h/ft²/°F",
  BtuPerPoundLb: "BTU/lb",
  Candela: "cd",
  CelsiusDewPoint: "dp°C",
  CelsiusWet: "wb°C",
  CentiMeter: "cm",
  Coulomb: "C",
  CubicCentiMeter: "cm³",
  CubicFeet: "ft³",
  CubicFeetPerHour: "ACFH",
  CubicFeetPerMinute: "ACFM",
  CubicFeetPerMinutePerSquareFeet: "acfm/ft²",
  CubicFeetPerMinutePerSquareRootInchOfWaterColumn: "ft³/min/√in WC",
  CubicInch: "in³",
  CubicMeterPerSecondPerSquareMeter: "m³/s/m²",
  Day: "days",
  Decibel: "dB",
  Decimeter: "dm",
  DeciPascal: "dPa",
  DeltaCelsius: "°C",
  DeltaCelsiusDewPoint: "dp°C",
  DeltaFahrenheit: "°F",
  DeltaFahrenheitDewPoint: "dp°F",
  Fahrenheit: "°F",
  FahrenheitDewPoint: "dp°F",
  FahrenheitWet: "wb°F",
  Farad: "F",
  FeetOfWaterColumn: "ft WC",
  FeetPerMinute: "ft/min",
  FeetPerSecond: "ft/s",
  Foot: "ft",
  FrenchDegree: "°f",
  Gallon: "gal",
  GallonsPerHour: "gal/h",
  GallonsPerMinute: "gal/min",
  GallonsPerMinutePerTonCooling: "gpm/ton",
  GigaWatt: "GW",
  GigaWattHour: "GWh",
  Grain: "gr",
  GrainPerHour: "gr/h",
  GrainPerPoundLb: "gr/lb",
  GrainPerSecond: "gr/s",
  GrainPerSquareFeetHour: "gr/ft²h",
  GrainPerSquareFeetSecond: "gr/ft²s",
  Gram: "g",
  GramPerCubicCentiMeter: "g/cm³",
  GramPerHour: "g/h",
  GramPerKiloWattHour: "g/kWh",
  GramPerPoundLb: "g/lb",
  GramPerSecond: "g/s",
  GramPerSquareMeterHour: "g/m²h",
  GramPerSquareMeterSecond: "g/m²s",
  Gray: "Gy",
  HectoPascal: "hPa",
  Henry: "H",
  HorsePower: "hp",
  HumidityFactor: "r.H. factor",
  HundredCubicFeet: "100 ft³",
  HundredCubicFeetPerHour: "cch",
  Inch: "in",
  InchOfMercury: "in HG",
  InchOfWaterColumn: "in WC",
  Joule: "J",
  Katal: "kat",
  KelvinDewPoint: "dp°K",
  KelvinWet: "wb°K",
  KiloBtu: "MBTU",
  KiloBtuPerHour: "MBH",
  KilogramPerKiloWattHour: "kg/kWh",
  KilogramPerSquareMeterHour: "kg/m²h",
  KilogramPerSquareMeterSecond: "kg/m²s",
  KilogramSquareMeter: "kg·m²",
  Kilojoule: "kJ",
  KiloJoulePerCubicMeter: "kJ/m³",
  KilojoulePerKilogram: "kJ/kg",
  KilojoulePerKilogramCelsius: "kJ/kg°C",
  KilojoulePerKilogramKelvin: "kJ/kg°K",
  Kilometer: "km",
  KilometerPerHour: "km/h",
  KiloOhm: "kOhm",
  KiloVolt: "kV",
  KiloWattHourPerCubicMeter: "kWh/m³",
  KiloWattHourPerKilogram: "kWh/kg",
  KilowattPerCelsius: "kW/°C",
  KiloWattPerCubicFootPerMinute: "kW/ft³/min",
  KilowattPerKelvin: "kW/K",
  Liter: "L",
  LiterPerKiloWattHour: "l/kWh",
  LiterPerMinute: "l/m",
  LiterPerSecondPerKiloWatt: "l/s/kW",
  LiterPerSecondPerSquareMeter: "l/s/m²",
  LiterPerSecondPerSquareRootPascal: "l/s/√Pa",
  Lumen: "lm",
  Lux: "lx",
  MegaBtu: "MMBTU",
  Megajoule: "MJ",
  MegaWatt: "MW",
  MegaWattHour: "MWh",
  MeterPerHour: "m/h",
  MeterPerSquareSecond: "m/s²",
  Mile: "mi",
  MilesPerHour: "mph",
  MilliAmpere: "mA",
  MilliBar: "mbar",
  MilliGram: "mg",
  MilliGramCalciumPerLiter: "mg Ca²⁺/l",
  MilliGramHydrogenCarbonatePerLiter: "mg HCO₃⁻/l",
  MilliLiter: "ml",
  MilliSecond: "ms",
  MilliVolt: "mV",
  Minute: "min",
  Mole: "mol",
  Newton: "N",
  NewtonPerSquareMeter: "N/m²",
  Ohm: "Ω",
  OnePerBtu: "/BTU",
  OnePerCubicMeter: "/m³",
  OnePerGallon: "/gal",
  OnePerHour: "/h",
  OnePerHundredCubicFeet: "/100 ft³",
  OnePerJoule: "/J",
  OnePerKilogram: "/kg",
  OnePerKilojoule: "/kJ",
  OnePerKiloWattHour: "/kWh",
  OnePerLiter: "/l",
  OnePerMegaBtu: "/MMBTU",
  OnePerMegajoule: "/MJ",
  OnePerPoundLb: "/lb",
  OnePerSecond: "/s",
  OnePerTherm: "/Therm",
  PascalSecond: "Pa·s",
  PoundForce: "lb",
  PoundForcePerSquareFoot: "psf",
  PoundForcePerSquareInch: "psi",
  PoundLb: "lb",
  PoundLbPerHour: "lb/h",
  PoundLbPerKiloWattHour: "lb/kWh",
  PoundLbPerPoundLb: "lb/lb",
  PoundLbPerSecond: "lb/s",
  PoundLbPerSquareFeetHour: "lb/ft²h",
  PoundLbPerSquareFeetSecond: "lb/ft²s",
  PoundPerCubicFoot: "lb/ft³",
  PPM: "ppm",
  Rankine: "Rankine",
  RevolutionsPerHour: "rph",
  Second: "s",
  Siemens: "S",
  Sievert: "Sv",
  Slug: "slug",
  SlugPerCubicFeet: "slug/ft³",
  SlugPerHour: "slug/h",
  SlugPerSecond: "slug/s",
  SquareCentimeter: "cm²",
  SquareDecimeter: "dm²",
  SquareFeet: "ft²",
  SquareInch: "in²",
  SquareMillimeter: "mm²",
  SquareRootInchOfWaterColumn: "√in WC",
  SquareRootPascal: "√Pa",
  StandardCubicFeetPerMinute: "SCFM",
  StandardCubicFootPerMinutePerSquareFeet: "SCFM/ft²",
  StandardCubicMeterPerHour: "Sm³/h",
  StandardCubicMeterPerHourPerSquareMeter: "Sm³/m²h",
  StandardLiterPerSecond: "Sl/s",
  StandardLiterPerSecondPerSquareMeter: "Sl/m²s",
  Steradian: "sr",
  Tesla: "T",
  Therm: "Therm",
  TonCooling: "tons",
  Tonne: "t",
  VoltAmpere: "VA",
  WattHour: "Wh",
  WattPerMeterPerKelvin: "W/m/°K",
  WattPerSquareMeter: "W/m²",
  WattPerSquareMeterPerKelvin: "W/m²/°K",
  WattSecond: "Ws",
  Weber: "Wb",
  Week: "weeks",
  Yard: "yd",
};

export function getUnitLabel(unitName: string): string {
  return unitLabels[unitName as keyof typeof EhbUnits] || unitName;
}
