import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@ehb/client-infra";
import { Texts } from "@ehb/shared";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as Header from "../header";
import * as State from "./state";
import * as ProductSelect from "../pages/product-select";
import * as ProductSearch from "../pages/product-search";
import * as ProductCalculate from "../pages/product-calculate";
import * as SpecialCalculation from "../pages/special-calculation";
import * as StartPage from "../pages/start-page";
import * as Projects from "../pages/projects";
import { Spinner } from "../elements";

export function View({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  return (
    <div>
      <Header.View
        dispatch={Dispatch.map(State.Action.DispatchHeader, dispatch)}
        sharedState={sharedState}
        state={state.headerState}
      />
      <div className="m-5">
        <div className="float-right">{state.waitingForResponse && <Spinner />}</div>
        <MainContent state={state} sharedState={sharedState} dispatch={dispatch} />
      </div>
    </div>
  );
}

function MainContent({
  state,
  sharedState,
  dispatch,
}: {
  readonly state: State.State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<State.Action>;
}): JSX.Element {
  const currentLocation = state.location;

  switch (currentLocation.type) {
    case "StartPage": {
      return <StartPage.View state={state.startPageState} sharedState={sharedState} />;
    }
    case "ProductSelect": {
      if (!state.productSelectState) {
        return <div />;
      }
      return (
        <ProductSelect.View
          state={state.productSelectState}
          dispatch={Dispatch.map(State.Action.DispatchProductSelect, dispatch)}
          sharedState={sharedState}
        />
      );
    }

    case "ProductSearch": {
      if (!state.productSearchState) {
        return <div />;
      }
      return (
        <ProductSearch.View
          state={state.productSearchState}
          dispatch={Dispatch.map(State.Action.DispatchProductSearch, dispatch)}
          sharedState={sharedState}
        />
      );
    }

    case "ProductCalculate": {
      if (!state.productCalculateState) {
        return <div />;
      }
      return (
        <ProductCalculate.View
          state={state.productCalculateState}
          dispatch={Dispatch.map(State.Action.DispatchProductCalculate, dispatch)}
          sharedState={sharedState}
        />
      );
    }

    case "SpecialCalculation": {
      if (!state.specialCalculationState) {
        return <div />;
      }
      return (
        <SpecialCalculation.View
          state={state.specialCalculationState}
          dispatch={Dispatch.map(State.Action.DispatchSpecialCalculation, dispatch)}
          sharedState={sharedState}
        />
      );
    }

    case "Projects": {
      if (!state.projectsState) {
        return <div />;
      }
      return (
        <Projects.View
          state={state.projectsState}
          dispatch={Dispatch.map(State.Action.DispatchProjects, dispatch)}
          sharedState={sharedState}
        />
      );
    }

    default: {
      exhaustiveCheck(currentLocation, false);
      return <div>{sharedState.translate(Texts.texts.not_found_404)}</div>;
    }
  }
}
