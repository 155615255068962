import { PropertyFilter, PropertyValue } from "@promaster-sdk/property";
import { AnyQuantity } from "../uom";

export interface ProductProperty {
  readonly sortNo: number;
  readonly name: string;
  readonly group: string;
  readonly validationFilter: PropertyFilter.PropertyFilter;
  readonly visibilityFilter: PropertyFilter.PropertyFilter;
  readonly quantity: AnyQuantity;
  readonly defValues: ReadonlyArray<ProductPropertyDefValue>;
  readonly values: ReadonlyArray<ProductPropertyValue>;
}

export interface ProductPropertyValue {
  readonly sortNo: number;
  readonly value: number;
  readonly image: string | undefined;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
}

export interface ProductPropertyDefValue {
  readonly value: PropertyValue.PropertyValue;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
}

export type ProductProperties = ReadonlyArray<ProductProperty>;
