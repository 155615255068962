import { Vector3 } from "./vector3";
import { Plane3, plane3Side } from "./plane3";

export interface Frustum3 {
  readonly planes: ReadonlyArray<Plane3>;
}

export function frustum3Create(planes: ReadonlyArray<Plane3>): Frustum3 {
  return {
    planes: planes,
  };
}

export function frustum3ContainsPoint(point: Vector3, frustum: Frustum3): boolean {
  return frustum.planes.every((p) => plane3Side(point, p));
}
