import { AbstractDoc as AD } from "abstract-document";
import { Paragraph, TextRun } from "abstract-document/lib/abstract-document-jsx";
import * as R from "ramda";
import React from "react";
import { style } from "../style";

export function createTextStyle({
  bold,
  italic,
  fontSize,
  color,
  underline,
  mediumBold,
}: {
  readonly bold?: boolean;
  readonly italic?: boolean;
  readonly fontSize?: number;
  readonly color?: string;
  readonly underline?: boolean;
  readonly mediumBold?: boolean;
}): AD.TextStyle.TextStyle {
  return AD.TextStyle.create({
    underline,
    fontFamily: "Helvetica",
    fontSize,
    bold,
    mediumBold,
    italic,
    color,
  });
}

export function createStyles(
  tuples: ReadonlyArray<readonly [string, AD.Style.Style]>
): AD.Types.Indexer<AD.Style.Style> {
  return R.fromPairs(
    tuples.map(
      (s) => [AD.StyleKey.create(s[1].type, s[0]), s[1]] as R.KeyValuePair<AD.StyleKey.StyleKey, AD.Style.Style>
    )
  );
}

export function createParagraphStyle({
  bold = false,
  italic = false,
  fontSize,
  spacingBefore,
  spacingAfter,
  color,
  alignment,
  underline,
  mediumBold,
}: {
  readonly bold?: boolean;
  readonly italic?: boolean;
  readonly fontSize?: number;
  readonly spacingBefore?: number;
  readonly spacingAfter?: number;
  readonly color?: string;
  readonly alignment?: AD.ParagraphStyle.TextAlignment;
  readonly underline?: boolean;
  readonly mediumBold?: boolean;
}): AD.ParagraphStyle.ParagraphStyle {
  return AD.ParagraphStyle.create({
    margins: AD.LayoutFoundation.create({
      top: spacingBefore,
      bottom: spacingAfter ?? 2,
    }),
    alignment: alignment,
    textStyle: createTextStyle({ bold, italic, fontSize, color, underline, mediumBold }),
  });
}

export function headerTitleMain(
  text: string,
  spacingAfter?: number,
  fontSize?: number,
  mediumBold?: boolean
): React.ReactElement<{}> {
  return (
    <Paragraph
      style={createParagraphStyle({
        bold: mediumBold ? false : true,
        fontSize: fontSize ?? 14,
        spacingBefore: 0,
        spacingAfter: spacingAfter ?? 4,
        color: style.primaryColor,
        mediumBold: mediumBold ? true : false,
      })}
    >
      <TextRun text={text} />
    </Paragraph>
  );
}

export function headerTitle(text: string, spacingAfter?: number, fontSize?: number): React.ReactElement<{}> {
  return (
    <Paragraph
      style={createParagraphStyle({
        fontSize: fontSize ?? 10,
        spacingBefore: 0,
        spacingAfter: spacingAfter ?? 4,
        color: style.primaryColor,
      })}
    >
      <TextRun text={text} />
    </Paragraph>
  );
}

// export function fonts(font: FontData): AD.Types.Indexer<AD.Font.Font> {
//   if (!font.normal) {
//     return {
//       DaxlineOffcPro: AD.Font.create({
//         normal: "Helvetica",
//         bold: "Helvetica",
//         italic: "Helvetica",
//         boldItalic: "Helvetica",
//       }),
//     };
//   }
//   return {
//     DaxlineOffcPro: AD.Font.create(font),
//   };
// }

export function styles(): AD.Types.Indexer<AD.Style.Style> {
  return { ...standardStyles(), ...defaultStyles() };
}

export function stylesWithSmallText(): AD.Types.Indexer<AD.Style.Style> {
  return { ...standardStyles(), ...defaultStyles(7) };
}

function defaultStyles(fontSize: number = 9): AD.Types.Indexer<AD.Style.Style> {
  const paragraphStyle = createParagraphStyle({ fontSize, spacingBefore: 0, spacingAfter: 4 });
  const textStyle = createTextStyle({ fontSize });
  const tableStyle = AD.TableStyle.create();
  const tableCellStyle = AD.TableCellStyle.create();
  return {
    [AD.StyleKey.create(paragraphStyle.type, "Default")]: paragraphStyle,
    [AD.StyleKey.create(textStyle.type, "Default")]: textStyle,
    [AD.StyleKey.create(tableStyle.type, "Default")]: tableStyle,
    [AD.StyleKey.create(tableCellStyle.type, "Default")]: tableCellStyle,
  };
}

function standardStyles(): AD.Types.Indexer<AD.Style.Style> {
  const styles2 = createStyles([
    ["H1", createTextStyle({ fontSize: 16, color: style.primaryColor })],
    ["H1", createParagraphStyle({ fontSize: 16, spacingBefore: 15, spacingAfter: 7, color: style.primaryColor })],
    ["H2", createTextStyle({ fontSize: 14, color: style.primaryColor })],
    ["H2", createParagraphStyle({ fontSize: 14, spacingBefore: 15, spacingAfter: 7, color: style.primaryColor })],
    ["H3", createTextStyle({ fontSize: 12, color: style.primaryColor })],
    ["H3", createParagraphStyle({ fontSize: 12, spacingBefore: 8, spacingAfter: 7, color: style.primaryColor })],
    ["H4", createTextStyle({ fontSize: 10, color: style.primaryColor })],
    ["H4", createParagraphStyle({ fontSize: 10, spacingBefore: 10, spacingAfter: 7, color: style.primaryColor })],
    ["H5", createTextStyle({ fontSize: 9, color: style.primaryColor })],
    ["H5", createParagraphStyle({ fontSize: 9, spacingBefore: 10, spacingAfter: 7, color: style.primaryColor })],
    ["H6", createTextStyle({ bold: true, fontSize: 7, color: "black" })],
    ["H6", createParagraphStyle({ bold: true, fontSize: 7, spacingBefore: 10, spacingAfter: 7, color: "black" })],
    ["Emphasis", createTextStyle({ italic: true })],
    ["Emphasis", createParagraphStyle({ italic: true })],
    ["Strong", createTextStyle({ bold: true, fontSize: 10 })],
    ["Strong", createParagraphStyle({ bold: true, fontSize: 10 })],
    [
      "Subscript",
      {
        ...createTextStyle({}),
        subScript: true,
        verticalPosition: -0.65,
        fontScale: 0.7,
      },
    ],
    [
      "Superscript",
      {
        ...createTextStyle({}),
        superScript: true,
        verticalPosition: -0.1,
        fontScale: 0.7,
      },
    ],
    ["Description", createTextStyle({ italic: true, fontSize: 7 })],
    ["Description", createParagraphStyle({ italic: true, fontSize: 7, spacingBefore: 1, spacingAfter: 3 })],
  ]);
  return styles2;
}
