import { Amount } from "uom";
import { GetFieldFormatFn } from "../units";

export type ResultValue = Amount.Amount<unknown> | number | string | undefined | null;

export function renderValue(getFieldFormat: GetFieldFormatFn, fieldName: string, value: ResultValue): string {
  if (value === undefined || value === null) {
    return "-";
  } else if (typeof value === "number") {
    if (!Number.isFinite(value)) {
      return "-";
    }
    return value.toString();
  } else if (typeof value === "string") {
    return value;
  } else {
    const amount = value as Amount.Amount<unknown>;
    if (amount.unit === undefined) {
      return "-";
    }
    const format = getFieldFormat(fieldName, amount.unit.quantity);
    const floatValue = Amount.valueAs(format.unit, amount);
    if (floatValue === undefined || floatValue === null || !Number.isFinite(floatValue)) {
      return "-";
    }
    const decimals = format.decimalCount;
    const valueText = numberToString(floatValue, decimals);
    return valueText;
  }
}

export function getSortableValue(
  getFieldFormat: GetFieldFormatFn,
  fieldName: string,
  value: ResultValue
): number | string {
  if (value === undefined || value === null) {
    return 0;
  } else if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    return value;
  } else {
    const amount = value as Amount.Amount<unknown>;
    if (amount.unit === undefined) {
      return 0;
    }
    const format = getFieldFormat(fieldName, amount.unit.quantity);
    const floatValue = Amount.valueAs(format.unit, amount);
    return floatValue;
  }
}

//const thousandSeparator = /[^\d]/.exec((1000.0).toLocaleString())?.[0] || "";
const decimalSeparator = /[^\d]/.exec((1.5).toLocaleString())?.[0] || ".";

export function numberToString(num: number, decimals?: number): string {
  const localeStr = decimals === undefined ? num.toLocaleString() : roundDec(num, decimals).toLocaleString();
  if (!decimals) {
    return localeStr;
  } else {
    const numDecs = getNumDecimals(localeStr);
    const zeroesToAdd = decimals - numDecs;
    const hasDecSep = localeStr.includes(decimalSeparator);
    const length = localeStr.length + (hasDecSep ? 0 : 1) + zeroesToAdd;
    const withDecSep = hasDecSep ? localeStr : localeStr + decimalSeparator;
    return withDecSep.padEnd(length, "0");
  }
}

function roundDec(value: number, decimals: number = 0): number {
  return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);
}

export function getNumDecimals(str: string): number {
  const index = str.indexOf(decimalSeparator);
  if (index >= 0) {
    return str.length - index - 1;
  }
  return 0;
}
