/* eslint-disable @typescript-eslint/no-explicit-any */
import * as THREE from "three";
import { Color } from "./color";
import { Light } from "./scene";
import { Geometry, ManualMesh, TextMesh, X3dMesh, X3dMeshAlignment, StaticTextMesh } from "./mesh";
import { Material, BasicMaterial, SolidMaterial, TexturedMaterial, LineMaterial } from "./material";
import { Face3 } from "./face3";
import { Euler } from "./euler";
import { Vector3 } from "./vector3";
import { Vector2 } from "./vector2";
import { Camera } from "./camera";
import { OrbitCamera, orbitCameraCreateCovering } from "./orbit-camera";
import { praseX3d } from "./x3d-parser";

export function scaleThree(s: number): THREE.Vector3 {
  return new THREE.Vector3(s, s, s);
}

export function eulerThree(c: Euler): THREE.Euler {
  return new THREE.Euler(c.x, c.y, c.z, "ZYX");
}

export function colThree(c: Color): THREE.Color {
  return new THREE.Color(c.r, c.g, c.b);
}

export function vec2Three(v: Vector2): THREE.Vector2 {
  return new THREE.Vector2(v.x, v.y);
}

export function vec3Three(v: Vector3): THREE.Vector3 {
  return new THREE.Vector3(v.x, v.y, v.z);
}

export function face3Three(f: Face3): THREE.Face3 {
  return new THREE.Face3(f.v0, f.v1, f.v2);
}

export function lightThree(light: Light): THREE.DirectionalLight {
  const color = colThree(light.color);
  const threeLight = new THREE.DirectionalLight(color.getHex(), light.intensity);
  threeLight.position.copy(vec3Three(light.position));
  threeLight.target.position.copy(vec3Three(light.lookAt));
  return threeLight;
}

export function cameraThree(camera: Camera): THREE.Camera {
  const up = vec3Three(camera.up);
  const lookAt = vec3Three(camera.lookAt);
  const position = vec3Three(camera.position);
  switch (camera.type) {
    case "OrthoCamera": {
      const orthoCamera = new THREE.OrthographicCamera(
        camera.left,
        camera.right,
        camera.top,
        camera.bottom,
        -camera.far,
        camera.far
      );
      orthoCamera.up.copy(up);
      orthoCamera.position.copy(position);
      orthoCamera.lookAt(lookAt);
      return orthoCamera;
    }
    case "PerspectiveCamera": {
      const perspCamera = new THREE.PerspectiveCamera(camera.fov, camera.aspect, camera.near, camera.far);
      perspCamera.up.copy(up);
      perspCamera.position.copy(position);
      perspCamera.lookAt(lookAt);
      return perspCamera;
    }
    default:
      throw new Error("Unknown camera type");
  }
}

// eslint-disable-next-line functional/prefer-readonly-type
const meshCache: { [id: string]: THREE.Object3D } = {};

export function x3dMeshThree(mesh: X3dMesh): THREE.Object3D {
  const cacheKey = mesh.alignment + mesh.data.id;
  if (meshCache[cacheKey]) {
    return meshCache[cacheKey].clone();
  }
  const parser = new DOMParser();
  const x3dXml1 = parser.parseFromString(mesh.data.data, "application/xhtml+xml");
  const scene = praseX3d(THREE, x3dXml1);
  const optimized = optimizeAndAlignMesh(mesh.alignment, scene);
  meshCache[cacheKey] = optimized;
  return optimized.clone();
}

export function getCoveringCameraForX3dMesh(mesh: X3dMesh): OrbitCamera {
  const obj = x3dMeshThree(mesh);
  const boundingBox = new THREE.Box3().setFromObject(obj);
  const center = new THREE.Vector3();
  boundingBox.getCenter(center);
  const boundingShpere = new THREE.Sphere();
  boundingBox.getBoundingSphere(boundingShpere);
  const radius = boundingShpere.radius;
  return orbitCameraCreateCovering(center, radius * 1.5);
}

function optimizeAndAlignMesh(alignment: X3dMeshAlignment, mesh: THREE.Object3D): THREE.Object3D {
  const groupedByMaterial: { [hash: string]: Array<THREE.Mesh> } = {};
  for (const subMesh of mesh.children) {
    if (subMesh instanceof THREE.Mesh) {
      const hash = materialHash(subMesh.material);
      if (!hash) {
        continue;
      }
      if (groupedByMaterial[hash] === undefined) {
        groupedByMaterial[hash] = [];
      }
      groupedByMaterial[hash].push(subMesh);
    }
  }
  const newObject = new THREE.Object3D();
  let boundingBox: THREE.Box3 | undefined = undefined;
  for (const group of Object.values(groupedByMaterial)) {
    const geometry = new THREE.Geometry();
    for (const mesh of group) {
      if (mesh.geometry instanceof THREE.Geometry) {
        geometry.merge(mesh.geometry as THREE.Geometry, mesh.matrix);
      } else {
        newObject.add(mesh);
      }
    }
    geometry.computeBoundingBox();
    if (geometry.boundingBox) {
      if (boundingBox) {
        boundingBox.union(geometry.boundingBox);
      } else {
        boundingBox = geometry.boundingBox;
      }
    }
    const material = group[0].material;
    const newMesh = new THREE.Mesh(geometry, material);
    newObject.add(newMesh);
  }
  if (boundingBox) {
    const offset = getAlignmentOffset(alignment, boundingBox).negate();
    const matrix = new THREE.Matrix4();
    matrix.setPosition(offset);
    newObject.applyMatrix4(matrix);
    newObject.updateMatrix();
  }

  return newObject;
}

function getAlignmentOffset(alignment: X3dMeshAlignment, boundingBox: THREE.Box3): THREE.Vector3 {
  const center = new THREE.Vector3();
  boundingBox.getCenter(center);
  if (alignment === "Center") {
    return center;
  } else if (alignment === "Bottom") {
    return new THREE.Vector3(center.x, center.y, boundingBox.min.z);
  } else if (alignment === "Top") {
    return new THREE.Vector3(center.x, center.y, boundingBox.max.z);
  } else {
    return new THREE.Vector3(boundingBox.max.x, center.y, center.z);
  }
}

function materialHash(material: THREE.Material): string | undefined {
  if (material instanceof THREE.MeshPhongMaterial) {
    const color = colorHash(material.color);
    return JSON.stringify({ c: color, s: colorHash(material.specular), sh: material.shininess });
  }
  return undefined;
}

function colorHash(color: THREE.Color): string {
  return `${color.r}${color.g}${color.b}`;
}

export async function manualMeshThree(wireframe: boolean, mesh: ManualMesh): Promise<THREE.Object3D> {
  const threeGeometry = geometryThree(mesh.geometry, mesh.material);
  const threeMaterial = await materialThree(wireframe, mesh.material, mesh, threeGeometry);
  if (mesh.material.type === "Line") {
    return new THREE.LineSegments(threeGeometry, threeMaterial as any);
  } else {
    return new THREE.Mesh(threeGeometry, threeMaterial);
  }
}

export function textMeshThree(mesh: TextMesh): THREE.Object3D {
  const canvas = textCanvas(mesh);
  const texture = new THREE.CanvasTexture(canvas);
  const material = new THREE.SpriteMaterial({ map: texture });
  material.depthWrite = false;
  material.depthTest = false;
  const sprite = new THREE.Sprite(material);
  sprite.scale.set(canvas.width * 0.5, canvas.height * 0.5, 1);
  return sprite;
}

export function staticTextMeshThree(mesh: StaticTextMesh): THREE.Object3D {
  const canvas = textCanvas({ ...mesh, fontScale: mesh.fontScale * 0.5 });
  const texture = new THREE.CanvasTexture(canvas);
  texture.anisotropy = getMaxAnisotropy();
  texture.minFilter = THREE.NearestMipmapLinearFilter;
  const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
  material.depthWrite = false;
  material.depthTest = false;
  const geometry = new THREE.PlaneGeometry(canvas.width, canvas.height);
  return new THREE.Mesh(geometry, material);
}

export function textCanvas(mesh: TextMesh | StaticTextMesh): HTMLCanvasElement {
  const imageHeight = 64;
  const canvas = document.createElement("canvas");
  canvas.width = 2048;
  canvas.height = imageHeight;
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Could not get 2d context for canvas");
  }
  context.font = `${imageHeight * 0.8}px Helvetica`;
  context.textAlign = "center";
  context.textBaseline = "middle";
  const textWidth = context.measureText(mesh.text).width + 50;
  const imageWidth = 2 ** Math.ceil(Math.log2(textWidth));
  canvas.width = imageWidth;
  canvas.height = imageHeight;
  if (mesh.backgroundColor !== "transparent") {
    context.fillStyle = mesh.backgroundColor;
    context.fillRect(0.5 * (imageWidth - textWidth), 0, textWidth, imageHeight);
  }
  context.font = `${imageHeight * 0.8 * mesh.fontScale}px Helvetica`;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillStyle = mesh.textColor;
  context.fillText(mesh.text, imageWidth / 2, imageHeight / 2);
  return canvas;
}

export async function materialThree(
  wireframe: boolean,
  m: Material,
  mesh: ManualMesh,
  threeGeometry: THREE.BufferGeometry
): Promise<THREE.Material> {
  switch (m.type) {
    case "Basic":
      return basicMaterialThree(m);
    case "Solid":
      return solidMaterialThree(wireframe, m);
    case "Textured":
      return texturedMaterialThree(m, mesh, threeGeometry);
    case "Line":
      return lineMaterialThree(m);
    default:
      throw new Error("Unknown material type");
  }
}

export function basicMaterialThree(m: BasicMaterial): THREE.Material {
  const threeMaterial = new THREE.MeshBasicMaterial();
  threeMaterial.color = colThree(m.color);
  threeMaterial.side = THREE.DoubleSide;
  if (m.opacity < 1) {
    threeMaterial.transparent = true;
    threeMaterial.opacity = m.opacity;
  }
  if (m.overlay) {
    threeMaterial.depthWrite = false;
    threeMaterial.depthTest = false;
  }
  return threeMaterial;
}

export function solidMaterialThree(wireframe: boolean, m: SolidMaterial): THREE.Material {
  const threeMaterial = new THREE.MeshLambertMaterial();
  threeMaterial.color = colThree(m.color);
  (threeMaterial as any).flatShading = !m.smooth;
  threeMaterial.side = m.doubleSided ? THREE.DoubleSide : THREE.FrontSide;
  threeMaterial.wireframe = wireframe;
  if (m.opacity < 1) {
    threeMaterial.transparent = true;
    threeMaterial.opacity = m.opacity;
  }
  return threeMaterial;
}

export function texturedMaterialThree(
  m: TexturedMaterial,
  mesh: ManualMesh,
  threeGeometry: THREE.BufferGeometry
): Promise<THREE.Material> {
  return new Promise((resolve) => {
    const threeMaterial = new THREE.MeshBasicMaterial();
    const image = new Image();
    image.src = m.texture;
    const threeTexture = new THREE.Texture(image);
    threeTexture.anisotropy = 8;
    threeMaterial.map = threeTexture;
    const uvs = [];
    for (const faceUvs of mesh.geometry.faceVertexUvs) {
      for (const vertexUvs of faceUvs) {
        for (const uv of vertexUvs) {
          uvs.push(uv.x, uv.y);
        }
      }
    }
    threeGeometry.setAttribute("uv", new (THREE as any).Float32BufferAttribute(new Float32Array(uvs), 2));
    image.onload = () => {
      threeTexture.needsUpdate = true;
      resolve(threeMaterial);
    };
  });
}

export function lineMaterialThree(m: LineMaterial): THREE.Material {
  const threeMaterial = new THREE.LineDashedMaterial();
  threeMaterial.color = colThree(m.color);
  threeMaterial.linewidth = m.width;
  if (m.opacity < 1) {
    threeMaterial.transparent = true;
    threeMaterial.opacity = m.opacity;
  }
  if (m.overlay) {
    threeMaterial.depthWrite = false;
    threeMaterial.depthTest = false;
  }
  return threeMaterial;
}

export function geometryThree(g: Geometry, m: Material): THREE.BufferGeometry {
  const threeGeometry = new THREE.BufferGeometry();
  const vertices = new Array(g.vertices.length * 3);
  let offset = 0;
  for (const v of g.vertices) {
    vertices[offset++] = v.x;
    vertices[offset++] = v.y;
    vertices[offset++] = v.z;
  }
  threeGeometry.setAttribute("position", new THREE.Float32BufferAttribute(vertices, 3));

  if (m.type !== "Line") {
    const faces = new Array(g.faces.length * 3);
    offset = 0;
    for (const f of g.faces) {
      faces[offset++] = f.v0;
      faces[offset++] = f.v1;
      faces[offset++] = f.v2;
    }
    threeGeometry.setIndex(new THREE.Uint32BufferAttribute(faces, 1));
    threeGeometry.computeVertexNormals();
  }
  return threeGeometry;
}

// eslint-disable-next-line functional/no-let
let maxAnisotropy: number | undefined = undefined;
export function getMaxAnisotropy(): number {
  if (maxAnisotropy === undefined) {
    const renderer = new THREE.WebGLRenderer();
    maxAnisotropy = renderer.capabilities.getMaxAnisotropy();
    renderer.dispose();
    return maxAnisotropy || 0;
  } else {
    return maxAnisotropy;
  }
}
