import { Serialize, Unit, UnitMap } from "uom";
import { Units, unitLookup } from "uom-units";
import { DefaultFieldFormatTable } from "./query";
import { isNonNull } from "../utils";

export const EhbUnits = {
  // Original EHB units
  Text: Units.Text,
  Integer: Units.Integer,
  Millimeter: Units.Millimeter,
  CubicMeterPerSecond: Units.CubicMeterPerSecond,
  CubicMeterPerHour: Units.CubicMeterPerHour,
  LiterPerSecond: Units.LiterPerSecond,
  Pascal: Units.Pascal,
  KiloPascal: Units.KiloPascal,
  Bar: Units.Bar,
  Celsius: Units.Celsius,
  KilogramPerKilogram: Units.KilogramPerKilogram,
  GramPerKilogram: Units.GramPerKilogram,
  Percent: Units.Percent,
  One: Units.One,
  Ampere: Units.Ampere,
  Volt: Units.Volt,
  RevolutionsPerMinute: Units.RevolutionsPerMinute,
  MeterPerSecond: Units.MeterPerSecond,
  PercentHumidity: Units.PercentHumidity,
  KilogramPerSecond: Units.KilogramPerSecond,
  Kilogram: Units.Kilogram,
  KiloWatt: Units.KiloWatt,
  KiloWattPerCubicMeterPerSecond: Units.KiloWattPerCubicMeterPerSecond,
  WattPerCubicMeterPerSecond: Units.WattPerCubicMeterPerSecond,
  SquareMeter: Units.SquareMeter,
  CubicMeter: Units.CubicMeter,
  KilogramPerHour: Units.KilogramPerHour,
  DecibelLw: Units.DecibelLw,
  KilogramPerCubicMeter: Units.KilogramPerCubicMeter,
  Hertz: Units.Hertz,
  Hour: Units.Hour,
  Year: Units.Year,
  Kelvin: Units.Kelvin,
  KiloWattHour: Units.KiloWattHour,
  NewtonMeter: Units.NewtonMeter,
  StandardCubicMeterPerSecond: Units.StandardCubicMeterPerSecond,
  Degrees: Units.Degrees,
  Radian: Units.Radian,

  // All other units
  Becquerel: Units.Becquerel,
  Bit: Units.Bit,
  Btu: Units.Btu,
  BtuPerCubicFeet: Units.BtuPerCubicFeet,
  BtuPerHour: Units.BtuPerHour,
  BtuPerHourPerFeetPerFahrenheit: Units.BtuPerHourPerFeetPerFahrenheit,
  BtuPerHourPerSquareFeet: Units.BtuPerHourPerSquareFeet,
  BtuPerHourPerSquareFeetPerFahrenheit: Units.BtuPerHourPerSquareFeetPerFahrenheit,
  BtuPerPoundLb: Units.BtuPerPoundLb,
  Candela: Units.Candela,
  CelsiusDewPoint: Units.CelsiusDewPoint,
  CelsiusWet: Units.CelsiusWet,
  CentiMeter: Units.CentiMeter,
  Coulomb: Units.Coulomb,
  CubicCentiMeter: Units.CubicCentiMeter,
  CubicFeet: Units.CubicFeet,
  CubicFeetPerHour: Units.CubicFeetPerHour,
  CubicFeetPerMinute: Units.CubicFeetPerMinute,
  CubicFeetPerMinutePerSquareFeet: Units.CubicFeetPerMinutePerSquareFeet,
  CubicFeetPerMinutePerSquareRootInchOfWaterColumn: Units.CubicFeetPerMinutePerSquareRootInchOfWaterColumn,
  CubicInch: Units.CubicInch,
  CubicMeterPerSecondPerSquareMeter: Units.CubicMeterPerSecondPerSquareMeter,
  Day: Units.Day,
  Decibel: Units.Decibel,
  Decimeter: Units.Decimeter,
  DeciPascal: Units.DeciPascal,
  DeltaCelsius: Units.DeltaCelsius,
  DeltaCelsiusDewPoint: Units.DeltaCelsiusDewPoint,
  DeltaFahrenheit: Units.DeltaFahrenheit,
  DeltaFahrenheitDewPoint: Units.DeltaFahrenheitDewPoint,
  Fahrenheit: Units.Fahrenheit,
  FahrenheitDewPoint: Units.FahrenheitDewPoint,
  FahrenheitWet: Units.FahrenheitWet,
  Farad: Units.Farad,
  FeetOfWaterColumn: Units.FeetOfWaterColumn,
  FeetPerMinute: Units.FeetPerMinute,
  FeetPerSecond: Units.FeetPerSecond,
  Foot: Units.Foot,
  FrenchDegree: Units.FrenchDegree,
  Gallon: Units.Gallon,
  GallonsPerHour: Units.GallonsPerHour,
  GallonsPerMinute: Units.GallonsPerMinute,
  GallonsPerMinutePerTonCooling: Units.GallonsPerMinutePerTonCooling,
  GigaWatt: Units.GigaWatt,
  GigaWattHour: Units.GigaWattHour,
  Grain: Units.Grain,
  GrainPerHour: Units.GrainPerHour,
  GrainPerPoundLb: Units.GrainPerPoundLb,
  GrainPerSecond: Units.GrainPerSecond,
  GrainPerSquareFeetHour: Units.GrainPerSquareFeetHour,
  GrainPerSquareFeetSecond: Units.GrainPerSquareFeetSecond,
  Gram: Units.Gram,
  GramPerCubicCentiMeter: Units.GramPerCubicCentiMeter,
  GramPerHour: Units.GramPerHour,
  GramPerKiloWattHour: Units.GramPerKiloWattHour,
  GramPerPoundLb: Units.GramPerPoundLb,
  GramPerSecond: Units.GramPerSecond,
  GramPerSquareMeterHour: Units.GramPerSquareMeterHour,
  GramPerSquareMeterSecond: Units.GramPerSquareMeterSecond,
  Gray: Units.Gray,
  HectoPascal: Units.HectoPascal,
  Henry: Units.Henry,
  HorsePower: Units.HorsePower,
  HumidityFactor: Units.HumidityFactor,
  HundredCubicFeet: Units.HundredCubicFeet,
  HundredCubicFeetPerHour: Units.HundredCubicFeetPerHour,
  Inch: Units.Inch,
  InchOfMercury: Units.InchOfMercury,
  InchOfWaterColumn: Units.InchOfWaterColumn,
  Joule: Units.Joule,
  Katal: Units.Katal,
  KelvinDewPoint: Units.KelvinDewPoint,
  KelvinWet: Units.KelvinWet,
  KiloBtu: Units.KiloBtu,
  KiloBtuPerHour: Units.KiloBtuPerHour,
  KilogramPerKiloWattHour: Units.KilogramPerKiloWattHour,
  KilogramPerSquareMeterHour: Units.KilogramPerSquareMeterHour,
  KilogramPerSquareMeterSecond: Units.KilogramPerSquareMeterSecond,
  KilogramSquareMeter: Units.KilogramSquareMeter,
  Kilojoule: Units.Kilojoule,
  KiloJoulePerCubicMeter: Units.KiloJoulePerCubicMeter,
  KilojoulePerKilogram: Units.KilojoulePerKilogram,
  KilojoulePerKilogramCelsius: Units.KilojoulePerKilogramCelsius,
  KilojoulePerKilogramKelvin: Units.KilojoulePerKilogramKelvin,
  Kilometer: Units.Kilometer,
  KilometerPerHour: Units.KilometerPerHour,
  KiloOhm: Units.KiloOhm,
  KiloVolt: Units.KiloVolt,
  KiloWattHourPerCubicMeter: Units.KiloWattHourPerCubicMeter,
  KiloWattHourPerKilogram: Units.KiloWattHourPerKilogram,
  KilowattPerCelsius: Units.KilowattPerCelsius,
  KiloWattPerCubicFootPerMinute: Units.KiloWattPerCubicFootPerMinute,
  KilowattPerKelvin: Units.KilowattPerKelvin,
  Liter: Units.Liter,
  LiterPerHour: Units.LiterPerHour,
  LiterPerKiloWattHour: Units.LiterPerKiloWattHour,
  LiterPerMinute: Units.LiterPerMinute,
  LiterPerSecondPerKiloWatt: Units.LiterPerSecondPerKiloWatt,
  LiterPerSecondPerSquareMeter: Units.LiterPerSecondPerSquareMeter,
  LiterPerSecondPerSquareRootPascal: Units.LiterPerSecondPerSquareRootPascal,
  Lumen: Units.Lumen,
  Lux: Units.Lux,
  MegaBtu: Units.MegaBtu,
  Megajoule: Units.Megajoule,
  MegaWatt: Units.MegaWatt,
  MegaWattHour: Units.MegaWattHour,
  Meter: Units.Meter,
  MeterPerHour: Units.MeterPerHour,
  MeterPerSquareSecond: Units.MeterPerSquareSecond,
  Mile: Units.Mile,
  MilesPerHour: Units.MilesPerHour,
  MilliAmpere: Units.MilliAmpere,
  MilliBar: Units.MilliBar,
  MilliGram: Units.MilliGram,
  MilliGramCalciumPerLiter: Units.MilliGramCalciumPerLiter,
  MilliGramHydrogenCarbonatePerLiter: Units.MilliGramHydrogenCarbonatePerLiter,
  MilliLiter: Units.MilliLiter,
  MilliSecond: Units.MilliSecond,
  MilliVolt: Units.MilliVolt,
  Minute: Units.Minute,
  Mole: Units.Mole,
  Newton: Units.Newton,
  NewtonPerSquareMeter: Units.NewtonPerSquareMeter,
  Ohm: Units.Ohm,
  OnePerBtu: Units.OnePerBtu,
  OnePerCubicMeter: Units.OnePerCubicMeter,
  OnePerGallon: Units.OnePerGallon,
  OnePerHour: Units.OnePerHour,
  OnePerHundredCubicFeet: Units.OnePerHundredCubicFeet,
  OnePerJoule: Units.OnePerJoule,
  OnePerKilogram: Units.OnePerKilogram,
  OnePerKilojoule: Units.OnePerKilojoule,
  OnePerKiloWattHour: Units.OnePerKiloWattHour,
  OnePerLiter: Units.OnePerLiter,
  OnePerMegaBtu: Units.OnePerMegaBtu,
  OnePerMegajoule: Units.OnePerMegajoule,
  OnePerPoundLb: Units.OnePerPoundLb,
  OnePerSecond: Units.OnePerSecond,
  OnePerTherm: Units.OnePerTherm,
  PascalSecond: Units.PascalSecond,
  PoundForce: Units.PoundForce,
  PoundForcePerSquareFoot: Units.PoundForcePerSquareFoot,
  PoundForcePerSquareInch: Units.PoundForcePerSquareInch,
  PoundLb: Units.PoundLb,
  PoundLbPerHour: Units.PoundLbPerHour,
  PoundLbPerKiloWattHour: Units.PoundLbPerKiloWattHour,
  PoundLbPerPoundLb: Units.PoundLbPerPoundLb,
  PoundLbPerSecond: Units.PoundLbPerSecond,
  PoundLbPerSquareFeetHour: Units.PoundLbPerSquareFeetHour,
  PoundLbPerSquareFeetSecond: Units.PoundLbPerSquareFeetSecond,
  PoundPerCubicFoot: Units.PoundPerCubicFoot,
  PPM: Units.PPM,
  Rankine: Units.Rankine,
  RevolutionsPerHour: Units.RevolutionsPerHour,
  Second: Units.Second,
  Siemens: Units.Siemens,
  Sievert: Units.Sievert,
  Slug: Units.Slug,
  SlugPerCubicFeet: Units.SlugPerCubicFeet,
  SlugPerHour: Units.SlugPerHour,
  SlugPerSecond: Units.SlugPerSecond,
  SquareCentimeter: Units.SquareCentimeter,
  SquareDecimeter: Units.SquareDecimeter,
  SquareFeet: Units.SquareFeet,
  SquareInch: Units.SquareInch,
  SquareMillimeter: Units.SquareMillimeter,
  SquareRootInchOfWaterColumn: Units.SquareRootInchOfWaterColumn,
  SquareRootPascal: Units.SquareRootPascal,
  StandardCubicFeetPerMinute: Units.StandardCubicFeetPerMinute,
  StandardCubicFootPerMinutePerSquareFeet: Units.StandardCubicFootPerMinutePerSquareFeet,
  StandardCubicMeterPerHour: Units.StandardCubicMeterPerHour,
  StandardCubicMeterPerHourPerSquareMeter: Units.StandardCubicMeterPerHourPerSquareMeter,
  StandardLiterPerSecond: Units.StandardLiterPerSecond,
  StandardLiterPerSecondPerSquareMeter: Units.StandardLiterPerSecondPerSquareMeter,
  Steradian: Units.Steradian,
  Tesla: Units.Tesla,
  Therm: Units.Therm,
  TonCooling: Units.TonCooling,
  Tonne: Units.Tonne,
  VoltAmpere: Units.VoltAmpere,
  Watt: Units.Watt,
  WattHour: Units.WattHour,
  WattPerMeterPerKelvin: Units.WattPerMeterPerKelvin,
  WattPerSquareMeter: Units.WattPerSquareMeter,
  WattPerSquareMeterPerKelvin: Units.WattPerSquareMeterPerKelvin,
  WattSecond: Units.WattSecond,
  Weber: Units.Weber,
  Week: Units.Week,
  Yard: Units.Yard,
};

export type SelectableFormat = {
  readonly unit: Unit.Unit<unknown>;
  readonly decimalCount: number;
  readonly isDefault?: boolean;
};

export type GetFieldFormatsFn = (fieldName: string, quantity?: unknown) => ReadonlyArray<SelectableFormat>;

export type GetFieldFormatFn = (fieldName: string, quantity?: unknown) => SelectableFormat;

export type SelectedUnits = { readonly [fieldName: string]: string };

export function getFieldFormatsFn(
  defaultFieldFormatTable: DefaultFieldFormatTable,
  language: string
): GetFieldFormatsFn {
  const formatsByName = getFieldFormatsByName(defaultFieldFormatTable, language);
  const formatsByNameEn = getFieldFormatsByName(defaultFieldFormatTable, "en");
  return (fieldName: string, quantity?: string): ReadonlyArray<SelectableFormat> => {
    return formatsByName.get(fieldName) || formatsByNameEn.get(fieldName) || getQuantityUnitFormats(quantity);
  };
}

export function getFieldFormatFn(
  defaultFieldFormatTable: DefaultFieldFormatTable,
  language: string,
  selectedUnits: SelectedUnits
): GetFieldFormatFn {
  const formatsByName = getFieldFormatsByName(defaultFieldFormatTable, language);
  const formatsByNameEn = getFieldFormatsByName(defaultFieldFormatTable, "en");
  return (fieldName: string, quantity?: string): SelectableFormat => {
    const formats = formatsByName.get(fieldName) || [];
    const formatsEn = formatsByNameEn.get(fieldName) || [];
    const selectedUnitName = selectedUnits[fieldName];
    return (
      formats.find((f) => f.unit.name === selectedUnitName) ||
      formats.find((f) => f.isDefault) ||
      formats[0] ||
      formatsEn.find((f) => f.unit.name === selectedUnitName) ||
      formatsEn.find((f) => f.isDefault) ||
      formatsEn[0] ||
      getQuantityUnitFormats(quantity)[0]
    );
  };
}

function getFieldFormatsByName(
  defaultFieldFormatTable: DefaultFieldFormatTable,
  language: string
): ReadonlyMap<string, ReadonlyArray<SelectableFormat>> {
  const entries = defaultFieldFormatTable
    .filter((r) => r.language === language)
    .filter(isNonNull)
    .map((r): [string, SelectableFormat] => {
      return [
        r.field_name,
        {
          unit: Serialize.stringToUnit(r.unit, EhbUnitsLookup) as Unit.Unit<string>,
          decimalCount: r.decimal_count,
          isDefault: r.type === "default",
        },
      ];
    })
    .filter((r) => !!r[1].unit);
  const formatsByName = new Map<string, Array<SelectableFormat>>();
  for (const [fieldName, format] of entries) {
    const curr = formatsByName.get(fieldName) || [];
    curr.push(format);
    formatsByName.set(fieldName, curr);
  }
  return formatsByName;
}

function getQuantityUnitFormats(quantity: string | undefined): ReadonlyArray<SelectableFormat> {
  if (!quantity) {
    return [];
  }
  if (quantity === "DeltaTemperature") {
    const quantityUnits = Object.values(EhbUnits).filter((e) => e.quantity === "Temperature");
    return quantityUnits.map((u) => ({
      unit: { ...u, quantity: "DeltaTemperature" },
      decimalCount: 2,
      isDefault: false,
    }));
  } else {
    const quantityUnits = Object.values(EhbUnits).filter((e) => e.quantity === quantity);
    return quantityUnits.map((u) => ({
      unit: u,
      decimalCount: 2,
      isDefault: false,
    }));
  }
}

export type EhbUnitName = keyof typeof EhbUnits;

export const EhbUnitsLookup: UnitMap.UnitLookup = (unitString: string) => unitLookup(unitString);
