import * as R from "ramda";
import { Amount } from "uom";
import { warn } from "console";
import { Quantity, Units } from "uom-units";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { CalculatorFrtCoil, Search } from "..";
import { CoilResult } from "../calculator-frt-coil";
import { AnyQuantity } from "../uom";

export function calculationWithinTargetThreshold(
  res: CalculatorFrtCoil.CalculationResult | undefined,
  properties: PropertyValueSet.PropertyValueSet,
  searchCalculationLimits: Search.SearchCalculationLimitsTable,
  searchCalculationMargin: Search.SearchCalculationMarginTable
): boolean {
  if (!res || res.type === "Err") {
    return false;
  }

  const limits = searchCalculationLimits.filter((s) => PropertyFilter.isValid(properties, s.property_filter));
  const margin = searchCalculationMargin.find((s) => PropertyFilter.isValid(properties, s.property_filter));

  if (!margin) {
    warn("Missing calculation margin target");
    return true;
  }

  return limits.every((l) => {
    const propertyValue = PropertyValueSet.getValue(l.property || "", properties);
    const resultValue = res.value[(l.result_field || "") as keyof CoilResult];

    const marginAmount = PropertyValueSet.getAmount(margin.property || "", properties);

    if (!marginAmount) {
      warn(`Missing margin property ${margin.property} from PVS`);
      return false;
    }

    if (margin.operation === "percent") {
      const marginPercent = Amount.valueAs(Units.One, marginAmount as Amount.Amount<Quantity.Dimensionless>);

      const upperMargin = Amount.times(propertyValue.value as Amount.Amount<AnyQuantity>, marginPercent + 1);
      const lowerMargin = Amount.times(propertyValue.value as Amount.Amount<AnyQuantity>, 1 - marginPercent);
      return (
        Amount.greaterOrEqualTo(resultValue as Amount.Amount<AnyQuantity>, lowerMargin) &&
        Amount.lessOrEqualTo(resultValue as Amount.Amount<AnyQuantity>, upperMargin)
      );
    } else {
      const upperMargin = Amount.plus(
        propertyValue.value as Amount.Amount<AnyQuantity>,
        marginAmount as Amount.Amount<Quantity.Temperature>
      );
      const lowerMargin = Amount.minus(
        propertyValue.value as Amount.Amount<AnyQuantity>,
        marginAmount as Amount.Amount<Quantity.Temperature>
      );
      return (
        Amount.greaterOrEqualTo(resultValue as Amount.Amount<AnyQuantity>, lowerMargin) &&
        Amount.lessOrEqualTo(resultValue as Amount.Amount<AnyQuantity>, upperMargin)
      );
    }
  });
}

export function fixedAirflowFilter(
  noErrorRows: readonly Search.ResultRow[],
  matches: readonly Search.Match[],
  properties: PropertyValueSet.PropertyValueSet
): readonly Search.ResultRow[] {
  const matchMap = new Map(matches.map((m) => [m.selectionKey, m]));

  const rows = noErrorRows.filter((r) => matchMap.get(r.selectionKey)?.selection.fixed_airflow === "");
  const fixedRows = noErrorRows.filter((r) => matchMap.get(r.selectionKey)?.selection.fixed_airflow !== "");

  const groups = R.groupBy((r) => r.group, fixedRows);

  for (const group of R.keys(groups)) {
    const productRows = groups[group];
    const airflowAmount = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airflow", properties);
    const airflowCmph = Amount.valueAs(Units.CubicMeterPerHour, airflowAmount!);

    const exactAirflow = productRows.find((r) => {
      const fixedAirflow = Number(matchMap.get(r.selectionKey)!.selection.fixed_airflow);

      return fixedAirflow === airflowCmph;
    });

    if (exactAirflow) {
      rows.push(exactAirflow);
      continue;
    }

    const lowerBoundFixed = boundFixedAirflow(productRows, "min", matchMap, airflowCmph);
    const upperBoundFixed = boundFixedAirflow(productRows, "max", matchMap, airflowCmph);

    if (lowerBoundFixed) {
      const fixedAirflow = matchMap.get(lowerBoundFixed.selectionKey)?.selection.fixed_airflow;
      if (fixedAirflow && airflowCmph && Number(fixedAirflow) > airflowCmph * 0.9) {
        // 10% margin
        rows.push(lowerBoundFixed);
      }
    }

    if (upperBoundFixed) {
      const fixedAirflow = matchMap.get(upperBoundFixed.selectionKey)?.selection.fixed_airflow;
      if (fixedAirflow && airflowCmph && Number(fixedAirflow) < airflowCmph * 1.1) {
        rows.push(upperBoundFixed);
      }
    }
  }

  return rows;
}

function boundFixedAirflow(
  rows: readonly Search.ResultRow[],
  bound: "min" | "max",
  matchMap: ReadonlyMap<string, Search.Match>,
  airflowCmph: number
): Search.ResultRow | undefined {
  const filtered = rows.filter((r) => {
    const fixedAirflow = Number(matchMap.get(r.selectionKey)!.selection.fixed_airflow);

    return bound === "min" ? fixedAirflow < airflowCmph : fixedAirflow > airflowCmph;
  });

  if (filtered.length === 0) {
    return undefined;
  }

  return filtered.reduce((a, b) => {
    const a_airflow = matchMap.get(a.selectionKey)?.selection.fixed_airflow;
    const b_airflow = matchMap.get(b.selectionKey)?.selection.fixed_airflow;

    if (a_airflow === "" || a_airflow === undefined) {
      return b;
    }
    if (b_airflow === "" || b_airflow === undefined) {
      return a;
    }

    if (bound === "min") {
      return a_airflow > b_airflow ? a : b;
    } else {
      return a_airflow < b_airflow ? a : b;
    }
  });
}
