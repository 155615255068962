import * as ReactDOM from "react-dom";
import { EffectManager, Program } from "@typescript-tea/core";
import { appInfo } from "@ehb/shared";
import {
  HttpEffectManager,
  NavigationEffectManager,
  OidcEffectManager,
  SessionStorage,
  LocalStorageEffectManager,
  PromiseEffectManager,
} from "@ehb/client-infra";
import * as Root from "./root";
import * as Main from "./main";
import { registerFontAwesomeIcons } from "./elements";
import { clientConfig } from "./client-config";
import { initSentry } from "./sentry";

initSentry(clientConfig.dsn, appInfo.pipelineId, clientConfig.env);
registerFontAwesomeIcons();
// Run the program
const effectManagers: readonly EffectManager[] = [
  PromiseEffectManager.createEffectManager(),
  NavigationEffectManager.createEffectManager<Root.Action>(Root.Action.UrlChanged, Root.Action.UrlRequested),
  OidcEffectManager.createOidcEffectManager(),
  HttpEffectManager.createEffectManager<Root.Action>((httpStatus) =>
    Root.Action.DispatchMain(Main.Action.HttpStateChanged(httpStatus))
  ),
  SessionStorage.createEffectManager(),
  LocalStorageEffectManager.createEffectManager(),
];

const rootProgram: Program<NavigationEffectManager.Url, Root.State, Root.Action, JSX.Element> = {
  init: Root.init,
  update: Root.update,
  view: Root.view,
  subscriptions: Root.subscriptions,
};

const rootElement = document.getElementById("root");
const render = (view: JSX.Element): void => ReactDOM.render(view, rootElement);
Program.run(rootProgram, NavigationEffectManager.getCurrentUrl(), render, effectManagers);
