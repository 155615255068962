import gql from "graphql-tag";
import { Cmd } from "@typescript-tea/core";
import { ctorsUnion, CtorsUnion } from "ctors-union";
import { allProductsQuery, LangText_ModulesTextsFragment } from "@ehb/shared/src/graphql-queries";
import {
  defaultFieldFormatFragment,
  GetFieldFormatFn,
  GetFieldFormatsFn,
  getFieldFormatFn,
  getFieldFormatsFn,
  SelectedUnits,
} from "@ehb/shared/src/units";
import { createTranslateFn } from "@ehb/shared/src/lang-texts";
import { propertyModulesFragment } from "@ehb/shared/src/graphql-queries/property-fragments";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  NavigationEffectManager as Navigation,
  SharedState,
  Routes,
  Route,
  OidcEffectManager,
  graphQLProductQueryWithAuth,
  graphQLQueryWithAuth,
} from "@ehb/client-infra";
import { Texts, User } from "@ehb/shared";
import * as Main from "../main";
import { userManagerSettings } from "../user-manager-settings";
import * as GQLOps from "../generated/generated-operations";
import { clientConfig } from "../client-config";
import { setUser } from "../sentry";

export const rootViewQuery = gql`
  query RootView($language: String!, $metaProductId: ID!) {
    products: products {
      key
      modules {
        properties {
          property {
            ...propertyModule
          }
        }
        ...LangText_ModulesTexts
      }
    }
    meta: product(id: $metaProductId) {
      modules {
        custom_tables {
          ...defaultFieldFormat
        }
      }
    }
  }
  ${propertyModulesFragment}
  ${LangText_ModulesTextsFragment}
  ${defaultFieldFormatFragment}
`;

export type State =
  | ErrorState
  | LoadingUserSettingsState
  | LoadingDataState
  | WaitingForUserSessionState
  | LoggedInState
  | LoggedOutState
  | UserExpired
  | UserNeedApproval;

export type UserExpired = {
  readonly type: "UserExpired";
};

export type UserNeedApproval = {
  readonly type: "UserNeedApproval";
};

export type LoadingUserSettingsState = {
  readonly type: "LoadingUserSettingsState";
  readonly activeUser: User.ActiveUser;
  readonly originalUrl: string;
};

export type LoadingDataState = {
  readonly type: "LoadingDataState";
  readonly activeUser: User.ActiveUser;
  readonly originalUrl: string;
  readonly selectedLanguage: string;
  readonly metaProductResponse: GQLOps.RootViewQuery | undefined;
  readonly allProductsResponse: GQLOps.AllProductsQuery | undefined;
};

type Product = GQLOps.AllProductsQuery["products"][number];

export type LoggedInState = {
  readonly type: "LoggedInState";
  readonly activeUser: User.ActiveUser;
  readonly metaProductResponse: GQLOps.RootViewQuery;
  readonly mainState: Main.State | undefined;
  readonly urlMatch: Route.UrlMatch<Routes.RootLocation> | undefined;
  readonly selectedLanguage: string;
  readonly selectedUnits: { readonly [key: string]: string };
  readonly translate: Texts.TranslateFn;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly productById: Record<string, Product>;
  readonly productByKey: Record<string, Product>;
  readonly openProjectId: string | undefined;
};

export type WaitingForUserSessionState = {
  readonly type: "WaitingForUserSessionState";
  readonly urlPath: string;
  readonly urlQuery: string;
};

export type LoggedOutState = {
  readonly type: "LoggedOutState";
};

type RedirectState = { readonly redirectUrl: string | undefined };

export type ErrorState = {
  readonly type: "ErrorState";
  readonly reason: string;
};

const openProjectIdKey = "open_project_id";

export const Action = ctorsUnion({
  UrlChanged: (url: Navigation.Url) => ({ url }),
  UrlRequested: (urlRequest: Navigation.UrlRequest) => ({ urlRequest }),
  UserSessionChanged: (user: OidcEffectManager.User | undefined) => ({ user }),
  // UserSettingsRecieved: (response: GQLOps.GetUserSettingsQuery) => ({
  //   response,
  // }),
  MetaProductRecieved: (response: GQLOps.RootViewQuery) => ({
    response,
  }),
  AllProductsRecieved: (response: GQLOps.AllProductsQuery) => ({
    response,
  }),
  AccessTokenRefreshed: (user: OidcEffectManager.User) => ({ user }),
  DispatchMain: (action: Main.Action) => ({ action }),
  Logout: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function init(url: Navigation.Url): readonly [State, Cmd<Action>?] {
  const initialUrl = url.path + (url.query ?? "");
  const urlMatch = Routes.parseUrl(initialUrl);
  if (
    urlMatch === undefined ||
    (urlMatch.location.type !== "LoginCallback" && urlMatch.location.type !== "LoggedOut")
  ) {
    // Since this is the init() function we never have a user in our state at this point,
    // so the only thing we can do is to try to login which will either result in a user being
    // found directly (becuase we were already have a token in local storage), or a redirect to the login server
    // If we are already logged in we will have our user session subscription triggered.
    // If we are nog logged in then we will be redirected to the login server.
    // Use the current url as the state to save in the redirect round-trip
    const redirectState: RedirectState = { redirectUrl: initialUrl };
    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcEffectManager.login(userManagerSettings, redirectState),
    ];
  }

  if (urlMatch.location.type === "LoginCallback") {
    // We got the login callback, let's process it and if successful the subscription will get a user session

    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcEffectManager.processSigninCallback(userManagerSettings),
    ];
  }

  // User logged out and was redirected to our application
  if (urlMatch.location.type === "LoggedOut") {
    return [{ type: "LoggedOutState" }];
  }

  // Should never get here
  return exhaustiveCheck(urlMatch.location as never, true);
}

export function update(action: Action, state: State): readonly [State, Cmd<Action>?] {
  if (state.type === "ErrorState" || state.type === "UserExpired" || state.type === "UserNeedApproval") {
    if (action.type === "Logout") {
      return [{ type: "LoggedOutState" }, OidcEffectManager.logout()];
    }
    return [state];
  }
  switch (action.type) {
    case "Logout": {
      return [{ type: "LoggedOutState" }, OidcEffectManager.logout()];
    }
    case "AccessTokenRefreshed": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      const { user } = action;
      const activeUser = User.buildActiveUser(user, user.access_token);

      if (!User.isValidUser(activeUser)) {
        return createInvalidUserState(activeUser, state);
      }

      return [{ ...state, activeUser: activeUser }];
    }
    case "UserSessionChanged": {
      const { user } = action;
      switch (state.type) {
        case "LoadingDataState":
        case "LoadingUserSettingsState": {
          // If we got here then there was some error in the login flow
          return [
            {
              type: "ErrorState",
              reason: `Error Invalid Action UserSessionChanged during state ${state.type}`,
            },
          ];
        }
        case "LoggedInState": {
          // If we have no user then set state as logged out
          if (user === undefined) {
            return [{ type: "LoggedOutState" }];
          }
          return [state];
        }
        case "WaitingForUserSessionState": {
          //If we got an undefind user then there was some error in the login flow
          if (user === undefined) {
            return [{ type: "ErrorState", reason: "OIDC user is undefined" }];
          }
          const activeUser = User.buildActiveUser(user, user.access_token);
          if (!User.isValidUser(activeUser)) {
            return createInvalidUserState(activeUser, state);
          }

          // Set active user for sentry reporting, side-effect in reducer, not nice!!
          setUser(activeUser.email);

          // There are two main cases for how we got here and what url we should go to next
          // 1. We got a callback from login, in this case we are currently at /signin-callback and originally requested url is in user.state
          // 2. There was no callback (user found in local storage), so we are still at the originally requested url which is in state.initUrl
          // In both cases we can replace the current url with the originally requested url, becuase that will trigger an UrlChanged action
          // We use the user.state to check if it was a callback with original url saved in user.state
          // Since UrlChange handles invalid url we do not need to check valid url here

          const redirectState = user.state as RedirectState;
          const originalUrl =
            redirectState && redirectState.redirectUrl ? redirectState.redirectUrl : state.urlPath + state.urlQuery;
          // const graphQLQuery = graphQLQueryWithAuth(activeUser);
          // const gqlCmd = graphQLQuery<GQLOps.GetUserSettingsQuery, GQLOps.GetUserSettingsQueryVariables, Action>(
          //   UserSettings.userSettingsQuery,
          //   {},
          //   (data) => {
          //     return Action.UserSettingsRecieved(data);
          //   }
          // );
          const selectedLanguage = localStorage.getItem("lang") ?? "en";
          localStorage.setItem("lang", localStorage.getItem("lang") ?? "en");
          return [
            {
              type: "LoadingDataState",
              activeUser: activeUser,
              originalUrl: originalUrl,
              selectedLanguage: selectedLanguage,
              metaProductResponse: undefined,
              allProductsResponse: undefined,
            },
            Cmd.batch([loadMetaProduct(activeUser, selectedLanguage), loadAllProductsMap(activeUser)]),
          ];
        }
        case "LoggedOutState": {
          // Once logged out, anything else that happens is in error
          return [{ type: "ErrorState", reason: "LoggedOutState" }];
        }
        default:
          return exhaustiveCheck(state, true);
      }
    }
    case "UrlChanged": {
      switch (state.type) {
        case "LoggedInState": {
          const urlMatch = Routes.parseUrl(action.url.path + (action.url.query ?? ""));
          const cmds = [];
          // eslint-disable-next-line no-console

          if (urlMatch === undefined) {
            // If the current location is undefined then goto the default location
            const defaultLocation = Routes.RootLocation.MainLocation(Routes.MainLocation.StartPage());
            const defaultUrl = Routes.buildUrl(defaultLocation);

            // Safety-check that defaultUrl really has a match becuase otherwise we will be stuck in client-side redirect-loop
            const defaultMatch = Routes.parseUrl(defaultUrl);
            if (defaultMatch === undefined) {
              throw new Error("Default URL does not match a route.");
            }

            cmds.push(Navigation.replaceUrl<Action>(defaultUrl));

            return [{ ...state }, Cmd.batch(cmds)];
          } else {
            const newState = { ...state, urlMatch };
            switch (urlMatch.location.type) {
              case "LoginCallback":
                // LoginCallback can only be triggered in init() as it starts the application
                return [{ type: "ErrorState", reason: "LoginCallback error" }];
              case "LoggedOut":
                return [{ type: "LoggedOutState" }];

              case "MainLocation": {
                const [mainState, mainCmd] = initMainState({ ...newState, urlMatch }, newState.mainState);
                cmds.push(mainCmd);
                return [{ ...newState, urlMatch, mainState }, Cmd.batch(cmds)];
              }
              default:
                return exhaustiveCheck(urlMatch.location as never, true);
            }
          }
        }

        default:
          // In other states this action has no relevance
          return [state];
      }
    }
    case "UrlRequested":
      switch (action.urlRequest.type) {
        case "InternalUrlRequest":
          return [state, Navigation.pushUrl(action.urlRequest.url)];
        case "ExternalUrlRequest":
          return [state, Navigation.load(Navigation.toString(action.urlRequest.url))];
        default:
          return exhaustiveCheck(action.urlRequest);
      }

    // case "UserSettingsRecieved": {
    //   if (state.type !== "LoadingUserSettingsState") {
    //     return [state];
    //   }
    //   const userSettings = UserSettings.updateWithResponse(state.userSettings, action.response);
    //   return [
    //     {
    //       type: "LoadingDataState",
    //       activeUser: state.activeUser,
    //       originalUrl: state.originalUrl,
    //       userSettings: userSettings,
    //     },
    //     loadMetaProduct(state.activeUser, state),
    //   ];
    // }
    case "MetaProductRecieved": {
      if (state.type !== "LoadingDataState" && state.type !== "LoggedInState") {
        return [state];
      }
      const newState = { ...state, metaProductResponse: action.response };
      if (newState.type === "LoadingDataState") {
        if (!newState.allProductsResponse) {
          return [newState];
        }

        return buildLoggedInState(newState);
      } else {
        const translate = createTranslateFn(newState.metaProductResponse);
        return [{ ...newState, translate }];
      }
    }

    case "AllProductsRecieved": {
      if (state.type !== "LoadingDataState" && state.type !== "LoggedInState") {
        return [state];
      }
      const newState = { ...state, allProductsResponse: action.response };
      if (newState.type === "LoadingDataState") {
        if (!newState.metaProductResponse) {
          return [newState];
        }

        return buildLoggedInState(newState);
      } else {
        return [{ ...newState }];
      }
    }

    case "DispatchMain": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [updateMainState, updateMainCmd, sharedStateAction] = Main.update(
        action.action,
        state.mainState!,
        buildSharedState(state)
      );

      const newStateWithUpdatedMainState = { ...state, mainState: updateMainState };
      const [newState, sharedCmd] = handleSharedStateAction(newStateWithUpdatedMainState, sharedStateAction);
      if (newState.type !== "LoggedInState") {
        return [newState, sharedCmd];
      }

      if (state.selectedLanguage === newState.selectedLanguage) {
        return [newState, Cmd.batch<Action>([Cmd.map(Action.DispatchMain, updateMainCmd), sharedCmd])];
      } else {
        const [newMainState, newMainStateCmd] = initMainState(newState, undefined);
        return [{ ...newState, mainState: newMainState }, Cmd.batch<Action>([newMainStateCmd, sharedCmd])];
      }
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

export function buildSharedState(state: LoggedInState): SharedState.SharedState {
  return {
    activeUser: state.activeUser,
    translate: state.translate,
    getFieldFormat: state.getFieldFormat,
    getFieldFormats: state.getFieldFormats,
    // userSettings: state.userSettings,
    selectedLanguage: localStorage.getItem("lang") ?? state.selectedLanguage,
    graphQLProductQuery: graphQLProductQueryWithAuth(state.activeUser, clientConfig.promaster_marker),
    graphQLQuery: graphQLQueryWithAuth(state.activeUser),
    productById: state.productById,
    productByKey: state.productByKey,
    openProjectId: state.openProjectId,
  };
}

function handleSharedStateAction(
  state: LoggedInState,
  action: SharedState.SharedStateAction | undefined
): readonly [LoggedInState | LoggedOutState, Cmd<Action>?] {
  if (action === undefined) {
    return [state];
  }
  switch (action.type) {
    case "Logout": {
      return [{ type: "LoggedOutState" }, OidcEffectManager.logout()];
    }
    case "SetLanguage": {
      const selectedLanguage = action.newLang;
      localStorage.setItem("lang", selectedLanguage);
      return [
        {
          ...state,
          selectedLanguage: selectedLanguage,
          ...buildTranslationFunctionsState(state.metaProductResponse, selectedLanguage, state.selectedUnits),
        },
        Cmd.batch([loadMetaProduct(state.activeUser, selectedLanguage)]),
      ];
    }
    case "SetSelectedFormat": {
      const selectedUnits = { ...state.selectedUnits, [action.fieldName]: action.selectedFormat.unit.name };
      localStorage.setItem("selectedFormats", JSON.stringify(selectedUnits));
      return [
        {
          ...state,
          selectedUnits: selectedUnits,
          ...buildTranslationFunctionsState(state.metaProductResponse, state.selectedLanguage, selectedUnits),
        },
      ];
    }
    case "ClearFieldUnit": {
      return [state, undefined];
    }
    case "SetFieldUnit": {
      return [state, undefined];
    }
    case "SetOpenProject": {
      if (action.projectId) {
        localStorage.setItem(openProjectIdKey, action.projectId);
      } else {
        localStorage.removeItem(openProjectIdKey);
      }
      return [{ ...state, openProjectId: action.projectId }];
    }
    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function loadMetaProduct(activeUser: User.ActiveUser, language: string): Cmd<Action> {
  const graphQLProductQuery = graphQLProductQueryWithAuth(activeUser, clientConfig.promaster_marker);
  return graphQLProductQuery<GQLOps.RootViewQuery, GQLOps.RootViewQueryVariables, Action>(
    rootViewQuery,
    {
      language,
      metaProductId: clientConfig.promaster_meta_id,
    },
    (data) => {
      return Action.MetaProductRecieved(data);
    }
  );
}

function loadAllProductsMap(activeUser: User.ActiveUser): Cmd<Action> {
  const graphQLProductQuery = graphQLProductQueryWithAuth(activeUser, clientConfig.promaster_marker);
  return graphQLProductQuery<GQLOps.AllProductsQuery, GQLOps.AllProductsQueryVariables, Action>(
    allProductsQuery,
    {},
    (data) => {
      return Action.AllProductsRecieved(data);
    }
  );
}

// function createUserSettingMutationCmd(
//   activeUser: User.ActiveUser,
//   userSettings: UserSettings.UserSettings,
//   prevUserSettings: UserSettings.UserSettings
// ): Cmd<Action> | undefined {
//   const [gql, input] = UserSettings.createGqlMutations(userSettings, prevUserSettings);
//   if (input.length > 0) {
//     return graphQLQueryWithAuth(activeUser)<
//       GQLOps.UpdateUserSettingsMutation,
//       GQLOps.UpdateUserSettingsMutationVariables,
//       Action
//     >(
//       gql,
//       {
//         input: input,
//       },
//       Action.NoOp
//     );
//   } else {
//     return undefined;
//   }
// }

function initMainState(
  state: LoggedInState,
  prevMainState: Main.State | undefined
): readonly [Main.State | undefined, Cmd<Action> | undefined] {
  if (!state.urlMatch || state.urlMatch.location.type !== "MainLocation") {
    return [undefined, undefined];
  }
  let newState = state;
  if (localStorage.getItem("lang") && localStorage.getItem("lang") !== state.selectedLanguage) {
    newState = { ...newState, translate: createTranslateFn(state.metaProductResponse!) };
  }
  const [mainState, mainCmd] = Main.init(state.urlMatch.location.location, prevMainState, buildSharedState(newState));
  return [mainState, Cmd.map(Action.DispatchMain, mainCmd)];
}

function buildTranslationFunctionsState(
  response: GQLOps.RootViewQuery | undefined,
  selectedLanguage: string,
  selectedUnits: SelectedUnits
): Pick<LoggedInState, "getFieldFormat" | "getFieldFormats"> {
  const getFieldFormat = getFieldFormatFn(
    response?.meta?.modules.custom_tables.default_field_format || [],
    selectedLanguage,
    selectedUnits
  );
  const getFieldFormats = getFieldFormatsFn(
    response?.meta?.modules.custom_tables.default_field_format || [],
    selectedLanguage
  );
  return {
    getFieldFormat,
    getFieldFormats,
  };
}

function buildProductMapState(
  response: GQLOps.AllProductsQuery | undefined
): Pick<LoggedInState, "productById" | "productByKey"> {
  if (!response) {
    return { productById: {}, productByKey: {} };
  }
  const productById = Object.fromEntries(response.products.map((p) => [p.id, p]));
  const productByKey = Object.fromEntries(response.products.map((p) => [p.key, p]));
  return { productById, productByKey };
}

function buildLoggedInState(loadingDataState: LoadingDataState): readonly [LoggedInState, Cmd<Action>?] {
  if (!loadingDataState.allProductsResponse || !loadingDataState.metaProductResponse) {
    throw new Error("Missing product response");
  }

  const selectedUnitsJson = localStorage.getItem("selectedFormats");
  const openProjectId = localStorage.getItem(openProjectIdKey) || undefined;
  const selectedUnits = selectedUnitsJson ? JSON.parse(selectedUnitsJson) : {};
  const translate = createTranslateFn(loadingDataState.metaProductResponse);
  const allProducts = buildProductMapState(loadingDataState.allProductsResponse);

  return [
    {
      type: "LoggedInState",
      activeUser: loadingDataState.activeUser,
      metaProductResponse: loadingDataState.metaProductResponse,
      mainState: undefined,
      urlMatch: undefined,
      selectedLanguage: loadingDataState.selectedLanguage,
      selectedUnits: selectedUnits,
      translate,
      ...buildTranslationFunctionsState(
        loadingDataState.metaProductResponse,
        loadingDataState.selectedLanguage,
        selectedUnits
      ),
      ...allProducts,
      openProjectId,
    },
    Navigation.replaceUrl(loadingDataState.originalUrl),
  ];
}

function createInvalidUserState(
  activeUser: User.UserError,
  state: LoggedInState | WaitingForUserSessionState
): readonly [State, Cmd<Action>?] {
  return [{ ...state, type: "UserExpired" }];

  if (activeUser.type === "UserExpired") {
    return [{ ...state, type: "UserExpired" }];
  }
  if (activeUser.type === "UserNeedApproval") {
    return [{ ...state, type: "UserNeedApproval" }];
  }
  return [
    {
      ...state,
      type: "ErrorState",
      reason: activeUser.reason,
    },
  ];
}
