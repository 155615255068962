import { PropertyValueSet } from "@promaster-sdk/property";
import { CalculatorFrtCoil } from "..";
import * as Types from "./types";
import { memoize } from "../utils/memoize";
import * as Search from "../search";
import { calculateProduct } from "./calculate-product";

export const calculateSearchResult = memoize(_calculateSearchResult);

async function _calculateSearchResult(
  calcData: CalculatorFrtCoil.CalculationData,
  properties: PropertyValueSet.PropertyValueSet,
  searchResult: Search.Result
): Promise<Types.CalculationResultByKey> {
  const calcuations = new Map<string, CalculatorFrtCoil.CalculationResult>();
  const chunkSize = 10;
  for (let i = 0; i < searchResult.matches.length; i += chunkSize) {
    const chunk = searchResult.matches.slice(i, i + chunkSize);
    const promises = [];
    for (const m of chunk) {
      promises.push({
        selectionKey: m.selectionKey,
        result: await calculateProduct(calcData, properties, m.selection, m.productVariantRow, searchResult.query),
      });
    }
    const results = await Promise.all(promises);
    for (const { selectionKey, result } of results) {
      calcuations.set(selectionKey, result);
    }
  }
  return calcuations;
}
