import { PropertyFilter as PF, PropertyValueSet as PVS } from "@promaster-sdk/property";
import { FP } from "..";
import * as GQLOps from "../generated/generated-operations";
import { EhbUnitsLookup } from "../units";
import { ErrorResult } from "./types";
import { createErrorResult } from "./util";

export function getSupplierCode(
  orderingCodeTable: ReadonlyArray<OrderingCodeRows>,
  variant: PVS.PropertyValueSet
): string {
  let supplierCode = orderingCodeTable.map((r) => r.code as string).join("");

  if (supplierCode) {
    for (const [key, value] of Object.entries(variant)) {
      if (value.type !== "amount") {
        supplierCode = supplierCode.replace(`{${key}}`, value.value.toString());
      }
    }
  }
  return supplierCode ? supplierCode : "";
}
export type OrderingCodeRows = {
  readonly sort_no: number;
  readonly property_filter: string;
  readonly type: string;
  readonly code: string;
};
export function getFilteredOrderingCodeTable(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet
): FP.Result<ErrorResult, ReadonlyArray<OrderingCodeRows>> {
  if (!productQuery) {
    return createErrorResult("Product data is missing!", "ERROR");
  }

  if (!productQuery.product?.modules.ordering_code.code) {
    return createErrorResult("Error when fetching ordering codes.", "ERROR");
  }
  const orderincCodeTable = productQuery.product?.modules.ordering_code.code ?? [];
  return FP.Ok(
    filteredTable(orderincCodeTable, variant, (r) => ({
      sort_no: r.sort_no!,
      property_filter: r.property_filter!,
      type: r.type!,
      code: r.code!,
    }))
  );
}

export type VoltageTableRow = {
  readonly property_filter: string;
  readonly voltageV: number;
  readonly phases: number;
  readonly frequency: number;
};

export function getFilteredRowsFromVoltageTable(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet
): FP.Result<ErrorResult, VoltageTableRow> {
  if (!productQuery) {
    throw Error("Product data is missing!");
  }
  if (!productQuery.product?.modules.custom_tables.voltage) {
    return createErrorResult("Error when fetching custom table voltage", "ERROR");
  }
  const voltageTable = productQuery.product?.modules.custom_tables.voltage ?? [];

  return FP.Ok(
    filteredTable(voltageTable, variant, (r) => ({
      property_filter: r.property_filter!,
      voltageV: r.voltage!,
      phases: r.phases!,
      frequency: r.frequency!,
    }))[0]
  );
}

export type WeightTableRow = {
  readonly property_filter: string;
  readonly minAmp: number;
  readonly maxAmp: number;
  readonly terminalBlock: number;
  readonly contactors: number;
  readonly switches: number;
  readonly cable: number;
};

export function getFilteredRowsFromWeightTable(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet
): FP.Result<ErrorResult, ReadonlyArray<WeightTableRow>> {
  if (!productQuery) {
    throw Error("Product data is missing!");
  }
  if (!productQuery.product?.modules.custom_tables.weight) {
    return createErrorResult("Error when fetching custom table voltage", "ERROR");
  }
  const weightTable = productQuery.product?.modules.custom_tables.weight ?? [];

  return FP.Ok(
    filteredTable(weightTable, variant, (r) => ({
      property_filter: r.property_filter!,
      minAmp: r.min_amp!,
      maxAmp: r.max_amp!,
      terminalBlock: r.terminal_block!,
      contactors: r.contactors!,
      switches: r.switches!,
      cable: r.cable!,
    }))
  );
}

export type PriceTableInsulationRows = {
  readonly property_filter: string;
  readonly effect_from: number;
  readonly effect_to: number;
  readonly eur: number;
  readonly sek: number;
};

export type PriceTableAreaRows = {
  readonly property_filter: string;
  readonly area_from: number;
  readonly area_to: number;
  readonly eur: number;
  readonly sek: number;
};

export type PriceTablesModelsRows = {
  readonly property_filter: string;
  readonly power: number;
  readonly sek: number;
  readonly eur: number;
};

export type PriceTables = {
  readonly priceInsulation: ReadonlyArray<PriceTableInsulationRows>;
  readonly priceArea: ReadonlyArray<PriceTableAreaRows>;
  readonly priceModels: ReadonlyArray<PriceTablesModelsRows>;
  readonly priceAccessories: ReadonlyArray<PriceTableAccessoriesRows>;
};

export function getFilteredPriceTables(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet
): FP.Result<string, PriceTables> {
  if (!productQuery) {
    return FP.Err("Product data is missing!");
  }

  const priceTableModels = productQuery.product?.modules.custom_tables.models ?? [];
  const priceAreaTables = productQuery.product?.modules.custom_tables.area ?? [];
  const priceTableInsulation = productQuery.product?.modules.custom_tables.ri ?? [];

  const priceModelsRows: ReadonlyArray<PriceTablesModelsRows> = filteredTable(priceTableModels, variant, (r) => ({
    property_filter: r.property_filter!,
    power: r.power!,
    eur: r.eur!,
    sek: r.sek!,
  }));
  // TODO HANDLE VOLTAGES. 3x230 should use 1x230 AND 2x400 should use 3X400
  const priceAreaRows: ReadonlyArray<PriceTableAreaRows> = filteredTable(priceAreaTables, variant, (r) => ({
    property_filter: r.property_filter!,
    area_from: r.area_from!,
    area_to: r.area_to!,
    eur: r.eur!,
    sek: r.sek!,
  }));

  const priceInsulationRows: ReadonlyArray<PriceTableInsulationRows> = filteredTable(
    priceTableInsulation,
    variant,
    (r) => ({
      property_filter: r.property_filter!,
      effect_from: r.effect_from!,
      effect_to: r.effect_to!,
      eur: r.eur!,
      sek: r.sek!,
    })
  );

  const accessoriesTable = productQuery.product?.modules.custom_tables.accessories ?? [];

  const accessoriesRows: ReadonlyArray<PriceTableAccessoriesRows> = filteredTable(accessoriesTable, variant, (r) => ({
    property_filter: r.property_filter!,
    name: r.name!,
    sek: r.sek!,
    eur: r.eur!,
    incluedInHeaterPrice: r.include_in_heaterprice === "yes" ? true : false,
    text_key: r.text_key!,
  }));

  return FP.Ok({
    priceModels: priceModelsRows,
    priceArea: priceAreaRows,
    priceInsulation: priceInsulationRows,
    priceAccessories: accessoriesRows,
  });
}

export type ElementTableRows = {
  readonly length: number;
  readonly artNr: string;
  readonly power: number;
  readonly resistance: number;
  readonly type: string;
  readonly voltage: number;
  readonly yteffekt: number;
};

export function getFilteredElementTable(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet
): FP.Result<string, ReadonlyArray<ElementTableRows>> {
  if (!productQuery) {
    throw Error("Product data is missing!");
  }
  const elementTable = productQuery.product?.modules.custom_tables.element ?? [];

  const elementRows: ReadonlyArray<ElementTableRows> = filteredTable(elementTable, variant, (r) => ({
    property_filter: r.property_filter!,
    length: r.length!,
    artNr: r.artnr!,
    power: r.power!,
    resistance: r.resistance!,
    type: r.type!,
    voltage: r.voltage!,
    yteffekt: r.yteffekt!,
  }));

  return FP.Ok(elementRows);
}

export type PriceTableAccessoriesRows = {
  readonly property_filter: string;
  readonly name: string;
  readonly sek: number;
  readonly eur: number;
  readonly incluedInHeaterPrice: boolean;
  readonly text_key: string;
};
export function getFilteredAccesoriesTable(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet
): FP.Result<string, ReadonlyArray<PriceTableAccessoriesRows>> {
  if (!productQuery) {
    throw Error("Product data is missing!");
  }
  const accessoriesTable = productQuery.product?.modules.custom_tables.accessories ?? [];

  const accessoriesRows: ReadonlyArray<PriceTableAccessoriesRows> = filteredTable(accessoriesTable, variant, (r) => ({
    property_filter: r.property_filter!,
    name: r.name!,
    sek: r.sek!,
    eur: r.eur!,
    incluedInHeaterPrice: r.include_in_heaterprice === "yes" ? true : false,
    text_key: r.text_key!,
  }));

  return FP.Ok(accessoriesRows);
}
export type CircuitDiagramTableRows = {
  readonly property_filter: string;
  readonly amp_from: number;
  readonly amp_to: number;
  readonly document: string;
  readonly file_name: string;
};

export function getFilteredCircuitDiagramTable(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet
): FP.Result<string, ReadonlyArray<CircuitDiagramTableRows>> {
  if (!productQuery) {
    throw Error("Product data is missing!");
  }
  const circuitDiagram = productQuery.product?.modules.custom_tables.circuit_diagram ?? [];

  const circuitDiagramRows: ReadonlyArray<CircuitDiagramTableRows> = filteredTable(circuitDiagram, variant, (r) => ({
    property_filter: r.property_filter!,
    amp_from: r.amp_from!,
    amp_to: r.amp_to!,
    document: r.document!,
    file_name: r.file_name!,
  }));

  return FP.Ok(circuitDiagramRows);
}

export type DocumentTableRows = {
  readonly sort_no: number;
  readonly property_filter: string;
  readonly type: String;
  readonly name: string;
  readonly language: string;
  readonly document: string;
  readonly file_name: string;
};
export function getFilteredDocumentsTable(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet
): FP.Result<string, ReadonlyArray<DocumentTableRows>> {
  if (!productQuery) {
    return FP.Err("Product data is missing!");
  }

  if (!productQuery.product?.modules.documents.document) {
    return FP.Err("Error when fetching documents codes.");
  }
  const documentsTable = productQuery.product?.modules.documents.document ?? [];
  return FP.Ok(
    filteredTable(documentsTable, variant, (r) => ({
      sort_no: r.sort_no!,
      property_filter: r.property_filter!,
      type: r.type!,
      name: r.name!,
      language: r.language!,
      document: r.document!,
      file_name: r.file_name!,
    }))
  );
}

export type ImageTableRows = {
  readonly property_filter: string;
  readonly image: string;
  readonly name: string;
  readonly file_name: string;
  readonly type: string;
};
export function getFilteredImagesTable(
  productQuery: GQLOps.ProductQuery | undefined,
  variant: PVS.PropertyValueSet,
  filterTable: boolean = true
): FP.Result<string, ReadonlyArray<ImageTableRows>> {
  if (!productQuery) {
    return FP.Err("Product data is missing!");
  }

  if (!productQuery.product?.modules.images) {
    return FP.Err("Error when fetching images codes.");
  }
  const imagesTable = productQuery.product?.modules.images.image ?? [];

  return filterTable
    ? FP.Ok(
        filteredTable(imagesTable, variant, (r) => ({
          property_filter: r.property_filter!,
          image: r.image!,
          name: r.name!,
          file_name: r.file_name!,
          type: r.type!,
        }))
      )
    : FP.Ok(
        imagesTable.map((r) => ({
          property_filter: r.property_filter!,
          image: r.image!,
          name: r.name!,
          file_name: r.file_name!,
          type: r.type!,
        }))
      );
}
interface PFTableItem {
  readonly property_filter: string | null | undefined;
}

export function filteredTable<T extends PFTableItem, K>(
  table: ReadonlyArray<T>,
  variant: PVS.PropertyValueSet,
  mapFn: (item: T) => K
): ReadonlyArray<K> {
  return table
    .filter((r) => PF.isValid(variant, PF.fromStringOrEmpty((r.property_filter ?? "") as string, EhbUnitsLookup)))
    .map(mapFn);
}

export type GlandEntrySizeTableRows = {
  readonly from_ampere: number;
  readonly to_ampere: number;
  readonly diameter: string;
};

export function getFilteredGlandEntrySizeTable(
  productQuery: GQLOps.ProductQuery | undefined
): FP.Result<string, ReadonlyArray<GlandEntrySizeTableRows>> {
  if (!productQuery) {
    throw Error("Product data is missing!");
  }
  const glandEntrySizeTable = productQuery.product?.modules.custom_tables.gland_entry_size ?? [];
  const glandEntrySizeRows: ReadonlyArray<GlandEntrySizeTableRows> = glandEntrySizeTable
    .filter((r) => r.from_amp !== null && r.to_amp !== null)
    .map((r) => ({ from_ampere: r.from_amp!, to_ampere: r.to_amp!, diameter: r.diameter || "", property_filter: "" }));

  return FP.Ok(glandEntrySizeRows);
}
